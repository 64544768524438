import {
  asyncWithLDProvider,
  AsyncProviderConfig,
} from 'launchdarkly-react-client-sdk'

import { TenantContext } from '@lib/feature-flags/contexts/base-context'
import SystemHelper from '@lib/helpers/system.helper'
import { ProviderComponent } from '@lib/engine-types'

const BOOTSTRAP_OPTION = 'localStorage'

function getContext(currentTenantName: string) {
  const context = new TenantContext(currentTenantName)
  return {
    kind: context.getKind(),
    key: context.getKey(),
  }
}
export async function initializeLDProvider(): Promise<ProviderComponent> {
  const currentTenantName = SystemHelper.getCurrentTenantName()
  const context = getContext(currentTenantName)

  const ldApiKey = await fetch('/api/launchdarkly/ld')
  const clientSideIDResp = await ldApiKey.json()

  const provider = await asyncWithLDProvider({
    clientSideID: clientSideIDResp.launchdarklyApi,
    context,
    options: {
      bootstrap: BOOTSTRAP_OPTION,
    },
    error: (message: string) => {
      const timestamp = new Date().toISOString()
      console.error(`[${timestamp}] Error: ${message}`)
      console.error('Operation: Initializing LaunchDarkly Provider')
    },
  } as AsyncProviderConfig)
  return provider
}
