import { DefinedDateRangeTuple, Nullable } from '@lib/engine-types'
import CoveredByBackupPoliciesAssets from '@lib/interfaces/covered-by-backup-policies-assets.interface'
import RecoverabilityStatsInterface from '@lib/interfaces/recoverability-stats.interface'
import { AggregatedStatisticsList } from '@lib/interfaces/statistics.interface'
import CostAdvantagesModel from '@lib/models/cost-advantages.model'
import JobModel from '@lib/models/job.model'
import BackupComplianceInterface from '@lib/interfaces/backup-compliance.interface'
import ValueInterface from '@lib/interfaces/value.interface'

// eslint-disable-next-line import/no-extraneous-dependencies
import { InventoryModel } from 'blues-corejs/dist'

export const REQUEST_DASHBOARD_ACCOUNT_STATISTICS =
  'REQUEST_DASHBOARD_ACCOUNT_STATISTICS'
export const SET_DASHBOARD_ACCOUNT_STATISTICS =
  'SET_DASHBOARD_ACCOUNT_STATISTICS'

export const REQUEST_DASHBOARD_TOTAL_ASSETS = 'REQUEST_DASHBOARD_TOTAL_ASSETS'
export const SET_DASHBOARD_TOTAL_ASSETS = 'SET_DASHBOARD_TOTAL_ASSETS'
export const SET_DASHBOARD_TOTAL_ASSETS_ERROR_MESSAGE =
  'SET_DASHBOARD_TOTAL_ASSETS_ERROR_MESSAGE'

export const SET_DASHBOARD_UNPROTECTED_ASSETS =
  'SET_DASHBOARD_UNPROTECTED_ASSETS'
export const SET_DASHBOARD_UNPROTECTED_ASSETS_ERROR_MESSAGE =
  'SET_DASHBOARD_UNPROTECTED_ASSETS_ERROR_MESSAGE'

export const REQUEST_DASHBOARD_PROTECTED_ASSETS =
  'REQUEST_DASHBOARD_PROTECTED_ASSETS'
export const SET_DASHBOARD_PROTECTED_ASSETS = 'SET_DASHBOARD_PROTECTED_ASSETS'
export const SET_DASHBOARD_PROTECTED_ASSETS_ERROR_MESSAGE =
  'SET_DASHBOARD_PROTECTED_ASSETS_ERROR_MESSAGE'

export const REQUEST_DASHBOARD_RECOVERABILITY_INDEX =
  'REQUEST_DASHBOARD_RECOVERABILITY_INDEX'
export const SET_DASHBOARD_RECOVERABILITY_INDEX =
  'SET_DASHBOARD_RECOVERABILITY_INDEX'
export const SET_DASHBOARD_RECOVERABILITY_INDEX_ERROR_MESSAGE =
  'SET_DASHBOARD_RECOVERABILITY_INDEX_ERROR_MESSAGE'

export const REQUEST_DASHBOARD_UNHEALTHY_RP = 'REQUEST_DASHBOARD_UNHEALTHY_RP'
export const SET_DASHBOARD_UNHEALTHY_RP = 'SET_DASHBOARD_UNHEALTHY_RP'
export const SET_DASHBOARD_UNHEALTHY_RP_ERROR_MESSAGE =
  'SET_DASHBOARD_UNHEALTHY_RP_ERROR_MESSAGE'

export const REQUEST_DASHBOARD_ASSETS_UNDER_PROTECTION_PERCENT =
  'REQUEST_DASHBOARD_ASSETS_UNDER_PROTECTION_PERCENT'
export const SET_DASHBOARD_ASSETS_UNDER_PROTECTION_PERCENT =
  'SET_DASHBOARD_ASSETS_UNDER_PROTECTION_PERCENT'
export const SET_DASHBOARD_ASSETS_UNDER_PROTECTION_PERCENT_ERROR_MESSAGE =
  'SET_DASHBOARD_ASSETS_UNDER_PROTECTION_PERCENT_ERROR_MESSAGE'
export const SET_RECOVERABILITY_STATS_ERROR_MESSAGE =
  'SET_RECOVERABILITY_STATS_ERROR_MESSAGE'

export const SET_DASHBOARD_JOBS_REQUEST_PARAMETERS =
  'SET_DASHBOARD_JOBS_REQUEST_PARAMETERS'
export const SET_DASHBOARD_BACKUP_JOBS_LIST = 'SET_DASHBOARD_BACKUP_JOBS_LIST'

export const SET_COST_ADVANTAGES = 'SET_COST_ADVANTAGES'

export const RECOVERABILITY_STATS = 'RECOVERABILITY_STATS'
export const SET_RECOVERABILITY_STATS = 'SET_RECOVERABILITY_STATS'

export const REQUEST_COVERED_BY_BACKUP_POLICIES_ASSETS =
  'REQUEST_COVERED_BY_BACKUP_POLICIES_ASSETS'
export const SET_COVERED_BY_BACKUP_POLICIES_ASSETS =
  'SET_COVERED_BY_BACKUP_POLICIES_ASSETS'
export const SET_COVERED_BY_BACKUP_POLICIES_ASSETS_ERROR_MESSAGE =
  'SET_COVERED_BY_BACKUP_POLICIES_ASSETS_ERROR_MESSAGE'

export const REQUEST_BACKUP_COMPLIANCE_SCORE = 'REQUEST_BACKUP_COMPLIANCE_SCORE'
export const SET_BACKUP_COMPLIANCE_SCORE = 'SET_BACKUP_COMPLIANCE_SCORE'

export const REQUEST_DASHBOARD_DATA = 'REQUEST_DASHBOARD_DATA'
export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA'
export const SET_VOLUME_WITH_SNAPSHOTS_SELECTED_FILTERS =
  'SET_VOLUME_WITH_SNAPSHOTS_SELECTED_FILTERS'
export const SET_VOLUME_WITH_SNAPSHOTS_SELECT_SEARCH =
  'SET_VOLUME_WITH_SNAPSHOTS_SELECT_SEARCH'
export const SET_VOLUME_INVENTORY_SELECTED_FILTERS =
  'SET_VOLUME_INVENTORY_SELECTED_FILTERS'
export const SET_VOLUME_INVENTORY_SELECT_SEARCH =
  'SET_VOLUME_INVENTORY_SELECT_SEARCH'
export const SET_VOLUME_INVENTORY_SELECT_SEARCH_V3 =
  'SET_VOLUME_INVENTORY_SELECT_SEARCH_V3'
export const SET_VOLUME_INVENTORY_SELECTED_FILTERS_V3 =
  'SET_VOLUME_INVENTORY_SELECTED_FILTERS_V3'
export const SET_SNAPSHOT_VULNERABILITY_SELECTED_FILTERS =
  'SET_SNAPSHOT_VULNERABILITY_SELECTED_FILTERS'
export const SET_SNAPSHOT_VULNERABILITY_SELECT_SEARCH =
  'SET_SNAPSHOT_VULNERABILITY_SELECT_SEARCH'

export const SET_EBS_VULNERABILITIES_SELECTED_FILTERS =
  'SET_EBS_VULNERABILITIES_SELECTED_FILTERS'
export const SET_EBS_VULNERABILITIES_SELECT_SEARCH =
  'SET_EBS_VULNERABILITIES_SELECT_SEARCH'

export const SET_QUARANTINED_VOLUMES_SELECTED_FILTERS =
  'SET_QUARANTINED_VOLUMES_SELECTED_FILTERS'
export const SET_QUARANTINED_VOLUMES_SELECT_SEARCH =
  'SET_QUARANTINED_VOLUMES_SELECT_SEARCH'

export const SET_SNAPSHOTS_INVENTORY_SELECTED_FILTERS =
  'SET_SNAPSHOTS_INVENTORY_SELECTED_FILTERS'
export const SET_SNAPSHOTS_INVENTORY_SELECT_SEARCH =
  'SET_SNAPSHOTS_INVENTORY_SELECT_SEARCH'
export const SET_EC2_INVENTORY_SELECTED_FILTERS =
  'SET_EC2_INVENTORY_SELECTED_FILTERS'
export const SET_EC2_INVENTORY_SELECTED_FILTERS_V3 =
  'SET_EC2_INVENTORY_SELECTED_FILTERS_V3'
export const SET_EC2_INVENTORY_SELECT_SEARCH = 'SET_EC2_INVENTORY_SELECT_SEARCH'

export const SET_EC2_INVENTORY_SELECT_SEARCH_V3 =
  'SET_EC2_INVENTORY_SELECT_SEARCH_V3'

export const SET_INSTANCES_THREATS_FILTERS = 'SET_INSTANCES_THREATS_FILTERS'

export const SET_VOLUMES_THREADS_FILTERS = 'SET_VOLUMES_THREADS_FILTERS'

export const SET_ASSETS_EBS_VULNERABILITIES_SELECTED_FILTERS =
  'SET_ASSETS_EBS_VULNERABILITIES_SELECTED_FILTERS'

export const SET_SNAPSHOTS_VULNERABILITIES_SELECTED_FILTERS =
  'SET_SNAPSHOTS_VULNERABILITIES_SELECTED_FILTERS'

export const SET_RECOVERY_EXPOSURE_FILTERS = 'SET_RECOVERY_EXPOSURE_FILTERS'

export const SET_S3_BUCKET_INVENTORY_SELECTED_FILTERS =
  'SET_S3_BUCKET_INVENTORY_SELECTED_FILTERS_V3'

export const requestAccountDashboardStatistics = (
  payload: DefinedDateRangeTuple
) => ({
  type: REQUEST_DASHBOARD_ACCOUNT_STATISTICS,
  payload,
})

export const requestTotalAssetsDashboardStatistics = () => ({
  type: REQUEST_DASHBOARD_TOTAL_ASSETS,
})

export const requestProtectedAssetsDashboardStatistics = () => ({
  type: REQUEST_DASHBOARD_PROTECTED_ASSETS,
})

export const requestRecoverabilityIndexDashboardStatistics = () => ({
  type: REQUEST_DASHBOARD_RECOVERABILITY_INDEX,
})

export const requestAssetsUnderProtectionPercentDashboardStatistics = () => ({
  type: REQUEST_DASHBOARD_ASSETS_UNDER_PROTECTION_PERCENT,
})

export const requestUnhealthyRPDashboardStatistics = () => ({
  type: REQUEST_DASHBOARD_UNHEALTHY_RP,
})

export const requestRecoverabilityStats = () => ({
  type: RECOVERABILITY_STATS,
})

export const requestCoveredByBackupPoliciesAssets = () => ({
  type: REQUEST_COVERED_BY_BACKUP_POLICIES_ASSETS,
})

export const requestBackupComplianceScore = () => ({
  type: REQUEST_BACKUP_COMPLIANCE_SCORE,
})

export const requestDashboardData = () => ({
  type: REQUEST_DASHBOARD_DATA,
})

export const setAccountDashboardStatistics = (
  payload: Nullable<AggregatedStatisticsList>
) => ({
  type: SET_DASHBOARD_ACCOUNT_STATISTICS,
  payload,
})

export const setTotalAssetsDashboardStatistics = (
  payload: Nullable<number>
) => ({
  type: SET_DASHBOARD_TOTAL_ASSETS,
  payload,
})

export const setUnprotectedAssetsDashboardStatistics = (
  payload: Nullable<number>
) => ({
  type: SET_DASHBOARD_UNPROTECTED_ASSETS,
  payload,
})

export const setProtectedAssetsDashboardStatistics = (
  payload: Nullable<number>
) => ({
  type: SET_DASHBOARD_PROTECTED_ASSETS,
  payload,
})

export const setRecoverabilityIndexDashboardStatistics = (
  payload: Nullable<number>
) => ({
  type: SET_DASHBOARD_RECOVERABILITY_INDEX,
  payload,
})

export const setAssetsUnderProtectionPercentDashboardStatistics = (
  payload: Nullable<number>
) => ({
  type: SET_DASHBOARD_ASSETS_UNDER_PROTECTION_PERCENT,
  payload,
})

export const setUnhealthyRPDashboardStatistics = (
  payload: Nullable<number>
) => ({
  type: SET_DASHBOARD_UNHEALTHY_RP,
  payload,
})

export const setTotalAssetsDashboardErrorMessage = (
  payload: Nullable<string>
) => ({
  type: SET_DASHBOARD_TOTAL_ASSETS_ERROR_MESSAGE,
  payload,
})

export const setUnprotectedAssetsDashboardErrorMessage = (
  payload: Nullable<string>
) => ({
  type: SET_DASHBOARD_UNPROTECTED_ASSETS_ERROR_MESSAGE,
  payload,
})

export const setProtectedAssetsDashboardErrorMessage = (
  payload: Nullable<string>
) => ({
  type: SET_DASHBOARD_PROTECTED_ASSETS_ERROR_MESSAGE,
  payload,
})

export const setRecoverabilityIndexDashboardErrorMessage = (
  payload: Nullable<string>
) => ({
  type: SET_DASHBOARD_RECOVERABILITY_INDEX_ERROR_MESSAGE,
  payload,
})

export const setAssetsUnderProtectionPercentDashboardErrorMessage = (
  payload: Nullable<string>
) => ({
  type: SET_DASHBOARD_ASSETS_UNDER_PROTECTION_PERCENT_ERROR_MESSAGE,
  payload,
})

export const setUnhealthyRPDashboardErrorMessage = (
  payload: Nullable<string>
) => ({
  type: SET_DASHBOARD_UNHEALTHY_RP_ERROR_MESSAGE,
  payload,
})

export const setRecoverabilityStatsErrorMessage = (
  payload: Nullable<string>
) => ({
  type: SET_RECOVERABILITY_STATS_ERROR_MESSAGE,
  payload,
})

export const setDashboardBackupJobList = (payload: Array<JobModel>) => ({
  type: SET_DASHBOARD_BACKUP_JOBS_LIST,
  payload,
})

export const setCostAdvantages = (costAdvantages: CostAdvantagesModel) => ({
  type: SET_COST_ADVANTAGES,
  payload: costAdvantages,
})

export const setrecoverabilityStats = (
  payload: Nullable<RecoverabilityStatsInterface>
) => ({
  type: SET_RECOVERABILITY_STATS,
  payload,
})

export const setCoveredByBackupPoliciesAssets = (
  payload: Nullable<CoveredByBackupPoliciesAssets>
) => ({
  type: SET_COVERED_BY_BACKUP_POLICIES_ASSETS,
  payload,
})

export const setCoveredByBackupPoliciesAssetsErrorMessage = (
  payload: Nullable<string>
) => ({
  type: SET_COVERED_BY_BACKUP_POLICIES_ASSETS_ERROR_MESSAGE,
  payload,
})

export const setBackupComplianceScore = (
  payload: BackupComplianceInterface
) => ({
  type: SET_BACKUP_COMPLIANCE_SCORE,
  payload,
})

export const setDashboardData = (payload: Nullable<InventoryModel>) => ({
  type: SET_DASHBOARD_DATA,
  payload,
})

export const setVolumeWithSnapshotsSelectedFilters = (
  payload: Array<ValueInterface>
) => ({
  type: SET_VOLUME_WITH_SNAPSHOTS_SELECTED_FILTERS,
  payload,
})

export const setVolumeWithSnapshotsSelectSearch = (payload: string) => ({
  type: SET_VOLUME_WITH_SNAPSHOTS_SELECT_SEARCH,
  payload,
})

export const setVolumeInventorySelectedFilters = (
  payload: Array<ValueInterface>
) => ({
  type: SET_VOLUME_INVENTORY_SELECTED_FILTERS,
  payload,
})

export const setVolumeInventorySelectedFiltersV3 = (
  payload: Array<ValueInterface>
) => ({
  type: SET_VOLUME_INVENTORY_SELECTED_FILTERS_V3,
  payload,
})

export const setVolumeInventorySelectSearch = (payload: string) => ({
  type: SET_VOLUME_INVENTORY_SELECT_SEARCH,
  payload,
})

export const setVolumeInventorySelectSearchV3 = (payload: string) => ({
  type: SET_VOLUME_INVENTORY_SELECT_SEARCH_V3,
  payload,
})

export const setSnapshotVulnerabilitiesSelectedFilters = (
  payload: Array<ValueInterface>
) => ({
  type: SET_SNAPSHOT_VULNERABILITY_SELECTED_FILTERS,
  payload,
})

export const setSnapshotVulnerabilitiesSelectSearch = (payload: string) => ({
  type: SET_SNAPSHOT_VULNERABILITY_SELECT_SEARCH,
  payload,
})

export const setEbsVulnerabilitiesSelectedFilters = (
  payload: Array<ValueInterface>
) => ({
  type: SET_EBS_VULNERABILITIES_SELECTED_FILTERS,
  payload,
})

export const setEbsVulnerabilitiesSelectedSearch = (payload: string) => ({
  type: SET_EBS_VULNERABILITIES_SELECT_SEARCH,
  payload,
})

export const setQuarantinedVolumesSelectedFilters = (
  payload: Array<ValueInterface>
) => ({
  type: SET_QUARANTINED_VOLUMES_SELECTED_FILTERS,
  payload,
})

export const setQuarantinedVolumesSelectedSearch = (payload: string) => ({
  type: SET_QUARANTINED_VOLUMES_SELECT_SEARCH,
  payload,
})

export const setSnapshotsInventorySelectedFilters = (
  payload: Array<ValueInterface>
) => ({
  type: SET_SNAPSHOTS_INVENTORY_SELECTED_FILTERS,
  payload,
})

export const setSnapshotsInventorySelectedSearch = (payload: string) => ({
  type: SET_SNAPSHOTS_INVENTORY_SELECT_SEARCH,
  payload,
})

export const setEc2InventorySelectedFilters = (
  payload: Array<ValueInterface>
) => ({
  type: SET_EC2_INVENTORY_SELECTED_FILTERS,
  payload,
})

export const setEc2InventorySelectedFiltersV3 = (
  payload: Array<ValueInterface>
) => ({
  type: SET_EC2_INVENTORY_SELECTED_FILTERS_V3,
  payload,
})

export const setEc2InventorySelectedSearch = (payload: string) => ({
  type: SET_EC2_INVENTORY_SELECT_SEARCH,
  payload,
})

export const setEc2InventorySelectedSearchV3 = (payload: string) => ({
  type: SET_EC2_INVENTORY_SELECT_SEARCH_V3,
  payload,
})

export const setInstancesThreatsFilters = (payload: Array<ValueInterface>) => ({
  type: SET_INSTANCES_THREATS_FILTERS,
  payload,
})

export const setVolumesThreatsFilters = (payload: Array<ValueInterface>) => ({
  type: SET_VOLUMES_THREADS_FILTERS,
  payload,
})

export const setAssetsEbsVulnerabilitiesFilters = (
  payload: Array<ValueInterface>
) => ({
  type: SET_ASSETS_EBS_VULNERABILITIES_SELECTED_FILTERS,
  payload,
})

export const setSnapshotsVulnerabilitiesFilters = (
  payload: Array<ValueInterface>
) => ({
  type: SET_SNAPSHOTS_VULNERABILITIES_SELECTED_FILTERS,
  payload,
})

export const setRecoveryExposureFilters = (payload: Array<ValueInterface>) => ({
  type: SET_RECOVERY_EXPOSURE_FILTERS,
  payload,
})

export const setS3BucketInventorySelectedFilters = (
  payload: Array<ValueInterface>
) => ({
  type: SET_S3_BUCKET_INVENTORY_SELECTED_FILTERS,
  payload,
})
