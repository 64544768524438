/* eslint-disable import/no-extraneous-dependencies */
import { LiveAssetsState } from '@store/states/list-live-assets.state'
import { BackupProcessor } from '@lib/clients/assets/backup-processor'
import { ManagementView } from '@lib/models/management-view'
import { ElastioRecoveryPoint, TenantStatisticsModel } from 'blues-corejs/dist'
import { Map as ProtobufMap } from 'google-protobuf'

export const INITIAL_STATE: LiveAssetsState = {
  managementView: new ManagementView({
    ec2Instances: [],
    ebsVolumes: [],
    ebsSnapshots: [],
    s3Buckets: [],
    filesystems: [],
    genericHosts: [],
    tenantStatistics: new TenantStatisticsModel({
      fsCheckStats: [],
      ransomwareStats: [],
      malwareStats: [],
      vaultStatistics: [],
    }),
  }),
  firstBackups: new BackupProcessor(new ProtobufMap([])),
  lastBackups: new BackupProcessor(new ProtobufMap([])),
  scans: [],
  threats: [],
  lastEbsSnapshots: [],
  tenantStatistics: new TenantStatisticsModel({
    fsCheckStats: [],
    ransomwareStats: [],
    malwareStats: [],
    vaultStatistics: [],
  }),
  lastElastioBackupsMap: new Map<string, ElastioRecoveryPoint>(),
  slice: {},
  isLiveAssetsLoading: false,
  isTenantStatisticsLoading: false,
}
