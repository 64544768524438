/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import {
  AWSBRecoveryPoint,
  Backup,
  EBSSnapshot,
  ElastioRecoveryPoint,
} from 'blues-corejs/dist'
import { StyledBackupSourceContainer, StyledIconContainer } from './styles'
import ElastioLogoBlueIcon from '@inline-img/general/elastio-blue-icon'
import AwsLogoIcon from '@inline-img/general/aws-logo-icon'
import { SystemHelper } from '@lib/helpers'
import { OvaBackup } from 'blues-corejs/dist/models'
import { OvaBackupProvider } from 'blues-corejs/dist/models/ova-backup-provider'
import {
  CohesityIcon,
  CommvaultIcon,
  NetBackupIcon,
  RubrikIcon,
  VeeamIcon,
} from '@inline-img/general/ova/backup'

interface Props {
  backup: Backup
}

const BACKUP_SOURCE_ELASTIO_NAME = 'Elastio'
const BACKUP_SOURCE_AWS_NAME = 'AWS Backup'
const BACKUP_SOURCE_EBS_SNAPSHOT_NAME = 'EBS Snapshot'

function computeOvaBackupSource(backup: OvaBackup) {
  switch (backup.provider) {
    case OvaBackupProvider.COHESITY:
      return {
        icon: CohesityIcon,
        text: 'Cohesity',
      }
    case OvaBackupProvider.VEEAM:
      return {
        icon: VeeamIcon,
        text: 'Veeam',
      }
    case OvaBackupProvider.COMMVAULT:
      return {
        icon: CommvaultIcon,
        text: 'Commvault',
      }
    case OvaBackupProvider.VERITAS_NET_BACKUP: {
      return {
        icon: NetBackupIcon,
        text: 'VeritasNetBackup',
      }
    }
    case OvaBackupProvider.RUBRIK: {
      return {
        icon: RubrikIcon,
        text: 'Rubrik',
      }
    }
    default:
      SystemHelper.sendSentryIfProd(
        `BackupSourceCell: Unknown OvaBackup provider: ${backup.provider}`
      )
      return null
  }
}

function computeBackupSource(backup: Backup) {
  if (backup instanceof ElastioRecoveryPoint) {
    return {
      icon: ElastioLogoBlueIcon,
      text: BACKUP_SOURCE_ELASTIO_NAME,
    }
  }

  if (backup instanceof AWSBRecoveryPoint) {
    return {
      icon: AwsLogoIcon,
      text: BACKUP_SOURCE_AWS_NAME,
    }
  }

  if (backup instanceof EBSSnapshot) {
    return {
      icon: AwsLogoIcon,
      text: BACKUP_SOURCE_EBS_SNAPSHOT_NAME,
    }
  }

  if (backup instanceof OvaBackup) {
    return computeOvaBackupSource(backup)
  }

  SystemHelper.sendSentryIfProd('BackupSourceCell: Unknown backup type')
  return null
}

export function BackupSourceCell({ backup }: Props) {
  const backupFromMap = computeBackupSource(backup)

  if (!backupFromMap) {
    return null
  }

  const { icon: Icon, text } = backupFromMap

  return (
    <StyledBackupSourceContainer>
      <StyledIconContainer>
        <Icon />
      </StyledIconContainer>
      {text}
    </StyledBackupSourceContainer>
  )
}
