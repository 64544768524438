enum PreloaderConstants {
  EMPTY,
  ADD_GROUP,
  EDIT_GROUP,
  INVITE_USER,
  UPDATE_USER,
  ACCOUNT_PROFILE,
  ACCOUNT_SECURITY,
  REQUEST_TOKEN_LIST,
  REQUEST_REVOKED_TOKEN_LIST,
  REQUEST_TOKEN,
  REQUEST_USER,
  UPDATE_TOKEN,
  ISSUE_TOKEN,
  REVOKE_TOKEN,
  REQUEST_POSSIBLE_SCOPES,
  ADD_TENANT,
  REQUEST_DEPLOYMENT_FILES,
  GET_ALL_RED_STACKS,
  REQUEST_IF_RED_STACK_EXIST,
  REQUEST_USERS_LIST,
  REMOVE_USER,
  DISABLE_USER,
  REQUEST_GROUPS_LIST,
  REMOVE_GROUP,
  REQUEST_UPDATE_USER,
  REQUEST_ASSETS_SELECT,
  REQUEST_ASSETS_SELECT_ALL_FILTERS_V3,
  REQUEST_RECOVERY_POINT_LIST,
  REQUEST_RECOVERY_POINT_ASSET,
  UPDATE_PASSWORD,
  REQUEST_SESSIONS,
  REQUEST_VAULT_LIST,
  REQUEST_ACCOUNT_VAULT_LIST,
  CREATE_VAULT,
  REQUEST_ACCOUNT_RED_STACKS,
  DEACTIVATE_SOURCES,
  ACTIVATE_SOURCES,
  DELETE_SOURCES,
  REQUEST_POSSIBLE_VPC_LIST,
  REQUEST_AWS_ACCOUNT_INFO_LIST,
  REQUEST_CF_LINK,
  REQUEST_POSSIBLE_ROLES,
  SUBMIT_SOURCE_ID,
  INIT_SOURCE_ID,
  RESEND_INVITE_USER,
  UPDATE_ROLE,
  DELETE_ROLE,
  SAVE_TENANT_JOBS_TASKS_IN_SETTINGS,
  SAVE_CLOUD_CONNECTOR_JOBS_TASKS_IN_SETTINGS,
  REQUEST_CFN,
  REQUEST_JOBS_LIST,
  REQUEST_POSSIBLE_JOBS_FILTERS,
  REQUEST_ALL_ACTIVE_RED_STACKS,
  REQUEST_RESTORE_START,
  SET_VAULT_AS_DEFAULT,
  REQUEST_DASHBOARD_STATISTICS,
  REQUEST_POSSIBLE_RP_FILTERS,
  REQUEST_IS_MFA_ENABLED,
  UPDATE_MFA_ENABLED,
  RESET_MFA,
  REQUEST_POSSIBLE_REGIONS_LIST,
  REQUEST_POLICIES,
  ADD_POLICIES,
  ADD_RETENTION_POLICY,
  DELETE_POLICY,
  PAUSE_POLICIES,
  RESUME_POLICIES,
  EXECUTE_POLICY,
  REQUEST_EDIT_POLICY,
  UPDATE_EDIT_POLICY,
  UPDATE_DEFAULT_POLICY,
  REQUEST_DATA_ITEMS_SNAPSHOT_LIST,
  REQUEST_DATA_ITEM_RP,
  REQUEST_DATA_ITEM_ASSET,
  REQUEST_RP_DATA_ITEMS,
  REQUEST_AM_REPORT_DETAILS,
  REQUEST_AM_REPORTS,
  REQUEST_AM_POSSIBLE_FILTERS,
  REQUEST_SOURCES_VAULT_LIST,
  REQUEST_AM_REPORT_RP,
  REQUEST_AM_REPORT_ASSET,
  REQUEST_AM_REPORT_RELATED_ASSET,
  REQUEST_AM_REPORT_LAST_CLEAN_RP,
  REQUEST_DOWNLOAD_AM_REPORT,
  ENABLE_USER,
  REQUEST_TOTAL_ASSET_DASHBOARD_STATISTICS,
  REQUEST_PAUSED_ASSET_DASHBOARD_STATISTICS,
  REQUEST_PROTECTED_ASSET_DASHBOARD_STATISTICS,
  REQUEST_UNPROTECTED_ASSET_DASHBOARD_STATISTICS,
  REQUEST_ASSETS_UNDER_PROTECTION_PERCENT_DASHBOARD_STATISTICS,
  REQUEST_RECOVERABILITY_INDEX_DASHBOARD_STATISTICS,
  REQUEST_UNHEALTHY_RP_DASHBOARD_STATISTICS,
  REQUEST_VAULT,
  REQUEST_VAULT_METRICS,
  UPDATE_VAULT,
  REQUEST_DASHBOARD_BACKUP_JOBS_LIST,
  REQUEST_BACKUP_JOBS_LIST_BY_REGION,
  INSTALL_RED_STACKS,
  ABORT_JOB,
  REPAIR_CLOUD_CONNECTOR,
  REPAIR_VAULT,
  REQUEST_COST_ADVANTAGES,
  REQUEST_COST_FOR_ACCOUNT,
  REQUEST_POLICIES_RETENTION,
  RECOVERABILITY_STATS,
  DELETE_POLICY_RETENTION,
  UPDATE_STATUS_POLICY_RETENTION,
  REQUEST_EDIT_POLICY_RETENTION,
  REQUEST_ASSETS_POLICY_RETENTION,
  SET_CUSTOM_RETENTION_POLICY_PRIORITIES,
  UPDATE_EDIT_POLICY_RETENTION,
  REQUEST_LIST_AWS_ACCOUNTS,
  UPDATE_EDIT_POLICY_RETENTION_DEFAULT,
  REQUEST_TENANT_NOTIFICATIONS_SETTINGS,
  SET_TENANT_NOTIFICATIONS_SETTINGS,
  UPDATE_TENANT_NOTIFICATIONS_SETTINGS,
  REQUEST_USER_NOTIFICATIONS_SETTINGS,
  UPDATE_USER_NOTIFICATIONS_SETTINGS,
  DISCONNECT_SLACK_FROM_TENANT,
  DISCONNECT_SLACK_FROM_USER,
  REQUEST_FS_CHECK_REPORT_DETAILS,
  REQUEST_FS_CHECK_REPORT_RP,
  REQUEST_FS_CHECK_REPORT_LAST_CLEAN_RP,
  REQUEST_FS_CHECK_REPORT_ASSET,
  REQUEST_DOWNLOAD_FS_CHECK_REPORT,
  REQUEST_POSSIBLE_VPC_LIST_BY_REGION_AND_ACCOUNT,
  REQUEST_COVERED_BY_BACKUP_POLICIES_ASSETS,
  REQUEST_BACKUP_COMPLIANCE_SCORE,
  REQUEST_EDIT_POLICY_RETENTION_DEFAULT,
  REQUEST_LIST_AVAILABILITY_ZONES,
  REQUEST_ISCAN_LAST_RECOVERY_POINT,
  REQUEST_EXECUTE_RP_ISCAN,
  REQUEST_LIST_AWS_SECURITY_GROUPS,
  REQUEST_COMPLIANCE_SUMMARY,
  REQUEST_BACKUP_AUDIT,
  REQUEST_DASHBOARD_COST,
  REQUEST_DASHBOARD_ASSETS_COST,
  REQUEST_ESTIMATED_SPEND,
  REQUEST_VULNERABILITIES_SUMMARY,
  REQUEST_DASHBOARD_DATA,
  REQUEST_DOWNLOAD_RANSOMWARE_REPORT,
  REQUEST_DOWNLOAD_MALWARE_REPORT,
  REQUEST_EXECUTE_FS_CHECK,
  REQUEST_FS_CHECK_LAST_RECOVERY_POINT,
  REQUEST_BACKUP_TIMELINE_STATS,
  REQUEST_WEBHOOKS_LIST,
  ADD_WEBHOOK,
  REQUEST_GET_WEBHOOK,
  DELETE_WEBHOOK,
  UPDATE_WEBHOOK,
  TEST_WEBHOOK,
  TEST_EXISTING_WEBHOOK,
  REQUEST_EVENT_TYPES,
  REQUEST_WEBHOOK_INVOCATIONS,
  REQUEST_S3_BUCKET_RECOVERY_POINT_LIST,
  REQUEST_S3_BUCKET_PATH_LIST,
  REQUEST_S3_BUCKET_REGEXP_LIST,
  REQUEST_SCHEDULE_REPORTS_LIST,
  REQUEST_CREATE_REPORT_SCHEDULE,
  REQUEST_UPDATE_REPORT_SCHEDULE,
  REQUEST_VOLUME_VULNERABILITIES_REPORTS_LIST,
  REQUEST_S3_BUCKET_PATH_RECOVERY_POINT_LIST,
  REQUEST_S3_BUCKET_REGEXP_RECOVERY_POINT_LIST,
  REQUEST_GET_TENANT,
  REQUEST_UPDATE_TENANT,
  REQUEST_BACKUP_START,
  REQUEST_ASSET_LIST_RECOVERY_POINTS,
  REQUEST_ASSETS_SELECT_INVENTORY,
  // [g.js - put new here]
}

export default PreloaderConstants
