import PagePathConstant from '@lib/constants/page-path.constant'
import { jwtDecode } from 'jwt-decode'
import { SystemHelper } from '@lib/helpers'

interface DecodedToken {
  exp?: number
}

export class AuthTokenManager {
  ensureTokenValidity(token: string): void {
    if (this.#isTokenExpired(token)) {
      this.#handleTokenExpiration()
    }
  }
  #decodeJwt(token: string): DecodedToken {
    try {
      return jwtDecode<DecodedToken>(token)
    } catch (error) {
      console.error('Failed to decode JWT', error)
      throw new InvalidTokenError('JWT token can not be parsed')
    }
  }

  // This process does not necessarily require verifying the token's signature if your sole purpose is to check for its expiration.
  #isTokenExpired(token: string): boolean {
    const decoded = this.#decodeJwt(token)
    if (!decoded?.exp) {
      return true
    }

    const tokenExpireTime = decoded.exp * 1000
    return Date.now() > tokenExpireTime
  }

  #handleTokenExpiration(): void {
    SystemHelper.pureNavigate(PagePathConstant.RELOGIN)
  }
}

export class InvalidTokenError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'InvalidTokenError'
  }
}
