import LangConstant from '@lib/constants/lang/lang.constant'

const LangAwsRegionConstant = new Map<string, LangConstant>()

LangAwsRegionConstant.set('us-east-2', {
  en: {
    single: 'US East (Ohio)',
  },
})

LangAwsRegionConstant.set('us-east-1', {
  en: {
    single: 'US East (N. Virginia)',
  },
})

LangAwsRegionConstant.set('us-west-1', {
  en: {
    single: 'US West (N. California)',
  },
})

LangAwsRegionConstant.set('us-west-2', {
  en: {
    single: 'US West (Oregon)',
  },
})

LangAwsRegionConstant.set('af-south-1', {
  en: {
    single: 'Africa (Cape Town)',
  },
})

LangAwsRegionConstant.set('ap-east-1', {
  en: {
    single: 'Asia Pacific (Hong Kong)',
  },
})

LangAwsRegionConstant.set('ap-south-1', {
  en: {
    single: 'Asia Pacific (Mumbai)',
  },
})

LangAwsRegionConstant.set('ap-northeast-3', {
  en: {
    single: 'Asia Pacific (Osaka-Local)',
  },
})

LangAwsRegionConstant.set('ap-northeast-2', {
  en: {
    single: 'Asia Pacific (Seoul)',
  },
})

LangAwsRegionConstant.set('ap-southeast-1', {
  en: {
    single: 'Asia Pacific (Singapore)',
  },
})

LangAwsRegionConstant.set('ap-southeast-2', {
  en: {
    single: 'Asia Pacific (Sydney)',
  },
})

LangAwsRegionConstant.set('ap-northeast-1', {
  en: {
    single: 'Asia Pacific (Tokyo)',
  },
})

LangAwsRegionConstant.set('ca-central-1', {
  en: {
    single: 'Canada (Central)',
  },
})

LangAwsRegionConstant.set('cn-north-1', {
  en: {
    single: 'China (Beijing)',
  },
})

LangAwsRegionConstant.set('cn-northwest-1', {
  en: {
    single: 'China (Ningxia)',
  },
})

LangAwsRegionConstant.set('eu-central-1', {
  en: {
    single: 'Europe (Frankfurt)',
  },
})

LangAwsRegionConstant.set('eu-west-1', {
  en: {
    single: 'Europe (Ireland)',
  },
})

LangAwsRegionConstant.set('eu-west-2', {
  en: {
    single: 'Europe (London)',
  },
})

LangAwsRegionConstant.set('eu-south-1', {
  en: {
    single: 'Europe (Milan)',
  },
})

LangAwsRegionConstant.set('eu-west-3', {
  en: {
    single: 'Europe (Paris)',
  },
})

LangAwsRegionConstant.set('eu-north-1', {
  en: {
    single: 'Europe (Stockholm)',
  },
})

LangAwsRegionConstant.set('me-south-1', {
  en: {
    single: 'Middle East (Bahrain)',
  },
})

LangAwsRegionConstant.set('sa-east-1', {
  en: {
    single: 'South America (São Paulo)',
  },
})

LangAwsRegionConstant.set('us-gov-east-1', {
  en: {
    single: 'AWS GovCloud (US-East)',
  },
})

LangAwsRegionConstant.set('us-gov-west-1', {
  en: {
    single: 'AWS GovCloud (US)',
  },
})

export default LangAwsRegionConstant
