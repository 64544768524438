/* eslint-disable import/no-extraneous-dependencies */
import { GrpcClient } from '@lib/clients/grpc-client'
import {
  OvaStatisticsPromiseClient,
  GetOvaThreatsStatisticsPb,
  GetOvaThreatExposureStatisticsPb,
  GetOvaAssetsScannedStatisticsPb,
  GetOvaFilesScannedStatisticsPb,
  OvaBackupProviderProto,
  ListInfectedAssetsPb,
  OvaInfectedServerPb,
  ListAccountSummariesPb,
} from '@lib/clients/grpc-imports'
import { OvaBackupProvider } from 'blues-corejs/dist/models/ova-backup-provider'
import { Pagination } from '@lib/clients/types'
import {
  OvaServerTransformer,
  OvaBackupTransformer,
} from '../assets/transformers'
import { AbstractListRequest } from '../list-request'
import {
  GetThreatsStatisticsResponse,
  GetThreatExposureStatisticsResponse,
  GetAssetsScannedStatisticsResponse,
  ListOvaInfectedServersResponse,
  OvaInfectedServerResponse,
  GetFilesScannedStatisticsResponse,
  AccountSummaryResponse,
} from './types'
import { ThreatKind } from 'blues-corejs/dist/models/threats/types'
import { Threat as ThreatModelPb } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/threats/threat_pb'

interface OvaStatisticsRequestParams {
  ovaAccountIds: Array<string>
  backupProviders: Array<OvaBackupProvider>
}

export class OvaStatisticsClient extends GrpcClient<OvaStatisticsPromiseClient> {
  #ovaStatisticsClient: OvaStatisticsPromiseClient

  constructor(hostName = '') {
    super()
    this.#ovaStatisticsClient = this.getClient(hostName)
  }

  protected innerClientTypeId(): string {
    return 'OvaStatisticsClient'
  }

  protected initClient(hostName = ''): OvaStatisticsPromiseClient {
    return new OvaStatisticsPromiseClient(hostName, null, null)
  }

  async getThreatsStatistics(
    params: OvaStatisticsRequestParams
  ): Promise<GetThreatsStatisticsResponse> {
    const request = new GetOvaThreatsStatisticsPb.Request()

    request.setOvaAccountIdsList(params.ovaAccountIds)

    request.setBackupProvidersList(
      params.backupProviders.map((provider) =>
        this.#convertBackupProviderEnumToProto(provider)
      )
    )

    const response = (
      await this.retryGrpcCall(
        () =>
          this.#ovaStatisticsClient.getThreatsStatistics(
            request,
            this.metadata()
          ),
        {
          requestName: 'OvaStatisticsPromiseClient/getThreatsStatistics',
        }
      )
    ).toObject()

    return {
      malwareCount: response.statistics?.malware?.total || 0,
      ransomwareCount: response.statistics?.ransomware?.total || 0,
    }
  }

  async getThreatExposureStatistics(
    params: OvaStatisticsRequestParams
  ): Promise<GetThreatExposureStatisticsResponse> {
    const request = new GetOvaThreatExposureStatisticsPb.Request()

    request.setOvaAccountIdsList(params.ovaAccountIds)

    request.setBackupProvidersList(
      params.backupProviders.map((provider) =>
        this.#convertBackupProviderEnumToProto(provider)
      )
    )

    const response = (
      await this.retryGrpcCall(
        () =>
          this.#ovaStatisticsClient.getThreatExposureStatistics(
            request,
            this.metadata()
          ),
        {
          requestName: 'OvaStatisticsPromiseClient/getThreatExposureStatistics',
        }
      )
    ).toObject()

    return {
      assetsWithThreatsCount:
        response.statistics?.servers?.assetsWithThreatsCount || 0,
      assetsWithCleanRpRatio:
        response.statistics?.servers?.assetsWithCleanRpRatio || 0,
    }
  }

  async getAssetsScannedStatistics(
    params: OvaStatisticsRequestParams
  ): Promise<GetAssetsScannedStatisticsResponse> {
    const request = new GetOvaAssetsScannedStatisticsPb.Request()

    request.setOvaAccountIdsList(params.ovaAccountIds)

    request.setBackupProvidersList(
      params.backupProviders.map((provider) =>
        this.#convertBackupProviderEnumToProto(provider)
      )
    )

    const response = (
      await this.retryGrpcCall(
        () =>
          this.#ovaStatisticsClient.getAssetsScannedStatistics(
            request,
            this.metadata()
          ),
        {
          requestName: 'OvaStatisticsPromiseClient/getAssetsScannedStatistics',
        }
      )
    ).toObject()

    return {
      cleanAssets: response.statistics?.servers?.clean || 0,
      infectedAssets: response.statistics?.servers?.infected || 0,
      totalAssets: response.statistics?.servers?.total || 0,
    }
  }

  async listInfectedAssets(
    request: AbstractListRequest<ListInfectedAssetsPb.Request>
  ): Promise<ListOvaInfectedServersResponse & Pagination> {
    const response = (
      await this.retryGrpcCall(
        () =>
          this.#ovaStatisticsClient.listInfectedAssets(
            request.createGrpcRequest(),
            this.metadata()
          ),
        {
          requestName: 'OvaStatisticsPromiseClient/listInfectedAssets',
        }
      )
    ).toObject()

    return {
      pageToken: response.pagination?.nextPageToken,
      infectedServers: response.infectedAssetsList.map((infectedServer) =>
        this.#transformOvaInfectedServerResponse(infectedServer.server!)
      ),
    }
  }

  async listAccountSummaries(
    params: OvaStatisticsRequestParams
  ): Promise<Array<AccountSummaryResponse>> {
    const request = new ListAccountSummariesPb.Request()

    const filter = new ListAccountSummariesPb.Request.Filter()

    filter.setOvaAccountIdsList(params.ovaAccountIds)

    filter.setBackupProvidersList(
      params.backupProviders.map((provider) =>
        this.#convertBackupProviderEnumToProto(provider)
      )
    )

    request.setFilter(filter)

    const response = (
      await this.retryGrpcCall(
        () =>
          this.#ovaStatisticsClient.listAccountSummaries(
            request,
            this.metadata()
          ),
        {
          requestName: 'OvaStatisticsPromiseClient/listAccountSummaries',
        }
      )
    ).toObject()

    return response.accountSummariesList.map((accountSummary) => {
      return {
        ovaAccountId: accountSummary.ovaAccountId,
        ovaAccountName: accountSummary.ovaAccountName,
        infectedServersCount: accountSummary.servers?.infected || 0,
        protectedServersCount: accountSummary.servers?.pb_protected || 0,
      }
    })
  }

  #transformOvaInfectedServerResponse(
    ovaInfectedServerResponse: OvaInfectedServerPb.AsObject
  ): OvaInfectedServerResponse {
    return {
      infectedFiles: ovaInfectedServerResponse.infectedFiles,
      server: new OvaServerTransformer(
        ovaInfectedServerResponse.server!
      ).transform(),
      threatsKindList: ovaInfectedServerResponse.threatsList.map(
        this.#convertKind
      ),
      firstDetectedAt: new Date(
        ovaInfectedServerResponse.firstDetected!.seconds * 1000
      ),
      lastCleanBackup:
        ovaInfectedServerResponse.lastCleanRp &&
        new OvaBackupTransformer(
          ovaInfectedServerResponse.lastCleanRp
        ).transform(),
      lastInfectedBackup:
        ovaInfectedServerResponse.lastInfectedRp &&
        new OvaBackupTransformer(
          ovaInfectedServerResponse.lastInfectedRp
        ).transform(),
    }
  }

  async getFilesScannedStatistics(
    params: OvaStatisticsRequestParams
  ): Promise<GetFilesScannedStatisticsResponse> {
    const request = new GetOvaFilesScannedStatisticsPb.Request()

    request.setOvaAccountIdsList(params.ovaAccountIds)

    request.setBackupProvidersList(
      params.backupProviders.map((provider) =>
        this.#convertBackupProviderEnumToProto(provider)
      )
    )

    const response = (
      await this.retryGrpcCall(
        () =>
          this.#ovaStatisticsClient.getFilesScannedStatistics(
            request,
            this.metadata()
          ),
        {
          requestName: 'OvaStatisticsPromiseClient/getFilesScannedStatistics',
        }
      )
    ).toObject()

    return {
      cleanFilesCount: response.statistics?.clean || 0,
      infectedFilesCount: response.statistics?.infected || 0,
    }
  }

  #convertBackupProviderEnumToProto(
    backupProvider: OvaBackupProvider
  ): OvaBackupProviderProto {
    const mapping: Record<OvaBackupProvider, OvaBackupProviderProto> = {
      [OvaBackupProvider.VEEAM]: OvaBackupProviderProto.VEEAM,
      [OvaBackupProvider.COMMVAULT]: OvaBackupProviderProto.COMMVAULT,
      [OvaBackupProvider.RUBRIK]: OvaBackupProviderProto.RUBRIK,
      [OvaBackupProvider.COHESITY]: OvaBackupProviderProto.COHESITY,
      [OvaBackupProvider.VERITAS_NET_BACKUP]:
        OvaBackupProviderProto.VERITAS_NET_BACKUP,
    }

    return mapping[backupProvider]
  }

  #convertKind(kind: ThreatModelPb.Kind): ThreatKind {
    const mapping: Record<ThreatModelPb.Kind, ThreatKind> = {
      [ThreatModelPb.Kind.KIND_RANSOMWARE]: ThreatKind.RANSOMWARE,
      [ThreatModelPb.Kind.KIND_MALWARE]: ThreatKind.MALWARE,
      [ThreatModelPb.Kind.KIND_FILESYSTEM_ERROR]: ThreatKind.FILESYSTEM_ERROR,
    }

    return mapping[kind]
  }
}
