import { setCustomRetentionPolicyPrioritiesEpic } from './epics/policies-retention.epic'
import {
  disconnectSlackFromProfileEpic,
  requestUserNotificationsEpic,
  requestUserNotificationsShadowEpic,
  updateUserNotificationsEpic,
} from './epics/user-notifications.epic'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { combineEpics, createEpicMiddleware } from 'redux-observable'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
  getAllRedStacksEpic,
  installRedStacksEpic,
  listAvailabilityZonesEpic,
  listAwsAccountsEpic,
  listAwsSecurityGroupsEpic,
  repairVaultEpic,
  requestCFLinkEpic,
  requestCfnEpic,
  requestCfnShadowEpic,
  requestIfRedStackExistsEpic,
  requestListAwsAccountInfoEpic,
  requestPossibleRegionsEpic,
  requestPossibleVpcListByRegionAndAccountEpic,
  requestPossibleVpcListEpic,
  requestRexEpic,
} from '@store/epics/rex.epic'
import {
  addTenantEpic,
  markAsTenantCreatorEpic,
  requestGetTenantEpic,
  requestUpdateTenantEpic,
  setCreatedTenantNameEpic,
} from '@store/epics/tenant.epic'
import userReducer from '@store/reducers/user.reducer'
import {
  enableUserEpic,
  disableUserEpic,
  inviteUserEpic,
  removeUserEpic,
  requestIsMfaEnabledEpic,
  requestPossibleRolesEpic,
  requestSessionsEpic,
  requestUpdateUserEpic,
  requestUserEpic,
  requestUsersListEpic,
  resendInviteUserEpic,
  resetMfaEpic,
  updateMfaEnabledEpic,
  updatePasswordEpic,
  updateUserEpic,
  verifyUserEpic,
} from '@store/epics/user.epic'
import {
  issueTokenEpic,
  requestRevokedTokenListEpic,
  requestSingleTokenEpic,
  requestTokenListEpic,
  revokeTokenEpic,
  updateTokenEpic,
} from '@store/epics/token.epic'
import preloaderReducer from '@store/reducers/preloader.reducer'
import rexReducer from '@store/reducers/rex.reducer'
import tenantReducer from '@store/reducers/tenant.reducer'
import tokenReducer from '@store/reducers/token.reducer'
import modalReducer from '@store/reducers/modal.reducer'
import vaultReducer from '@store/reducers/vault.reducer'
import {
  createVaultEpic,
  requestAccountRedStacksEpic,
  requestAccountVaultListEpic,
  requestAllActiveRedStacksEpic,
  requestSourcesVaultListEpic,
  requestVaultEpic,
  requestVaultListEpic,
  requestVaultMetricsEpic,
  setVaultAsDefaultEpic,
  updateVaultEpic,
} from '@store/epics/vault.epic'
import {
  activateSourcesEpic,
  deactivateSourcesEpic,
  deleteSourcesEpic,
  initSourceIdEpic,
  requestVaultsCostEpic,
  submitSourceIdEpic,
} from '@store/epics/source-id.epic'
import sourceIdReducer from '@store/reducers/source-id.reducer'
import managementRolesReducer from '@store/reducers/management-roles.reducer'
import {
  deleteRoleEpic,
  submitRoleForEditEpic,
} from '@store/epics/management-roles.epic'
import jobReducer from '@store/reducers/job.reducer'

import {
  requestBackupComplianceScoreEpic,
  requestCoveredByBackupPoliciesAssetsEpic,
  requestDashboardAccountStatisticsEpic,
  requestDashboardAssetsUnderProtectionStatisticsEpic,
  requestDashboardBackupJobsListEpic,
  requestDashboardProtectedAssetStatisticsEpic,
  requestDashboardTotalAssetStatisticsEpic,
  requestDashboardUnprotectedAssetStatisticsEpic,
  requestRecoverabilityIndexStatisticsEpic,
  requestRecoverabilityStatsEpic,
  requestUnhealthyRPStatisticsEpic,
} from '@store/epics/dashboard.epic'
import dashboardReducer from '@store/reducers/dashboard.reducer'
import addPolicyReducer from '@store/reducers/add-policy.reducer'
import editPolicyReducer from '@store/reducers/edit-policy.reducer'
import policiesReducer from '@store/reducers/policies.reducer'
import {
  deletePolicyEpic,
  executePolicyEpic,
  pausePolicyEpic,
  requestPoliciesEpic,
  resumePolicyEpic,
} from '@store/epics/policies.epic'
import { addPolicyEpic } from '@store/epics/add-policy.epic'
import {
  requestAllAssetsSelectInventoryEpic,
  requestLiveAssetsSelectInventoryEpic,
} from '@store/epics/assets-select.epic'
import assetsSelectReducer from '@store/reducers/assets-select.reducer'
import {
  requestEditPolicyEpic,
  updateEditPolicyEpic,
} from '@store/epics/edit-policy.epic'
import engineReducer from '@store/reducers/engine.reducer'
import SystemHelper from '@lib/helpers/system.helper'
import SourceVaultsReducer from '@store/reducers/source-vaults.reducer'
import { requestBackupTimelineStatsEpic } from '@store/epics/source-vaults.epic'
import policiesRetentionReducer from '@store/reducers/policies-retention.reducer'
import {
  deletePolicyRetentionEpic,
  requestPoliciesRetentionEpic,
  updateStatusPolicyRetentionEpic,
} from '@store/epics/policies-retention.epic'
import editPolicyRetentionReducer from '@store/reducers/edit-policy-retention.reducer'
import {
  requestEditPolicyRetentionDefaultEpic,
  requestEditPolicyRetentionEpic,
  updateEditPolicyRetentionDefaultEpic,
  updateEditPolicyRetentionEpic,
} from '@store/epics/edit-policy-retention.epic'
import addRetentionPolicyReducer from '@store/reducers/add-retention-policy.reducer'
import { addRetentionPolicyEpic } from '@store/epics/add-policy-retention.epic'
import userNotificationsReducer from './reducers/user-notifications.reducer'
import tenantNotificationsReducer from './reducers/tenant-notifications.reducer'
import {
  disconnectSlackFromTenantEpic,
  requestTenantNotificationsEpic,
  requestTenantNotificationsShadowEpic,
  updateTenantNotificationsEpic,
} from '@store/epics/tenant-notifications.epic'
import {
  createReportScheduleEpic,
  listReportSchedulesEpic,
  requestBackupAuditEpic,
  requestComplianceSummaryEpic,
  updateReportScheduleEpic,
  volumeVulnerabilitiesReportsEpic,
} from './epics/compliance-reports.epic'
import complianceSummaryReportReducer from './reducers/reports.reducer'
import backupAuditReportReducer from './reducers/reports.backup-audit.reducer'
import {
  createWebhookEpic,
  deleteWebhookEpic,
  getEventTypesEpic,
  getWebhookEpic,
  getWebhookInvocationsEpic,
  requestWebhooksListEpic,
  testExistingWebhookEpic,
  testWebhookEpic,
  updateWebhookEpic,
} from './epics/webhook.epic'
import webhookReducer from './reducers/webhook.reducer'
import scheduleReportReducer from './reducers/schedule-report.reducer'
import generatedReportReducer from './reducers/generated-report.reducer'
import liveAssetsReducer from './reducers/live-assets/list-live-assets.reducer'
import fsCheckScansReportsReducer from './reducers/fs-check-scans/fs-check-scans.reducer'
import iscanReportsReducer from './reducers/iscan-reports.reducer'

let composeFunc: any = compose
if (SystemHelper.shouldAddDebugCode()) {
  // eslint-disable-next-line no-console
  console.log('[REDUX] Dev mode enabled')
  composeFunc = composeWithDevTools
}

// @ts-ignore
const skipEmptyActionMiddleware = () => (next) => (action) => {
  // @ts-ignore
  if (action.type === 'EMPTY_ACTION') {
    return null
  }
  // @ts-ignore
  return next(action)
}

export const createAppStore = () => {
  const epicMiddleware = createEpicMiddleware()
  const rootEpic = combineEpics(
    requestUsersListEpic,
    addTenantEpic,
    markAsTenantCreatorEpic,
    setCreatedTenantNameEpic,
    requestTokenListEpic,
    requestSingleTokenEpic,
    issueTokenEpic,
    requestUserEpic,
    updateTokenEpic,
    revokeTokenEpic,
    inviteUserEpic,
    resendInviteUserEpic,
    removeUserEpic,
    enableUserEpic,
    disableUserEpic,
    verifyUserEpic,
    requestRexEpic,
    getAllRedStacksEpic,
    requestUpdateUserEpic,
    updatePasswordEpic,
    requestSessionsEpic,
    requestRevokedTokenListEpic,
    requestAccountVaultListEpic,
    requestSourcesVaultListEpic,
    createVaultEpic,
    setVaultAsDefaultEpic,
    requestVaultListEpic,
    requestAccountRedStacksEpic,
    deactivateSourcesEpic,
    activateSourcesEpic,
    deleteSourcesEpic,
    requestPossibleVpcListEpic,
    requestCFLinkEpic,
    requestPossibleRolesEpic,
    initSourceIdEpic,
    submitSourceIdEpic,
    updateUserEpic,
    submitRoleForEditEpic,
    deleteRoleEpic,
    requestCfnEpic,
    requestAllActiveRedStacksEpic,
    requestCfnShadowEpic,
    requestDashboardAccountStatisticsEpic,
    requestDashboardProtectedAssetStatisticsEpic,
    requestDashboardTotalAssetStatisticsEpic,
    requestDashboardUnprotectedAssetStatisticsEpic,
    requestDashboardAssetsUnderProtectionStatisticsEpic,
    requestRecoverabilityIndexStatisticsEpic,
    requestUnhealthyRPStatisticsEpic,
    requestDashboardBackupJobsListEpic,
    requestIsMfaEnabledEpic,
    updateMfaEnabledEpic,
    resetMfaEpic,
    requestPossibleRegionsEpic,
    requestPoliciesEpic,
    addPolicyEpic,
    deletePolicyEpic,
    executePolicyEpic,
    pausePolicyEpic,
    resumePolicyEpic,
    requestEditPolicyEpic,
    updateEditPolicyEpic,
    requestVaultEpic,
    requestVaultMetricsEpic,
    updateVaultEpic,
    requestListAwsAccountInfoEpic,
    installRedStacksEpic,
    repairVaultEpic,
    requestVaultsCostEpic,
    requestPoliciesRetentionEpic,
    requestRecoverabilityStatsEpic,
    deletePolicyRetentionEpic,
    updateStatusPolicyRetentionEpic,
    requestEditPolicyRetentionEpic,
    requestEditPolicyRetentionDefaultEpic,
    setCustomRetentionPolicyPrioritiesEpic,
    updateEditPolicyRetentionEpic,
    listAwsAccountsEpic,
    addRetentionPolicyEpic,
    updateEditPolicyRetentionDefaultEpic,
    requestUserNotificationsEpic,
    updateUserNotificationsEpic,
    requestTenantNotificationsEpic,
    requestTenantNotificationsShadowEpic,
    updateTenantNotificationsEpic,
    requestUserNotificationsShadowEpic,
    disconnectSlackFromProfileEpic,
    disconnectSlackFromTenantEpic,
    requestPossibleVpcListByRegionAndAccountEpic,
    requestCoveredByBackupPoliciesAssetsEpic,
    requestBackupComplianceScoreEpic,
    listAvailabilityZonesEpic,
    listAwsSecurityGroupsEpic,
    requestComplianceSummaryEpic,
    requestBackupAuditEpic,
    requestIfRedStackExistsEpic,
    requestBackupTimelineStatsEpic,
    requestWebhooksListEpic,
    createWebhookEpic,
    getWebhookEpic,
    deleteWebhookEpic,
    updateWebhookEpic,
    testWebhookEpic,
    getEventTypesEpic,
    testExistingWebhookEpic,
    getWebhookInvocationsEpic,
    createReportScheduleEpic,
    updateReportScheduleEpic,
    listReportSchedulesEpic,
    volumeVulnerabilitiesReportsEpic,
    requestGetTenantEpic,
    requestUpdateTenantEpic,
    requestLiveAssetsSelectInventoryEpic,
    requestAllAssetsSelectInventoryEpic
  )

  const result = createStore(
    combineReducers({
      rexState: rexReducer,
      tenantState: tenantReducer,
      tokenState: tokenReducer,
      userState: userReducer,
      preloaderState: preloaderReducer,
      modalState: modalReducer,
      vaultState: vaultReducer,
      sourceIdState: sourceIdReducer,
      sourceVaultsState: SourceVaultsReducer,
      managementRolesState: managementRolesReducer,
      jobState: jobReducer,
      dashboardState: dashboardReducer,
      addPolicyState: addPolicyReducer,
      editPolicyState: editPolicyReducer,
      policiesState: policiesReducer,
      policiesRetentionState: policiesRetentionReducer,
      addRetentionPolicyState: addRetentionPolicyReducer,
      editPolicyRetentionState: editPolicyRetentionReducer,
      assetsSelectState: assetsSelectReducer,
      engineState: engineReducer,
      userNotificationsState: userNotificationsReducer,
      tenantNotificationsState: tenantNotificationsReducer,
      complianceSummaryReportState: complianceSummaryReportReducer,
      backupAuditReportState: backupAuditReportReducer,
      webhookState: webhookReducer,
      scheduleReportState: scheduleReportReducer,
      generatedReportState: generatedReportReducer,
      liveAssetsState: liveAssetsReducer,
      fsCheckScansReportsState: fsCheckScansReportsReducer,
      iscanReportsState: iscanReportsReducer,
    }),

    composeFunc(applyMiddleware(epicMiddleware, skipEmptyActionMiddleware))
  )
  epicMiddleware.run(rootEpic)
  return result
}
const store = createAppStore()

export type StoreReturnType = ReturnType<typeof store.getState>
export type AppStoreType = typeof store

export default store
