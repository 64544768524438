/* eslint-disable import/no-extraneous-dependencies */
import { baseRequestScenario } from '@store/epics/epic-func'
import {
  REQUEST_ALL_ASSETS_SELECT,
  REQUEST_ASSETS_SELECT,
  setAssetIdsMissingInLiveAssets,
  setAssetsSelect,
} from '@store/actions/assets-select.action'
import PreloaderConstants from '@lib/constants/preloader.constant'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import GrpcMixedService from '@lib/services/grpc/high/grpc-mixed.service'
import { ListAssetsForPolicyCoverageAttrs } from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage'
import StoreService from '@lib/services/store.service'

export const requestLiveAssetsSelectInventoryEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_ASSETS_SELECT],
      PreloaderConstants.REQUEST_ASSETS_SELECT_INVENTORY,
      () => {
        return GrpcMixedService.assetsLiveWithCanBeProtectedInventoryAttrs()
      },
      (result: ListAssetsForPolicyCoverageAttrs) => {
        return setAssetsSelect(result)
      },
      ErrorGroupConstants.ASSETS_SELECT
    )
  )

export const requestAllAssetsSelectInventoryEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_ALL_ASSETS_SELECT],
      PreloaderConstants.REQUEST_ASSETS_SELECT_INVENTORY,
      () => {
        return GrpcMixedService.assetsAllWithCanBeProtectedInventoryAttrs()
      },
      ({
        allAssets,
        missingInLiveAssets,
      }: {
        allAssets: ListAssetsForPolicyCoverageAttrs
        missingInLiveAssets: Array<string>
      }) => {
        StoreService.dispatchAction(
          setAssetIdsMissingInLiveAssets(missingInLiveAssets)
        )
        return setAssetsSelect(allAssets)
      },
      ErrorGroupConstants.ASSETS_SELECT
    )
  )
