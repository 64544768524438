import ErrorGroupConstants from '@lib/constants/error-group.constant'
import PreloaderConstants from '@lib/constants/preloader.constant'
import { ArrayTotalResult, Nullable } from '@lib/engine-types'
import DataHelper from '@lib/helpers/data.helper'
import ToastHelper from '@lib/helpers/toast.helper'
import ActionInterface from '@lib/interfaces/action.interface'
import CoveredByBackupPoliciesAssets from '@lib/interfaces/covered-by-backup-policies-assets.interface'
import RecoverabilityStatsInterface from '@lib/interfaces/recoverability-stats.interface'
import { AggregatedStatisticsList } from '@lib/interfaces/statistics.interface'
import JobModel from '@lib/models/job.model'
import GrpcJobStatusService from '@lib/services/grpc/grpc-job-status.service'
import GrpcKrabsService from '@lib/services/grpc/grpc-krabs.service'
import GrpcPechkinService from '@lib/services/grpc/grpc-pechkin.service'
import StoreService from '@lib/services/store.service'
import {
  REQUEST_BACKUP_COMPLIANCE_SCORE,
  REQUEST_COVERED_BY_BACKUP_POLICIES_ASSETS,
  REQUEST_DASHBOARD_ACCOUNT_STATISTICS,
  REQUEST_DASHBOARD_ASSETS_UNDER_PROTECTION_PERCENT,
  REQUEST_DASHBOARD_RECOVERABILITY_INDEX,
  REQUEST_DASHBOARD_TOTAL_ASSETS,
  REQUEST_DASHBOARD_UNHEALTHY_RP,
  RECOVERABILITY_STATS,
  setAccountDashboardStatistics,
  setAssetsUnderProtectionPercentDashboardErrorMessage,
  setAssetsUnderProtectionPercentDashboardStatistics,
  setBackupComplianceScore,
  setCoveredByBackupPoliciesAssets,
  setCoveredByBackupPoliciesAssetsErrorMessage,
  setDashboardBackupJobList,
  setProtectedAssetsDashboardErrorMessage,
  setProtectedAssetsDashboardStatistics,
  setRecoverabilityIndexDashboardErrorMessage,
  setRecoverabilityIndexDashboardStatistics,
  setrecoverabilityStats,
  setRecoverabilityStatsErrorMessage,
  setTotalAssetsDashboardErrorMessage,
  setTotalAssetsDashboardStatistics,
  setUnhealthyRPDashboardErrorMessage,
  setUnhealthyRPDashboardStatistics,
  setUnprotectedAssetsDashboardErrorMessage,
  setUnprotectedAssetsDashboardStatistics,
  SET_DASHBOARD_JOBS_REQUEST_PARAMETERS,
} from '@store/actions/dashboard.action'
import {
  baseRequestScenario,
  sentryReThrowCatchHandler,
} from '@store/epics/epic-func'
import { StateObservable } from 'redux-observable'
import BackupComplianceInterface from '@lib/interfaces/backup-compliance.interface'

export const requestDashboardAccountStatisticsEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_DASHBOARD_ACCOUNT_STATISTICS],
      PreloaderConstants.REQUEST_DASHBOARD_STATISTICS,
      // request
      (action: ActionInterface) =>
        GrpcKrabsService.getAggregatedStatistics({
          dateRange: action.payload,
        }).catch((error: Error) => {
          ToastHelper.error(error.message)
          sentryReThrowCatchHandler(
            `Cannot get account usage dashboard statistic - ${error.message}`
          )
        }),
      (result: Nullable<AggregatedStatisticsList>) =>
        setAccountDashboardStatistics(result),
      // response
      ErrorGroupConstants.DASHBOARD
    )
  )

export const requestDashboardTotalAssetStatisticsEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_DASHBOARD_TOTAL_ASSETS],
      PreloaderConstants.REQUEST_TOTAL_ASSET_DASHBOARD_STATISTICS,
      // request
      () =>
        GrpcKrabsService.getAssetsTotalNumber().catch((error) => {
          switch (error.code) {
            // resync in progress or failed
            case 9:
              StoreService.dispatchAction(
                setTotalAssetsDashboardErrorMessage(error.message)
              )
              return
            default:
              ToastHelper.error(
                `Cannot get total assets number statistic - ${error.message}`
              )
              break
          }
          sentryReThrowCatchHandler(error.message)
        }),
      (result: Nullable<number>) => setTotalAssetsDashboardStatistics(result),
      // response
      ErrorGroupConstants.DASHBOARD
    )
  )

export const requestDashboardProtectedAssetStatisticsEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_DASHBOARD_TOTAL_ASSETS],
      PreloaderConstants.REQUEST_PROTECTED_ASSET_DASHBOARD_STATISTICS,
      // request
      () =>
        GrpcKrabsService.getProtectedAssetsNumber().catch((error) => {
          switch (error.code) {
            // resync in progress or failed
            case 9:
              StoreService.dispatchAction(
                setProtectedAssetsDashboardErrorMessage(error.message)
              )
              return
            default:
              ToastHelper.error(
                `Cannot get protected assets number statistic - ${error.message}`
              )
              break
          }
          sentryReThrowCatchHandler(error.message)
        }),
      (result: Nullable<number>) =>
        setProtectedAssetsDashboardStatistics(result),
      // response
      ErrorGroupConstants.DASHBOARD
    )
  )

export const requestDashboardUnprotectedAssetStatisticsEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_DASHBOARD_TOTAL_ASSETS],
      PreloaderConstants.REQUEST_UNPROTECTED_ASSET_DASHBOARD_STATISTICS,
      // request
      () =>
        GrpcKrabsService.getUnprotectedAssetsNumber().catch((error) => {
          switch (error.code) {
            // resync in progress or failed
            case 9:
              StoreService.dispatchAction(
                setUnprotectedAssetsDashboardErrorMessage(error.message)
              )
              return
            default:
              ToastHelper.error(
                `Cannot get unprotected assets number statistic - ${error.message}`
              )
              break
          }
          sentryReThrowCatchHandler(error.message)
        }),
      (result: Nullable<number>) =>
        setUnprotectedAssetsDashboardStatistics(result),
      // response
      ErrorGroupConstants.DASHBOARD
    )
  )

export const requestDashboardAssetsUnderProtectionStatisticsEpic = (
  action$: any
) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_DASHBOARD_ASSETS_UNDER_PROTECTION_PERCENT],
      PreloaderConstants.REQUEST_ASSETS_UNDER_PROTECTION_PERCENT_DASHBOARD_STATISTICS,
      // request
      () =>
        GrpcKrabsService.getPercentOfProtectedAssets().catch((error) => {
          switch (error.code) {
            // resync in progress or failed
            case 9:
              StoreService.dispatchAction(
                setAssetsUnderProtectionPercentDashboardErrorMessage(
                  error.message
                )
              )
              return
            default:
              ToastHelper.error(
                `Cannot get assets under protection percent statistic - ${error.message}`
              )
              break
          }
          sentryReThrowCatchHandler(error.message)
        }),
      (result: Nullable<number>) =>
        setAssetsUnderProtectionPercentDashboardStatistics(result),
      // response
      ErrorGroupConstants.DASHBOARD
    )
  )

export const requestRecoverabilityIndexStatisticsEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_DASHBOARD_RECOVERABILITY_INDEX],
      PreloaderConstants.REQUEST_RECOVERABILITY_INDEX_DASHBOARD_STATISTICS,
      // request
      () =>
        GrpcKrabsService.getRecoverabilityIndex().catch((error) => {
          switch (error.code) {
            // resync in progress or failed
            case 9:
              StoreService.dispatchAction(
                setRecoverabilityIndexDashboardErrorMessage(error.message)
              )
              return
            default:
              ToastHelper.error(
                `Cannot get recoverability index statistic - ${error.message}`
              )
              break
          }
          sentryReThrowCatchHandler(error.message)
        }),
      (result: Nullable<number>) =>
        setRecoverabilityIndexDashboardStatistics(result),
      // response
      ErrorGroupConstants.DASHBOARD
    )
  )

export const requestUnhealthyRPStatisticsEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_DASHBOARD_UNHEALTHY_RP],
      PreloaderConstants.REQUEST_UNHEALTHY_RP_DASHBOARD_STATISTICS,
      // request
      () =>
        GrpcKrabsService.getUnhealthyRecoveryPointsNumber().catch((error) => {
          switch (error.code) {
            // resync in progress
            case 9:
              StoreService.dispatchAction(
                setUnhealthyRPDashboardErrorMessage(error.message)
              )
              return
            default:
              ToastHelper.error(
                `Cannot get unhealthy recovery points statistic - ${error.message}`
              )
              break
          }
          sentryReThrowCatchHandler(error.message)
        }),
      (result: Nullable<number>) => setUnhealthyRPDashboardStatistics(result),
      // response
      ErrorGroupConstants.DASHBOARD
    )
  )

export const requestDashboardBackupJobsListEpic = (
  action$: any,
  state$: StateObservable<any>
) =>
  action$.pipe(
    baseRequestScenario(
      [SET_DASHBOARD_JOBS_REQUEST_PARAMETERS],
      PreloaderConstants.REQUEST_DASHBOARD_BACKUP_JOBS_LIST,
      () => {
        const pagination =
          state$.value.dashboardState.backupJobRequestParameters.pagination
        const filters =
          state$.value.dashboardState.backupJobRequestParameters.filters
        const dateRange =
          state$.value.dashboardState.backupJobRequestParameters.dateRange

        return GrpcJobStatusService.listJobs(
          pagination.offset,
          pagination.limit,
          filters,
          '',
          dateRange
        ).catch(sentryReThrowCatchHandler('Cannot get a list of Jobs'))
      },
      (data: ArrayTotalResult<JobModel>) =>
        setDashboardBackupJobList(data.data),
      ErrorGroupConstants.DASHBOARD
    )
  )

export const requestRecoverabilityStatsEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [RECOVERABILITY_STATS],
      PreloaderConstants.RECOVERABILITY_STATS,
      // request
      () =>
        GrpcKrabsService.getRecoverabilityStats().catch((error) => {
          switch (error.code) {
            // resync in progress or failed
            case 9:
              StoreService.dispatchAction(
                setRecoverabilityStatsErrorMessage(error.message)
              )
              return
            default:
              ToastHelper.error(
                `Cannot get recoverability statistic - ${error.message}`
              )
              break
          }
          sentryReThrowCatchHandler(error.message)
        }),
      (result: Nullable<RecoverabilityStatsInterface>) => {
        const stats = DataHelper.buildrecoverabilityStats(result)
        return setrecoverabilityStats(stats)
      },
      // response
      ErrorGroupConstants.DASHBOARD
    )
  )

export const requestCoveredByBackupPoliciesAssetsEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_COVERED_BY_BACKUP_POLICIES_ASSETS],
      PreloaderConstants.REQUEST_COVERED_BY_BACKUP_POLICIES_ASSETS,
      // request
      () =>
        GrpcKrabsService.getPercentageOfCoveredByBackupPoliciesAssets().catch(
          (error) => {
            switch (error.code) {
              // resync in progress or failed
              case 9:
                StoreService.dispatchAction(
                  setCoveredByBackupPoliciesAssetsErrorMessage(error.message)
                )
                return
              default:
                ToastHelper.error(
                  `Cannot get covered by protection policies assets statistic - ${error.message}`
                )
                break
            }
            sentryReThrowCatchHandler(error.message)
          }
        ),
      (result: Nullable<CoveredByBackupPoliciesAssets>) =>
        setCoveredByBackupPoliciesAssets(result),
      // response
      ErrorGroupConstants.DASHBOARD
    )
  )

export const requestBackupComplianceScoreEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_BACKUP_COMPLIANCE_SCORE],
      PreloaderConstants.REQUEST_BACKUP_COMPLIANCE_SCORE,
      () =>
        GrpcPechkinService.getBackupComplianceScore().catch((error: Error) => {
          ToastHelper.error(
            `Cannot get backup window compliance - ${error.message}`
          )
          sentryReThrowCatchHandler(error.message)
        }),
      (result: BackupComplianceInterface) => setBackupComplianceScore(result),
      ErrorGroupConstants.DASHBOARD
    )
  )
