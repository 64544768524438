/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useMemo } from 'react'
import { Table } from '@components/table'
import { MENU } from './types'
import { EngineCallback } from '@lib/engine-types'
import { getRecoveryPointsTableColumnDefinitions } from './columns-definition'
import { OnMenuClickData } from '@tables/core/table-handlers'
import { DateRangePickerWithCallAPI } from '@components-composite/date-range-picker-with-call-api'
import Box from '@mui/material/Box'
import styles from './styles.module.css'
import {
  ListBackupsForAsset,
  ListBackupsForAssetFilters,
} from '@lib/clients/backups/types'
import { useRouter } from 'next/router'
import { useInfiniteScrollDataFetch } from '@components/table/use-infinite-scroll-data-fetch'
import { TimeRange } from '@lib/clients/types'
import { Asset, Backup, EBS, EC2 } from 'blues-corejs/dist'
import {
  composeMountInstanceUrl,
  composeMountVolumeUrl,
} from './recovery-point-mount-url-composer'
import {
  composeRestoreVolumeUrl,
  composeRestoreInstanceUrl,
} from './recovery-point-restore-url-composer'

import { retrieveRpCapabilities } from '@features/asset-page/modals/recovery-point-details/components/recovery-point-details-header/backup-actions'
import { getMethodFromSingleMenuItem } from '@features/asset-page/modals/recovery-point-details/components/recovery-point-details-header/handlers'

interface Props {
  backups: ListBackupsForAsset
  asset: Asset
  onDateRangeSelect: (timeRange: TimeRange) => void
  onBackupClick: EngineCallback<Backup>
  isLoading?: boolean
  onScrollEnd: (filter?: ListBackupsForAssetFilters) => Promise<void>
  onFetchInitial: (
    filter?: Partial<ListBackupsForAssetFilters>
  ) => Promise<void>
}

function getSortedBackups(backups: ListBackupsForAsset) {
  return [
    ...backups.awsbRpsList,
    ...backups.elastioRpsList,
    ...backups.ebsSnapshotsList,
    ...backups.ovaBackupList,
  ].sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
}

function RecoveryPointsTable({
  onBackupClick,
  backups,
  asset,
  onScrollEnd,
  onDateRangeSelect,
  onFetchInitial,
  isLoading = false,
}: Props) {
  const allBackups = useMemo(() => getSortedBackups(backups), [backups])

  const router = useRouter()

  const observable = useInfiniteScrollDataFetch({
    initialItems: allBackups,
    fetchNextBatch: onScrollEnd,
  })

  const handleMenuClick = (menuData: OnMenuClickData) => {
    if (menuData.chosenMenu === MENU.Restore) {
      if (asset instanceof EC2) {
        router.push(
          composeRestoreInstanceUrl({
            rpId: menuData.data.id,
            assetId: asset.id,
          })
        )
      }

      if (asset instanceof EBS) {
        router.push(
          composeRestoreVolumeUrl({
            rpId: menuData.data.id,
            assetId: asset.id,
          })
        )
      }
    }

    if (menuData.chosenMenu === MENU.Mount) {
      if (asset instanceof EC2) {
        router.push(
          composeMountInstanceUrl({
            rpId: menuData.data.id,
            assetId: asset.id,
          })
        )
      }

      if (asset instanceof EBS) {
        router.push(
          composeMountVolumeUrl({
            rpId: menuData.data.id,
            assetId: asset.id,
          })
        )
      }
    }

    if (menuData.chosenMenu === MENU.Scan) {
      const executeOnClick = getMethodFromSingleMenuItem(
        retrieveRpCapabilities({
          asset,
          backup: menuData.data,
          includeScanOnly: true,
        }),
        'onClick'
      )
      executeOnClick()
    }
  }

  useEffect(() => {
    onFetchInitial()
  }, [])

  return (
    <Box className={styles.recoveryPointsTableContainer}>
      <Box className={styles.recoveryPointsDateSelector}>
        <DateRangePickerWithCallAPI onDateRangeChange={onDateRangeSelect} />
      </Box>
      <Table
        data={allBackups}
        columns={getRecoveryPointsTableColumnDefinitions({
          onBackupClick,
          onRowMenuClick: handleMenuClick,
        })}
        advancedProps={{
          observableState: observable,
        }}
        data-testid="recoveryPointsTable"
        className={styles.recoveryPointsTableWrap}
        generalProps={{
          noDataMessage: 'No Recovery points found',
          isLoading,
        }}
      />
    </Box>
  )
}

export default RecoveryPointsTable
