import { LaunchDarklyFeatureFlagProvider } from '@components-context/feature-flags/launch-darkly/launch-darkly-provider'
import { ProviderComponent } from '@lib/engine-types'
import { ServiceName, LAUNCHDARKLY } from '@lib/feature-flags/consts'
import SystemHelper from '@lib/helpers/system.helper'

const providerRegistry: Record<ServiceName, ProviderComponent> = {
  [LAUNCHDARKLY]: LaunchDarklyFeatureFlagProvider as ProviderComponent,
}
export const getFeatureFlagsProvider = (
  serviceName: ServiceName = LAUNCHDARKLY
): ProviderComponent => {
  const Provider = providerRegistry[serviceName] as ProviderComponent
  if (!Provider) {
    SystemHelper.throwErrorInLocalEnv(
      `No provider registered for service name: ${serviceName}`
    )
  }
  return Provider
}
