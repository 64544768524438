/* eslint-disable import/no-extraneous-dependencies */
import {
  AccountStatusConstant,
  CheckboxConstant,
  PagePathConstant,
  RedStackStatusConstant,
  Severity,
  TimeFormatConstants,
} from '@lib/constants'
import { AssetKind } from '@lib/constants/grpc/asset-kind.constant'
import {
  Nullable,
  RowSimpleUnit,
  StringTupleArray,
  VIMatrix,
  VIRow,
} from '@lib/engine-types'
import VpcFactory from '@lib/factories/vpc.factory'
import ArrHelper from '@lib/helpers/arr.helper'
import DataHelper from '@lib/helpers/data.helper'
import LangHelper from '@lib/helpers/lang.helper'
import NumHelper from '@lib/helpers/num.helper'
import PageHelper from '@lib/helpers/page.helper'
import ScopeHelper from '@lib/helpers/scope.helper'
import StrHelper from '@lib/helpers/str.helper'
import TimeHelper from '@lib/helpers/time.helper'
import { WebhookValueInterface } from '@lib/interfaces/notifications.interface'
import {
  AggregatedStatistics,
  AggregatedStatisticsBySource,
} from '@lib/interfaces/statistics.interface'
import { AssetsEntityType } from '@lib/interfaces/tables/assets-table.interface'
import ValueInterface from '@lib/interfaces/value.interface'
import AccessTokenModel from '@lib/models/access-token.model'
import PolicyModel from '@lib/models/policy.model'
import RedStackModel from '@lib/models/red-stack.model'
import RetentionPolicyModel from '@lib/models/retention-policy.model'
import UserRoleModel from '@lib/models/user-role.model'
import UserModel from '@lib/models/user.model'
import VaultModel from '@lib/models/vault.model'
import VpcModel from '@lib/models/vpc.model'
import WebhookModel from '@lib/models/webhook.model'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  InstanceModel,
  InstanceWithVolumes,
  InventoryModel,
  RiskLevel,
  SnapshotVulnerabilitiesWithTypes,
  VolumeModel,
  VolumeWithInstance,
  TagsArray,
} from 'blues-corejs/dist'
import { VulnerabilitiesDetectionStrategy } from 'blues-corejs/dist/use_cases'
import { ReportScheduleRequestInterface } from '@lib/interfaces/schedule-report.interface'

// eslint-disable-next-line import/no-extraneous-dependencies
import { IntegrityStatus } from 'blues-corejs/dist/models/inventory.model/threat-status.model'
import {
  EbsVulnerabilityKind,
  ElastioRecoveryPoint,
} from 'blues-corejs/dist/models'
import {
  Asset,
  AssetWithRelatedAssets,
} from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import TypeHelper from '@lib/helpers/type.helper'
import { EBS, EC2 } from '@lib/models/assets'
import { SelectSpecificPathsInterface } from '@lib/interfaces/assets-s3-select.interface'
import BaseDataConstant from '@lib/constants/data/base-data.constant'

export interface AssetKindParsedData extends ValueInterface {
  pathSlug: string
  pathLabel: string
}

class AssetKindParsedConstantInner extends BaseDataConstant<AssetKindParsedData> {
  get KIND_UNDEFINED(): AssetKindParsedData {
    return {
      pathSlug: '',
      pathLabel: '',
      name: '',
      label: 'Undefined',
      value: -1,
    }
  }

  get KIND_AWS_EC2(): AssetKindParsedData {
    return {
      pathSlug: 'instances',
      pathLabel: 'Instances',
      name: 'ec2',
      label: 'EC2',
      value: 0,
    }
  }

  get KIND_AZURE_VM(): AssetKindParsedData {
    return {
      pathSlug: '',
      pathLabel: '',
      name: 'azure_vm',
      label: '',
      value: 1,
    }
  }

  get KIND_VM_WARE_VM(): AssetKindParsedData {
    return {
      pathSlug: '',
      pathLabel: '',
      name: 'vm_ware_vm',
      label: '',
      value: 2,
    }
  }

  get KIND_AWS_EBS(): AssetKindParsedData {
    return {
      pathSlug: 'volumes',
      pathLabel: 'Volumes',
      name: 'aws_ebs',
      label: 'EBS',
      value: 3,
    }
  }

  get KIND_AWS_EFS(): AssetKindParsedData {
    return {
      pathSlug: '',
      pathLabel: '',
      name: 'aws_efs',
      label: 'EFS',
      value: 4,
    }
  }

  get KIND_GENERIC_HOST(): AssetKindParsedData {
    return {
      pathSlug: 'local-machine',
      pathLabel: 'Other Assets',
      name: 'generic_host',
      label: 'Generic Host',
      value: 5,
    }
  }

  get KIND_GENERIC_FS(): AssetKindParsedData {
    return {
      pathSlug: '',
      pathLabel: '',
      name: 'generic_fs',
      label: 'Generic FS',
      value: 6,
    }
  }

  get KIND_S3_BUCKET(): AssetKindParsedData {
    return {
      pathSlug: 's3buckets',
      pathLabel: 'S3 Buckets',
      name: 's3_bucket',
      label: 'S3',
      value: 7,
    }
  }

  get KIND_AWS_S3_OBJECTS(): AssetKindParsedData {
    return {
      pathSlug: 's3objects',
      pathLabel: 'S3 Objects',
      name: 's3_objects',
      label: 'S3 Objects',
      value: 8,
    }
  }

  get KIND_ALL(): AssetKindParsedData {
    return {
      pathSlug: 'all',
      pathLabel: 'All',
      name: 'all',
      label: 'All',
      value: 999,
    }
  }

  public getByPathSlug(value: string): AssetKindParsedData {
    return this.getByField('pathSlug', value)
  }
}

const AssetKindParsedConstant = AssetKindParsedConstantInner.build(
  AssetKindParsedConstantInner,
  'KIND_'
)

// TODO: for future - move it in the model with a new field
function getApiAccessTableLabel(value: AccessTokenModel): string {
  if (value.updatedAt) {
    return value.updatedAt.toISOString()
  }
  return 'Never used'
}

function extractDate(value: string): string {
  const [, date] = value.split(': ')
  return String(date)
}

// By not implementing any additional methods in the `TableFactory` class, we keep the class lightweight and rely on the inherited behavior.
abstract class VolumeIDTable {
  private static exceptionVolumeId = 'vol-ffffffff'

  public static getVolumeID(volumeID: string): string {
    const shouldContainLink = !DataHelper.protectedObjectIdParsed(
      volumeID
    ).includes(this.exceptionVolumeId)
    return shouldContainLink ? PageHelper.buildItemUrl(volumeID, 'volume') : ''
  }
}

export function getVolumeVulnerabilities(
  inventory: Nullable<InventoryModel>,
  volume: VolumeModel
): Array<EbsVulnerabilityKind> {
  const ebsVulnerabilities = new Set<EbsVulnerabilityKind>()

  const vulnerabilities = inventory?.volumeVulnerabilitiesList(
    volume,
    inventory?.getSnapshotsByVolume().get(volume.getId()) ?? []
  )

  vulnerabilities?.forEach((vulnerability) =>
    ebsVulnerabilities.add(vulnerability)
  )

  return [...ebsVulnerabilities]
}

export function getVulnerabilitiesRisks(
  vulnerabilities: Array<EbsVulnerabilityKind> | Array<string>
) {
  const strategy = new VulnerabilitiesDetectionStrategy()

  const risks = vulnerabilities.map((vulnerability: string) => ({
    vulnerability,
    risk: strategy.identifyRiskLevel(vulnerability as string),
  }))

  return risks
}

export function getSnapshotVulnerabilitiesRisks(
  snapshotVulnerabilityTypes: Array<string>
) {
  const strategy = new VulnerabilitiesDetectionStrategy()

  const risks = snapshotVulnerabilityTypes.map((vulnerability) => ({
    vulnerability,
    risk: strategy.identifyRiskLevel(vulnerability),
  }))

  return risks
}

export enum AntiMalwareLinkType {
  LOCAL_SCAN,
  RECOVERY_POINT,
}

abstract class TableFactory extends VolumeIDTable {
  public static managementVIUsers(data: Array<UserModel>): VIMatrix {
    return data.map((user) => [
      // name and email
      {
        name: user.name,
        label: user.email,
      },

      /*TODO: hidden 09.02.2024 by ticket #9484*/
      // groups
      // {
      //     name: 'None',
      // },
      // roles
      // {
      //   name: '',
      //   options: [
      //     {
      //       name: user.role.name,
      //       label: user.role.name,
      //       options: DataHelper.groupScopes(user.role.scopesList),
      //     },
      //   ],
      // },
      // last logged in
      {
        name: user.lastSeen?.toISOString() ?? '',
      },
      // status
      {
        name: '',
        value: user.statusParsed.value,
      },
    ])
  }

  public static EBSRelatedForEC2ForInventory(data: Array<EBS | EC2>): VIMatrix {
    return data?.map((asset) => {
      let assetTypeNumber = AssetKind.AWS_EC2

      if (asset instanceof EBS) {
        assetTypeNumber = AssetKind.AWS_EBS
      }

      const result: VIRow = [
        {
          id: asset?.id,
          name: '',
          type: AssetsEntityType.Checkbox,
          value: CheckboxConstant.Empty,
          selectedOption: {
            name: String(asset?.id),
            // disabled: !volume.canBeProtected,
            // value: volume.accountAliasName,
            extraValue: asset?.awsAccountId,
            type: assetTypeNumber,
            selectedOption: {
              name: LangHelper.getAwsRegionSingleTranslation(asset?.awsRegion),
              label: String(asset?.awsRegion),
            },
          },
        },
      ]
      return result
    })
  }

  public static EC2RelatedForEBSForInventory(data: Array<EBS | EC2>): VIMatrix {
    return data?.map((asset) => {
      let assetTypeNumber = AssetKind.AWS_EC2

      if (asset instanceof EBS) {
        assetTypeNumber = AssetKind.AWS_EBS
      }
      const result: VIRow = [
        {
          id: asset?.id,
          name: '',
          type: AssetsEntityType.Checkbox,
          value: CheckboxConstant.Empty,
          selectedOption: {
            name: String(asset?.id),
            label: asset?.id,
            // disabled: !asset.canBeProtected,
            // value: asset.accountAliasName,
            extraValue: asset?.awsAccountId,
            type: assetTypeNumber,
            selectedOption: {
              name: LangHelper.getAwsRegionSingleTranslation(asset?.awsRegion),
              label: String(asset?.awsRegion),
            },
          },
        },
      ]
      return result
    })
  }

  public static assetsInventoryPoliciesVI(
    data: Array<AssetWithRelatedAssets<Asset>>,
    assetsMissingInLiveAssets: Array<string>,
    lastBackups: Map<string, ElastioRecoveryPoint> | null
  ): VIMatrix {
    return data?.map((item) => {
      const assetTypeString = LangHelper.getAssetKindSingleTranslation(
        item.type
      )

      const assetTags: StringTupleArray = (item?.asset?.tags as TagsArray).map(
        ({ key, value }) => [key, value]
      )

      const isMissingInLiveAssets = assetsMissingInLiveAssets.some(
        (assetId) => assetId === item.asset.id
      )

      const lastBackup = lastBackups?.get(item.asset.id)

      // TODO remove this check after GenericHost Inventory page will be created
      const isHideLinks =
        item.type === AssetKind.GENERIC_HOST || isMissingInLiveAssets

      const result: VIRow = [
        {
          id: item.asset?.id,
          name: '',
          type: AssetsEntityType.Checkbox,
          value: CheckboxConstant.Empty,
          selectedOption: {
            name: String(item.asset?.id),
            label: item.asset?.id,
            // disabled: !asset.isProtected(),
            // value: asset.accountAliasName,
            extraValue: item.asset?.awsAccountId,
            type: item.type,
            selectedOption: {
              name: LangHelper.getAwsRegionSingleTranslation(
                item.asset?.awsRegion
              ),
              label: String(item.asset?.awsRegion),
            },
          },
          matrix:
            item.type === AssetKind.AWS_EBS
              ? TableFactory.EC2RelatedForEBSForInventory(item?.relatedAssets)
              : item.type === AssetKind.AWS_EC2
              ? TableFactory.EBSRelatedForEC2ForInventory(item?.relatedAssets)
              : [],
        },
        {
          // name: asset.state?.label ?? '',
          name: '',
          type: AssetsEntityType.Icon,
        },
        {
          type: AssetsEntityType.Link,
          name:
            item?.asset?.id && !isHideLinks
              ? PageHelper.buildItemUrl(
                  item.asset?.awsId,
                  TypeHelper.getAssetKindForInventory(item.type)
                )
              : '',

          label: item?.asset?.name || item?.asset?.awsId,
          value: item?.asset?.name ? String(item?.asset?.awsId) : '',
        },
        {
          name: '',
          type: AssetsEntityType.Tags,
          tags: assetTags,
        },
        {
          name: assetTypeString,
        },
        { name: item?.asset?.awsAccountId },
        {
          name: LangHelper.getAwsRegionSingleTranslation(
            item?.asset?.awsRegion
          ),
        },
        {
          type: AssetsEntityType.Date,
          name: lastBackup ? String(lastBackup.timestamp) : '',
        },
        {
          label:
            item?.asset.backupPolicies.length > 0
              ? item?.asset?.backupPolicies?.join()
              : 'Not assigned',
          type:
            item?.asset.backupPolicies.length > 0
              ? AssetsEntityType.Link
              : AssetsEntityType.Text,
          name: PagePathConstant.POLICIES,
        },
      ]
      return result
    })
  }

  public static apiAccessTable(data: Array<AccessTokenModel>): VIMatrix {
    return data.map(
      (v: AccessTokenModel): VIRow => [
        { name: String(v.innerId) },
        { name: String(v.name) },
        {
          name: '',
          options: ScopeHelper.getScopeValues(v.scopesList),
        },
        { name: 'Active' },
        { name: getApiAccessTableLabel(v) },
      ]
    )
  }

  public static revokedTokensHistory(data: Array<AccessTokenModel>): VIMatrix {
    return data.map(
      (v: AccessTokenModel): VIRow => [
        { name: String(v.name) },
        {
          name: '',
          options: ScopeHelper.getScopeValues(v.scopesList),
        },
        { name: 'Revoked' },
        { name: getApiAccessTableLabel(v) },
      ]
    )
  }

  public static enabledSourcesVI(
    rsArr: Array<RedStackModel>,
    vaults: Array<VaultModel>
  ): VIMatrix {
    // grouping by status "0", "1", "3"
    const connectAndDisconnectSources = rsArr.filter(
      (row: RedStackModel) =>
        row.isActive ||
        row.isInactive ||
        row.status === RedStackStatusConstant.UPGRADE_REQUIRED
    )
    const groupedByAwsAccount: Record<string, any> =
      DataHelper.groupedByAwsAccount(connectAndDisconnectSources)
    // add count of assetsCount
    vaults.forEach((rowVault: VaultModel) => {
      if (groupedByAwsAccount[rowVault.accountId]) {
        groupedByAwsAccount[rowVault.accountId].assetsCount +=
          rowVault.assetsCount
      }
    })
    const uniqueEnabledSources = [...Object.values(groupedByAwsAccount)]
    return uniqueEnabledSources.map((row: any) => {
      // general account status and label
      let activeRSCount = 0
      let inactiveRSCount = 0
      row.statuses.forEach((value: number) => {
        if (value === RedStackStatusConstant.ACTIVE) {
          activeRSCount += 1
        }
        if (value === RedStackStatusConstant.INACTIVE) {
          inactiveRSCount += 1
        }
      })
      let accountStatusValue = AccountStatusConstant.MIXED

      if (activeRSCount === 0) {
        accountStatusValue = AccountStatusConstant.DISCONNECTED
      }
      if (inactiveRSCount === 0) {
        accountStatusValue = AccountStatusConstant.CONNECTED
      }
      if (row.cfnUpgradeRequired) {
        accountStatusValue = AccountStatusConstant.UPGRADE_REQUIRED
      }

      // fill message
      const messages: Array<string> = []
      if (
        accountStatusValue === AccountStatusConstant.DISCONNECTED ||
        accountStatusValue === AccountStatusConstant.MIXED
      ) {
        row.awsRegions.forEach((region: string, index: number) => {
          let messageValue = ''
          if (row.statuses[index] === RedStackStatusConstant.ACTIVE) {
            messageValue = 'Success'
          }
          if (row.statuses[index] === RedStackStatusConstant.INACTIVE) {
            messageValue = 'Failed'
          }
          messageValue = `${messageValue} - ${region}`
          if (row.lastErrorMessages[index]) {
            messageValue += `: "${row.lastErrorMessages[index]}"`
          }
          messages.push(messageValue)
        })
      }

      return [
        // unprotected
        {
          name: '',
          type: accountStatusValue,
          messages,
          label: row.lastWarningMessage,
        },
        // id
        {
          name: row.awsAccount,
        },
        // name
        {
          name: row.accountAlias,
        },
        // description
        {
          name: row.accountDescription,
        },
        // region
        {
          name: row.awsRegions
            .map((regionName: string) =>
              LangHelper.getAwsRegionSingleTranslation(regionName)
            )
            .join(', '),
        },
        {
          name: '',
          type: accountStatusValue,
        },
      ]
    })
  }

  public static removedSourcesVI(data: Array<RedStackModel>): VIMatrix {
    const removedSourcesData = data.filter((row) => row.isDeleted)
    const groupedByAwsAccount: Record<string, any> =
      DataHelper.groupedByAwsAccount(removedSourcesData)

    const uniqueRemovesSources = [...Object.values(groupedByAwsAccount)]
    return uniqueRemovesSources.map((row: any) => [
      // id
      {
        name: row.awsAccount,
      },
      // name
      {
        name: row.accountAlias,
      },
      // region
      {
        name: ArrHelper.unique(
          row.awsRegions.map((regionName: string) =>
            LangHelper.getAwsRegionSingleTranslation(regionName)
          )
        ).join(', '),
      },
      // status
      {
        name: 'Removed',
      },
    ])
  }

  public static vaultModelToTableRow(
    data: ReadonlyArray<VaultModel>,
    cloudConnectorsInAction: Array<string>
  ): VIMatrix {
    return data.map((vault: VaultModel) => [
      // vault
      {
        label: `${vault.name}${vault.isDefault ? ' (Default)' : ''}`,
        name: vault.innerId,
        value: vault.isDefault,
      },
      // assets
      {
        name: String(vault.assetsCount),
      },
      // allocated
      {
        name: vault.totalOptimizedSizeFormatted,
      },
      // backup size
      {
        name: vault.totalRecoveryPointSizeFormatted,
      },
      // reduction
      {
        name: vault.reductionFactorFormatted,
      },
      // replication - not implemented so far
      {
        name: '',
      },
      // repair button
      {
        name: vault.name,
        value: vault.redStackId,
        disabled: cloudConnectorsInAction.includes(vault.redStackId),
      },
      // buttons - it might be removed soon
      // type 0 - empty
      // type 1 - pin button
      // type 2 - unpin button
      {
        name: vault.innerId,
        // for testing purposes in demo mode
        type: vault.name === 'used' ? 1 : 2,
      },
    ])
  }

  public static vaultReplication(): VIMatrix {
    return [
      [
        {
          name: 'US-East-1',
        },
        {
          name: 'Region',
        },
        {
          name: '-',
        },
        {
          name: '-',
        },
        {
          name: '',
        },
        {
          name: '',
        },
      ],
      [
        {
          name: 'Test-Dev (Default)',
        },
        {
          name: 'Account',
        },
        {
          name: '-',
        },
        {
          name: '-',
        },
        {
          name: '',
        },
        {
          name: '',
        },
      ],
    ]
  }

  public static rolesTableAllScopesGrouped(
    possibleRoles: Array<UserRoleModel>
  ): VIRow {
    const allScopes = possibleRoles.reduce(
      (generalArr: Array<string>, currentRole) =>
        generalArr.concat(currentRole.scopesList),
      []
    )
    return DataHelper.groupScopes(allScopes)
  }

  public static rolesTableScopesCategories(
    possibleRoles: Array<UserRoleModel>
  ): Array<string> {
    return TableFactory.rolesTableAllScopesGrouped(possibleRoles).map(
      (elem) => elem.name
    )
  }

  public static possibleRolesTable(
    possibleRoles: Array<UserRoleModel>,
    allScopesGrouped: VIRow
  ): Array<RowSimpleUnit> {
    const tableData: Array<RowSimpleUnit> = []
    allScopesGrouped.forEach((elem: ValueInterface, index: number) => {
      const tableElem: RowSimpleUnit = [
        index,
        elem.options?.map((v) => v.name).join(', ') ?? '',
      ]
      possibleRoles.forEach((role) => {
        let mask = ''
        elem.options?.forEach((viScope: ValueInterface) => {
          mask +=
            (role?.scopesList.includes(viScope.label ?? '') ? '1' : '0') ?? '0'
        })
        tableElem.push(mask)
      })

      tableData.push(tableElem)
    })
    return tableData
  }

  public static sourceIdTable(
    redStacks: Array<RedStackModel>,
    vpcList: Array<VpcModel>,
    regions: Array<ValueInterface>
  ): VIMatrix {
    const connectAndDisconnectIds = redStacks.filter(
      (row: RedStackModel) =>
        row.isActive ||
        row.isInactive ||
        row.status === RedStackStatusConstant.UPGRADE_REQUIRED
    )

    const groupedPossibleVPC = DataHelper.groupByField(vpcList, 'regionName')

    return regions.map((region) => {
      const currentRSForRegions = connectAndDisconnectIds.find(
        (rs: RedStackModel) => region.name === rs.awsRegion
      )

      const vpcLabelList: VIRow = ArrHelper.flatDeep(
        [...groupedPossibleVPC.entries()].map(([regionName, vpcArr]) =>
          regionName === region.name ? vpcArr.map(VpcFactory.fromModelToVi) : []
        )
      )

      let subnetsLabelList: VIRow = []

      vpcLabelList.forEach((currentVpc: ValueInterface) => {
        const subnetsLabelListForVpc: VIRow = ArrHelper.flatDeep(
          [...groupedPossibleVPC.entries()].map(([regionName, vpcArr]) =>
            regionName === region.name
              ? vpcArr
                  .filter((m) => m.innerId === currentVpc.name)[0]
                  ?.subnetsList?.map((el) =>
                    VpcFactory.fromSubnetModelAndVpcNameToVi(
                      el,
                      currentVpc.name,
                      regionName
                    )
                  )
              : []
          )
        )

        subnetsLabelList = [...subnetsLabelList, ...subnetsLabelListForVpc]
      })

      let subnetsLabelSelectedList: VIRow = []

      vpcLabelList.forEach((currentVpc: ValueInterface) => {
        const subnetsSelectedListForVpc: VIRow = ArrHelper.flatDeep(
          [...groupedPossibleVPC.entries()].map(([regionName, vpcArr]) =>
            regionName === region.name
              ? vpcArr
                  .filter((m) => m.innerId === currentVpc.name)[0]
                  ?.subnetsList?.filter((s) => s.isPublic)
                  .map((el) =>
                    VpcFactory.fromSubnetModelAndVpcNameToVi(
                      el,
                      currentVpc.name,
                      regionName
                    )
                  )
              : []
          )
        )

        subnetsLabelSelectedList = [
          ...subnetsLabelSelectedList,
          ...subnetsSelectedListForVpc,
        ]
      })

      const label =
        currentRSForRegions?.status ===
          RedStackStatusConstant.UPGRADE_REQUIRED &&
        currentRSForRegions?.redStackId
          ? 'Connected (Upgrade Required)'
          : currentRSForRegions?.isActive
          ? 'Connected'
          : 'Disconnected'

      return [
        {
          name: region.name,
          label: region.label,
          options: vpcLabelList,
        },
        {
          name: currentRSForRegions?.redStackId ?? '',
          value: currentRSForRegions?.isActive ?? false,
          defaultValue: currentRSForRegions?.isActive ?? false,
          label,
          extraValue: region.name,
          options: vpcLabelList,
          selectedOption: vpcLabelList[0],
          matrix: [subnetsLabelList, subnetsLabelSelectedList ?? []],
        },
      ]
    })
  }

  public static policiesTable(policies: Array<PolicyModel>): VIMatrix {
    return policies.map(
      (p): VIRow => [
        // name
        {
          name: p.policyName,
          value: p.innerId,
          extraValue: p.started,
        },
        // status
        {
          name:
            p.status === 'Paused'
              ? p.schedule.pausedUntil
                ? 'Paused'
                : 'Disabled'
              : 'Active',
        },
        // Last Run
        {
          name: String(p.previousRun || ''),
        },
        // Next Run
        {
          name: p.status,
        },
        // pause/resume
        {
          name: p.policyName,
          value: p.innerId,
          extraValue: p.started,
        },
      ]
    )
  }

  public static policiesDefaultTable(
    policies: Array<PolicyModel>,
    selectedPolicyName: string
  ): VIMatrix {
    return policies.map(
      (policy): VIRow => [
        // name
        {
          name: policy.policyName,
          value: selectedPolicyName === policy.policyName,
          extraValue: policy.kind,
        },
      ]
    )
  }

  public static policiesRetentionTable(
    policies: Array<RetentionPolicyModel>
  ): VIMatrix {
    const tableMatrix: VIMatrix = []

    policies.map((p) => {
      const tableRow: VIRow = []
      tableRow.push(
        // name
        {
          name: p.policyName,
          value: p.innerId,
        },
        // status
        {
          name: p.status,
        }
      )
      tableRow.push(
        // retention
        {
          name: p.retention,
        },
        // pause/resume
        {
          name: p.policyName,
          value: p.innerId,
          extraValue: p.started,
        }
      )

      tableMatrix.push(tableRow)
    })

    return tableMatrix
  }

  public static webhookEventsTable(
    events: Array<WebhookValueInterface>
  ): VIMatrix {
    const severitiesToStrings = (severities?: Array<Severity>): string => {
      if (!severities) {
        return ''
      }

      const strings = severities.map((severity) => {
        switch (severity) {
          case Severity.ERROR:
            return 'Error'
          case Severity.WARNING:
            return 'Warning'
          case Severity.INFO:
            return 'Info'
          case Severity.TRACE:
            return 'Trace'
          default:
            throw new Error(`Unrecognized severity value: ${severity}`)
        }
      })
      return strings.join(', ')
    }
    return events.map(
      (p): VIRow => [
        // type
        {
          name: DataHelper.getWebhooksEventType(p.eventType),
        },
        // version
        {
          name: String(p.version).toUpperCase(),
        },
        // severity
        {
          name: severitiesToStrings(p.severities),
        },
        // delete
        {
          name: p.eventType,
          id: p.id,
        },
      ]
    )
  }

  public static webhooksTable(webhooks: Array<WebhookModel>): VIMatrix {
    return webhooks.map(
      (p): VIRow => [
        // name
        {
          name: p.getName(),
          value: p.getId(),
        },
        // Endpoint URL
        {
          name: p.getEndpoint(),
        },
        // Description
        {
          name: p.getDescription(),
        },
        // test run
        {
          name: p.getName(),
          value: p.getId(),
          extraValue: p.getId(),
        },
        {
          name: p.getName(),
          value: p.getId(),
        },
        {
          name: p.getName(),
          value: p.getId(),
        },
      ]
    )
  }

  public static dashboardAccountUsage(
    accountStatistic: Array<AggregatedStatisticsBySource>,
    allDataStatistic?: Array<AggregatedStatisticsBySource>
  ): VIMatrix {
    const accountData = accountStatistic.map((account) => [
      {
        name: account.sourceAlias
          ? `${account.sourceAlias} (${account.sourceId})`
          : account.sourceId,
      },
      {
        name: `${account.assetCount}`,
      },
      {
        name: account.regions.join(', ') || '',
      },
      // Assets status, uncomment when data from backend comes back
      //{
      //name: '',
      //},
      {
        name: StrHelper.realFileSize(account.totalUsage),
      },
    ])

    const totalNumberOfAssets = (allDataStatistic ?? accountStatistic).reduce(
      (accum, currAccount) => accum + currAccount.assetCount,
      0
    )

    const totalSize = (allDataStatistic ?? accountStatistic).reduce(
      (accum, currAccount) => accum + currAccount.totalUsage,
      0
    )

    const total = [
      {
        name: 'Totals',
      },
      {
        name: `${totalNumberOfAssets}`,
      },
      {
        name: '',
      },
      {
        name: StrHelper.realFileSize(totalSize),
      },
    ]

    return [...accountData, total]
  }

  public static policiesDashboardTable(policies: Array<PolicyModel>): VIMatrix {
    const policyRows = policies.map(
      (p): VIRow => [
        // name
        {
          name: p.policyName,
          value: p.innerId,
          extraValue: p.started,
        },
        {
          name:
            p.status === 'Paused'
              ? p.schedule.pausedUntil
                ? 'Paused'
                : 'Disabled'
              : 'Active',
        },
        // frequency
        {
          name: p.schedule.frequency.name,
        },
        {
          name:
            p.status === 'Paused'
              ? '-'
              : p.status.startsWith('Next run: ')
              ? `Next run: ${extractDate(p.status)}`
              : p.status,
        },
        // last protected
        {
          name: p.previousRun ? `${p.previousRun}` : '-',
        },
        // assets
        {
          name: String(p.assetsCount),
          // policy id
          extraValue: p.policyName,
        },
      ]
    )
    const totalsRow = [
      { name: 'Total' },
      { name: '' },
      { name: '' },
      { name: '' },
      { name: '' },
      {
        name: String(
          policies.reduce(
            (accum, currPolicy) => accum + currPolicy.assetsCount,
            0
          )
        ),
      },
    ]
    return [...policyRows, totalsRow]
  }

  public static vaultModelToDashboardVaultListTableRow(
    data: Array<VaultModel>
  ): VIMatrix {
    const groupedVaultsByAccountId = DataHelper.groupByField<
      string,
      VaultModel
    >(data, 'accountId', 'innerId')
    const accounts = [...groupedVaultsByAccountId.entries()]
    const getVaultCellData = (vaults: Array<VaultModel>): ValueInterface =>
      vaults.length > 1
        ? {
            name: '',
            label: `${vaults.length} Vaults`,
          }
        : {
            label: `${vaults[0]?.name ?? ''}${
              vaults[0]?.isDefault ? ' (Default)' : ''
            }`,
            name: vaults[0]?.innerId ?? '',
            value: vaults[0]?.isDefault ?? '',
          }

    const getReductionFactorFormatted = (vaults: Array<VaultModel>) => {
      const reductionFactors = new Set(
        vaults.map((vault) => vault.reductionFactor)
      )

      return reductionFactors.size > 1
        ? `${NumHelper.roundTo(
            Math.min(...reductionFactors),
            2
          )} - ${NumHelper.roundTo(Math.max(...reductionFactors), 2)}x`
        : vaults[0]?.reductionFactorFormatted ?? ''
    }

    return accounts.map(([accountId, vaults]) => {
      const accountVaultsGroupedByRegion = DataHelper.groupByField<
        string,
        VaultModel
      >(vaults, 'region', 'innerId')

      return [
        {
          name: accountId,
          matrix:
            vaults.length > 1
              ? [...accountVaultsGroupedByRegion.entries()].map(
                  ([region, regionVaults]) => [
                    {
                      name: '',
                      matrix:
                        regionVaults.length > 1
                          ? regionVaults.map((regionVault) => [
                              // account
                              {
                                name: '',
                              },
                              // region
                              { name: '' },
                              // vaults cell
                              getVaultCellData([regionVault]),
                              // data protected
                              {
                                name: regionVault.totalRecoveryPointSizeFormatted,
                              },
                              // reduction
                              {
                                name: getReductionFactorFormatted([
                                  regionVault,
                                ]),
                              },
                            ])
                          : [],
                    },
                    // region
                    {
                      name: region,
                    },
                    // vaults cell
                    getVaultCellData(regionVaults),
                    // data protected
                    {
                      name: StrHelper.realFileSize(
                        regionVaults.reduce(
                          (accum, vault) =>
                            accum + vault.totalRecoveryPointSize,
                          0
                        )
                      ),
                    },
                    // reduction
                    {
                      name: getReductionFactorFormatted(regionVaults),
                    },
                  ]
                )
              : [],
        },
        // region
        {
          name:
            accountVaultsGroupedByRegion.size > 1
              ? `${accountVaultsGroupedByRegion.size} Regions`
              : [...accountVaultsGroupedByRegion.keys()][0] ?? '',
        },
        // vaults cell
        getVaultCellData(vaults),
        // data protected
        {
          name: StrHelper.realFileSize(
            vaults.reduce(
              (accum, vault) => accum + vault.totalRecoveryPointSize,
              0
            )
          ),
        },
        // reduction
        {
          name: getReductionFactorFormatted(vaults),
        },
      ]
    })
  }

  public static assetsPolicyRetentionTable(
    assets: Array<InstanceModel | VolumeModel>
  ): VIMatrix {
    const assetsList = assets.map((asset): VIRow => {
      const assetType = DataHelper.getParsedInventoryAssetType(
        asset.getCloudProviderAssetId()
      )
      return [
        // ResourceID
        {
          name: asset.getParsedCloudProviderAssetId(),
          value: asset.getParsedCloudProviderAssetId()
            ? PageHelper.buildItemUrl(
                `${asset.getParsedCloudProviderAssetId()}`,
                assetType === AssetKind.AWS_EC2 ? 'instance' : 'volume'
              )
            : '',
          label: asset.getName(),
        },
        //Type
        { name: LangHelper.getAssetKindSingleTranslation(assetType) },
        //Account ID
        { name: asset.getAccountId() },
        //Region
        {
          name: LangHelper.getAwsRegionSingleTranslation(asset.getRegion()),
        },
      ]
    })

    return assetsList
  }

  public static missingAssetsTable(data: VIRow): VIMatrix {
    return data.map(
      (v: ValueInterface): VIRow => [
        {
          name: String(v.name),
          value: true,
        },
        { name: String(v.name) },
        {
          name: DataHelper.getAssetSourceName(v.type),
          value: v.type,
        },
        { name: String(v.value) },
        { name: LangHelper.getAwsRegionSingleTranslation(String(v.label)) },
      ]
    )
  }

  public static shceduledReportsTable(
    data: Array<ReportScheduleRequestInterface>
  ): VIMatrix {
    return data.map(
      (v: ReportScheduleRequestInterface): VIRow => [
        {
          name: String(v.id),
          value: v.reportKind,
        },
        { name: String(v.id) },
      ]
    )
  }

  public static webhooksEvents(data: Array<WebhookValueInterface>): VIMatrix {
    const severitiesToStrings = (severities?: Array<Severity>): string => {
      if (!severities) {
        return ''
      }

      const strings = severities.map((severity) => {
        switch (severity) {
          case Severity.ERROR:
            return 'Error'
          case Severity.WARNING:
            return 'Warning'
          case Severity.INFO:
            return 'Info'
          case Severity.TRACE:
            return 'Trace'
          default:
            throw new Error(`Unrecognized severity value: ${severity}`)
        }
      })
      return strings.join(', ')
    }
    return data.map(
      (v: WebhookValueInterface): VIRow => [
        {
          // Event type
          name: DataHelper.getWebhooksEventType(v.eventType),
        },
        // Version
        { name: String(v.version).toUpperCase() },
        // Severity
        {
          name: severitiesToStrings(v.severities as Array<Severity>),
        },

        // Delete icon
        {
          name: '',
          value: v.id,
        },
      ]
    )
  }

  public static assetsVIWithSortingByTypeAndSelected(
    assets: Array<AssetWithRelatedAssets<Asset>>,
    selectedAssets: Array<AssetWithRelatedAssets<Asset>>,
    assetsMissingInLiveAssets: Array<string>,
    lastBackups: Map<string, ElastioRecoveryPoint> | null
  ): Record<string, VIMatrix> {
    const data = TableFactory.assetsInventoryPoliciesVI(
      assets,
      assetsMissingInLiveAssets,
      lastBackups
    )

    // set up checkboxes
    data?.forEach((row: VIRow) => {
      const isSelected = selectedAssets.find((v) => v.asset.id === row[0]?.id)

      if (row[0]?.value !== undefined) {
        // main assets checked
        row[0].value =
          isSelected && !row[0]?.selectedOption?.disabled
            ? CheckboxConstant.Checked
            : CheckboxConstant.Empty
      }

      if (row[0]?.matrix?.length !== 0) {
        row[0]?.matrix?.forEach((subRowId) => {
          const isSubSelected = selectedAssets.find(
            (vv) => vv.asset?.id === subRowId[0]?.id
          )

          if (row[0]?.value !== undefined) {
            // main asset checked
            row[0].value = isSelected
              ? CheckboxConstant.Checked
              : CheckboxConstant.Empty

            if (isSubSelected) {
              // sub asset disabled and Indeterminated
              row[0].value = CheckboxConstant.Indeterminate

              if (row[0]?.selectedOption) {
                row[0].selectedOption.disabled = true
              }
            }
          }
        })
      }
      return row
    })

    return {
      EBS: data.filter(
        (row) =>
          row[0]?.selectedOption?.type ===
          AssetKindParsedConstant.KIND_AWS_EBS.value
      ),
      EC2: data.filter(
        (row) =>
          row[0]?.selectedOption?.type ===
          AssetKindParsedConstant.KIND_AWS_EC2.value
      ),
      S3: data.filter(
        (row) =>
          row[0]?.selectedOption?.type ===
          AssetKindParsedConstant.KIND_S3_BUCKET.value
      ),
      EFS: data.filter(
        (row) =>
          row[0]?.selectedOption?.type ===
          AssetKindParsedConstant.KIND_AWS_EFS.value
      ),
      othersAssetKind: data.filter(
        (row) =>
          row[0]?.selectedOption?.type !==
            AssetKindParsedConstant.KIND_AWS_EBS.value &&
          row[0]?.selectedOption?.type !==
            AssetKindParsedConstant.KIND_AWS_EC2.value &&
          row[0]?.selectedOption?.type !==
            AssetKindParsedConstant.KIND_S3_BUCKET.value &&
          row[0]?.selectedOption?.type !==
            AssetKindParsedConstant.KIND_AWS_EFS.value
      ),
    }
  }

  public static assetsS3PoliciesVI(
    data: Array<AssetWithRelatedAssets<Asset>>
  ): VIMatrix {
    return data?.map((item) => {
      const assetTypeString = LangHelper.getAssetKindSingleTranslation(
        item.type
      )

      const result: VIRow = [
        {
          id: item.asset?.id,
          name: '',
          type: AssetsEntityType.Checkbox,
          value: CheckboxConstant.Empty,
          selectedOption: {
            name: String(item.asset?.id),
            label: item.asset?.id,
            //disabled: item.asset?.isProtected(),
            // value: asset.accountAliasName,
            extraValue: item.asset?.awsAccountId,
            type: item.type,
            selectedOption: {
              name: LangHelper.getAwsRegionSingleTranslation(
                item.asset?.awsRegion
              ),
              label: String(item.asset?.awsRegion),
            },
          },
          matrix: [],
        },
        {
          name: '',
          type: AssetsEntityType.Icon,
        },
        {
          type: AssetsEntityType.Link,
          name: '',
          // TODO: uncommented link, when s3 page will be ready)
          // item?.asset?.getCloudConnectorId() &&
          // item?.asset?.getCloudProviderAssetId()
          //   ? PageHelper.buildItemUrl(
          //       item.asset?.getParsedCloudProviderAssetId(),
          //       TypeHelper.getAssetKindForInventory(item.type)
          //     )
          //   : '',
          label: item?.asset?.awsId,
          value: String(item?.asset?.name),
        },
        { name: assetTypeString },
        { name: item?.asset?.awsAccountId },
        {
          name: LangHelper.getAwsRegionSingleTranslation(
            item?.asset?.awsRegion
          ),
        },
        {
          label:
            item?.asset.backupPolicies.length > 0
              ? item?.asset?.backupPolicies?.join()
              : 'Not assigned',
          type:
            item?.asset.backupPolicies.length > 0
              ? AssetsEntityType.Link
              : AssetsEntityType.Text,
          name: PagePathConstant.POLICIES,
        },
        {
          id: item.asset?.id,
          name: '',
          label: 'All paths',
          //disabled: item.asset?.isProtected(),
          value: CheckboxConstant.Empty,
        },
        {
          id: item.asset?.id,
          name: '',
          label: 'Select paths...',
          //disabled: item.asset?.isProtected(),
        },
      ]
      return result
    })
  }

  public static assetsVIS3WithSelected(
    assets: Array<AssetWithRelatedAssets<Asset>>,
    selectedAssets: Array<AssetWithRelatedAssets<Asset>>,
    selectedAllPaths: Array<string>,
    selectedSpecificPaths: Array<SelectSpecificPathsInterface>
  ): VIMatrix {
    const data = TableFactory.assetsS3PoliciesVI(assets)
    // set up checkboxes
    data?.forEach((row: VIRow) => {
      const isSelected = selectedAssets?.find((v) => v.asset?.id === row[0]?.id)
      const isAllPathsSelected = selectedAllPaths?.find((v) => v === row[7]?.id)
      const specificPaths = selectedSpecificPaths?.find(
        (v) => v.asset.asset?.id === row[0]?.id
      )
      if (row[0]?.value !== undefined) {
        // main assets checked
        row[0].value =
          isSelected && !row[0]?.selectedOption?.disabled
            ? CheckboxConstant.Checked
            : CheckboxConstant.Empty
      }

      if (row[0]?.matrix !== undefined) {
        if (
          specificPaths !== undefined &&
          specificPaths.selectedPaths !== undefined
        ) {
          Object.entries(specificPaths.selectedPaths).forEach(
            ([key, value]: [string, Array<string>]) => {
              row[0]?.matrix?.push([
                {
                  name: key,
                  messages: value,
                },
              ])
            }
          )
        } else {
          row[0].matrix = []
        }
      }

      if (row[7]?.value !== undefined) {
        // all paths checked
        row[7].value =
          isAllPathsSelected && !row[7]?.disabled
            ? CheckboxConstant.Checked
            : CheckboxConstant.Empty

        if (row[8]?.disabled !== undefined) {
          row[8].disabled =
            row[7]?.value === CheckboxConstant.Checked ? true : false
        }
      }

      return row
    })

    return data
  }

  public static currentMonthUsage(
    accountStatistic: Array<AggregatedStatistics>
  ): VIMatrix {
    const accountData = accountStatistic.map((account) => {
      const dataSize = StrHelper.realFileSizeVi(account.totalUsage)
      return [
        {
          name: account.sourceAlias
            ? `${account.sourceAlias} (${account.sourceId})`
            : account.sourceId,
        },
        {
          name: `${account.assetCount}`,
        },
        {
          name: LangHelper.getAwsRegionSingleTranslation(account.cloudRegion),
        },
        {
          name: dataSize.name,
          value: dataSize.value,
        },
      ]
    })

    const totalNumberOfAssets = accountStatistic.reduce(
      (accum, currAccount) => accum + currAccount.assetCount,
      0
    )

    const totalSize = accountStatistic.reduce(
      (accum, currAccount) => accum + currAccount.totalUsage,
      0
    )

    const totalNumberOfAccounts = new Set([
      ...accountStatistic?.map((acc) => acc.sourceId),
    ]).size

    const totalNumberOfUniqueRegions = new Set([
      ...accountStatistic?.map((acc) => acc.cloudRegion),
    ]).size

    const total = [
      {
        name: 'Tot. accounts',
        value: totalNumberOfAccounts,
      },
      {
        name: 'Tot. assets',
        value: totalNumberOfAssets,
      },
      {
        name: 'Tot. regions',
        value: totalNumberOfUniqueRegions,
      },
      {
        name: 'Tot. size',
        value: StrHelper.realFileSizeVi(totalSize).value,
        extraValue: StrHelper.realFileSizeVi(totalSize).name,
      },
    ]

    return [...accountData, total]
  }

  public static quarantinedVolumesThreatsWidget(
    volumes: Array<VolumeModel>
  ): VIMatrix {
    return volumes?.map(
      (volumeModel): VIRow => [
        // Name
        {
          name: DataHelper.protectedObjectIdParsed(
            volumeModel.getCloudProviderVolumeId()
          ),
          value: volumeModel.getThreatStatus()?.threatScanReportId,
        },

        // Region
        {
          name: LangHelper.getAwsRegionSingleTranslation(
            volumeModel.getRegion()
          ),
        },

        // Download button
        {
          name: '',
          value: volumeModel.getThreatStatus()?.threatScanReportId,
        },
      ]
    )
  }

  public static volumesForInstance(
    volumes: Array<VolumeModel>,
    dashboardData: InventoryModel
  ): VIMatrix {
    const volumeDetailsData = volumes.map((volume): VIRow => {
      const snapshotsByVolume =
        dashboardData?.getSnapshotsByVolume()?.get(volume?.getId() ?? '') ?? []
      const isVulnerability =
        dashboardData?.volumeVulnerabilitiesList(volume, snapshotsByVolume)
          ?.length === 0
          ? false
          : true

      return [
        // fs check status, iscan status and vulnerabilities
        {
          name: '',
          value: isVulnerability
            ? IntegrityStatus.FAILED
            : Number(volume?.getIntegrityStatus()),
        },
        // volume ID, name
        {
          name: volume.getParsedCloudProviderVolumeId(),
          value: this.getVolumeID(volume.getParsedCloudProviderVolumeId()),
          label: volume.getName() ?? '',
        },
        // Tags
        {
          name: '',
          tags: volume?.getTags()?.length ? volume?.getTags() : [],
        },
        // size
        {
          name: '',
          value: NumHelper.getSizeFormattedInGib(volume.getInventorySize()),
        },
        // Created
        {
          name: `${TimeHelper.timestampShortDatetimeFormat(
            volume?.getCreatedAt(),
            TimeFormatConstants.SHORT_DATETIME_FORMAT_SMALL
          )} (${String(
            TimeHelper.getAgeInMonthsOrDays(volume?.getCreatedAt(), true)
          )})`,
          // countDaysOrMonth
          extraValue: true,
        },
      ]
    })
    return [...volumeDetailsData]
  }

  public static assetsThreatsInstances(
    instances: Array<InstanceWithVolumes>
  ): VIMatrix {
    return instances.map(({ instance, volumesList }): VIRow => {
      const volumeByInstance = instance.getVolumeIds()

      const threats = (
        instance.isFsCheckFailed()
          ? [...instance.getUniqueThreatNames(), 'Integrity Scan Failed']
          : [...instance.getUniqueThreatNames()]
      ).join(', ')

      const lastBackup = instance.getThreatStatus()?.recoveryPointCreatedAt ?? 0

      return [
        {
          name: '',
          value: false,
        },
        {
          // Instance ID
          name: instance.getParsedCloudProviderInstanceId(),
          value: instance.getParsedCloudProviderInstanceId()
            ? PageHelper.buildItemUrl(
                `${instance.getParsedCloudProviderInstanceId()}`,
                'instance'
              )
            : '',
          label: instance.getName(), // Name of instance
        },
        {
          // Threats
          name: threats ?? '',
          value: 'Threats',
        },
        {
          // Account Id
          name: instance.getAccountId(),
          value: 'Account',
        },
        {
          // Region
          name: LangHelper.getAwsRegionSingleTranslation(instance.getRegion()),
          value: LangHelper.getAwsRegionSingleTranslation(instance.getRegion()),
        },
        {
          // OsKind
          name: StrHelper.getOsNameByType(instance.getOsKind()),
          value: StrHelper.getOsNameByType(instance.getOsKind()),
        },
        {
          // Attached volumes
          name: 'Attached volumes',
          value: volumesList.filter((volume) =>
            volumeByInstance.includes(volume.getId())
          ).length,
        },
        {
          // Status
          name: StrHelper.getInstanceStateName(instance.getState()),
          value: StrHelper.getInstanceStateName(instance.getState()),
        },
        {
          // Last backup
          name: Boolean(lastBackup)
            ? `${TimeHelper.timestampShortDatetimeFormat(
                lastBackup ?? 0,
                TimeFormatConstants.SHORT_DATETIME_FORMAT_SMALL
              )}`
            : '',
          //  time on second line format
          value: true,
        },
      ]
    })
  }

  public static assetsThreatsVolumes(
    volumes: Array<VolumeWithInstance>
  ): VIMatrix {
    return volumes?.map(({ volume, instances }): VIRow => {
      const threats = (
        volume.isFsCheckFailed()
          ? [...volume.getUniqueThreatNames(), 'Integrity Scan Failed']
          : [...volume.getUniqueThreatNames()]
      ).join(', ')

      const instancesParsed = instances
        .map((instance) => `${instance.getParsedCloudProviderInstanceId()}\n`)
        .join('')

      const lastBackup = volume.getThreatStatus()?.recoveryPointCreatedAt ?? 0

      return [
        {
          name: '',
          value: false,
        },
        // ID
        {
          name: volume.getParsedCloudProviderVolumeId(),
          value: volume.getParsedCloudProviderVolumeId()
            ? PageHelper.buildItemUrl(
                `${volume.getParsedCloudProviderVolumeId()}`,
                'volume'
              )
            : '',
          label: volume.getName(),
        },
        // Threats
        {
          name: '',
          value: threats,
        },
        // Account no
        {
          name: 'Account',
          value: volume.getAccountId(),
        },
        // Region
        {
          name: 'Region',
          value: LangHelper.getAwsRegionSingleTranslation(volume.getRegion()),
        },
        // Attached to the instances
        {
          name: 'Attached to the instances',
          value: instancesParsed,
        },
        {
          // Status
          name: 'Status',
          value: instances.some((instance) =>
            instance.getVolumeIds().includes(volume.getId())
          )
            ? 'Attached'
            : 'Unattached',
        },
        {
          // Last backup
          name: Boolean(lastBackup)
            ? `${TimeHelper.timestampShortDatetimeFormat(
                lastBackup ?? 0,
                TimeFormatConstants.SHORT_DATETIME_FORMAT_SMALL
              )}`
            : '',
          //  time on second line format
          value: true,
        },
      ]
    })
  }

  public static assetsEc2Vulnerabilities(
    instances: Array<InstanceModel>,
    dashboardData?: Nullable<InventoryModel>,
    riskFilter?: Array<number>
  ): VIMatrix {
    return instances.map((instance) => {
      const ebsVulnerabilities = new Set<string>()
      const risks: Set<RiskLevel> = new Set()
      const attachedVolumes = (dashboardData
        ?.getVolumesWithVulnerabilities()
        .filter((volume) => instance.getVolumeIds().includes(volume.getId())) ||
        []) as Array<VolumeModel>

      attachedVolumes.forEach((volume) => {
        const volumeVulnerabilities = getVolumeVulnerabilities(
          dashboardData,
          volume
        )

        const vulnerabilitiesWithRisks = getVulnerabilitiesRisks(
          volumeVulnerabilities
        )

        const selectedVulnerabilities = vulnerabilitiesWithRisks.filter(
          ({ risk }) => !riskFilter?.length || riskFilter?.includes(risk)
        )

        const highestRisk = selectedVulnerabilities.reduce(
          (accum, curr) => Math.max(accum, curr.risk),
          0
        )

        risks.add(highestRisk)

        selectedVulnerabilities
          .map((v) => v.vulnerability)
          .forEach((vulnerability) => {
            ebsVulnerabilities.add(vulnerability as EbsVulnerabilityKind)
          })
      })

      const lastBackup = instance.getThreatStatus()?.recoveryPointCreatedAt ?? 0

      const highestRisk = [...risks].reduce(
        (accum, curr) => Math.max(accum, curr),
        0
      ) as number

      return [
        {
          name: '',
          value: false,
        },
        {
          // Instance ID
          name: instance.getParsedCloudProviderInstanceId(),
          value: instance.getParsedCloudProviderInstanceId()
            ? PageHelper.buildItemUrl(
                `${instance.getParsedCloudProviderInstanceId()}`,
                'instance'
              )
            : '',
          label: instance.getName(), // Name of instance
        },
        {
          // Vulnerabilities
          name: [...ebsVulnerabilities].join(', '),
          value: 'Misconfigurations',
        },
        {
          // Risk
          name: RiskLevel[highestRisk] as string,
        },
        {
          // Account Id
          name: instance.getAccountId(),
          value: 'Account',
        },
        {
          // Region
          name: LangHelper.getAwsRegionSingleTranslation(instance.getRegion()),
          value: LangHelper.getAwsRegionSingleTranslation(instance.getRegion()),
        },
        {
          // OsKind
          name: StrHelper.getOsNameByType(instance.getOsKind()),
          value: StrHelper.getOsNameByType(instance.getOsKind()),
        },
        {
          // Attached volumes
          name: 'Attached volumes',
          value: attachedVolumes.length,
        },
        {
          // Status
          name: StrHelper.getInstanceStateName(instance.getState()),
          value: StrHelper.getInstanceStateName(instance.getState()),
        },
        {
          // Last backup
          name: Boolean(lastBackup)
            ? `${TimeHelper.timestampShortDatetimeFormat(
                lastBackup ?? 0,
                TimeFormatConstants.SHORT_DATETIME_FORMAT_SMALL
              )}`
            : '',
          //  time on second line format
          value: true,
        },
      ]
    })
  }

  public static assetsEbsVulnerabilities(
    volumes: Array<VolumeWithInstance>,
    dashboardData: Nullable<InventoryModel>,
    riskFilter = [] as Array<number>
  ): VIMatrix {
    return volumes?.map(({ volume, instances }): VIRow => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const ebsVulnerabilities = getVolumeVulnerabilities(dashboardData, volume)

      const instancesParsed = instances
        .map((instance) => `${instance.getParsedCloudProviderInstanceId()}\n`)
        .join('')

      const lastBackup = volume.getThreatStatus()?.recoveryPointCreatedAt ?? 0

      const vulnerabilitiesWithRisks =
        getVulnerabilitiesRisks(ebsVulnerabilities)

      const selectedVulnerabilities = vulnerabilitiesWithRisks.filter(
        ({ risk }) => !riskFilter?.length || riskFilter?.includes(risk)
      )

      const highestRisk = selectedVulnerabilities.reduce(
        (accum, curr) => Math.max(accum, curr.risk),
        0
      )

      return [
        {
          name: '',
          value: false,
        },
        // ID
        {
          name: volume.getParsedCloudProviderVolumeId(),
          value: volume.getParsedCloudProviderVolumeId()
            ? PageHelper.buildItemUrl(
                `${volume.getParsedCloudProviderVolumeId()}`,
                'volume'
              )
            : '',
          label: volume.getName(),
        },
        // Vulnerabilities
        {
          name: 'Misconfigurations',
          value: [
            ...selectedVulnerabilities.map(
              ({ vulnerability }) => vulnerability
            ),
          ].join(', '),
        },
        {
          // Risk
          name: RiskLevel[highestRisk] as string,
        },
        // Account no
        {
          name: 'Account',
          value: volume.getAccountId(),
        },
        // Region
        {
          name: 'Region',
          value: LangHelper.getAwsRegionSingleTranslation(volume.getRegion()),
        },
        // Attached to the instances
        {
          name: 'Attached to the instances',
          value: instancesParsed,
        },
        {
          // Status
          name: 'Status',
          value: instances.some((instance) =>
            instance.getVolumeIds().includes(volume.getId())
          )
            ? 'Attached'
            : 'Unattached',
        },
        {
          // Last backup
          name: Boolean(lastBackup)
            ? `${TimeHelper.timestampShortDatetimeFormat(
                lastBackup ?? 0,
                TimeFormatConstants.SHORT_DATETIME_FORMAT_SMALL
              )}`
            : '',
          //  time on second line format
          value: true,
        },
      ]
    })
  }

  public static assetsSnapshots(
    snapshots: Array<SnapshotVulnerabilitiesWithTypes>,
    riskFilter?: Array<number>
  ): VIMatrix {
    return snapshots?.map(({ snapshot, snapshotVulnerabilityTypes }) => {
      const id = snapshot.getParsedCloudProviderSnapshotId()
      const volumeId = snapshot.getParsedCloudProviderVolumeId()
      const snapshotLink = snapshot.getParsedCloudProviderSnapshotId()
        ? PageHelper.buildItemUrl(
            `${snapshot?.getParsedCloudProviderSnapshotId()}`,
            'snapshot'
          )
        : ''
      const accountId = snapshot.getAccountId()
      const region = LangHelper.getAwsRegionSingleTranslation(
        snapshot.getRegion()
      )

      const snapshotsVulnerabilitiesWithRisk = getSnapshotVulnerabilitiesRisks(
        snapshotVulnerabilityTypes
      )

      const highestRisk = snapshotsVulnerabilitiesWithRisk.reduce(
        (accum, curr) => Math.max(accum, curr.risk),
        0
      )

      const vulnerabilities = snapshotsVulnerabilitiesWithRisk
        .filter(({ risk }) => !riskFilter?.length || riskFilter?.includes(risk))
        .map((v) => v.vulnerability)

      return [
        {
          name: '',
          value: false,
        },
        {
          name: id,
          value: snapshotLink,
        },
        {
          name: 'Misconfiguration',
          value: vulnerabilities.join(', '),
        },
        {
          // Risk
          name: RiskLevel[highestRisk] as string,
        },
        {
          name: volumeId,
          value: this.getVolumeID(volumeId),
        },
        {
          name: accountId,
        },
        {
          name: region,
        },
      ]
    })
  }

  public static assetsEc2RecoveryExposure(
    instances: Array<InstanceModel>,
    dashboardData?: Nullable<InventoryModel>
  ): VIMatrix {
    return instances.map((instance) => {
      const threats = (
        instance.isFsCheckFailed()
          ? [...instance.getUniqueThreatNames(), 'Integrity Scan Failed']
          : [...instance.getUniqueThreatNames()]
      ).join(', ')
      const ebsVulnerabilities = new Set<string>()
      const ebsVulnerabilitiesData = (dashboardData
        ?.getVolumesWithVulnerabilities()
        .filter((volume) => instance.getVolumeIds().includes(volume.getId())) ||
        []) as Array<VolumeModel>

      ebsVulnerabilitiesData.forEach((volume) => {
        const volumeVulnerabilities = getVolumeVulnerabilities(
          dashboardData,
          volume
        )

        volumeVulnerabilities?.forEach((vulnerability) => {
          ebsVulnerabilities.add(vulnerability)
        })
      })

      const lastBackup = instance.getThreatStatus()?.recoveryPointCreatedAt ?? 0

      return [
        {
          name: '',
          value: false,
        },
        {
          // Instance ID
          name: instance.getParsedCloudProviderInstanceId(),
          value: instance.getParsedCloudProviderInstanceId()
            ? PageHelper.buildItemUrl(
                `${instance.getParsedCloudProviderInstanceId()}`,
                'instance'
              )
            : '',
          label: instance.getName(), // Name of instance
        },
        {
          // Threats
          name: threats.length > 0 ? threats : '- -',
        },
        {
          // Vulnerabilities
          name:
            ebsVulnerabilities.size > 0
              ? [...ebsVulnerabilities].join(', ')
              : '- -',
        },
        {
          // Account ID
          name: instance.getAccountId(),
          value: 'Account',
        },
        {
          // Region
          name: LangHelper.getAwsRegionSingleTranslation(instance.getRegion()),
          value: LangHelper.getAwsRegionSingleTranslation(instance.getRegion()),
        },
        {
          // Status
          name: StrHelper.getInstanceStateName(instance.getState()),
        },
        {
          // Last backup
          name: Boolean(lastBackup)
            ? `${TimeHelper.timestampShortDatetimeFormat(
                lastBackup ?? 0,
                TimeFormatConstants.SHORT_DATETIME_FORMAT_SMALL
              )}`
            : '',
          //  time on second line format
          value: true,
        },
      ]
    })
  }

  public static assetsEbsRecoveryExposure(
    volumes: Array<VolumeWithInstance>,
    dashboardData?: Nullable<InventoryModel>
  ): VIMatrix {
    return volumes?.map(({ volume, instances }): VIRow => {
      const threats = (
        volume.isFsCheckFailed()
          ? [...volume.getUniqueThreatNames(), 'Integrity Scan Failed']
          : [...volume.getUniqueThreatNames()]
      ).join(', ')

      const instancesParsed = instances
        .map((instance) => `${instance.getParsedCloudProviderInstanceId()}\n`)
        .join('')

      const lastBackup = volume.getThreatStatus()?.recoveryPointCreatedAt ?? 0

      const ebsVulnerabilities =
        getVolumeVulnerabilities(dashboardData, volume).join(', ') ?? ''

      return [
        {
          name: '',
          value: false,
        },
        // ID
        {
          name: volume.getParsedCloudProviderVolumeId(),
          value: volume.getParsedCloudProviderVolumeId()
            ? PageHelper.buildItemUrl(
                `${volume.getParsedCloudProviderVolumeId()}`,
                'volume'
              )
            : '',
          label: volume.getName(),
        },
        // Threats
        {
          name: '',
          value: threats.length > 0 ? threats : '- -',
        },
        {
          // Vulnerabilities
          name: '',
          value: ebsVulnerabilities?.length > 0 ? ebsVulnerabilities : '- -',
        },
        // Account no
        {
          name: 'Account',
          value: volume.getAccountId(),
        },
        // Region
        {
          name: 'Region',
          value: LangHelper.getAwsRegionSingleTranslation(volume.getRegion()),
        },
        // Attached to the instances
        {
          name: 'Attached to the instances',
          value: instancesParsed,
        },
        {
          // Status
          name: 'Status',
          value: instances.some((instance) =>
            instance.getVolumeIds().includes(volume.getId())
          )
            ? 'Attached'
            : 'Unattached',
        },
        {
          // Last backup
          name: Boolean(lastBackup)
            ? `${TimeHelper.timestampShortDatetimeFormat(
                lastBackup ?? 0,
                TimeFormatConstants.SHORT_DATETIME_FORMAT_SMALL
              )}`
            : '',
          //  time on second line format
          value: true,
        },
      ]
    })
  }
}

export default TableFactory
