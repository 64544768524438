/* eslint-disable import/no-extraneous-dependencies */
import TimeHelper from '@lib/helpers/time.helper'
import { Backup, OvaBackupProviderProto } from '@lib/clients/grpc-imports'
import { OvaBackup } from 'blues-corejs/dist/models'
import { CheckStatus } from 'blues-corejs/dist/models/backups/ova/backup/types'
import { OvaBackupSnapshot } from 'blues-corejs/dist/models/backups/ova/backup/ova-backup-snapshot'
import { OvaBackupProvider } from 'blues-corejs/dist/models/ova-backup-provider'

export class OvaBackupTransformer {
  #backup: Backup.AsObject

  constructor(backup: Backup.AsObject) {
    this.#backup = backup
  }

  transform(): OvaBackup {
    return this.#transformBackup(this.#backup)
  }

  #transformBackup(backup: Backup.AsObject): OvaBackup {
    return new OvaBackup({
      backupProviderBackupId: backup.backupProviderBackupId,
      ccBackupId: backup.ccBackupId,
      createdAt: TimeHelper.secondsToDate(backup.createdAt?.seconds),
      id: backup.id,
      malwareCheckStatus: this.#convertCheckStatus(backup.malwareCheckStatus),
      provider: this.#convertBackupProvider(backup.provider),
      ransomwareCheckStatus: this.#convertCheckStatus(
        backup.ransomwareCheckStatus
      ),
      totalSize: backup.sizeInfo?.totalSize ?? 0,
      snapshots: this.#convertSnapshots(backup.snapshotsList),
      sourceAssetId: backup.sourceAssetId,
      scanIds: backup.scanIdsList,
      threatIds: backup.threatIdsList,
      timestamp: TimeHelper.secondsToDate(backup.timestamp?.seconds),
      updatedAt: TimeHelper.secondsToDate(backup.updatedAt?.seconds),
    })
  }

  #convertSnapshots(
    snapshots: Array<Backup.Snapshot.AsObject>
  ): Array<OvaBackupSnapshot> {
    return snapshots.map(
      (snapshot) =>
        new OvaBackupSnapshot({
          id: snapshot.id,
          ccVolumeId: snapshot.ccVolumeId,
          ovaVolumeId: snapshot.ovaVolumeId,
          backupProviderVolumeId: snapshot.backupProviderVolumeId,
          malwareCheckStatus: this.#convertCheckStatus(
            snapshot.malwareCheckStatus
          ),
          ransomwareCheckStatus: this.#convertCheckStatus(
            snapshot.ransomwareCheckStatus
          ),
          totalSize: snapshot.sizeInfo?.totalSize ?? 0,
          allocatedSize: snapshot.sizeInfo?.allocatedSize ?? 0,
          createdAt: TimeHelper.secondsToDate(snapshot.createdAt?.seconds),
          updatedAt: TimeHelper.secondsToDate(snapshot.updatedAt?.seconds),
        })
    )
  }

  #convertCheckStatus(checkStatus: Backup.CheckStatus): CheckStatus {
    const mapping = {
      [Backup.CheckStatus.CHECK_STATUS_UNCHECKED]: CheckStatus.UNCHECKED,
      [Backup.CheckStatus.CHECK_STATUS_NOT_APPLICABLE]:
        CheckStatus.NOT_APPLICABLE,
      [Backup.CheckStatus.CHECK_STATUS_BAD]: CheckStatus.BAD,
      [Backup.CheckStatus.CHECK_STATUS_GOOD]: CheckStatus.GOOD,
    }

    return mapping[checkStatus]
  }

  #convertBackupProvider(provider: OvaBackupProviderProto): OvaBackupProvider {
    const mapping: Record<OvaBackupProviderProto, OvaBackupProvider> = {
      [OvaBackupProvider.VERITAS_NET_BACKUP]:
        OvaBackupProvider.VERITAS_NET_BACKUP,
      [OvaBackupProvider.VEEAM]: OvaBackupProvider.VEEAM,
      [OvaBackupProvider.COHESITY]: OvaBackupProvider.COHESITY,
      [OvaBackupProvider.RUBRIK]: OvaBackupProvider.RUBRIK,
      [OvaBackupProvider.COMMVAULT]: OvaBackupProvider.COMMVAULT,
    }

    return mapping[provider]
  }
}
