import BaseModel from '@lib/models/base-model/base.model'
import StrHelper from '@lib/helpers/str.helper'
import NumHelper from '@lib/helpers/num.helper'

class VaultMetricsModel extends BaseModel {
  public readonly totalRecoveryPointSize: number

  public readonly reductionFactor: number

  public readonly assetsCountProtect: number

  public readonly assetsCountAcc: number

  public readonly costCpuUsd: number

  public readonly costStorageUsd: number

  // extra
  public readonly totalRecoveryPointSizeFormatted: string

  public readonly reductionFactorFormatted: string

  public readonly percentageAssetsFormatted: number

  public readonly costCpuUsdFormatted: string

  public readonly costStorageUsdFormatted: string

  constructor(readonly params: any = {}) {
    super(params)

    // base
    this.totalRecoveryPointSize = params.totalRecoveryPointSize
    this.reductionFactor = params.reductionFactor
    this.assetsCountProtect = params.assetsCountProtect
    this.assetsCountAcc = params.assetsCountAcc
    this.costCpuUsd = params.costCpuUsd
    this.costStorageUsd = params.costStorageUsd

    // extra
    this.totalRecoveryPointSizeFormatted = StrHelper.realFileSize(
      this.totalRecoveryPointSize,
      '0'
    )
    const reductionFactorFormatted = String(
      NumHelper.roundTo(this.reductionFactor, 2)
    )

    this.costCpuUsdFormatted =
      this.costCpuUsd !== 0
        ? `$ ${NumHelper.moneyFormat(this.costCpuUsd)}`
        : '0'

    this.costStorageUsdFormatted =
      this.costStorageUsd !== 0
        ? `$ ${NumHelper.moneyFormat(this.costStorageUsd)}`
        : '0'

    this.reductionFactorFormatted = reductionFactorFormatted
      ? `${reductionFactorFormatted}x`
      : '- - x'

    this.percentageAssetsFormatted =
      this.assetsCountAcc !== 0
        ? (this.assetsCountProtect * 100) / this.assetsCountAcc
        : 0
  }
}

export default VaultMetricsModel
