import { StatisticValueInterfaceType } from '@lib/constants/statistics.constant'
import { VIRow } from '@lib/engine-types'
import StrHelper from '@lib/helpers/str.helper'
import TypeHelper from '@lib/helpers/type.helper'
import CoveredByBackupPoliciesAssets from '@lib/interfaces/covered-by-backup-policies-assets.interface'
import RecoverabilityStatsInterface from '@lib/interfaces/recoverability-stats.interface'
import {
  AccountStatistics,
  AccountStatisticsList,
  AggregatedStatisticsBySource,
  AggregatedStatisticsList,
  AssetStatistics,
  AssetStatisticsList,
  RecoveryPointStatisticsList,
  RegionStatisticsList,
  VaultStatisticsList,
} from '@lib/interfaces/statistics.interface'
import CostAdvantagesModel from '@lib/models/cost-advantages.model'

export abstract class StatisticsFactory {
  public static groupStatisticBySource(
    list: AggregatedStatisticsList
  ): Array<AggregatedStatisticsBySource> {
    return Object.values(
      list.reduce(
        (
          acc: any,
          { sourceId, cloudRegion, assetCount, sourceAlias, totalUsage }
        ) => {
          acc[sourceId] = acc[sourceId] || {
            regions: [],
            assetCount: 0,
            totalUsage: 0,
            sourceId,
            sourceAlias,
          }
          return (
            (acc[sourceId].assetCount += assetCount),
            (acc[sourceId].totalUsage += totalUsage),
            acc[sourceId].regions.push(cloudRegion),
            (acc[sourceId].sourceId = sourceId),
            (acc[sourceId].sourceAlias = sourceAlias),
            acc
          )
        },
        {}
      )
    )
  }

  public static getAssetsNumberFromAccountStatistic(
    account: AccountStatistics
  ): number {
    return (
      account.regions?.regionsList.reduce(
        (accum, region) => accum + (region.assets?.assetsList.length || 0),
        0
      ) || 0
    )
  }

  public static buildRegionStatistics(
    regions: RegionStatisticsList,
    idHash = ''
  ): VIRow {
    return regions.map((region) => {
      const matrix = []

      if (region.accounts) {
        matrix.push(
          StatisticsFactory.buildAccountStatistics(region.accounts.accountsList)
        )
      }

      if (region.assets) {
        matrix.push(
          StatisticsFactory.buildAssetStatistics(region.assets.assetsList)
        )
      }

      return {
        id: StrHelper.makeHashNum(`${region.name}${idHash}`),
        name: region.name,
        value: region.size,
        label: StrHelper.realFileSize(region.size),
        matrix: matrix.length ? matrix : undefined,
        type: StatisticValueInterfaceType.REGION,
      }
    })
  }

  public static buildAssetStatistics(
    assets: AssetStatisticsList,
    idHash = ''
  ): VIRow {
    return assets.map((asset) => {
      const nameParts = asset.name.split(':')
      let name = asset.name
      if (nameParts.length > 0) {
        name = nameParts.pop() ?? asset.name
      }

      return {
        name,
        id: StrHelper.makeHashNum(`${asset.id}${asset.size}${idHash}`),
        value: asset.size,
        label: StrHelper.realFileSize(asset.size),
        matrix: !asset.recoveryPointsList
          ? undefined
          : [
              StatisticsFactory.buildRecoveryPointStatistics(
                asset.recoveryPointsList,
                asset
              ),
            ],
        type: StatisticValueInterfaceType.ASSET,
      }
    })
  }

  public static buildAccountStatistics(accounts: AccountStatisticsList): VIRow {
    return accounts.map((account) => {
      const matrix = []

      if (account.regions) {
        matrix.push(
          StatisticsFactory.buildRegionStatistics(
            account.regions.regionsList,
            account.id
          )
        )
      }

      if (account.assets) {
        matrix.push(
          StatisticsFactory.buildAssetStatistics(account.assets.assetsList)
        )
      }

      return {
        id: StrHelper.makeHashNum(
          `${account.id}${account.alias}${account.size}`
        ),
        name: account.alias || account.id,
        value: account.size,
        label: StrHelper.realFileSize(account.size),
        matrix: matrix.length ? matrix : undefined,
        type: StatisticValueInterfaceType.ACCOUNT,
      }
    })
  }

  public static buildVaultStatistics(vaults: VaultStatisticsList): VIRow {
    return vaults.map((vault) => ({
      id: StrHelper.makeHashNum(vault.id),
      name: vault.id,
      value: vault.size,
      label: StrHelper.realFileSize(vault.size),
      matrix: !vault.assetsList
        ? undefined
        : [StatisticsFactory.buildAssetStatistics(vault.assetsList, vault.id)],
      type: StatisticValueInterfaceType.VAULT,
    }))
  }

  public static buildRecoveryPointStatistics(
    recoveryPoints: RecoveryPointStatisticsList,
    asset: AssetStatistics
  ): VIRow {
    return recoveryPoints.map((recoveryPoint) => ({
      id: StrHelper.makeHashNum(recoveryPoint.id),
      name: `[${StrHelper.realFileSize(recoveryPoint.size)}] ${
        recoveryPoint.cloudConnectorRecoveryPointId
      }`,
      defaultValue: recoveryPoint.cloudConnectorRecoveryPointId,
      value: recoveryPoint.size,
      label: StrHelper.realFileSize(recoveryPoint.size),
      extraValue: asset.id,
      type: StatisticValueInterfaceType.RECOVERY_POINT,
    }))
  }

  public static buildCostAdvantages(params: any): CostAdvantagesModel {
    return new CostAdvantagesModel({
      percentProtectedEc2: TypeHelper.numberValDef(
        params.percentProtectedEc2,
        0
      ),
      percentProtectedEbs: TypeHelper.numberValDef(
        params.percentProtectedEbs,
        0
      ),
      awsBackupPriceUsd: TypeHelper.numberValDef(params.awsBackupPriceUsd, 0),
      elastioBackupPriceUsd: TypeHelper.numberValDef(
        params.elastioBackupPriceUsd,
        0
      ),
      isEmpty: false,
    })
  }

  public static buildCostAdvantagesEmpty(): CostAdvantagesModel {
    return new CostAdvantagesModel({
      percentProtectedEc2: 0,
      percentProtectedEbs: 0,
      awsBackupPriceUsd: 0,
      elastioBackupPriceUsd: 0,
      isEmpty: true,
    })
  }

  public static buildRecoverabilityStatsEmpty(): RecoverabilityStatsInterface {
    return {
      unscanned: 0,
      healthy: 0,
      quarantined: 0,
      manuallyRecoverable: 0,
      recoverabilityIndex: 0,
    }
  }

  public static buildCoveredByBackupPoliciesAssetsEmpty(): CoveredByBackupPoliciesAssets {
    return {
      covered: 0,
      paused: 0,
      notCovered: 0,
    }
  }
}
