import { UsersClient } from '@lib/clients'
import { useFetchUserConfig } from './use-fetch-user-config'
import { useFetchUserProfile } from './use-fetch-user-profile'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { PagePathConstant, UserSettings } from '@lib/constants'
import SettingsService from '@lib/services/high/settings.service'

const usersClient = new UsersClient()

export function useFetchUserData() {
  const isProfileFetched = useFetchUserProfile(usersClient)
  const isConfigFetched = useFetchUserConfig(usersClient)
  const router = useRouter()

  useEffect(() => {
    if (isProfileFetched && isConfigFetched) {
      const shouldRedirectToOnboarding =
        router.pathname !== PagePathConstant.ONBOARDING &&
        !SettingsService.getSetting(UserSettings.OnBoardingCongratulationsShown)

      if (shouldRedirectToOnboarding) {
        router.push(PagePathConstant.ONBOARDING)
      }
    }
  }, [isProfileFetched, isConfigFetched])
}
