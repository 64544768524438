import { ofType } from 'redux-observable'
import { exhaustMap, map } from 'rxjs/operators'
import {
  ENABLE_USER,
  INVITE_USER,
  REMOVE_USER,
  REQUEST_IS_MFA_ENABLED,
  REQUEST_POSSIBLE_ROLES,
  REQUEST_SESSIONS,
  REQUEST_UPDATE_USER,
  REQUEST_USER,
  REQUEST_USERS_LIST,
  requestUser,
  requestUsersList,
  RESEND_INVITE_USER,
  RESET_MFA,
  setIsMfaEnabled,
  setPossibleRoles,
  setSessions,
  setUser,
  setUsersList,
  UPDATE_MFA_ENABLED,
  UPDATE_PASSWORD,
  UPDATE_USER,
  VERIFY_USER,
  DISABLE_USER,
} from '@store/actions/user.action'
import StoreService from '@lib/services/store.service'
import PreloaderConstants from '@lib/constants/preloader.constant'
import ActionInterface from '@lib/interfaces/action.interface'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import GeneralService from '@lib/services/general.service'
import RequestService from '@lib/services/request.service'
import UserModel from '@lib/models/user.model'
import {
  baseRequestScenario,
  sentryReThrowCatchHandler,
  sentrySimpleReThrowHandler,
  toastRequestScenario,
} from '@store/epics/epic-func'
import { emptyAction } from '@store/actions/default/empty.action'
import Modal from '@lib/constants/modal.constant'
import ToastHelper from '@lib/helpers/toast.helper'
import SessionModel from '@lib/models/session.model'
import UserRoleModel from '@lib/models/user-role.model'
import PageHelper from '@lib/helpers/page.helper'
import PagePathConstant from '@lib/constants/page-path.constant'
import GrpcUsersService from '@lib/services/grpc/grpc-users.service'
import GrpcRbacService from '@lib/services/grpc/grpc-rbac.service'

export const requestUsersListEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_USERS_LIST],
      PreloaderConstants.REQUEST_USERS_LIST,
      () =>
        GrpcUsersService.list().catch(
          sentryReThrowCatchHandler('Cannot fetch a list of users')
        ),
      (usersList: Array<UserModel>) => setUsersList(usersList),
      ErrorGroupConstants.USER
    )
  )

export const removeUserEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REMOVE_USER],
      PreloaderConstants.REMOVE_USER,
      (action: ActionInterface) =>
        GrpcUsersService.deleteUser(action.payload).catch(
          sentryReThrowCatchHandler('Cannot perform users removing')
        ),
      () => {
        ToastHelper.success('The user has been deleted')
        return requestUsersList()
      },
      ErrorGroupConstants.USER
    )
  )

export const disableUserEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [DISABLE_USER],
      PreloaderConstants.DISABLE_USER,
      (action: ActionInterface) =>
        GrpcUsersService.deactivateUser(action.payload).catch(
          sentryReThrowCatchHandler('Cannot perform users removing')
        ),
      () => {
        ToastHelper.success('The user has been disabled')
        return requestUsersList()
      },
      ErrorGroupConstants.USER
    )
  )

export const enableUserEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [ENABLE_USER],
      PreloaderConstants.ENABLE_USER,
      (action: ActionInterface) =>
        GrpcUsersService.activateUser(action.payload).catch(
          sentryReThrowCatchHandler('Cannot perform users enabling')
        ),
      () => {
        ToastHelper.success('The user has been enabled')
        return requestUsersList()
      },
      ErrorGroupConstants.USER
    )
  )

export const requestUserEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_USER],
      PreloaderConstants.REQUEST_USER,
      () => GrpcUsersService.getUserProfile(),
      (user: UserModel) => setUser(user),
      ErrorGroupConstants.USER
    )
  )

export const requestUpdateUserEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_UPDATE_USER],
      PreloaderConstants.REQUEST_UPDATE_USER,
      (action: ActionInterface) =>
        GrpcUsersService.updateUserProfile(action.payload).catch(
          sentryReThrowCatchHandler('Cannot perform the users updating action')
        ),
      () => {
        ToastHelper.success('The profile has been updated')
        return requestUser()
      },
      ErrorGroupConstants.USER
    )
  )

export const inviteUserEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [INVITE_USER],
      PreloaderConstants.INVITE_USER,
      (action: ActionInterface) =>
        GrpcUsersService.userInvite(
          action.payload.email,
          action.payload.roleId
        ).catch(sentrySimpleReThrowHandler),
      () => {
        StoreService.hideModal(Modal.manageUsersAddUser)
        ToastHelper.success('The user is successfully invited')
        return requestUsersList()
      },
      ErrorGroupConstants.USER
    )
  )

export const resendInviteUserEpic = (action$: any) =>
  action$.pipe(
    toastRequestScenario(
      [RESEND_INVITE_USER],
      PreloaderConstants.RESEND_INVITE_USER,
      (action: ActionInterface) =>
        GrpcUsersService.userInvite(
          action.payload.email,
          action.payload.roleId
        ).catch(sentrySimpleReThrowHandler),
      () => requestUsersList(),
      () => 'The user is successfully invited',
      () => 'The user has already been invited'
    )
  )

export const updateUserEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [UPDATE_USER],
      PreloaderConstants.UPDATE_USER,
      (action: ActionInterface) =>
        GrpcUsersService.updateUserRole(
          action.payload.userId,
          action.payload.roleId
        ).catch(
          sentryReThrowCatchHandler('Cannot perform a users update action')
        ),
      () => {
        StoreService.hideModal(Modal.manageUsersEditUser)
        return requestUsersList()
      },
      ErrorGroupConstants.USER
    )
  )

export const verifyUserEpic = (action$: any) =>
  action$.pipe(
    ofType(VERIFY_USER),

    exhaustMap((action: ActionInterface) =>
      RequestService.verifyUser(action.payload).catch(
        sentryReThrowCatchHandler('Cannot perform a users verify action')
      )
    ),

    map((result: { status: string }) => {
      GeneralService.navigate(
        PageHelper.buildUrl(
          PagePathConstant.VERIFICATION_STATUS_ID,
          result.status
        )
      )
      return emptyAction()
    })
  )

export const updatePasswordEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [UPDATE_PASSWORD],
      PreloaderConstants.UPDATE_PASSWORD,
      (action: ActionInterface) =>
        RequestService.updatePassword(
          action.payload.currentPassword,
          action.payload.newPassword
        ),
      () => {
        GeneralService.navigate(PagePathConstant.AUTH0_LOGOUT)
        return emptyAction()
      },
      ErrorGroupConstants.USER
    )
  )

export const requestSessionsEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_SESSIONS],
      PreloaderConstants.REQUEST_SESSIONS,
      () =>
        RequestService.sessions().catch(
          sentryReThrowCatchHandler('Cannot get users sessions')
        ),
      (data: Array<SessionModel>) => setSessions(data),
      ErrorGroupConstants.USER
    )
  )

export const requestPossibleRolesEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_POSSIBLE_ROLES],
      PreloaderConstants.REQUEST_POSSIBLE_ROLES,
      () =>
        GrpcRbacService.listRoles().catch(
          sentryReThrowCatchHandler('Cannot get a list of possible users roles')
        ),
      (roles: Array<UserRoleModel>) => setPossibleRoles(roles),
      ErrorGroupConstants.USER
    )
  )

export const requestIsMfaEnabledEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_IS_MFA_ENABLED],
      PreloaderConstants.REQUEST_IS_MFA_ENABLED,
      () => RequestService.isMfaEnabled().catch(sentrySimpleReThrowHandler),
      (result: boolean) => setIsMfaEnabled(result),
      ErrorGroupConstants.USER
    )
  )

export const updateMfaEnabledEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [UPDATE_MFA_ENABLED],
      PreloaderConstants.UPDATE_MFA_ENABLED,
      (action: ActionInterface) =>
        RequestService.updateMfaEnabled(action.payload).catch(
          sentrySimpleReThrowHandler
        ),
      () => {
        GeneralService.navigate(PagePathConstant.AUTH0_LOGOUT)
        return emptyAction()
      },
      ErrorGroupConstants.USER
    )
  )

export const resetMfaEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [RESET_MFA],
      PreloaderConstants.RESET_MFA,
      () => RequestService.resetMfa().catch(sentrySimpleReThrowHandler),
      () => {
        GeneralService.navigate(PagePathConstant.AUTH0_LOGOUT)
        return emptyAction()
      },
      ErrorGroupConstants.USER
    )
  )
