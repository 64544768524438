import { TenantStatisticsFactory } from '@lib/factories/tenant-statistics.factory'
import { TenantStatisticsClient } from '@lib/clients/tenant-statistics'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import {
  setTenantStatistics,
  setTenantStatisticsLoading,
} from '@store/actions/list-live-assets.action'
import { getIsTenantStatisticsLoading } from '@store/selectors/list-live-assets.selector'

const tenantStatisticsClient = new TenantStatisticsClient()

async function fetchTenantStatistics() {
  const [tenantStatistics, storageReduction] = await Promise.all([
    tenantStatisticsClient.getTenantStatistics(),
    tenantStatisticsClient.getStorageReductionMetrics(),
  ])

  return new TenantStatisticsFactory().build({
    vaultMetricsList: storageReduction.vaultMetricsList,
    tenantStatistics,
  })
}

export function useFetchTenantStatistics() {
  const dispatch = useDispatch()
  const isTenantStatisticsLoading = useSelector(getIsTenantStatisticsLoading)

  useEffect(() => {
    if (isTenantStatisticsLoading) {
      return
    }

    dispatch(setTenantStatisticsLoading(true))
    fetchTenantStatistics()
      .then((tenantStatistics) => {
        dispatch(setTenantStatistics(tenantStatistics))
      })
      .finally(() => dispatch(setTenantStatisticsLoading(false)))
  }, [])
}
