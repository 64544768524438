import React, { useEffect, useRef, useState } from 'react'
import { AppProps } from 'next/app'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import store from '@store/store'
import theme from '@lib/theme/theme'
import SystemHelper from '@lib/helpers/system.helper'
import { getFeatureFlagsProvider } from '@components-context/feature-flags/launch-darkly'
import Layout from '@components-complex/layouts/layout'
import { LoadingScreen } from '@components-simple/loading-screen'
import { PageMeta } from '@lib/engine-types'
import GeneralService from '@lib/services/general.service'
import useEngineInit from '@lib/hooks/engine/useEngineInit'
import PagePathConstant from '@lib/constants/page-path.constant'

import { HistoryProvider } from 'components/context/HistoryContext'

import '../styles/styles.scss'
import '../styles/root.css'
import { useFetchTenantSettings } from '@lib/zustand/tenant-settings'

type AppPropsExtended = AppProps & { Component: { pageMeta: PageMeta } }

const SelectedFeatureFlagsProvider = getFeatureFlagsProvider()

const AppWrapper = ({ children }: { children: React.ReactNode }) => {
  return <SelectedFeatureFlagsProvider>{children}</SelectedFeatureFlagsProvider>
}

function App({ Component, router }: AppPropsExtended) {
  // we use this hook only in this single place
  const { hasToken, isReady } = useEngineInit(
    Component.pageMeta,
    store.dispatch,
    router
  )

  useFetchTenantSettings()

  const [isGlobalLogin, setIsGlobalLogin] = useState(true)

  // in order to prevent re-rendering - useRef
  const loadingTimer = useRef<any>()

  useEffect(() => {
    const globalDomains = [
      process.env.NEXT_PUBLIC_LOGIN_DOMAIN,
      process.env.NEXT_PUBLIC_TENANT_CREATION_DOMAIN,
    ]
    const isGlobalHost = globalDomains.includes(window.location.hostname)

    if (!isGlobalHost) {
      setIsGlobalLogin(false)
    }
  }, [])

  useEffect(() => {
    if (!loadingTimer.current) {
      loadingTimer.current = setTimeout(() => {
        // if for some unexpected reason we can not proceed
        // in order to prevent showing endless loading
        if (!GeneralService.initFinished) {
          SystemHelper.pureNavigate(PagePathConstant.INIT_ERROR)
        }
      }, 30000)
    }
  }, [])

  const appContent = (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <HistoryProvider>
          <Layout pageMeta={Component.pageMeta}>
            <Component />
          </Layout>
        </HistoryProvider>
      </ThemeProvider>
    </Provider>
  )

  if (!GeneralService.initFinished || !isReady) {
    return <LoadingScreen isGlobalLogin={isGlobalLogin} />
  }

  // This is a temporary solution to the problem.
  // In this case, we have different timing for initializing LaunchDarkly.
  // We do not wrap our app if we don't have a token.
  // I will replace our current authentication solution with Auth0, and we will have a proper wrapper for our app.
  return hasToken ? <AppWrapper>{appContent}</AppWrapper> : appContent
}

export default App
