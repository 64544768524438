/* eslint-disable import/no-extraneous-dependencies */
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import PagePathConstant from '@lib/constants/page-path.constant'
import {
  SelectedCloudConnectorVariant,
  SnapshotImport,
} from '@lib/constants/policies.constant'
import PreloaderConstants from '@lib/constants/preloader.constant'
import { AssetType } from '@lib/helpers/data.helper'
import ActionInterface, {
  ActionInterfaceUpdateEditPolicyEpic,
} from '@lib/interfaces/action.interface'
import GeneralService from '@lib/services/general.service'
import GrpcPechkinService from '@lib/services/grpc/grpc-pechkin.service'
import GrpcMixedService from '@lib/services/grpc/high/grpc-mixed.service'
import { emptyAction } from '@store/actions/default/empty.action'
import {
  REQUEST_EDIT_POLICY,
  setEditIntegrityCheck,
  setEditPolicyIntegrityScan,
  setEditPolicyName,
  setEditPolicySchedule,
  setEditPolicyTags,
  setEditPolicyVaultList,
  setEditProtectNewImmediately,
  setEditScanWithTimestamps,
  setEditSelectedCloudConnectors,
  setEditSelectedCloudConnectorsVariant,
  setEditSelectedVariant,
  setEditSkipEbsBackup,
  setEditSnapshotImportVariant,
  setEntropyDetectionConfiguration,
  setIntegrityScanOption,
  setKeepDataCopy,
  UPDATE_EDIT_POLICY,
} from '@store/actions/edit-policy.action'
import {
  baseRequestScenario,
  sentryReThrowCatchHandler,
} from '@store/epics/epic-func'
import {
  getEfsSelectedAllPaths,
  getEfsSelectedAssetsSelect,
  getEfsSelectedSpecificPaths,
  getS3SelectedAllPaths,
  getS3SelectedAssetsSelect,
  getS3SelectedSpecificPaths,
  getSelectedAssetsSelect,
} from '@store/selectors/assets-select.selector'
import {
  getEditIntegrityCheck,
  getEditIntegrityScan,
  getEditPolicyName,
  getEditPolicySchedule,
  getEditPolicySelectedCloudConnectors,
  getEditPolicyTags,
  getEditPolicyVaultsList,
  getEditProtectNewImmediately,
  getEditScanWithTimestamps,
  getEditSelectedVariant,
  getEditSkipEbsBackup,
  getEditSnapshotImportVariant,
  getIntegrityScanOption,
  getIsEntropyDetectionEnabled,
  getKeepDataCopy,
} from '@store/selectors/edit-policy.selector'
import ToastHelper from '@lib/helpers/toast.helper'
import StoreService from '@lib/services/store.service'
import {
  setAllEfsSelectedAssets,
  setAllS3SelectedAssets,
  setAllSelectedAssets,
  setAssetsSelectTabSelectedNum,
  setEditMainTabAssets,
} from '@store/actions/assets-select.action'
import {
  EmptyAllEfsSelectedAssets,
  EmptyAllS3SelectedAssets,
  SelectedVariant,
  TabAssetType,
} from '@lib/constants/app.constant'

import { PolicyInfoWithSelectedAssets } from '@lib/services/grpc/high/types'

export const requestEditPolicyEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_EDIT_POLICY],
      PreloaderConstants.REQUEST_EDIT_POLICY,
      (action: ActionInterface) =>
        GrpcMixedService.getPlanInfoWithSelectedAssets(action.payload).catch(
          sentryReThrowCatchHandler('Cannot get a policy')
        ),
      ({
        policyName,
        schedule,
        iscan,
        selectedAssets,
        selectedS3Assets,
        selectedS3AssetComplex,
        policyTags,
        snapshotImportVariant,
        isType,
        integrityCheck,
        protectNewImmediately,
        vaultsList,
        skipEbsBackup,
        accountRegionSelectorsList,
        selectedEfsAssets,
        selectedEfsAssetComplex,
        integrityScanOption,
        keepDataCopy,
        scanWithTimestamps,
        isEntropyDetectionEnabled,
      }: PolicyInfoWithSelectedAssets) => {
        StoreService.dispatchAction(setEditPolicySchedule(schedule))
        StoreService.dispatchAction(setEditPolicyIntegrityScan(iscan))
        StoreService.dispatchAction(setAllSelectedAssets(selectedAssets))
        StoreService.dispatchAction(setEditPolicyTags(policyTags))
        StoreService.dispatchAction(
          setEditProtectNewImmediately(protectNewImmediately)
        )
        StoreService.dispatchAction(
          setEntropyDetectionConfiguration(isEntropyDetectionEnabled)
        )

        if (integrityCheck) {
          StoreService.dispatchAction(setEditIntegrityCheck(integrityCheck))
        }

        // set up the selected tab
        let selectedVariant = SelectedVariant.AllEC2Variant
        let selectedTab = TabAssetType.Ec2andEbs

        if (policyTags.length > 0 && isType === undefined) {
          selectedVariant = SelectedVariant.AllEC2EBSVariant
        } else if (selectedAssets?.length > 0) {
          selectedVariant = SelectedVariant.AssetsVariant
        } else if (selectedS3Assets?.length !== 0) {
          selectedTab = TabAssetType.S3
          selectedVariant = SelectedVariant.S3Variant
        } else if (selectedEfsAssets?.length !== 0) {
          selectedTab = TabAssetType.EFS
          selectedVariant = SelectedVariant.EfsVariant
        } else if (isType !== undefined) {
          if (isType == AssetType.ASSET_TYPE_EC2) {
            selectedTab = TabAssetType.Ec2andEbs
            selectedVariant = SelectedVariant.AllEC2Variant
          }
          if (isType == AssetType.ASSET_TYPE_EBS) {
            selectedTab = TabAssetType.Ec2andEbs
            selectedVariant = SelectedVariant.AllEBSVariant
          }
          if (isType == AssetType.ASSET_TYPE_EC2_EBS) {
            selectedTab = TabAssetType.Ec2andEbs
            selectedVariant = SelectedVariant.AllEC2EBSVariant
          }
          if (isType == AssetType.ASSET_TYPE_S3) {
            selectedTab = TabAssetType.S3
            selectedVariant = SelectedVariant.AllS3Variant
          }
        }

        StoreService.dispatchAction(setEditSelectedVariant(selectedVariant))
        StoreService.dispatchAction(setAssetsSelectTabSelectedNum(selectedTab))
        StoreService.dispatchAction(setEditMainTabAssets(selectedTab))
        StoreService.dispatchAction(
          setEditSnapshotImportVariant(snapshotImportVariant)
        )
        StoreService.dispatchAction(setEditPolicyVaultList(vaultsList))
        StoreService.dispatchAction(setEditSkipEbsBackup(skipEbsBackup))
        StoreService.dispatchAction(
          setAllS3SelectedAssets(
            {
              allSelected: selectedS3Assets,
              allSelectedComplex: selectedS3AssetComplex,
            } ?? EmptyAllS3SelectedAssets()
          )
        )
        StoreService.dispatchAction(
          setAllEfsSelectedAssets(
            {
              allSelected: selectedEfsAssets,
              allSelectedComplex: selectedEfsAssetComplex,
            } ?? EmptyAllEfsSelectedAssets()
          )
        )

        let selectedCloudConnectorsVariant = SelectedCloudConnectorVariant.ALL
        if (accountRegionSelectorsList.length > 0) {
          selectedCloudConnectorsVariant = SelectedCloudConnectorVariant.CUSTOM
        }

        StoreService.dispatchAction(
          setEditSelectedCloudConnectorsVariant(selectedCloudConnectorsVariant)
        )
        StoreService.dispatchAction(
          setEditSelectedCloudConnectors(accountRegionSelectorsList)
        )

        StoreService.dispatchAction(setIntegrityScanOption(integrityScanOption))
        StoreService.dispatchAction(setKeepDataCopy(keepDataCopy))
        StoreService.dispatchAction(
          setEditScanWithTimestamps(scanWithTimestamps)
        )

        return setEditPolicyName(policyName)
      },
      ErrorGroupConstants.EDIT_POLICY
    )
  )

export const updateEditPolicyEpic = (action$: any, state$: any) =>
  action$.pipe(
    baseRequestScenario(
      [UPDATE_EDIT_POLICY],
      PreloaderConstants.UPDATE_EDIT_POLICY,
      (action: ActionInterfaceUpdateEditPolicyEpic) => {
        const selectedVariant = getEditSelectedVariant(state$.value)
        const policyName = getEditPolicyName(state$.value)
        const schedule = getEditPolicySchedule(state$.value)
        const iscan = getEditIntegrityScan(state$.value)
        const selectedAssets = getSelectedAssetsSelect(state$.value)
        const selectedS3Assets = getS3SelectedAssetsSelect(state$.value)
        const selectedS3AllPath = getS3SelectedAllPaths(state$.value)
        const selectedS3SpecificPath = getS3SelectedSpecificPaths(state$.value)
        const selectedEfsAssets = getEfsSelectedAssetsSelect(state$.value)
        const selectedEfsAllPath = getEfsSelectedAllPaths(state$.value)
        const selectedEfsSpecificPath = getEfsSelectedSpecificPaths(
          state$.value
        )
        const policyTags = getEditPolicyTags(state$.value)
        const integrityCheck = getEditIntegrityCheck(state$.value)
        const executeImmediately = action.payload
        const snapshotImportVariant = getEditSnapshotImportVariant(state$.value)
        const protectNewImmediately = getEditProtectNewImmediately(state$.value)
        const vaultsList = getEditPolicyVaultsList(state$.value)
        const skipEbsBackup =
          snapshotImportVariant === SnapshotImport.SKIP
            ? false
            : getEditSkipEbsBackup(state$.value)
        const selectedCloudConnectors = getEditPolicySelectedCloudConnectors(
          state$.value
        )
        const integrityScanOption = getIntegrityScanOption(state$.value)
        const keepDataCopy = getKeepDataCopy(state$.value)
        const scanWithTimestamps = getEditScanWithTimestamps(state$.value)
        const isEntropyDetectionEnabled = getIsEntropyDetectionEnabled(
          state$.value
        )
        return GrpcPechkinService.updatePlan({
          policyName,
          selectedVariant,
          schedule,
          iscan,
          snapshotImport: snapshotImportVariant,
          executeImmediately,
          skipEbsBackup,
          selectedAssets,
          selectedS3Assets,
          selectedS3AllPath,
          selectedS3SpecificPath,
          policyTags,
          integrityCheck,
          protectNewImmediately,
          vaultsList,
          selectedCloudConnectors,
          selectedEfsAssets,
          selectedEfsAllPath,
          selectedEfsSpecificPath,
          integrityScanOption,
          keepDataCopy,
          scanWithTimestamps,
          isEntropyDetectionEnabled,
        }).catch((error) => {
          switch (error.code) {
            case 9:
              ToastHelper.error(error.message)
              break

            default:
              ToastHelper.error(`Can not update the policy - ${error.message}`)
              break
          }
          sentryReThrowCatchHandler(
            `Can not update the policy - ${error.message}`
          )
        })
      },
      (result: any) => {
        if (result) {
          ToastHelper.success('The policy has been updated')
        }
        GeneralService.navigate(PagePathConstant.POLICIES)
        return emptyAction()
      },
      ErrorGroupConstants.EDIT_POLICY
    )
  )
