/* eslint-disable import/no-extraneous-dependencies */
import { BackupProcessor } from '@lib/clients/assets/backup-processor'
import { StoreReturnType } from '@store/store'
import {
  MalwareScan,
  RansomwareScan,
  FilesystemScanCheck,
  AWSBRecoveryPoint,
  EBSSnapshot,
  ElastioRecoveryPoint,
} from 'blues-corejs/dist'
import { SliceCriteriaTags } from '@lib/models/management-view/types'

export interface LiveAssetsCriteria {
  accountIds?: Array<string>
  regions?: Array<string>
  includeTags?: SliceCriteriaTags
  excludeTags?: SliceCriteriaTags
}

function convertBackupsToMap({ backups }: BackupProcessor) {
  const backupsMapped = new Map<
    string,
    { backup: EBSSnapshot | AWSBRecoveryPoint | ElastioRecoveryPoint }
  >()

  for (const [key, value] of backups) {
    backupsMapped.set(key, { backup: value })
  }

  return backupsMapped
}

export const getIsLiveAssetsLoading = (state: StoreReturnType) => {
  return state.liveAssetsState.isLiveAssetsLoading
}

export const getLiveAssets = (state: StoreReturnType) => {
  const liveAssets = state.liveAssetsState.managementView.slice(
    state.liveAssetsState.slice || {}
  )

  return {
    ...liveAssets,
  }
}

export const getThreats = (state: StoreReturnType) => {
  return state.liveAssetsState.threats
}

export const getBackups = (state: StoreReturnType) => {
  return {
    firstBackups: state.liveAssetsState.firstBackups,
    lastBackups: state.liveAssetsState.lastBackups,
  }
}

export const getBackupsMapped = (state: StoreReturnType) => {
  return {
    firstBackups: convertBackupsToMap(state.liveAssetsState.firstBackups),
    lastBackups: convertBackupsToMap(state.liveAssetsState.lastBackups),
  }
}

export const getScans = (state: StoreReturnType) => {
  const ransomwareScans: Array<RansomwareScan> = []
  const malwareScans: Array<MalwareScan> = []
  const fsCheckScans: Array<FilesystemScanCheck> = []

  for (const scan of state.liveAssetsState.scans) {
    if (scan.ransomwareScan) {
      ransomwareScans.push(scan.ransomwareScan)
    }
    if (scan.malwareScan) {
      malwareScans.push(scan.malwareScan)
    }
    if (scan.filesystemCheckScan) {
      fsCheckScans.push(scan.filesystemCheckScan)
    }
  }

  return {
    allScans: state.liveAssetsState.scans,
    ransomwareScans,
    malwareScans,
    fsCheckScans,
  }
}

export const getLiveAssetsCriteria = (
  state: StoreReturnType
): LiveAssetsCriteria => {
  const {
    accountIds,
    regions,
    include_tags: includeTags,
    exclude_tags: excludeTags,
  } = state.liveAssetsState.slice
  return {
    accountIds,
    regions,
    includeTags,
    excludeTags,
  }
}

export const getIsTenantStatisticsLoading = (state: StoreReturnType) => {
  return state.liveAssetsState.isTenantStatisticsLoading
}

export const getTenantStatistics = (state: StoreReturnType) => {
  return state.liveAssetsState.tenantStatistics
}
