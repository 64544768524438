import GrpcKrabsService from '@lib/services/grpc/grpc-krabs.service'
import GrpcPatService from '@lib/services/grpc/grpc-pat.service'
import GrpcPechkinService from '@lib/services/grpc/grpc-pechkin.service'
import GrpcRbacService from '@lib/services/grpc/grpc-rbac.service'
import GrpcRexService from '@lib/services/grpc/grpc-rex.service'
import GrpcUsersService from '@lib/services/grpc/grpc-users.service'
import GrpcVaultService from '@lib/services/grpc/grpc-vault.service'
import GrpcRetentionPoliciesService from '@lib/services/grpc/grpc-retention-policies.service'
import GrpcTenantNotificationService from '@lib/services/grpc/notifications/grpc-tenant-notifications.service'
import GrpcUserNotificationService from '@lib/services/grpc/notifications/grpc-user-notifications.service'
import GrpcNotificationsReportsService from '@lib/services/grpc/notifications/grpc-reports.service'
import GrpcWebhookService from '@lib/services/grpc/notifications/grpc-webhook.service'
import GrpcReportSchedulesService from '@lib/services/grpc/notifications/grps-schedule-reports.service'
import GrpcTenantsService from '@lib/services/grpc/grpc-tenants.service'
import GrpcJobStatusService from '@lib/services/grpc/grpc-job-status.service'

export const grpcServices: Array<{ init(token: string): void }> = [
  GrpcKrabsService,
  GrpcPatService,
  GrpcPechkinService,
  GrpcRbacService,
  GrpcRexService,
  GrpcUsersService,
  GrpcVaultService,
  GrpcRetentionPoliciesService,
  GrpcTenantNotificationService,
  GrpcUserNotificationService,
  GrpcNotificationsReportsService,
  GrpcWebhookService,
  GrpcReportSchedulesService,
  GrpcTenantsService,
  GrpcJobStatusService,
]
