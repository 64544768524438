import { SelectedVariant } from '@lib/constants/app.constant'
/* eslint-disable import/no-extraneous-dependencies */
import CheckboxConstant from '@lib/constants/checkbox.constant'
import DurationConstant from '@lib/constants/data/time/duration.constant'
import FrequencyConstant from '@lib/constants/data/time/frequency.constant'
import PeriodConstant from '@lib/constants/data/time/period.constant'
import { DetailInfoCardEntity } from '@lib/constants/detail-info.constant'
import { AssetKind } from '@lib/constants/grpc/asset-kind.constant'
import {
  ButtonsRowProps,
  MoreBlockV2Entity,
} from '@lib/constants/more-v2-block.constant'
import {
  DefaultRetentionPoliciesConstant,
  TableTabsConstant,
} from '@lib/constants/policies.constant'
import RedStackStatusConstant from '@lib/constants/red-stack-status.constant'
import RegexConstants from '@lib/constants/regex.constant'
import { SelectCategory } from '@lib/constants/retention-policy/select-category.constant'
import SearchResultConstant from '@lib/constants/search-result.constant'
import TimeFormatConstant from '@lib/constants/time-format.constant'
import TimeFormatConstants from '@lib/constants/time-format.constant'
import {
  ArrayTotalResult,
  Nullable,
  RowSimpleUnit,
  StringTupleArray,
  VIMatrix,
  VIRow,
} from '@lib/engine-types'
import { StatisticsFactory } from '@lib/factories/statistics.factory'
import ArrHelper from '@lib/helpers/arr.helper'
import NumHelper from '@lib/helpers/num.helper'
import StrHelper from '@lib/helpers/str.helper'
import TimeHelper from '@lib/helpers/time.helper'
import HourMinuteInterface from '@lib/interfaces/hour-minute.interface'
import RetentionDurationInterface from '@lib/interfaces/retention-policy/retention-duration.interface'
import RecoverabilityStatsInterface from '@lib/interfaces/recoverability-stats.interface'
import ScheduleInterface from '@lib/interfaces/schedule.interface'
import ValueInterface from '@lib/interfaces/value.interface'
import { PossibleAndSelectedVaultInterface } from '@lib/interfaces/vault/possible-and-selected-vault.interface'
import CfnModel from '@lib/models/cfn.model'
import PaginationModel from '@lib/models/pagination.model'
import RedStackModel from '@lib/models/red-stack.model'
import ReportDetailsModel, {
  MalwareScan,
  RansomwareScan,
} from '@lib/models/report-details.model'
import { RetentionPolicy, Selector } from '@lib/models/retention-policy.model'
import VaultModel from '@lib/models/vault.model'
import { $enum } from 'ts-enum-util'
import LangHelper from './lang.helper'
import PageHelper from './page.helper'
import SystemHelper from './system.helper'
import { FormPeriodInterface } from '@lib/interfaces/form/form-period.interface'
import {
  AssetSource,
  getAssetSourceName,
} from '@lib/constants/retention-policy/asset-source.constant'
import TypeHelper from './type.helper'
import SnapshotVulnerabilities from '@lib/interfaces/dashboard-v2/snapshot-vulnerabilities.interface'
import { Region } from '@lib/constants/aws-regions.constant'
import { DashboardColors } from '@lib/constants/dashboard.constant'
import { EventTypeConstant } from '@lib/constants/webhook.constant'
import { SnapshotModel, VolumeModel } from 'blues-corejs/dist'
import VolumeStatusConstant from '@lib/constants/inventory/volume-status.constant'
import VolumeStatusCheckConstant from '@lib/constants/inventory/volume-status-check.constant'
import {
  ElastioRecoveryPoint,
  SnapshotVulnerabilitiesTypeName,
} from 'blues-corejs/dist/models'
import { MINIMUM_RED_STACK_VERSION_FOR_S3_BUCKETS } from '@lib/constants/cloud-connector-version.constant'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Asset,
  AssetWithRelatedAssets,
} from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import {
  EBS,
  EC2,
  MalwareScan as MalwareScanCore,
  RansomwareScan as RansomwareScanCore,
  FilesystemScanCheck,
} from 'blues-corejs/dist'
import { FilterNamesConstant } from '@lib/constants/filters/filter-names.constant'
import { ListScans } from '@lib/clients/scans/types'

type Scan = MalwareScanCore | RansomwareScanCore | FilesystemScanCheck

enum VolumeStatus {
  VOLUME_STATUS_UNKNOWN = 0,
  AVAILABLE = 1,
  CREATING = 2,
  DELETED = 3,
  DELETING = 4,
  ERROR = 5,
  IN_USE = 6,
}

enum VolumeStatusCheck {
  VOLUME_STATUS_CHECK_UNKNOWN = 0,
  IMPAIRED = 1,
  INSUFFICIENT_DATA = 2,
  OK = 3,
}

export enum CloudConnectorProblems {
  NO_DEFAULT = 'No default',
  NO_VAULTS = 'No vaults',
  NO_VPC = 'VPC error',
  BS_ERROR = 'BS error',
  UNKNOWN = 'Unknown',
}

export enum AssetType {
  ASSET_TYPE_EC2 = 0,
  ASSET_TYPE_EBS = 1,
  ASSET_TYPE_S3 = 2,
  ASSET_TYPE_EC2_EBS = 3,
}

abstract class DataHelper {
  public static groupByField<KeyType, ObjType>(
    arr: Array<ObjType>,
    fieldName: string,
    uniqueField = ''
  ): Map<KeyType, Array<ObjType>> {
    const result = new Map<KeyType, Array<ObjType>>()
    arr.forEach((value: ObjType) => {
      // @ts-ignore
      const current = result.get(value[fieldName]) ?? []
      if (
        uniqueField &&
        // @ts-ignore
        current.find((v) => v[uniqueField] === value[uniqueField])
      ) {
        return
      }
      current.push(value)
      // @ts-ignore
      result.set(value[fieldName], current)
    })
    return result
  }

  public static groupedByAwsAccount(
    arr: Array<RedStackModel>
  ): Record<string, any> {
    const result: Record<string, any> = {}

    arr.map((rs: RedStackModel) => {
      if (result[rs.awsAccount] === undefined) {
        result[rs.awsAccount] = {
          accountAlias: rs.accountAlias,
          accountDescription: rs.accountDescription,
          awsRegions: [],
          lastErrorMessages: [],
          statuses: [],
          assetsCount: 0,
          awsAccount: rs.awsAccount,
          redStackAccount: rs.redStackId,
          lastWarningMessage: rs.lastWarningMessage,
          cfnUpgradeRequired: rs.cfnUpgradeRequired,
          // isActive: '',
        }
      }
      result[rs.awsAccount].awsRegions.push(rs.awsRegion)
      result[rs.awsAccount].lastErrorMessages.push(rs.lastErrorMessage)
      result[rs.awsAccount].statuses.push(rs.status)
      // result[rs.awsAccount].isActive.push(rs.isActive);

      return result
    })
    return result
  }

  // it checks - if it is a prent - then check children
  public static updateChildrenCheckboxesIfParent(
    data: VIMatrix,
    // in order to detect a row index in the data
    row: VIRow,
    // column index
    index: number,
    checkboxValue: CheckboxConstant
  ) {
    const rowIndex = data.findIndex((v) => v[0]?.id === row[0]?.id)
    if (rowIndex === -1) {
      return
    }

    const matrixElem = data[rowIndex]?.find(
      (v) => !!v.matrix && v.matrix.length > 0
    )
    if (matrixElem) {
      matrixElem.matrix?.forEach((elem) => {
        const elemToUpdate = elem[index]
        if (elemToUpdate) {
          elemToUpdate.value = checkboxValue
        }
      })
    }
  }

  public static updateParentCheckboxValues(data: VIMatrix, index: number) {
    data.forEach((row: VIRow) => {
      const parentElem = row.find((v) => !!v.matrix && v.matrix.length > 0)
      if (!parentElem) {
        return
      }
      // @ts-ignore
      const selectedAmount = parentElem.matrix.filter(
        (v) => v[index]?.value === CheckboxConstant.Checked
      ).length

      let finalCheckboxState = CheckboxConstant.Empty
      // @ts-ignore
      if (parentElem.matrix.length > 0 && selectedAmount > 0) {
        // @ts-ignore
        if (selectedAmount === parentElem.matrix.length) {
          finalCheckboxState = CheckboxConstant.Checked
        } else {
          finalCheckboxState = CheckboxConstant.Indeterminate
        }
      }
      const elem = row[index]
      if (elem) {
        elem.value = finalCheckboxState
      }
    })
  }

  public static updateHeaderCheckbox(
    data: VIRow,
    searchResult: SearchResultConstant,
    index: number
  ) {
    let valueToAssign: CheckboxConstant
    switch (searchResult) {
      case SearchResultConstant.NotFound:
        valueToAssign = CheckboxConstant.Empty
        break
      case SearchResultConstant.Found:
        valueToAssign = CheckboxConstant.Indeterminate
        break
      case SearchResultConstant.FoundAll:
        valueToAssign = CheckboxConstant.Checked
        break
      default:
        throw new Error('Invalid Search result')
    }
    const elem = data[index]
    if (elem) {
      elem.value = valueToAssign
    }
  }

  public static buildCloudFormationLink(
    cfLink: string,
    region: string
  ): string {
    return `https://${region}.console.aws.amazon.com/cloudformation/home?region=${region}#/stacks/create/review?templateURL=${cfLink}&stackName=elastio-account-level-stack`
  }

  public static buildCfnUpdatingLink(
    cfLink: string,
    cfnModel: CfnModel
  ): string {
    return `https://${cfnModel.region}.console.aws.amazon.com/cloudformation/home?region=${cfnModel.region}#/stacks/update/template?stackId=${cfnModel.arn}&templateURL=${cfLink}`
  }

  public static buildCfnDeployCommand(cfLink: string, region: string): string {
    return `aws cloudformation create-stack --stack-name elastio-account-level-stack --template-url '${cfLink}' --capabilities CAPABILITY_NAMED_IAM --region ${region}`
  }

  public static buildCfnUpdatingCommand(
    cfLink: string,
    cfnModel: CfnModel
  ): string {
    return `aws cloudformation update-stack --stack-name elastio-account-level-stack --template-url '${cfLink}' --capabilities CAPABILITY_NAMED_IAM --region ${cfnModel.region}`
  }

  public static groupScopes(scopesList: Array<string>): VIRow {
    const data: Array<ValueInterface> = scopesList.map((elem) => {
      const [name, permission] = elem.split(':')
      return {
        name: StrHelper.toCamelCase(permission ?? ''),
        value: StrHelper.toCamelCase(name ?? ''),
        label: elem,
      }
    })
    const groupedData = DataHelper.groupByField<string, ValueInterface>(
      data,
      'value',
      'name'
    )

    const result: VIRow = []
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of groupedData.entries()) {
      result.push({
        name: key,
        label: key,
        options: value,
      })
    }
    result.sort((a, b) => (a.name > b.name ? 1 : -1))
    return result
  }

  public static calcAbsoluteIndexByTemplate(
    relativeIndex: number,
    columnState: Array<number>
  ) {
    // calc the absolute column state index
    let calcIndex = 0
    let absoluteIndex = 0
    for (let i = 0; i < columnState.length; ++i) {
      absoluteIndex += 1
      if (columnState[i] === 0) {
        continue
      }
      if (calcIndex === relativeIndex) {
        return absoluteIndex - 1
      }
      calcIndex += 1
    }
    return -1
  }

  // because the table data has wrong structure
  // we have to paginate it differently
  public static rolesTableDataPagination(
    data: Array<RowSimpleUnit>,
    rolesLength: number,
    offset: number,
    limit: number
  ): Array<RowSimpleUnit> {
    return data.map((row) => {
      const baseIndex = row.length - rolesLength + offset
      const endIndex = baseIndex + limit
      const permissionsPart = row.slice(baseIndex, endIndex)
      const labelsPart = row.slice(0, row.length - rolesLength)
      return labelsPart.concat(permissionsPart)
    })
  }

  public static isDefined<T>(value: T | undefined): value is T {
    return value !== undefined && value !== null
  }

  public static groupedVaultsByRSIds(
    arr: Array<VaultModel>
  ): Record<string, PossibleAndSelectedVaultInterface> {
    const result: Record<string, PossibleAndSelectedVaultInterface> = {}

    arr.map((vm: VaultModel) => {
      if (result[vm.redStackId] === undefined) {
        result[vm.redStackId] = {
          possibleVaults: [],
          currentSelectedVault: result[vm.redStackId]?.possibleVaults[0] ?? {
            name: '',
          },
        }
      }
      const elem = result[vm.redStackId]
      if (elem) {
        elem.possibleVaults.push({
          name: vm.name,
          value: vm.redStackId,
          extraValue: vm.innerId,
        })
        elem.currentSelectedVault = result[vm.redStackId]
          ?.possibleVaults[0] ?? { name: '' }
      }

      return result
    })
    return result
  }

  public static calcMonthBackups(schedule: ScheduleInterface): number {
    const daysInMonth = TimeHelper.daysInCurrentMonth()

    switch (schedule.frequency.value) {
      case FrequencyConstant.FREQUENCY_EVERY_15_MIN.value:
      case FrequencyConstant.FREQUENCY_EVERY_30_MIN.value:
      case FrequencyConstant.FREQUENCY_EVERY_HOUR.value:
        const diffMinutes = TimeHelper.diffMinutes(
          schedule.windowStart,
          schedule.windowEnd
        )
        const backupsPerWindow = Math.floor(
          diffMinutes / Number(schedule.frequency.extraValue)
        )
        // + 1 - because we suppose that it starts in the beginning
        // for example if the window is 5m - it starts once
        return (backupsPerWindow + 1) * daysInMonth

      case FrequencyConstant.FREQUENCY_EVERY_12_HOURS.value:
        return daysInMonth * 2

      case FrequencyConstant.FREQUENCY_DAILY.value:
        return daysInMonth

      case FrequencyConstant.FREQUENCY_WEEKLY.value:
        return TimeHelper.weekdaysInMonth(Number(schedule.dayOfWeek.value))

      case FrequencyConstant.FREQUENCY_MONTHLY.value:
        return 1

      default:
        return 0
    }
  }

  public static concatArraysTotalResults<T>(
    ...arr: Array<ArrayTotalResult<T>>
  ): ArrayTotalResult<T> {
    let total = 0
    let data: Array<T> = []
    arr.map((elem) => {
      total += elem.total
      data = data.concat(elem.data)
    })
    return {
      total,
      data,
    }
  }

  public static protectedObjectIdParsed(protectedObject?: string): string {
    // the protectedObject examples:
    // for ebs: elastio:asset:aws-ebs:s:0123456789012:eu-west-1:v-123
    // for ec2: elastio:asset:aws-ec2:s:0123456789012:eu-central-1:i-123
    // for file: elastio:asset:file:s:aws-ebs:s:0123456789012:eu-west-1:v-012:/file1
    // for local-disk: elastio:asset:local-disk:s:aws-ec2:s:0123456789012:eu-central-1:i-abcd:/dev/sda1
    // for local-volume: elastio:asset:local-volume:s:aws-ec2:s:0123456789012:eu-central-1:i-abcd:/dev/sda1
    // for nas: elastio:asset:nas:s:nas.example.com
    // for stream: elastio:asset:stream:s:generic-host:s:host.example.com:stream1
    // for generic-host: elastio:asset:generic-host:s:host.example.com

    // for protectedObject with only one value, like: C:\, D:\
    if (
      protectedObject &&
      !StrHelper.isSubstring(protectedObject.toLowerCase(), 'elastio')
    ) {
      return protectedObject
    }

    let result = protectedObject
      ? protectedObject.split(':').pop() ?? protectedObject
      : ''

    // the protectedObject example with escape symbols
    // for local-volume: elastio:asset:local-volume:s:aws-ec2:s:300892765037:us-east-1:i-01e73d64a4d4024f6:C\:\\
    // for local-volume: elastio:asset:local-volume:s:generic-host:s:block-win:C\:\\
    if (
      protectedObject &&
      (StrHelper.isSubstring(protectedObject, 'local-volume') ||
        StrHelper.isSubstring(protectedObject, 'local-disk'))
    ) {
      const splitResult = protectedObject.split(':')
      if (splitResult.length > 0) {
        splitResult.map((elem) => {
          if (elem.startsWith('i-') || elem.startsWith('block-')) {
            const localPathString =
              protectedObject.split(elem + ':').pop() ?? ''
            // unescape local-volume or local-disk path
            if (localPathString) {
              result = localPathString.replace(/(?:\\(.))/g, '$1')
            }
          }
        })
      }
    }
    return result
  }

  public static integrityScanErrorDetails(
    reportDetails: Nullable<ReportDetailsModel>,
    buttonRowData: Array<ButtonsRowProps>
  ): VIRow {
    const reportDetailsResults = reportDetails
      ?.results()
      ?.map((result) => ({ name: result }))

    const reportResult = [
      {
        type: MoreBlockV2Entity.ButtonsRowBlock,
        name: 'Recovery point',
        value: reportDetails?.recoveryPointCreationDateFormatted,
        options: [
          {
            callBackPure: buttonRowData[0]?.data[0]?.onClick,
            disabled: buttonRowData[0]?.data[0]?.isDisabled,
            label: buttonRowData[0]?.data[0]?.label,
            name: String(buttonRowData[0]?.data[0]?.variant),
          },
        ],
        index: 1,
      },
      {
        type: MoreBlockV2Entity.ButtonsRowBlock,
        name: 'Last known clean recovery point',
        value:
          reportDetails?.lastCleanRecoveryPoint
            ?.recoveryPointTimestampFormatted,
        options: [
          {
            callBackPure: buttonRowData[1]?.data[0]?.onClick,
            disabled: buttonRowData[1]?.data[0]?.isDisabled,
            label: buttonRowData[1]?.data[0]?.label,
            name: String(buttonRowData[1]?.data[0]?.variant),
          },
          {
            value: buttonRowData[1]?.data[1]?.link,
            disabled: buttonRowData[1]?.data[1]?.isDisabled,
            label: buttonRowData[1]?.data[1]?.label,
            name: String(buttonRowData[1]?.data[1]?.variant),
          },
          {
            callBackPure: buttonRowData[1]?.data[2]?.onClick,
            disabled: buttonRowData[1]?.data[2]?.isDisabled,
            label: buttonRowData[1]?.data[2]?.label,
            name: String(buttonRowData[1]?.data[2]?.variant),
          },
        ],
        index: 1,
      },
      {
        type: reportDetailsResults?.length
          ? MoreBlockV2Entity.ErrorsList
          : MoreBlockV2Entity.SuccessText,
        name: 'Results',
        value: reportDetailsResults?.length ? '' : 'Integrity check passed',
        options: reportDetailsResults ?? [],
        index: 1,
      },
    ]

    // Remove Last Clean Recovery Point data row
    if (!reportDetails?.lastCleanRecoveryPoint?.recoveryPointId) {
      reportResult.splice(1, 1)
    }

    // Remove Last Clean Recovery Point data row
    if (!reportDetails?.recoveryPointCreationDate) {
      reportResult.splice(0, 1, {
        type: MoreBlockV2Entity.Default,
        name: 'Protected object:',
        value:
          (reportDetails?.protectedObject || '').length || 0 > 0
            ? DataHelper.protectedObjectIdParsed(reportDetails?.protectedObject)
            : reportDetails?.humanReadablePath,
        options: [],
        index: 1,
      })
    }

    return reportResult
  }

  public static accountIdFilter(accountId?: string) {
    return accountId?.match(RegexConstants.ACCOUNT_ID_FILTER)?.join('') || ''
  }

  public static accountIdToServerFormat(accountId: string) {
    return accountId.replace(RegexConstants.ACCOUNT_ID_SERVER_FORMAT, '')
  }

  public static malwareScanDetails(malwareScan: MalwareScan): VIRow {
    return [
      {
        type:
          malwareScan.details.length > 0 && malwareScan.infectedFilesNumber > 0
            ? DetailInfoCardEntity.Error
            : DetailInfoCardEntity.Default,
        name: 'Infected files',
        value: malwareScan.infectedFilesNumber,
        index: 1,
      },
      {
        type:
          malwareScan.details.length > 0 &&
          malwareScan.suspiciousFilesNumber > 0
            ? DetailInfoCardEntity.Warning
            : DetailInfoCardEntity.Default,
        name: 'Suspicious files',
        value: malwareScan.suspiciousFilesNumber,
        index: 2,
      },
      {
        type:
          malwareScan.details.length > 0 && malwareScan.cleanFilesNumber > 0
            ? DetailInfoCardEntity.Warning
            : DetailInfoCardEntity.Default,
        name: 'Clean files',
        value: malwareScan.cleanFilesNumber,
        index: 3,
      },
      {
        type:
          malwareScan.details.length > 0 &&
          malwareScan.incompleteFilesNumber > 0
            ? DetailInfoCardEntity.Warning
            : DetailInfoCardEntity.Default,
        name: 'Incomplete files',
        value: malwareScan.incompleteFilesNumber,
        index: 1,
      },
      {
        type:
          malwareScan.details.length > 0 && malwareScan.encryptedFilesNumber > 0
            ? DetailInfoCardEntity.Warning
            : DetailInfoCardEntity.Default,
        name: 'Encrypted files',
        value: malwareScan.encryptedFilesNumber,
        index: 2,
      },
      {
        type:
          malwareScan.details.length > 0 && malwareScan.corruptedFilesNumber > 0
            ? DetailInfoCardEntity.Warning
            : DetailInfoCardEntity.Default,
        name: 'Corrupted files',
        value: malwareScan.corruptedFilesNumber,
        index: 3,
      },
      {
        type:
          malwareScan.details.length > 0 && malwareScan.errorsNumber() > 0
            ? DetailInfoCardEntity.Error
            : DetailInfoCardEntity.Default,
        name: 'Errors',
        value: malwareScan.errorsNumber(),
        index: 1,
      },
      {
        type: DetailInfoCardEntity.Default,
        name: 'Total scanned',
        value: malwareScan.scannedFilesNumber,
        index: 2,
      },
    ]
  }

  public static ransomwareScanDetails(ransomwareScan: RansomwareScan): VIRow {
    return [
      {
        type:
          ransomwareScan.details.length > 0 &&
          ransomwareScan.detectedRansomwaresNumber > 0
            ? DetailInfoCardEntity.Error
            : DetailInfoCardEntity.Default,
        name: 'Detected ransomware',
        value: ransomwareScan.detectedRansomwaresNumber,
        index: 1,
      },
      {
        type:
          ransomwareScan.details.length > 0 && ransomwareScan.errorsNumber > 0
            ? DetailInfoCardEntity.Error
            : DetailInfoCardEntity.Default,
        name: 'Errors',
        value: ransomwareScan.errorsNumber,
        index: 3,
      },
      {
        type: DetailInfoCardEntity.Default,
        name: 'Total scanned',
        value: ransomwareScan.totalFilesNumber,
        index: 1,
      },
    ]
  }

  public static filterNameTag(
    tags: Nullable<StringTupleArray>
  ): StringTupleArray {
    if (!tags) {
      return []
    }
    return tags.filter(([tagKey]) => tagKey !== 'Name')
  }

  public static nextRunIntervalHourlyLabel(
    schedule: ScheduleInterface
  ): string {
    const { windowStart, windowEnd } = schedule

    const currentTime = TimeHelper.getDateWithTimezone(Date.now())
    const currentTimeInPolicyTimeZone = TimeHelper.getDateWithTimezone(
      new Date().getTime(),
      windowStart.timezone
    )

    const startDatetime = currentTimeInPolicyTimeZone
      .clone()
      .hour(windowStart.hour)
      .minute(windowStart.minute)
      .second(0)

    const endDatetime = currentTimeInPolicyTimeZone
      .clone()
      .hour(windowEnd.hour)
      .minute(windowEnd.minute)
      .second(0)

    const interval = 60

    if (startDatetime.diff(endDatetime, 'ms') > 0) {
      endDatetime.add(1, 'day')
    }

    if (currentTime.isBetween(startDatetime, endDatetime)) {
      const remainder = interval - (currentTime.clone().minute() % interval)

      const nextRun = currentTime.clone().add(remainder, 'm').second(0)

      if (nextRun.diff(endDatetime, 'ms') > 0) {
        return `Next run: ${startDatetime.clone().add(1, 'day')}`
      }

      return `Next run: ${nextRun}`
    }

    if (currentTime.diff(endDatetime, 'ms') > 0) {
      return `Next run: ${startDatetime.clone().add(1, 'day')}`
    }

    return `Next run: ${startDatetime}`
  }

  public static nextRunInterval30MinutesLabel(
    schedule: ScheduleInterface
  ): string {
    const { windowStart, windowEnd } = schedule

    const currentTime = TimeHelper.getDateWithTimezone(Date.now())
    const currentTimeInPolicyTimeZone = TimeHelper.getDateWithTimezone(
      new Date().getTime(),
      windowStart.timezone
    )

    const startDatetime = currentTimeInPolicyTimeZone
      .clone()
      .hour(windowStart.hour)
      .minute(windowStart.minute)
      .second(0)
    const endDatetime = currentTimeInPolicyTimeZone
      .clone()
      .hour(windowEnd.hour)
      .minute(windowEnd.minute)
      .second(0)
    const interval = 30

    if (startDatetime.diff(endDatetime, 'ms') > 0) {
      endDatetime.add(1, 'day')
    }

    // If currentTime is in the range between start and end time, then take % of 30 and use the remainder (currentTime) - nextRun should not be greater than endTime, if it is, then nextRun = startDatetime + 1 day
    // If currentTime is not in the range between start and endTime, but currentTime > endTime, then nextRun === startTime + 1 day,
    // if currentTime < endTime, nextRun ==== startTime

    if (currentTime.isBetween(startDatetime, endDatetime)) {
      const remainder = interval - (currentTime.clone().minute() % interval)

      const nextRun = currentTime.clone().add(remainder, 'm').second(0)

      if (nextRun.diff(endDatetime, 'ms') > 0) {
        return `Next run: ${startDatetime.clone().add(1, 'day')}`
      }

      return `Next run: ${nextRun}`
    }

    if (currentTime.diff(endDatetime, 'ms') > 0) {
      return `Next run: ${startDatetime.clone().add(1, 'day')}`
    }

    return `Next run: ${startDatetime}`
  }

  public static nextRunInterval15MinutesLabel(
    schedule: ScheduleInterface
  ): string {
    const { windowStart, windowEnd } = schedule

    const currentTime = TimeHelper.getDateWithTimezone(Date.now())
    const currentTimeInPolicyTimeZone = TimeHelper.getDateWithTimezone(
      new Date().getTime(),
      windowStart.timezone
    )

    const startDatetime = currentTimeInPolicyTimeZone
      .clone()
      .hour(windowStart.hour)
      .minute(windowStart.minute)
      .second(0)
    const endDatetime = currentTimeInPolicyTimeZone
      .clone()
      .hour(windowEnd.hour)
      .minute(windowEnd.minute)
      .second(0)

    const interval = 15

    if (startDatetime.diff(endDatetime, 'ms') > 0) {
      endDatetime.add(1, 'day')
    }

    if (currentTime.isBetween(startDatetime, endDatetime)) {
      const remainder = interval - (currentTime.clone().minute() % interval)

      const nextRun = currentTime.clone().add(remainder, 'm').second(0)

      if (nextRun.diff(endDatetime, 'ms') > 0) {
        return `Next run: ${startDatetime.clone().add(1, 'day')}`
      }

      return `Next run: ${nextRun}`
    }

    if (currentTime.diff(endDatetime, 'ms') > 0) {
      return `Next run: ${startDatetime.clone().add(1, 'day')}`
    }

    return `Next run: ${startDatetime}`
  }

  // If current time less than start time, then policy will start at "start time"
  // If current time more than start and less than start time + 12h, then policy will start at "start time + 12h"
  public static nextRunInterval12HoursLabel(
    schedule: ScheduleInterface
  ): string {
    const { windowStart } = schedule

    const currentTime = TimeHelper.getDateWithTimezone(Date.now())
    const currentTimeInPolicyTimeZone = TimeHelper.getDateWithTimezone(
      new Date().getTime(),
      windowStart.timezone
    )

    const startDatetime = currentTimeInPolicyTimeZone
      .clone()
      .hour(windowStart.hour)
      .minute(windowStart.minute)
      .second(0)

    const between = currentTime.isBetween(
      startDatetime,
      startDatetime.clone().add(12, 'h')
    )

    if (between) {
      return `Next run: ${startDatetime.clone().add(12, 'h')}`
    }

    return `Next run: ${startDatetime.clone().add(1, 'day')}`
  }

  public static nextRunIntervalDailyLabel(schedule: ScheduleInterface): string {
    const { windowStart, daysList } = schedule

    const currentTime = TimeHelper.getDateWithTimezone(Date.now())
    const currentTimeInPolicyTimeZone = TimeHelper.getDateWithTimezone(
      new Date().getTime(),
      windowStart.timezone
    )

    let startDatetime = currentTimeInPolicyTimeZone
      .clone()
      .hour(windowStart.hour)
      .minute(windowStart.minute)
      .second(0)

    const dayNamesList = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ]

    for (let day = 0; day < 7; day++) {
      const datetime = startDatetime.clone().add(day, 'd')
      const datetimeDay = datetime.day()

      // @ts-ignore
      if (daysList[dayNamesList[datetimeDay]]) {
        startDatetime = datetime
        break
      }
    }

    const diff = currentTime.diff(startDatetime, 'ms')

    if (diff > 0) {
      let nextRun = startDatetime.clone().add(24, 'h')

      for (let day = 0; day < 7; day++) {
        const datetime = nextRun.clone().add(day, 'd')
        const datetimeDay = datetime.day()

        // @ts-ignore
        if (daysList[dayNamesList[datetimeDay]]) {
          nextRun = datetime
          break
        }
      }

      return `Next run: ${nextRun}`
    }

    return `Next run: ${startDatetime}`
  }

  public static nextRunIntervalWeeklyLabel(
    schedule: ScheduleInterface
  ): string {
    const { windowStart, dayOfWeek } = schedule

    const dayInPolicy = dayOfWeek.value

    const currentTime = TimeHelper.getDateWithTimezone(Date.now())
    const currentTimeInPolicyTimeZone = TimeHelper.getDateWithTimezone(
      new Date().getTime(),
      windowStart.timezone
    )

    let startDatetime = currentTimeInPolicyTimeZone
      .clone()
      .hour(windowStart.hour)
      .minute(windowStart.minute)
      .second(0)

    for (let day = 0; day < 7; day++) {
      const datetime = startDatetime.clone().add(day, 'd')
      const datetimeDay = datetime.day()

      if (datetimeDay === dayInPolicy) {
        startDatetime = datetime
        break
      }
    }

    const diff = currentTime.diff(startDatetime, 'ms')

    if (diff > 0) {
      return `Next run: ${startDatetime.clone().add(7, 'd')}`
    }

    return `Next run: ${startDatetime}`
  }

  public static nextRunIntervalMonthlyLabel(
    schedule: ScheduleInterface
  ): string {
    const { windowStart, dayOfMonth } = schedule

    const currentTime = TimeHelper.getDateWithTimezone(Date.now())
    const currentTimeInPolicyTimeZone = TimeHelper.getDateWithTimezone(
      new Date().getTime(),
      windowStart.timezone
    )

    const lastDayOfTheMonth = currentTime.clone().endOf('month')
    const startDatetime = currentTimeInPolicyTimeZone
      .clone()
      .date(dayOfMonth > 0 ? dayOfMonth : lastDayOfTheMonth.date() + dayOfMonth)
      .hour(windowStart.hour)
      .minute(windowStart.minute)
      .second(0)
    const diff = currentTime.diff(startDatetime, 'ms')

    if (diff > 0) {
      const nextRun = startDatetime.clone().add(1, 'month')

      return `Next run: ${nextRun
        .clone()
        .date(
          dayOfMonth > 0
            ? dayOfMonth
            : nextRun.clone().endOf('month').date() + dayOfMonth
        )}`
    }

    return `Next run: ${startDatetime}`
  }

  public static previousRunTime(
    interval: number,
    start: HourMinuteInterface | number,
    end: HourMinuteInterface | number
  ): number {
    const currentMinute = TimeHelper.dayMinutes(Date.now())
    const policyPreviousStartTimeExpectedMinute =
      TimeHelper.normalizeMinutesByInterval(currentMinute, -interval)
    const startMinute = TimeHelper.dayMinutes(start)
    // the actual time when the policy will start
    const startMinuteNormalized = TimeHelper.normalizeMinutesByInterval(
      startMinute,
      -interval
    )
    const endMinute = TimeHelper.dayMinutes(end)

    // this can be possible if for example the time window is 14:40-14-50 and
    // the interval is 30 min, in this case "startMinuteNormalized" will be 15:00
    // and the backuping will never start
    if (endMinute > startMinute && startMinuteNormalized > endMinute) {
      return 0
    }
    // if earlier than a window
    if (policyPreviousStartTimeExpectedMinute < startMinuteNormalized) {
      return TimeHelper.currentMinusMinutes(startMinuteNormalized).valueOf()
    }

    // if later than the window
    if (policyPreviousStartTimeExpectedMinute > endMinute) {
      const nextTime = 1440 - startMinuteNormalized
      return TimeHelper.currentMinusMinutes(nextTime).valueOf()
    }

    return TimeHelper.currentMinusMinutes(
      currentMinute - policyPreviousStartTimeExpectedMinute
    ).valueOf()
  }

  public static nextWeekLabel(dayOfWeek: number): string {
    const currentTime = TimeHelper.momentNow()
    const resultTime = currentTime
      .clone()
      .startOf('week')
      .add(dayOfWeek, 'days')
    if (currentTime.day() >= dayOfWeek) {
      resultTime.add(1, 'weeks')
    }
    return `Next run: ${resultTime.format(
      TimeFormatConstant.SHORT_DATETIME_FORMAT
    )}`
  }

  public static nextMonthLabel(dayOfMonth: number): string {
    let dayOfMonthHandled = dayOfMonth
    const daysInMonthTotal = TimeHelper.daysInCurrentMonth()
    // -1 - the day before the last day of the month
    if (dayOfMonth === -1) {
      dayOfMonthHandled = daysInMonthTotal - 1
    }
    // 0 - the last day of the month
    if (dayOfMonth === 0) {
      dayOfMonthHandled = daysInMonthTotal
    }
    const currentTime = TimeHelper.momentNow()
    const resultTime = currentTime
      .clone()
      .startOf('month')
      .add(dayOfMonthHandled - 1, 'days')
    if (currentTime.date() >= dayOfMonthHandled) {
      resultTime.add(1, 'months')
    }
    return `Next run: ${resultTime.format(
      TimeFormatConstant.SHORT_DATETIME_FORMAT
    )}`
  }

  public static scheduleNextRunLabel(schedule: ScheduleInterface): string {
    switch (schedule.frequency.value) {
      case FrequencyConstant.FREQUENCY_EVERY_15_MIN.value:
        return DataHelper.nextRunInterval15MinutesLabel(schedule)
      case FrequencyConstant.FREQUENCY_EVERY_30_MIN.value:
        return DataHelper.nextRunInterval30MinutesLabel(schedule)
      case FrequencyConstant.FREQUENCY_EVERY_HOUR.value:
        return DataHelper.nextRunIntervalHourlyLabel(schedule)
      case FrequencyConstant.FREQUENCY_EVERY_12_HOURS.value:
        return DataHelper.nextRunInterval12HoursLabel(schedule)
      case FrequencyConstant.FREQUENCY_DAILY.value:
        return DataHelper.nextRunIntervalDailyLabel(schedule)
      case FrequencyConstant.FREQUENCY_WEEKLY.value:
        return DataHelper.nextRunIntervalWeeklyLabel(schedule)
      case FrequencyConstant.FREQUENCY_MONTHLY.value:
        return DataHelper.nextRunIntervalMonthlyLabel(schedule)
      default:
        SystemHelper.throwErrorInLocalEnv(
          'Cannot determine the schedule next run label'
        )
        return ''
    }
  }

  public static isRetentionDefaultPolicy(policyName: string): boolean {
    return $enum(DefaultRetentionPoliciesConstant)
      .getValues()
      .includes(policyName as DefaultRetentionPoliciesConstant)
  }

  public static getPaginatedDataForSortedTableData = (
    paginationData: PaginationModel,
    tableTabsDataInit: Record<string, VIMatrix>
  ): Record<string, VIMatrix> => {
    const pagination = paginationData
    const paginatedTabsData: Record<string, VIMatrix> = {}

    for (const [key, value] of Object.entries(tableTabsDataInit)) {
      if (value) {
        paginatedTabsData[key] = value.slice(
          pagination.offset,
          pagination.offset + pagination.limit
        )
      }
    }

    return paginatedTabsData
  }

  public static getPaginatedDataForS3TableData = (
    paginationData: PaginationModel,
    tableS3DataInit: VIMatrix
  ): VIMatrix => {
    const pagination = paginationData
    let paginatedS3Data: VIMatrix = []

    if (tableS3DataInit) {
      paginatedS3Data = tableS3DataInit.slice(
        pagination.offset,
        pagination.offset + pagination.limit
      )
    }

    return paginatedS3Data
  }

  public static headCheckboxStatus = (
    itemTotal: number,
    itemSelected: number
  ): CheckboxConstant => {
    if (itemTotal && itemSelected) {
      if (itemTotal === itemSelected) {
        return CheckboxConstant.Checked
      } else {
        return CheckboxConstant.Indeterminate
      }
    } else {
      return CheckboxConstant.Empty
    }
  }

  public static getRetentionPeriod(period: RetentionDurationInterface): string {
    const result: Array<string> = []

    if (period.days) {
      result.push(`${period.days} days`)
    }
    if (period.hours) {
      result.push(`${period.hours} hours`)
    }
    if (period.weeks) {
      result.push(`${period.weeks} weeks`)
    }
    if (period.months) {
      result.push(`${period.months} months`)
    }
    if (period.years) {
      result.push(`${period.years} years`)
    }
    if (period.infinite) {
      result.push('forever')
    }

    return result.length === 0 ? '' : result.join(', ')
  }

  public static getRetentionString(policy?: RetentionPolicy): string {
    let keepAllPoints = ''
    let resultAll = ''
    const keepOnePoint = 'Keep one '
    const result: Array<string> = []
    if (policy) {
      if (policy.keepAll) {
        keepAllPoints = `Keep all ${DataHelper.getRetentionPeriod(
          policy.keepAll
        )}`
      }
      if (policy.keepHourly) {
        result.push(
          `hourly ${DataHelper.getRetentionPeriod(policy.keepHourly)}`
        )
      }
      if (policy.keepDaily) {
        result.push(`daily ${DataHelper.getRetentionPeriod(policy.keepDaily)}`)
      }
      if (policy.keepWeekly) {
        result.push(
          `weekly ${DataHelper.getRetentionPeriod(policy.keepWeekly)}`
        )
      }
      if (policy.keepMonthly) {
        result.push(
          `monthly ${DataHelper.getRetentionPeriod(policy.keepMonthly)}`
        )
      }
      if (policy.keepYearly) {
        result.push(
          `yearly ${DataHelper.getRetentionPeriod(policy.keepYearly)}`
        )
      }
    }

    resultAll = `${keepAllPoints ? keepAllPoints + '; ' : ''}${
      result.length > 0 ? keepOnePoint + result.join(', ') : ''
    }`

    return resultAll ? resultAll : 'Not assigned'
  }

  public static getRecoverabilityIndexInPercent(
    recoverabilityStats: RecoverabilityStatsInterface
  ): RecoverabilityStatsInterface {
    const result = StatisticsFactory.buildRecoverabilityStatsEmpty()

    // calculate indexes to show in %
    result.recoverabilityIndex = recoverabilityStats.recoverabilityIndex
      ? NumHelper.roundTo(recoverabilityStats.recoverabilityIndex * 100, 2)
      : 0

    const allRecoveryPointsCount =
      ArrHelper.arrSumValues(Object.values(recoverabilityStats)) -
      recoverabilityStats.recoverabilityIndex

    result.allRecoveryPointCount = allRecoveryPointsCount

    if (allRecoveryPointsCount > 0) {
      result.unscanned = NumHelper.roundedPercent(
        recoverabilityStats.unscanned,
        allRecoveryPointsCount
      )
      // only if healthy count > 0 then recoverabilityIndex > 0
      result.healthy = NumHelper.roundedPercent(
        recoverabilityStats.healthy,
        allRecoveryPointsCount
      )
      result.manuallyRecoverable = NumHelper.roundedPercent(
        recoverabilityStats.manuallyRecoverable,
        allRecoveryPointsCount
      )
      result.quarantined = NumHelper.roundedPercent(
        recoverabilityStats.quarantined,
        allRecoveryPointsCount
      )
    }

    return result
  }

  public static getUniqueEnabledSources(
    allRedStacksList: Array<RedStackModel>
  ) {
    // grouping by status "0", "1", "3"
    const connectAndDisconnectSources = allRedStacksList.filter(
      (row: RedStackModel) =>
        row.isActive ||
        row.isInactive ||
        row.status === RedStackStatusConstant.UPGRADE_REQUIRED
    )
    const groupedByAwsAccount: Record<string, any> =
      DataHelper.groupedByAwsAccount(connectAndDisconnectSources)

    const uniqueEnabledSources = [...Object.values(groupedByAwsAccount)]

    return uniqueEnabledSources
  }

  /**
   * This function is designed to search for various problems in cloud connectors
   * @param accounts
   * @param vaults
   */
  public static findProblematicCC(
    accounts: Array<RedStackModel>,
    vaults: Array<VaultModel>
  ) {
    if (!accounts.length) {
      return {
        isProblem: false,
        problemsArray: [],
      }
    }

    const findReason = (redStackId: string) => {
      const redStackVaults = vaults.filter(
        (vault) => vault.redStackId === redStackId
      )
      const isNoDefaultVaults = !redStackVaults.some((vault) => vault.isDefault)

      const otherErrors = accounts.some(
        (rs) =>
          rs.redStackId === redStackId &&
          (rs.lastErrorMessage || rs.lastWarningMessage)
      )

      if (!redStackVaults.length) {
        return CloudConnectorProblems.NO_VAULTS
      }

      if (isNoDefaultVaults) {
        return CloudConnectorProblems.NO_DEFAULT
      }

      if (otherErrors) {
        return CloudConnectorProblems.BS_ERROR
      }

      return ''
    }

    const problematicRS = accounts
      .map((rs) => {
        return {
          reason: findReason(rs.redStackId),
          model: rs,
        }
      })
      .filter((rs) => rs.reason)

    // We return boolean and the array
    return {
      isProblem: !!problematicRS.length,
      problemsArray: problematicRS,
    }
  }

  public static getAssetType(
    selectedVariant: SelectedVariant
  ): AssetType | undefined {
    if (selectedVariant === SelectedVariant.AllEC2Variant) {
      return AssetType.ASSET_TYPE_EC2
    }

    if (selectedVariant === SelectedVariant.AllEBSVariant) {
      return AssetType.ASSET_TYPE_EBS
    }

    if (selectedVariant === SelectedVariant.AllEC2EBSVariant) {
      return AssetType.ASSET_TYPE_EC2_EBS
    }

    if (selectedVariant === SelectedVariant.S3Variant) {
      return AssetType.ASSET_TYPE_S3
    }

    if (selectedVariant === SelectedVariant.AllS3Variant) {
      return AssetType.ASSET_TYPE_S3
    }
  }

  public static getDurationParams = (
    period: RetentionDurationInterface
  ): ValueInterface => {
    if (period.hours) {
      return {
        name: DurationConstant.DURATION_HOURS.name,
        label: String(period.hours),
      }
    }
    if (period.days) {
      return {
        name: DurationConstant.DURATION_DAYS.name,
        label: String(period.days),
      }
    }
    if (period.weeks) {
      return {
        name: DurationConstant.DURATION_WEEKS.name,
        label: String(period.weeks),
      }
    }
    if (period.months) {
      return {
        name: DurationConstant.DURATION_MONTHS.name,
        label: String(period.months),
      }
    }
    if (period.years) {
      return {
        name: DurationConstant.DURATION_YEARS.name,
        label: String(period.years),
      }
    }
    return {
      name: DurationConstant.DURATION_INFINITE.name,
      label: '',
    }
  }

  public static buildSelectedPeriodFormData = (
    policy?: RetentionPolicy
  ): FormPeriodInterface => {
    const result: FormPeriodInterface = { period: [] }
    if (!policy) {
      return result
    }
    if (policy.keepAll) {
      const currentDuration = DataHelper.getDurationParams(policy.keepAll)
      result?.period?.push({
        periodValue: PeriodConstant.PERIOD_KEEP_ALL.name,
        countDuration: currentDuration.label ?? '',
        duration: currentDuration.name,
        removable: false,
      })
    }
    if (policy.keepHourly) {
      const currentDuration = DataHelper.getDurationParams(policy.keepHourly)
      result?.period?.push({
        periodValue: PeriodConstant.PERIOD_KEEP_HOURLY.name,
        countDuration: currentDuration.label ?? '',
        duration: currentDuration.name,
        removable: false,
      })
    }
    if (policy.keepDaily) {
      const currentDuration = DataHelper.getDurationParams(policy.keepDaily)
      result?.period?.push({
        periodValue: PeriodConstant.PERIOD_KEEP_DAILY.name,
        countDuration: currentDuration.label ?? '',
        duration: currentDuration.name,
        removable: false,
      })
    }
    if (policy.keepWeekly) {
      const currentDuration = DataHelper.getDurationParams(policy.keepWeekly)
      result?.period?.push({
        periodValue: PeriodConstant.PERIOD_KEEP_WEEKLY.name,
        countDuration: currentDuration.label ?? '',
        duration: currentDuration.name,
        removable: false,
      })
    }
    if (policy.keepMonthly) {
      const currentDuration = DataHelper.getDurationParams(policy.keepMonthly)
      result?.period?.push({
        periodValue: PeriodConstant.PERIOD_KEEP_MONTHLY.name,
        countDuration: currentDuration.label ?? '',
        duration: currentDuration.name,
        removable: false,
      })
    }
    if (policy.keepYearly) {
      const currentDuration = DataHelper.getDurationParams(policy.keepYearly)
      result?.period?.push({
        periodValue: PeriodConstant.PERIOD_KEEP_YEARLY.name,
        countDuration: currentDuration.label ?? '',
        duration: currentDuration.name,
        removable: false,
      })
    }
    return result
  }

  public static buildSelectedRecoveryPointTypes = (
    selectors?: Array<Selector>,
    assetSources?: VIRow,
    backupTypes?: VIRow
  ): VIRow => {
    const result: VIRow = []
    const editPolicyAccountIDs: VIRow = []
    const editPolicyRegions: VIRow = []
    const editPolicyVaults: VIRow = []
    const editPolicyTags: VIRow = []
    const editPolicyAssetSources = assetSources || []
    const editPolicyBackupTypes = backupTypes || []
    const editPolicyAssets: VIRow = []
    selectors?.map((selector) => {
      selector.criteriaList.map((criteria) => {
        if (criteria.awsAccountId) {
          editPolicyAccountIDs.push({
            name: criteria.awsAccountId,
          })
        }
        if (criteria.awsRegion) {
          editPolicyRegions.push({
            id: NumHelper.numberHash(criteria.awsRegion),
            name: criteria.awsRegion,
            label: LangHelper.getAwsRegionSingleTranslation(criteria.awsRegion),
          })
        }

        if (criteria.vault) {
          const vaultLabel = `${criteria.vault.vault} (Account ID: ${criteria.vault.awsAccountId}, Region: ${criteria.vault.region})`
          editPolicyVaults.push({
            name: String(NumHelper.numberHash(vaultLabel)),
            value: criteria.vault.vault,
            label: vaultLabel,
            defaultValue: criteria.vault.awsAccountId,
            extraValue: criteria.vault.region,
            supplementalValue: 0,
          })
        }

        if (criteria.tags) {
          criteria.tags.tagsMap.map((tag) => {
            const currentTag = `${tag[0]}${tag[1] ? '=' + tag[1] : ''}`
            editPolicyTags.push({
              name: tag[0],
              label: currentTag,
              value: tag[1],
            })
          })
        }

        if (criteria.assets) {
          criteria.assets.awsList.map((aws) => {
            if (aws.ebs) {
              editPolicyAssets.push({
                name: aws.ebs,
                value: aws.accountId,
                label: aws.region,
                type: AssetKind.AWS_EBS,
              })
            } else if (aws.ec2) {
              editPolicyAssets.push({
                name: aws.ec2,
                value: aws.accountId,
                label: aws.region,
                type: AssetKind.AWS_EC2,
              })
            } else {
              editPolicyAssets.push({
                name: aws.s3,
                value: aws.accountId,
                label: aws.region,
                type: AssetKind.AWS_S3,
              })
            }
          })
          criteria.assets.genericList.map((generic) => {
            editPolicyAssets.push({
              name: generic.hostname,
              value: '',
              label: '',
              type: AssetKind.GENERIC_HOST,
            })
          })
          criteria.assets.nasList.map((nas) => {
            editPolicyAssets.push({
              name: nas.hostname,
              value: '',
              label: '',
              type: AssetKind.GENERIC_FS,
            })
          })
        }
      })
    })

    if (editPolicyAccountIDs.length > 0) {
      result.push({
        name: SelectCategory.ACCOUNT_IDS,
        options: editPolicyAccountIDs,
      })
    }
    if (editPolicyRegions.length > 0) {
      result.push({
        name: SelectCategory.REGIONS,
        options: editPolicyRegions,
      })
    }
    if (editPolicyVaults.length > 0) {
      result.push({
        name: SelectCategory.VAULTS,
        options: editPolicyVaults,
      })
    }
    if (editPolicyTags.length > 0) {
      result.push({
        name: SelectCategory.TAGS,
        options: editPolicyTags,
      })
    }
    if (editPolicyAssetSources.length > 0) {
      result.push({
        name: SelectCategory.ASSET_SOURCES,
        options: editPolicyAssetSources,
      })
    }
    if (editPolicyBackupTypes.length > 0) {
      result.push({
        name: SelectCategory.BACKUP_TYPES,
        options: editPolicyBackupTypes,
      })
    }
    if (editPolicyAssets.length > 0) {
      result.push({
        name: SelectCategory.ASSETS,
        options: editPolicyAssets,
      })
    }
    return result
  }

  public static getAssetSourceName(assetType: number | undefined): string {
    switch (assetType) {
      case AssetKind.AWS_EBS:
        return 'EBS'
      case AssetKind.AWS_EC2:
        return 'EC2'
      case AssetKind.GENERIC_HOST:
        return 'Generic host'
      case AssetKind.GENERIC_FS:
        return 'Nas'
      case AssetKind.AWS_S3:
        return 'S3'
      default:
        return ''
    }
  }

  public static getEnabledAssetSelectTabs(value: VIRow): Array<string> {
    const tabsEnabled: Array<string> = []
    value.map((item) => {
      if (
        item.name ===
          getAssetSourceName(AssetSource.ASSET_SOURCE_GENERIC_HOST) ||
        item.name === getAssetSourceName(AssetSource.ASSET_SOURCE_NAS)
      ) {
        tabsEnabled.push(TableTabsConstant.OTHER)
      } else {
        tabsEnabled.push(item.name)
      }
    })

    return tabsEnabled
  }

  public static getSelectedAssetsFiltersRow(
    category: SelectCategory,
    value: VIRow
  ): VIRow {
    const filtersRow: VIRow = []
    const filterOptions: VIRow = []
    const enableFilterMessage =
      'To enable filtering remove the pre-selected values from the Recovery point types selectors.'
    value.map((item) => {
      filterOptions.push({
        type: 1,
        name: item.name,
        label: item.name,
        value: true,
      })
    })
    switch (category) {
      case SelectCategory.ACCOUNT_IDS:
        if (filterOptions.length > 0) {
          filtersRow.push({
            name: 'accountId',
            label: 'Account ID',
            value: '',
            type: 2,
            disabled: true,
            supplementalValue: `The list below is filtered by the account number(s) you selected on the previous steps. ${enableFilterMessage}`,
            options: filterOptions,
          })
        }
        break
      case SelectCategory.REGIONS:
        if (filterOptions.length > 0) {
          filtersRow.push({
            name: 'regions',
            label: 'Regions',
            value: '',
            type: 2,
            disabled: true,
            supplementalValue: `The list below is filtered by the region(s) you selected on the previous steps. ${enableFilterMessage}`,
            options: filterOptions,
          })
        }
        break
      case SelectCategory.VAULTS:
        const filterVaultOptions: VIRow = []
        value.map((item) => {
          if (item.supplementalValue !== 0) {
            filterVaultOptions.push({
              type: 1,
              name: String(item.supplementalValue),
              label: item.label,
              value: true,
              defaultValue: item.defaultValue,
              extraValue: item.extraValue,
            })
          }
        })
        if (filterVaultOptions.length > 0) {
          filtersRow.push({
            name: 'vaults',
            label: 'Vaults',
            value: '',
            type: 2,
            disabled: true,
            supplementalValue: `The list below is filtered by the vault(s) you selected on the previous steps. ${enableFilterMessage}`,
            options: filterVaultOptions,
          })

          // disable accountId and regions filters in case vaults filter selected
          filtersRow.push({
            name: 'accountId',
            label: 'Account ID',
            value: '',
            type: 2,
            disabled: true,
            supplementalValue:
              'To enable filtering remove the pre-selected vault(s) values from the Recovery point types selectors.',
            options: [],
          })

          filtersRow.push({
            name: 'regions',
            label: 'Regions',
            value: '',
            type: 2,
            disabled: true,
            supplementalValue:
              'To enable filtering remove the pre-selected vault(s) values from the Recovery point types selectors.',
            options: [],
          })
        }
        break
      case SelectCategory.TAGS:
        const filterTagsOptions: VIRow = []
        value.map((item) => {
          filterTagsOptions.push({
            type: 1,
            name: String(item.label),
            label: item.label,
            value: true,
          })
        })
        if (filterTagsOptions.length > 0) {
          filtersRow.push({
            name: 'assetTags',
            label: 'Asset Tags',
            value: '',
            type: 2,
            disabled: true,
            supplementalValue: `The list below is filtered by the tag(s) you selected on the previous steps. ${enableFilterMessage}`,
            options: filterTagsOptions,
          })
        }
        break
    }

    return filtersRow
  }

  public static getAllSelectedAssetsFilters(
    category: SelectCategory,
    value: VIRow,
    currentSelectedFilters: VIRow
  ): VIRow {
    const selectedFiltersRow = DataHelper.getSelectedAssetsFiltersRow(
      category,
      value
    )
    let assetsFiltersOther: VIRow = []
    switch (category) {
      case SelectCategory.ACCOUNT_IDS:
        assetsFiltersOther = currentSelectedFilters.filter(
          (item) => item.name !== 'accountId'
        )
        break
      case SelectCategory.REGIONS:
        assetsFiltersOther = currentSelectedFilters.filter(
          (item) => item.name !== 'regions'
        )
        break
      case SelectCategory.VAULTS:
        assetsFiltersOther = currentSelectedFilters.filter(
          (item) =>
            item.name !== 'vaults' &&
            item.name !== 'regions' &&
            item.name !== 'accountId'
        )
        break
      case SelectCategory.TAGS:
        assetsFiltersOther = currentSelectedFilters.filter(
          (item) => item.name !== 'assetTags'
        )
        break
    }

    const allAssetsFilters = [...assetsFiltersOther, ...selectedFiltersRow]

    return allAssetsFilters
  }

  public static getSelectedAssetsForRecoveryPointTypesSelect(
    selectedAssets: Array<AssetWithRelatedAssets<Asset>>
  ): VIRow {
    const selectedAssetsRow: VIRow = []

    selectedAssets.map((item) => {
      selectedAssetsRow.push({
        name: item.asset.awsId,
        value: item.asset.awsAccountId,
        label: item.asset.awsRegion,
        type: item.type,
      })
    })

    return selectedAssetsRow
  }

  public static getRandomInt(maxNum: number): number {
    return Math.floor(Math.random() * maxNum)
  }

  public static disabledFieldDuration(
    value: string,
    lastStr: boolean
  ): Array<string> {
    switch (value) {
      case PeriodConstant.PERIOD_KEEP_ALL.name:
        return [DurationConstant.DURATION_INFINITE.name]

      case PeriodConstant.PERIOD_KEEP_HOURLY.name:
        if (lastStr) {
          return []
        }
        return [DurationConstant.DURATION_INFINITE.name]

      case PeriodConstant.PERIOD_KEEP_DAILY.name:
        if (lastStr) {
          return [DurationConstant.DURATION_HOURS.name]
        }
        return [
          DurationConstant.DURATION_HOURS.name,
          DurationConstant.DURATION_INFINITE.name,
        ]

      case PeriodConstant.PERIOD_KEEP_WEEKLY.name:
        if (lastStr) {
          return [
            DurationConstant.DURATION_HOURS.name,
            DurationConstant.DURATION_DAYS.name,
          ]
        }
        return [
          DurationConstant.DURATION_HOURS.name,
          DurationConstant.DURATION_DAYS.name,
          DurationConstant.DURATION_INFINITE.name,
        ]

      case PeriodConstant.PERIOD_KEEP_MONTHLY.name:
        if (lastStr) {
          return [
            DurationConstant.DURATION_HOURS.name,
            DurationConstant.DURATION_DAYS.name,
            DurationConstant.DURATION_WEEKS.name,
          ]
        }
        return [
          DurationConstant.DURATION_HOURS.name,
          DurationConstant.DURATION_DAYS.name,
          DurationConstant.DURATION_WEEKS.name,
          DurationConstant.DURATION_INFINITE.name,
        ]

      case PeriodConstant.PERIOD_KEEP_YEARLY.name:
        if (lastStr) {
          return [
            DurationConstant.DURATION_HOURS.name,
            DurationConstant.DURATION_DAYS.name,
            DurationConstant.DURATION_WEEKS.name,
            DurationConstant.DURATION_MONTHS.name,
          ]
        }
        return [
          DurationConstant.DURATION_HOURS.name,
          DurationConstant.DURATION_DAYS.name,
          DurationConstant.DURATION_WEEKS.name,
          DurationConstant.DURATION_MONTHS.name,
          DurationConstant.DURATION_INFINITE.name,
        ]
      default:
        return []
    }
  }

  public static buildrecoverabilityStats(
    stats: Nullable<RecoverabilityStatsInterface>
  ): Nullable<RecoverabilityStatsInterface> {
    if (stats) {
      return {
        unscanned: TypeHelper.numberVal(stats.unscanned) ?? 0,
        healthy: TypeHelper.numberVal(stats.healthy) ?? 0,
        quarantined: TypeHelper.numberVal(stats.quarantined) ?? 0,
        manuallyRecoverable:
          TypeHelper.numberVal(stats.manuallyRecoverable) ?? 0,
        recoverabilityIndex:
          TypeHelper.numberVal(stats.recoverabilityIndex) ?? 0,
      }
    }

    return stats
  }

  public static buildRetentionPolicyEmptyFilters(): VIRow {
    return [
      {
        name: SelectCategory.ACCOUNT_IDS,
        options: [],
      },
      {
        name: SelectCategory.REGIONS,
        options: [],
      },
      {
        name: SelectCategory.VAULTS,
        options: [],
      },
      {
        name: SelectCategory.BACKUP_TYPES,
        options: [],
      },
      {
        name: SelectCategory.ASSET_SOURCES,
        options: [],
      },
      {
        name: SelectCategory.TAGS,
        options: [],
      },
    ]
  }

  public static getFsTypeLabel(fsType: string): string {
    switch (fsType.toLowerCase()) {
      case 'xfs':
        return 'XFS'
      case 'ntfs':
        return 'NTFS'
      case 'btrfs':
        return 'Btrfs'
      case 'vfat':
        return 'FAT32'
      case '':
        return 'N/A'
      default:
        return fsType
    }
  }

  public static getSnapshotVulnerabilitiesData(
    vulnerabilities: Nullable<SnapshotVulnerabilities>
  ): VIRow {
    const result: VIRow = []

    if (vulnerabilities) {
      result.push(
        {
          name: 'Unencrypted',
          value: vulnerabilities.snapshotsUnencrypted,
          label: DashboardColors.RED,
        },
        {
          name: 'w. Public Permissions',
          value: vulnerabilities.snapshotsPublicPermissions,
          label: DashboardColors.RED,
        },
        {
          name: 'Older than 30 days',
          value: vulnerabilities.snapshotsOlder30Days,
          label: DashboardColors.RED,
        }
      )
    }

    return result
  }

  public static getSnapshotVulnerabilitiesV3Data(
    vulnerabilities: Nullable<SnapshotVulnerabilities>
  ): VIRow {
    const result: VIRow = []

    if (vulnerabilities) {
      result.push(
        {
          name: SnapshotVulnerabilitiesTypeName.UNENCRYPTED,
          value: vulnerabilities.snapshotsUnencrypted,
          label: vulnerabilities.snapshotsUnencrypted > 0 ? 'error' : '',
          extraValue: SnapshotVulnerabilitiesTypeName.UNENCRYPTED, // used for drilldown
        },
        {
          name: SnapshotVulnerabilitiesTypeName.PUBLIC_PERMISSIONS,
          value: vulnerabilities.snapshotsPublicPermissions,
          label: vulnerabilities.snapshotsPublicPermissions > 0 ? 'error' : '',
          extraValue: SnapshotVulnerabilitiesTypeName.PUBLIC_PERMISSIONS, // used for drilldown
        }
      )
    }

    return result
  }

  public static calculateCircleData(angle: number, radius: number) {
    const circumference = 2 * Math.PI * radius

    const strokeOffset = (1 / 4) * circumference
    const strokeDasharray = (angle / 360) * circumference

    return {
      radius,
      strokeDashArray: `${strokeDasharray}, ${circumference - strokeDasharray}`,
      strokeDashOffset: strokeOffset,
    }
  }

  public static getDataForDashboardFilter(
    filters?: Array<ValueInterface>,
    search?: string
  ) {
    const dataForNewTable: Record<string, any> = {}
    // @ts-ignore
    const isFilters = (filters?.length ?? []) > 0

    if (isFilters) {
      filters?.map((filter: ValueInterface) => {
        if (filter.name === 'accountId') {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.accountIds) {
              dataForNewTable.accountIds = []
              dataForNewTable.accountIds?.push(value.name)
            } else {
              dataForNewTable.accountIds?.push(value.name)
            }
          })
        } else if (filter.name === 'regions') {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.regions) {
              dataForNewTable.regions = []
              dataForNewTable.regions?.push(value.name)
            } else {
              dataForNewTable.regions?.push(value.name)
            }
          })
        } else if (filter.name === 'covered') {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.covered) {
              dataForNewTable.covered = []
              dataForNewTable.covered?.push(JSON.parse(value.name))
            } else {
              delete dataForNewTable.covered
            }
          })
        } else if (filter.name === 'backupsOnSchedule') {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.backupsOnSchedule) {
              dataForNewTable.backupsOnSchedule = []
              dataForNewTable.backupsOnSchedule?.push(JSON.parse(value.name))
            } else {
              delete dataForNewTable.backupsOnSchedule
            }
          })
        } else if (filter.name === 'protected') {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.protected) {
              dataForNewTable.protected = []
              dataForNewTable.protected?.push(JSON.parse(value.name))
            } else {
              delete dataForNewTable.protected
            }
          })
        } else if (filter.name === 'malwares') {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.malwaresList) {
              dataForNewTable.malwaresList = []
              dataForNewTable.malwaresList?.push(value.name)
            } else {
              dataForNewTable.malwaresList?.push(value.name)
            }
          })
        } else if (filter.name === 'ransomwares') {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.ransomwaresList) {
              dataForNewTable.ransomwaresList = []
              dataForNewTable.ransomwaresList?.push(value.name)
            } else {
              dataForNewTable.ransomwaresList?.push(value.name)
            }
          })
        } else if (filter.name === 'ebsVulnerabilities') {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.ebsVulnerabilities) {
              dataForNewTable.ebsVulnerabilities = []
              dataForNewTable.ebsVulnerabilities?.push(value.name)
            } else {
              dataForNewTable.ebsVulnerabilities?.push(value.name)
            }
          })
        } else if (filter.name === 'snapshotVulnerabilityTypes') {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.snapshotVulnerabilityTypes) {
              dataForNewTable.snapshotVulnerabilityTypes = []
              dataForNewTable.snapshotVulnerabilityTypes?.push(value.name)
            } else {
              dataForNewTable.snapshotVulnerabilityTypes?.push(value.name)
            }
          })
        } else if (filter.name === 'osKinds') {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.osKinds) {
              dataForNewTable.osKinds = []
              dataForNewTable.osKinds?.push(JSON.parse(value.name))
            } else {
              dataForNewTable.osKinds?.push(JSON.parse(value.name))
            }
          })
        } else if (filter.name === 'fsCheck') {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.fsCheck) {
              dataForNewTable.fsCheck = []
              dataForNewTable.fsCheck?.push(value.name)
            } else {
              dataForNewTable.fsCheck?.push(value.name)
            }
          })
        } else if (filter.name === 'riskType') {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.riskType) {
              dataForNewTable.riskType = []
              dataForNewTable.riskType?.push(JSON.parse(value.name))
            } else {
              dataForNewTable.riskType?.push(JSON.parse(value.name))
            }
          })
        } else if (filter.name === 'state') {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.state) {
              dataForNewTable.state = []
              dataForNewTable.state?.push(JSON.parse(value.name))
            } else {
              delete dataForNewTable.state
            }
          })
        } else if (filter.name === 'volumeAwsIds') {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.volumeAwsIds) {
              dataForNewTable.volumeAwsIds = []
              dataForNewTable.volumeAwsIds?.push(value.name)
            } else {
              dataForNewTable.volumeAwsIds?.push(value.name)
            }
          })
        } else if (filter.name === 'attachedInstancesAwsIds') {
          filter.options?.map((value: ValueInterface) => {
            if (!dataForNewTable.attachedInstancesAwsIds) {
              dataForNewTable.attachedInstancesAwsIds = []
              dataForNewTable.attachedInstancesAwsIds?.push(value.name)
            } else {
              dataForNewTable.attachedInstancesAwsIds?.push(value.name)
            }
          })
        } else {
          delete dataForNewTable.accountIds
          delete dataForNewTable.regions
          delete dataForNewTable.covered
          delete dataForNewTable.protected
          delete dataForNewTable.malwaresList
          delete dataForNewTable.ransomwaresList
          delete dataForNewTable.ebsVulnerabilities
          delete dataForNewTable.snapshotVulnerabilityTypes
          delete dataForNewTable.protected
          delete dataForNewTable.osKinds
          delete dataForNewTable.state
        }
      })
    }

    if (search) {
      if (search !== '') {
        dataForNewTable.idSearch = search
      } else {
        delete dataForNewTable.idSearch
      }
    }
    return dataForNewTable
  }

  public static mapToHumanReadableThreats(
    map: Map<Region, number>
  ): Array<[string, number]> {
    const threatsByRegion: Array<[string, number]> = []
    for (const [key, value] of map.entries()) {
      const regionName = StrHelper.getHumanReadableRegion(key)
      threatsByRegion.push([regionName, value])
    }
    return threatsByRegion
  }

  public static regionsHavePrefix(
    regionsList: Array<string>,
    regionPrefix: string
  ): boolean {
    if (!regionsList.length) {
      return false
    }

    return regionsList.every((region) => region.startsWith(regionPrefix + '-'))
  }

  public static getThreatsMapRegionOption(
    regionsList: Array<string>,
    regionPrefix: string
  ): Record<string, string> {
    return DataHelper.regionsHavePrefix(regionsList, regionPrefix)
      ? { region: regionPrefix.toUpperCase() }
      : {}
  }

  public static getWebhooksEventType = (name: string) => {
    switch (name) {
      case EventTypeConstant.ACCOUNT_LEVEL_STACK:
        return 'Account Level Stack'
      case EventTypeConstant.ASSET:
        return 'Asset'
      case EventTypeConstant.BACKUP_POLICY:
        return 'Backup Policy'
      case EventTypeConstant.CLOUD_CONNECTOR:
        return 'Cloud Connector'
      case EventTypeConstant.LOCAL_PATH:
        return 'Local Path'
      case EventTypeConstant.SNAPSHOTS:
        return 'Snapshots'
      case EventTypeConstant.RECOVERY_POINT:
        return 'Recovery Point'
      case EventTypeConstant.VAULT:
        return 'Vault'
      case EventTypeConstant.ROLE:
        return 'Role'
      case EventTypeConstant.USER:
        return 'User'
      default:
        return ''
    }
  }

  public static groupVaultsByAccountIdAndRegion = (
    vaults: Array<VaultModel>
  ) => {
    const groups: {
      [id: string]: { accountId: string; vaults: Array<VaultModel> }
    } = {}

    for (const vault of vaults) {
      if (!groups[vault.accountId]) {
        groups[vault.accountId] = {
          accountId: vault.accountId,
          vaults: [],
        }
      }
      groups[vault.accountId]?.vaults.push(vault)
    }

    const result = []

    for (const { accountId, vaults: vaultsArr } of Object.values(groups)) {
      const regionToVaults: { [region: string]: Array<VaultModel> } = {}
      for (const vault of vaultsArr) {
        if (!regionToVaults[vault.region]) {
          regionToVaults[vault.region] = []
        }
        regionToVaults[vault.region]?.push(vault)
      }
      result.push({
        accountId,
        regionToVaults,
      })
    }

    return result
  }

  public static getVolumeStatus(status: VolumeStatus): string {
    switch (status) {
      case VolumeStatusConstant.VOLUME_STATUS_UNKNOWN.value:
        return VolumeStatusConstant.VOLUME_STATUS_UNKNOWN.name
      case VolumeStatusConstant.VOLUME_STATUS_AVAILABLE.value:
        return VolumeStatusConstant.VOLUME_STATUS_AVAILABLE.name
      case VolumeStatusConstant.VOLUME_STATUS_CREATING.value:
        return VolumeStatusConstant.VOLUME_STATUS_CREATING.name
      case VolumeStatusConstant.VOLUME_STATUS_DELETED.value:
        return VolumeStatusConstant.VOLUME_STATUS_DELETED.name
      case VolumeStatusConstant.VOLUME_STATUS_DELETING.value:
        return VolumeStatusConstant.VOLUME_STATUS_DELETING.name
      case VolumeStatusConstant.VOLUME_STATUS_ERROR.value:
        return VolumeStatusConstant.VOLUME_STATUS_ERROR.name
      case VolumeStatusConstant.VOLUME_STATUS_IN_USE.value:
        return VolumeStatusConstant.VOLUME_STATUS_IN_USE.name
      default:
        return VolumeStatusConstant.VOLUME_STATUS_UNKNOWN.name
    }
  }

  public static getVolumeStatusCheck(status: VolumeStatusCheck): string {
    switch (status) {
      case VolumeStatusCheckConstant.VOLUME_STATUS_CHECK_UNKNOWN.value:
        return VolumeStatusCheckConstant.VOLUME_STATUS_CHECK_UNKNOWN.name
      case VolumeStatusCheckConstant.VOLUME_STATUS_IMPAIRED.value:
        return VolumeStatusCheckConstant.VOLUME_STATUS_IMPAIRED.name
      case VolumeStatusCheckConstant.VOLUME_STATUS_INSUFFICIENT_DATA.value:
        return VolumeStatusCheckConstant.VOLUME_STATUS_INSUFFICIENT_DATA.name
      case VolumeStatusCheckConstant.VOLUME_STATUS_OK.value:
        return VolumeStatusCheckConstant.VOLUME_STATUS_OK.name

      default:
        return VolumeStatusCheckConstant.VOLUME_STATUS_CHECK_UNKNOWN.name
    }
  }

  public static volumeDashboardInfo(
    volume: Nullable<VolumeModel>,
    instances: Nullable<Array<string>>
  ): VIRow {
    return [
      {
        name: 'Volume ID',
        label: volume?.getParsedCloudProviderVolumeId() ?? '-',
      },
      {
        name: 'Asset Type',
        label: 'EBS',
      },
      {
        name: 'Account ID',
        label: volume?.getAccountId() ?? '-',
      },
      {
        name: 'Region',
        label:
          LangHelper.getAwsRegionSingleTranslation(volume?.getRegion() ?? '') ??
          '-',
      },
      {
        name: 'State',
        label: instances?.length ? 'Attached' : 'Unattached',
      },
      {
        name: 'Size (GiB)',
        label:
          NumHelper.getSizeFormattedInGib(volume?.getInventorySize() ?? 0) ??
          '-',
      },
      {
        name: 'Type',
        label: volume?.getVolumeType() ?? '-',
      },
      {
        name: 'Volume Status',
        label:
          DataHelper.getVolumeStatusCheck(volume?.getStatusCheck() ?? 0) ?? '-',
      },
      {
        name: 'Volume State',
        label: DataHelper.getVolumeStatus(volume?.getStatus() ?? 0) ?? '-',
      },
      {
        name: 'IOPS',
        label: String(volume?.getIops()) ?? '-',
      },
      {
        name: 'Throughput',
        label: String(volume?.getThroughput()) ?? '-',
      },
      {
        name: 'Encryption',
        label: volume?.getEncrypted() ? 'Encrypted' : 'Unencrypted',
      },
      {
        name: 'Availability Zone',
        label: volume?.getAvailabilityZone() ?? '-',
      },
      {
        name: 'Multi-Attach Enabled',
        label: String(volume?.getMultiAttachEnabled()) ?? '-',
      },
      {
        name: 'Attached Instances',
        label: instances?.length ? instances?.join(', ') : '-',
        value: instances?.length
          ? instances
              ?.map((instance) =>
                PageHelper.buildItemUrl(`${instance}`, 'instance')
              )
              .join(', ')
          : '',
      },
      {
        name: 'Created',
        label: `${TimeHelper.timestampShortDatetimeFormat(
          volume?.getCreatedAt(),
          TimeFormatConstants.SHORT_DATETIME_FORMAT_SMALL
        )} (${String(
          TimeHelper.getAgeInMonthsOrDays(volume?.getCreatedAt() ?? 0, true)
        )})`,
      },
      {
        name: 'Policies',
        label:
          volume?.getCoveredByPolicies() &&
          volume?.getCoveredByPolicies()?.length > 0
            ? volume?.getCoveredByPolicies()?.join(', ')
            : '-',
      },
      {
        name: 'Tags',
        label: volume?.getTags()?.length ? '' : '-',
        tags: volume?.getTags()?.length ? volume?.getTags() : [],
      },
    ]
  }

  // Used for restore RP (both ec2 and ebs)
  private static aggregateScans(scansList: Array<Scan>) {
    return scansList.reduce((acc: number, scan) => {
      if (scan.isInfected) {
        return 1 // infected
      }

      return acc
    }, 0) // clean
  }

  public static restoreRpInstanceInfo({
    backup,
    scans,
    ec2,
  }: {
    backup: ElastioRecoveryPoint
    scans: ListScans
    ec2: EC2
  }): VIRow {
    const malware = DataHelper.aggregateScans(scans.malwares)
    const ransomware = DataHelper.aggregateScans(scans.ransomwares)
    const fs = DataHelper.aggregateScans(scans.filesystemChecks)

    // This logic used in the modals on the asset page, so we use it here to keep the same logic and synchronize the date
    const getScanDate = (scansList: Array<Scan>) => {
      const latestScan = scansList[0]

      return latestScan ? latestScan.createdAt.getTime() : undefined
    }

    return [
      {
        name: 'Ransomware',
        supplementalValue: getScanDate(scans.ransomwares), // Date of Scan
        value: ransomware,
      },
      {
        name: 'Malware',
        supplementalValue: getScanDate(scans.malwares), // Date of Scan
        value: malware,
      },
      {
        name: 'File Integrity Issues',
        supplementalValue: getScanDate(scans.filesystemChecks), // Date of Scan
        value: fs,
      },
      {
        name: 'Size',
        label: StrHelper.realFileSize(backup.sizeInfo.optimizedSize), // backup
      },
      {
        name: 'RP completion time',
        label: String(backup.timestamp.getTime()),
      },
      {
        name: 'Recovery point ID',
        label: backup.ccRpId, // backup
      },
      {
        name: 'Vault',
        label: backup.vault.name,
      },
      {
        name: 'Vault region',
        label: LangHelper.getAwsRegionSingleTranslation(ec2.awsRegion), // EC2
      },
      {
        name: 'AWS Account',
        label: ec2.awsAccountId,
      },
    ]
  }

  public static snapshotDashboardInfo(
    snapshot: Nullable<SnapshotModel>,
    volume: Nullable<VolumeModel>
  ): VIRow {
    return [
      {
        name: 'Volume ID',
        label: snapshot?.getParsedCloudProviderVolumeId() ?? '-',
        value: volume?.getParsedCloudProviderVolumeId()
          ? PageHelper.buildItemUrl(
              `${volume.getParsedCloudProviderVolumeId()}`,
              'volume'
            )
          : '',
      },
      {
        name: 'Snapshot name',
        label: snapshot?.getName() !== '' ? snapshot?.getName() : '-',
      },
      {
        name: 'Account ID',
        label: snapshot?.getAccountId() ?? '-',
      },
      {
        name: 'Size (GiB)',
        label:
          NumHelper.getSizeFormattedInGib(snapshot?.getInventorySize() ?? 0) ??
          '-',
      },
      {
        name: 'Created',
        label: `${TimeHelper.timestampShortDatetimeFormat(
          snapshot?.getCreatedAt(),
          TimeFormatConstants.SHORT_DATETIME_FORMAT_SMALL
        )} (${String(
          TimeHelper.getAgeInMonthsOrDays(snapshot?.getCreatedAt() ?? 0, true)
        )})`,
      },
      {
        name: 'Encrypted',
        label: snapshot?.isUnencrypted() ? 'No' : 'Yes',
      },
      {
        name: 'Tags',
        label: snapshot?.getTags()?.length ? '' : '-',
        tags: snapshot?.getTags()?.length ? snapshot?.getTags() : [],
      },
    ]
  }

  public static restoreRpVolumeInfo({
    backup,
    ebs,
    scans,
  }: {
    backup: ElastioRecoveryPoint
    scans: ListScans
    ebs: EBS
  }): VIRow {
    const malware = DataHelper.aggregateScans(scans.malwares)
    const ransomware = DataHelper.aggregateScans(scans.ransomwares)
    const fs = DataHelper.aggregateScans(scans.filesystemChecks)

    // This logic used in the modals on the asset page, so we use it here to keep the same logic and synchronize the date
    const getScanDate = (scansList: Array<Scan>) => {
      const latestScan = scansList[0]

      return latestScan ? latestScan.createdAt.getTime() : undefined
    }

    return [
      {
        name: 'Ransomware',
        supplementalValue: getScanDate(scans.ransomwares), // Date of Scan
        value: ransomware,
      },
      {
        name: 'Malware',
        supplementalValue: getScanDate(scans.malwares), // Date of Scan
        value: malware,
      },
      {
        name: 'File Integrity Issues',
        supplementalValue: getScanDate(scans.filesystemChecks), // Date of Scan
        value: fs,
      },
      {
        name: 'Size',
        label: StrHelper.realFileSize(backup.sizeInfo.optimizedSize), // backup
      },
      {
        name: 'RP completion time',
        label: String(backup.timestamp.getTime()),
      },
      {
        name: 'Recovery point ID',
        label: backup.ccRpId, // backup
      },
      {
        name: 'Vault',
        label: backup.vault.name,
      },
      {
        name: 'Vault region',
        label: LangHelper.getAwsRegionSingleTranslation(ebs.awsRegion), // EBS
      },
      {
        name: 'AWS Account',
        label: ebs.awsAccountId,
      },
    ]
  }

  public static accountRegionPairsList(arr: Array<RedStackModel>): VIRow {
    const result: VIRow = []
    arr
      .sort((a: RedStackModel, b: RedStackModel) => {
        const firstAccount = `${a.awsAccount} ${a.awsRegion}`
        const secondAccount = `${b.awsAccount} ${b.awsRegion}`

        return firstAccount.localeCompare(secondAccount)
      })
      .map((rs: RedStackModel) => {
        const regionName = LangHelper.getAwsRegionSingleTranslation(
          rs.awsRegion
        )
        const accountRegionLabel = `${rs.awsAccount} (${regionName})`
        result.push({
          name: accountRegionLabel,
          label: accountRegionLabel,
          value: rs.awsAccount,
          extraValue: rs.awsRegion,
        })
      })
    return result
  }

  public static buildCloudConnectorIdsFilter(
    cloudConnectorIds: Array<string>
  ): ValueInterface {
    const result: ValueInterface = {
      name: 'cloudConnectorIds',
      label: 'Cloud Connector IDs',
      value: '',
      options: [],
    }

    cloudConnectorIds.map((id) => {
      result.options?.push({
        type: 1,
        name: id,
        label: id,
        value: true,
      })
    })

    return result
  }

  public static buildCloudConnectorsFilter(
    cloudConnectors: VIRow
  ): ValueInterface {
    const result: ValueInterface = {
      name: 'cloudConnectors',
      label: 'Cloud Connectors',
      value: '',
      options: [],
    }

    cloudConnectors.map((cloudConnector) => {
      result.options?.push({
        type: 1,
        name: String(NumHelper.numberHash(cloudConnector.name)),
        label: cloudConnector.name,
        defaultValue: cloudConnector.value,
        extraValue: cloudConnector.extraValue,
        value: true,
      })
    })

    return result
  }

  // red stack version for displaying s3 buckets
  public static isNotSupportedVersionCCsFunc(
    arrayRedStacks: Array<RedStackModel>
  ): boolean {
    return arrayRedStacks?.some((rs: RedStackModel) => {
      // version view: '000.000.000'
      const versionView: Array<string> = []
      rs.version?.split('.')?.map((partOfVersion) => {
        versionView.push(partOfVersion.padStart(3, '0'))
      })
      // example: '000.024.044' < '000.026.028'
      return versionView?.join('.') < MINIMUM_RED_STACK_VERSION_FOR_S3_BUCKETS
    })
  }

  // parsed asset type for Inventory asset
  public static getParsedInventoryAssetType(assetId: string): AssetKind {
    let assetType = AssetKind.UNDEFINED
    assetId.split(':')?.map((partOfId) => {
      if (
        partOfId.toLowerCase() ===
        AssetKind[AssetKind.AWS_EC2]?.toLowerCase().replace('_', '-')
      ) {
        return (assetType = AssetKind.AWS_EC2)
      }

      if (
        partOfId.toLowerCase() ===
        AssetKind[AssetKind.AWS_EBS]?.toLowerCase().replace('_', '-')
      ) {
        return (assetType = AssetKind.AWS_EBS)
      }

      if (
        partOfId.toLowerCase() ===
        AssetKind[AssetKind.AWS_S3]?.toLowerCase().replace('_', '-')
      ) {
        return (assetType = AssetKind.AWS_S3)
      }

      if (
        partOfId.toLowerCase() ===
        AssetKind[AssetKind.AWS_S3_OBJECTS]?.toLowerCase().replace('_', '-')
      ) {
        return (assetType = AssetKind.AWS_S3_OBJECTS)
      }

      if (
        partOfId.toLowerCase() ===
        AssetKind[AssetKind.GENERIC_HOST]?.toLowerCase().replace('_', '-')
      ) {
        return (assetType = AssetKind.GENERIC_HOST)
      }
    })
    return assetType
  }

  public static randomUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0
        const v = c === 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      }
    )
  }

  // protected filter contains two options - protected and unprotected
  // both options should be removed to switch out this filter
  public static getFiltersWithoutProtectedFilter(filters: VIRow): VIRow {
    filters.forEach((filter) => {
      if (filter.name === FilterNamesConstant.IS_PROTECTED) {
        filter.options?.splice(0, 2)
      }
    })

    return filters
  }
}

export default DataHelper
