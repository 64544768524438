import React, { memo, useEffect } from 'react'
import Script from 'next/script'
import { useSelector } from 'react-redux'
import { getUser } from '@store/selectors/user.selector'
import { INTERCOM_API_ID } from '@lib/constants/app.constant'
import SystemHelper from '@lib/helpers/system.helper'

function Intercom() {
  const user = useSelector(getUser)
  useEffect(() => {
    if (SystemHelper.isLocalEnv()) {
      return
    }
    try {
      // @ts-ignore
      globalThis.Intercom('boot', {
        app_id: INTERCOM_API_ID,
        name: user.name,
        email: user.email,
        user_id: user.innerId,
        alignment: 'right',
        vertical_padding: 0,
        custom_launcher_selector: '#intercom_custom_link',
        hide_default_launcher: true,
      })
    } catch (_e) {
      SystemHelper.throwErrorInLocalEnv('Intercom was not loaded')
    }
  }, [user.id])

  if (SystemHelper.isLocalEnv()) {
    return null
  }
  return (
    <div data-testid="intercom-component">
      <Script
        id="intercom-script"
        dangerouslySetInnerHTML={{
          __html: `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');
          ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};
          i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');
          s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${INTERCOM_API_ID}';
          var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};
          if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}
          else{w.addEventListener('load',l,false);}}})();`,
        }}
      />
    </div>
  )
}

export default memo(Intercom)
