import {
  baseRequestScenario,
  sentryReThrowCatchHandler,
  toastRequestScenario,
} from '@store/epics/epic-func'
import {
  REQUEST_EDIT_POLICY_RETENTION,
  REQUEST_EDIT_POLICY_RETENTION_DEFAULT,
  requestEditPolicyRetention,
  setEditPolicyCurrentAssetsSelected,
  setEditPolicyMissingAssetsSelectedInitial,
  setEditPolicyRecoveryPeriod,
  setEditPolicyRecoveryPeriodInitial,
  setEditPolicyRetention,
  setEditPolicyRetentionAccountIDsInitial,
  setEditPolicyRetentionAssetSourcesInitial,
  setEditPolicyRetentionBackupTypesInitial,
  setEditPolicyRetentionName,
  setEditPolicyRetentionRegionsInitial,
  setEditPolicyRetentionSelectedFilters,
  setEditPolicyRetentionStatus,
  setEditPolicyRetentionTagsInitial,
  setEditPolicyRetentionVaultsInitial,
  setEditPolicySelectedAssetsInitial,
  UPDATE_EDIT_POLICY_RETENTION,
  UPDATE_EDIT_POLICY_RETENTION_DEFAULT,
} from '@store/actions/edit-policy-retention.action'
import PreloaderConstants from '@lib/constants/preloader.constant'
import ActionInterface from '@lib/interfaces/action.interface'
import StoreService from '@lib/services/store.service'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import RetentionPolicyModel from '@lib/models/retention-policy.model'
import GrpcRetentionPoliciesService from '@lib/services/grpc/grpc-retention-policies.service'
import {
  getEditPolicyCurrentAssetsSelected,
  getEditPolicyRecoveryPeriod,
  getEditPolicyRetentionAccountIDs,
  getEditPolicyRetentionAssetSources,
  getEditPolicyRetentionBackupTypes,
  getEditPolicyRetentionName,
  getEditPolicyRetentionRegions,
  getEditPolicyRetentionStatus,
  getEditPolicyRetentionTags,
  getEditPolicyRetentionVaults,
} from '@store/selectors/edit-policy-retention.selector'
import {
  setAllSelectedAssets,
  setAssetsSelectEnabledTabs,
} from '@store/actions/assets-select.action'
import { VIRow } from '@lib/engine-types'
import GrpcMixedService from '@lib/services/grpc/high/grpc-mixed.service'
import { SelectCategory } from '@lib/constants/retention-policy/select-category.constant'
import GeneralService from '@lib/services/general.service'
import { emptyAction } from '@store/actions/default/empty.action'
import PagePathConstant from '@lib/constants/page-path.constant'
import DataHelper from '@lib/helpers/data.helper'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Asset,
  AssetWithRelatedAssets,
} from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'

export const requestEditPolicyRetentionEpicOld = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_EDIT_POLICY_RETENTION],
      PreloaderConstants.REQUEST_EDIT_POLICY_RETENTION,
      (action: ActionInterface) =>
        GrpcRetentionPoliciesService.getRetentionPolicy(action.payload).catch(
          sentryReThrowCatchHandler('Cannot get a retention policy')
        ),
      (data: RetentionPolicyModel) => {
        StoreService.dispatchAction(setEditPolicyRetentionName(data.policyName))
        StoreService.dispatchAction(
          setEditPolicyRetentionStatus(data.policyStatus)
        )
        return setEditPolicyRetention(data)
      },
      ErrorGroupConstants.EDIT_POLICY_RETENTION
    )
  )

export const requestEditPolicyRetentionDefaultEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_EDIT_POLICY_RETENTION_DEFAULT],
      PreloaderConstants.REQUEST_EDIT_POLICY_RETENTION_DEFAULT,
      (action: ActionInterface) =>
        GrpcRetentionPoliciesService.getRetentionPolicy(action.payload).catch(
          sentryReThrowCatchHandler('Cannot get a default retention policy')
        ),
      (retentionPolicy: RetentionPolicyModel) => {
        StoreService.dispatchAction(
          setEditPolicyRetentionName(retentionPolicy.policyName)
        )
        StoreService.dispatchAction(
          setEditPolicyRetentionStatus(retentionPolicy.policyStatus)
        )
        StoreService.dispatchAction(
          setEditPolicyRecoveryPeriod(retentionPolicy.dataForPeriodForm)
        )
        StoreService.dispatchAction(
          setEditPolicyRecoveryPeriodInitial(retentionPolicy.dataForPeriodForm)
        )
        return setEditPolicyRetention(retentionPolicy)
      },
      ErrorGroupConstants.EDIT_POLICY_RETENTION_DEFAULT
    )
  )

export const requestEditPolicyRetentionEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_EDIT_POLICY_RETENTION],
      PreloaderConstants.REQUEST_EDIT_POLICY_RETENTION,
      (action: ActionInterface) =>
        GrpcMixedService.getRetentionPolicyWithSelectedAssets(
          action.payload
        ).catch(sentryReThrowCatchHandler('Cannot get a retention policy')),
      ({
        retentionPolicy,
        selectedAssets,
        missingAssets,
        selectedAssetsFilters,
      }: {
        retentionPolicy: RetentionPolicyModel
        selectedAssets: Array<AssetWithRelatedAssets<Asset>>
        missingAssets: VIRow
        selectedAssetsFilters: VIRow
      }) => {
        StoreService.dispatchAction(
          setEditPolicyRetentionName(retentionPolicy.policyName)
        )
        StoreService.dispatchAction(
          setEditPolicyRetentionStatus(retentionPolicy.policyStatus)
        )
        StoreService.dispatchAction(
          setEditPolicyCurrentAssetsSelected(
            retentionPolicy.dataForSelectedAssets || []
          )
        )
        StoreService.dispatchAction(
          setEditPolicyMissingAssetsSelectedInitial(missingAssets)
        )
        StoreService.dispatchAction(
          setEditPolicyRetentionSelectedFilters(selectedAssetsFilters)
        )
        //TODO uncomment and fix
        StoreService.dispatchAction(setAllSelectedAssets(selectedAssets))
        StoreService.dispatchAction(
          setEditPolicySelectedAssetsInitial(selectedAssets)
        )
        StoreService.dispatchAction(
          setEditPolicyRecoveryPeriodInitial(retentionPolicy.dataForPeriodForm)
        )
        if (
          !retentionPolicy.isDefault &&
          retentionPolicy.dataForRecoveryPointTypes
        ) {
          StoreService.dispatchAction(
            setEditPolicyRetentionAccountIDsInitial(
              retentionPolicy.dataForRecoveryPointTypes.find(
                (s) => s.name === SelectCategory.ACCOUNT_IDS
              )?.options || []
            )
          )
          StoreService.dispatchAction(
            setEditPolicyRetentionRegionsInitial(
              retentionPolicy.dataForRecoveryPointTypes.find(
                (s) => s.name === SelectCategory.REGIONS
              )?.options || []
            )
          )
          StoreService.dispatchAction(
            setEditPolicyRetentionVaultsInitial(
              retentionPolicy.dataForRecoveryPointTypes.find(
                (s) => s.name === SelectCategory.VAULTS
              )?.options || []
            )
          )
          const sources =
            retentionPolicy.dataForRecoveryPointTypes.find(
              (s) => s.name === SelectCategory.ASSET_SOURCES
            )?.options || []
          const tabsEnabled = DataHelper.getEnabledAssetSelectTabs(sources)
          StoreService.dispatchAction(setAssetsSelectEnabledTabs(tabsEnabled))
          StoreService.dispatchAction(
            setEditPolicyRetentionAssetSourcesInitial(sources)
          )

          StoreService.dispatchAction(
            setEditPolicyRetentionBackupTypesInitial(
              retentionPolicy.dataForRecoveryPointTypes.find(
                (s) => s.name === SelectCategory.BACKUP_TYPES
              )?.options || []
            )
          )
          StoreService.dispatchAction(
            setEditPolicyRetentionTagsInitial(
              retentionPolicy.dataForRecoveryPointTypes.find(
                (s) => s.name === SelectCategory.TAGS
              )?.options || []
            )
          )
        }

        return setEditPolicyRetention(retentionPolicy)
      },
      ErrorGroupConstants.EDIT_POLICY_RETENTION
    )
  )

export const updateEditPolicyRetentionEpic = (action$: any, state$: any) =>
  action$.pipe(
    toastRequestScenario(
      [UPDATE_EDIT_POLICY_RETENTION],
      PreloaderConstants.UPDATE_EDIT_POLICY_RETENTION,
      (action: ActionInterface) => {
        const accountIdsForUpdate = getEditPolicyRetentionAccountIDs(
          state$.value
        )
        const regionsForUpdate = getEditPolicyRetentionRegions(state$.value)
        const backupTypesForUpdate = getEditPolicyRetentionBackupTypes(
          state$.value
        )
        const assetSourcesForUpdate = getEditPolicyRetentionAssetSources(
          state$.value
        )
        const vaultsForUpdate = getEditPolicyRetentionVaults(state$.value)
        const tagsForUpdate = getEditPolicyRetentionTags(state$.value)
        const policyStatus = getEditPolicyRetentionStatus(state$.value)
        const recoveryPeriod = getEditPolicyRecoveryPeriod(state$.value)
        const selectedAssets = getEditPolicyCurrentAssetsSelected(state$.value)

        return GrpcRetentionPoliciesService.editRetentionPolicy(
          action.payload,
          recoveryPeriod,
          selectedAssets,
          accountIdsForUpdate,
          regionsForUpdate,
          backupTypesForUpdate,
          assetSourcesForUpdate,
          vaultsForUpdate,
          tagsForUpdate,
          policyStatus
        ).catch(sentryReThrowCatchHandler('Cannot update the retention policy'))
      },
      () => {
        const policyName = getEditPolicyRetentionName(state$.value)
        return requestEditPolicyRetention(policyName)
      },
      () => 'The retention policy has been updated',
      () => 'Error during retention policy updating'
    )
  )

export const updateEditPolicyRetentionDefaultEpic = (
  action$: any,
  state$: any
) =>
  action$.pipe(
    toastRequestScenario(
      [UPDATE_EDIT_POLICY_RETENTION_DEFAULT],
      PreloaderConstants.UPDATE_EDIT_POLICY_RETENTION_DEFAULT,
      () => {
        const policyStatus = getEditPolicyRetentionStatus(state$.value)
        const recoveryPeriod = getEditPolicyRecoveryPeriod(state$.value)

        return GrpcRetentionPoliciesService.editDefaultRetentionPolicy(
          recoveryPeriod,
          policyStatus
        ).catch(
          sentryReThrowCatchHandler(
            'Cannot update the Default retention policy'
          )
        )
      },
      () => {
        GeneralService.navigate(PagePathConstant.POLICIES_RETENTION)
        return emptyAction()
      },
      () => 'The Default retention policy has been updated',
      () => 'Error during Default retention policy updating'
    )
  )
