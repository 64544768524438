import React, { memo, ReactNode } from 'react'
import LayoutConstant from '@lib/constants/layout.constant'
import { useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import DeploymentLayout from '@components-complex/layouts/deployment-layout/DeploymentLayout'
import { useModal } from '@lib/hooks/useModal'
import Modal from '@lib/constants/modal.constant'
import AccessDeniedModal, {
  AccessDeniedModalInputData,
} from '@components-composite/modals/AccessDeniedModal'
import { getAccessDeniedModalMessage } from '@store/selectors/modal.selector'
import SystemHelper from '@lib/helpers/system.helper'
import { PageMeta } from '@lib/engine-types'
import ImageLayout from './image-layout/ImageLayout'
import LoginLayout from './login-layout/LoginLayout'
import Meta from '@components-complex/layouts/sections/Meta'
import { EMPTY_PAGE_META } from '@lib/constants/app.constant'
import ErrorLayout from './error-layout/ErrorLayout'
import { MainTopBarLayout } from '@features/layouts'
import { useGlobalSyncJobs } from '@features/scheduled-jobs-monitoring/hooks'
import { useFetchUserData } from '@lib/hooks/api-hooks'

function drawLayout(pageMeta: PageMeta, pageComponent: ReactNode) {
  switch (pageMeta.layout) {
    case LayoutConstant.MainTopBar:
      return (
        <MainTopBarLayout title={String(pageMeta.title)}>
          {pageComponent}
        </MainTopBarLayout>
      )
    case LayoutConstant.Login:
      return <LoginLayout>{pageComponent}</LoginLayout>
    case LayoutConstant.Image:
      return <ImageLayout>{pageComponent}</ImageLayout>
    case LayoutConstant.Error:
      return <ErrorLayout>{pageComponent}</ErrorLayout>
    case LayoutConstant.Deployment:
      return <DeploymentLayout>{pageComponent}</DeploymentLayout>
    case LayoutConstant.Empty:
      return <>{pageComponent}</>
    default:
      SystemHelper.throwErrorInLocalEnv('Layout was not found')
      return <>{pageComponent}</>
  }
}

interface Props {
  children: ReactNode
  pageMeta?: PageMeta
}

function Layout({
  children: pageComponent,
  pageMeta: pageMetaComponent,
}: Props) {
  const { modalProps: notAllowedModalProps } =
    useModal<AccessDeniedModalInputData>(Modal.accessDenied, () => null)
  const accessDeniedModalMessage = useSelector(getAccessDeniedModalMessage)
  const pageMeta = pageMetaComponent ?? EMPTY_PAGE_META()

  useGlobalSyncJobs({})

  useFetchUserData()

  return (
    <>
      <Meta page={pageMeta} />

      {drawLayout(pageMeta, pageComponent)}

      <AccessDeniedModal
        {...notAllowedModalProps}
        data={{
          message: accessDeniedModalMessage,
        }}
      />

      <ToastContainer />
    </>
  )
}

export default memo(Layout)
