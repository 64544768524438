/* eslint-disable import/no-extraneous-dependencies */
import { LiveAssets } from '@lib/clients/assets/types'
import {
  AWSSliceCriteria,
  TenantStatisticsModel,
} from 'blues-corejs/dist/models/inventory.model'

export const SET_LIVE_ASSETS_STATE = 'SET_MANAGEMENT_VIEW'

export const SET_SLICE = 'SET_SLICE'

export const SET_LIVE_ASSETS_LOADING = 'SET_LIVE_ASSETS_LOADING'

export const SET_TENANT_STATISTICS_LOADING = 'SET_TENANT_STATISTICS_LOADING'

export const SET_TENANT_STATISTICS = 'SET_TENANT_STATISTICS'

export const setListLiveAssetsState = (payload: {
  liveAssetsResponse: LiveAssets
  tenantStatistics: TenantStatisticsModel
}) => ({
  type: SET_LIVE_ASSETS_STATE,
  payload,
})

export const setSlice = (payload: AWSSliceCriteria) => ({
  type: SET_SLICE,
  payload,
})

export const setListLiveAssetsLoading = (payload: boolean) => ({
  type: SET_LIVE_ASSETS_LOADING,
  payload,
})

export const setTenantStatisticsLoading = (payload: boolean) => ({
  type: SET_TENANT_STATISTICS_LOADING,
  payload,
})

export const setTenantStatistics = (payload: TenantStatisticsModel) => ({
  type: SET_TENANT_STATISTICS,
  payload,
})
