"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalculateBackupCoverageStats = void 0;
class CalculateBackupCoverageStats {
    #tenantStatistics;
    constructor(parameters) {
        this.#tenantStatistics = parameters.tenantStatistics;
    }
    execute() {
        return {
            storageReduction: this.#storageReduction(),
            storageSize: this.#storageSize(),
        };
    }
    #storageReduction() {
        const included = this.#tenantStatistics.getBytesIncluded();
        const uploaded = this.#tenantStatistics.getTotalBytesUploaded();
        return included === 0 ? 0 : ((included - uploaded) / included) * 100;
    }
    #storageSize() {
        return this.#tenantStatistics.getTotalBytesUploaded();
    }
}
exports.CalculateBackupCoverageStats = CalculateBackupCoverageStats;
