import React, { ReactNode } from 'react'
import ConfigureView from '@components-composite/configure-view/ConfigureView'
import Intercom from '@components-complex/layouts/sections/intercom/Intercom'
import FullStory from '@components-complex/layouts/sections/full-story/FullStory'
import { TopNavigationBar } from '@features/TopNavigationBar'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import { useInitOnboarding } from '@lib/hooks/useInitOnboarding'
import { DynamicBreadcrumbs } from '@features/DynamicBreadcrumbs'
import styles from './MainTopBarLayout.module.scss'
import { LayoutProvider } from '@features/contexts'
import WarningMessage from '@components-simple/messages/warning-message/WarningMessage'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useAppStoreActions, KEYS } from '@lib/zustand/use-page-store'
import {
  useFetchLiveAssets,
  useFetchTenantStatistics,
} from '@features/live-assets'

interface Props {
  children: ReactNode
  title: string
}

const maintenanceMessage =
  'Elastio Portal is being upgraded at the moment. Some information might not be displayed correctly in the Tenant as a result. <br/>Elastio protection and retention policies and all related workloads will run as usual during this time, however, the jobs and their results might not show up in Elastio Tenant right away.'

function MainTopBarLayout({ children, title }: Props) {
  const { isRedStackExists } = useInitOnboarding()
  const { maintenanceMode } = useFlags()
  const { getValue } = useAppStoreActions()

  const hasThreats = getValue(KEYS.hasThreats)
  useFetchTenantStatistics()
  useFetchLiveAssets()

  return (
    <LayoutProvider>
      <div data-testid="main-top-bar-layout" className={styles.layout}>
        <TopNavigationBar
          title={title}
          showOnlyOnboarding={!isRedStackExists}
        />
        {maintenanceMode && (
          <WarningMessage
            message={maintenanceMessage}
            className={'maintenanceMessage'}
          />
        )}

        <Stack direction="row" justifyContent="space-between" px={3} pt={3}>
          <DynamicBreadcrumbs />
          {hasThreats ? (
            <Chip
              sx={{ fontWeight: '600' }}
              label="Infected"
              variant="el-error"
            />
          ) : null}
        </Stack>

        {children}
        <div id="base-portal-root" />

        <Intercom />
        <FullStory />
        <ConfigureView />
      </div>
    </LayoutProvider>
  )
}

export default MainTopBarLayout
