import BaseModel from '@lib/models/base-model/base.model'
import NumHelper from '@lib/helpers/num.helper'

// related to
// krabs-grpc-libs/js/krabs/krabs_pb.d.ts

class CostAdvantagesModel extends BaseModel {
  public readonly percentProtectedEc2: number

  public readonly percentProtectedEbs: number

  public readonly awsBackupPriceUsd: number

  public readonly elastioBackupPriceUsd: number

  // extra
  public readonly isEmpty: boolean

  public readonly isProfitable: boolean

  public readonly isNotProfitable: boolean

  public readonly awsPriceFormatted: string

  public readonly elastioPriceFormatted: string

  public readonly retainFormatted: string

  constructor(readonly params: any = {}) {
    super(params)

    // base

    // according to the problem - #3382, by design we round them
    this.percentProtectedEc2 = Math.round(params.percentProtectedEc2)
    this.percentProtectedEbs = Math.round(params.percentProtectedEbs)

    this.awsBackupPriceUsd = params.awsBackupPriceUsd
    this.elastioBackupPriceUsd = params.elastioBackupPriceUsd

    // extra
    this.isProfitable = this.elastioBackupPriceUsd < this.awsBackupPriceUsd
    this.isNotProfitable = !this.isProfitable
    this.awsPriceFormatted = `$${NumHelper.moneyFormat(
      this.awsBackupPriceUsd,
      0
    )} / m`
    this.elastioPriceFormatted = `$${NumHelper.moneyFormat(
      this.elastioBackupPriceUsd,
      0
    )} / m`
    this.retainFormatted = `$${NumHelper.moneyFormat(
      this.awsBackupPriceUsd - this.elastioBackupPriceUsd,
      0
    )} / m`
    this.isEmpty = params.isEmpty
  }
}

export default CostAdvantagesModel
