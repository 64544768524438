import ErrorGroupConstants from '@lib/constants/error-group.constant'
import PagePathConstant from '@lib/constants/page-path.constant'
import PreloaderConstants from '@lib/constants/preloader.constant'
import WebhookFactory from '@lib/factories/webhook.factory'
import ToastHelper from '@lib/helpers/toast.helper'
import TypedActionInterface from '@lib/interfaces/typed-action.interface'
import {
  WebhookInvocationRequest,
  WebhooksInvocations,
} from '@lib/interfaces/webhooks.interface'
import WebhookModel, {
  EventType,
  WebhookModelAttributes,
} from '@lib/models/webhook.model'
import GeneralService from '@lib/services/general.service'
import GrpcWebhookService from '@lib/services/grpc/notifications/grpc-webhook.service'
import { emptyAction } from '@store/actions/default/empty.action'
import {
  ADD_WEBHOOK,
  DELETE_WEBHOOK,
  requestGetWebhook,
  requestWebhooksList,
  REQUEST_EVENT_TYPES,
  REQUEST_GET_WEBHOOK,
  REQUEST_WEBHOOKS_LIST,
  REQUEST_WEBHOOK_INVOCATIONS,
  setEventTypes,
  setWebhook,
  setWebhooksInvocations,
  setWebhooksList,
  TEST_WEBHOOK,
  UPDATE_WEBHOOK,
  setTestWebhookResult,
  TEST_EXISTING_WEBHOOK,
} from '@store/actions/webhook.action'
import { getSelectedWebhook } from '@store/selectors/webhook.selector'
import { Webhook } from 'blue-stack-libs/notifications-grpc-libs/js/notifications/webhooks_pb'
import {
  baseRequestScenario,
  sentryReThrowCatchHandler,
  sentrySimpleReThrowHandler,
} from './epic-func'
import { GrpcCodes } from '@lib/constants/data/error/api-errors.constant'
import GrpcRequestError from '@lib/errors/grpc-request-error'
import ActionInterface from '@lib/interfaces/action.interface'
import StrHelper from '@lib/helpers/str.helper'

export const requestWebhooksListEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_WEBHOOKS_LIST],
      PreloaderConstants.REQUEST_WEBHOOKS_LIST,
      () =>
        GrpcWebhookService.getWebhooksList().catch(
          sentryReThrowCatchHandler('Error')
        ),
      (data: Array<WebhookModel>) => setWebhooksList(data),
      ErrorGroupConstants.WEBHOOK
    )
  )

export const createWebhookEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [ADD_WEBHOOK],
      PreloaderConstants.ADD_WEBHOOK,
      (action: TypedActionInterface<WebhookModel>) =>
        GrpcWebhookService.createWebhook(action.payload).catch(
          (error: GrpcRequestError) => {
            if (error.code === GrpcCodes.ALREADY_EXISTS) {
              ToastHelper.error('Webhook with this name already exists')
              return
            }

            sentrySimpleReThrowHandler(error)
          }
        ),
      (data: ActionInterface) => {
        if (data.type === 'EMPTY_ACTION') {
          return emptyAction()
        }
        ToastHelper.success('Webhook has been created')
        return requestWebhooksList()
      },
      ErrorGroupConstants.WEBHOOK
    )
  )

export const getWebhookEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_GET_WEBHOOK],
      PreloaderConstants.REQUEST_GET_WEBHOOK,
      (action: TypedActionInterface<string>) =>
        GrpcWebhookService.getWebhook(action.payload).catch(
          (error: GrpcRequestError) => sentrySimpleReThrowHandler(error)
        ),
      (webhook: Webhook) => {
        const factory = new WebhookFactory()
        return setWebhook(factory.buildFromGrpc(webhook))
      },
      ErrorGroupConstants.WEBHOOK
    )
  )

export const deleteWebhookEpic = (action$: any, state$: any) =>
  action$.pipe(
    baseRequestScenario(
      [DELETE_WEBHOOK],
      PreloaderConstants.DELETE_WEBHOOK,
      (action: TypedActionInterface<string>) =>
        GrpcWebhookService.deleteWebhook(action.payload).catch(
          (error: GrpcRequestError) => sentrySimpleReThrowHandler(error)
        ),
      () => {
        const selectedWebhook = getSelectedWebhook(state$.value)
        ToastHelper.success('Webhook has been deleted')

        if (selectedWebhook) {
          GeneralService.navigate(PagePathConstant.SETTINGS_WEBHOOKS)
          return emptyAction()
        }

        return requestWebhooksList()
      },
      ErrorGroupConstants.WEBHOOK
    )
  )

export const updateWebhookEpic = (action$: any, state$: any) =>
  action$.pipe(
    baseRequestScenario(
      [UPDATE_WEBHOOK],
      PreloaderConstants.UPDATE_WEBHOOK,
      (action: TypedActionInterface<WebhookModel>) =>
        GrpcWebhookService.updateWebhook(action.payload).catch(
          (error: GrpcRequestError) => {
            if (error.code === GrpcCodes.ALREADY_EXISTS) {
              ToastHelper.error('Webhook with this name already exists')
              return
            }

            if (error.name !== 'TypeError') {
              ToastHelper.error(StrHelper.capitalize(error.message))
            }

            sentrySimpleReThrowHandler(error)
          }
        ),
      (data: ActionInterface) => {
        if (data.type === 'EMPTY_ACTION') {
          return emptyAction()
        }

        const selectedWebhook = getSelectedWebhook(state$.value)
        ToastHelper.success('Webhook has been updated')

        return requestGetWebhook(selectedWebhook.getId())
      },
      ErrorGroupConstants.WEBHOOK
    )
  )

export const testWebhookEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [TEST_WEBHOOK],
      PreloaderConstants.TEST_WEBHOOK,
      (
        action: TypedActionInterface<
          Pick<WebhookModelAttributes, 'name' | 'endpoint' | 'authentication'>
        >
      ) => {
        ToastHelper.info(
          'Webhook test has started. It might take several seconds to complete.'
        )
        return GrpcWebhookService.testWebhook(action.payload).catch(
          (error: GrpcRequestError) => {
            if (error.code === GrpcCodes.RESOURCE_EXHAUSTED) {
              ToastHelper.error(
                'Too many invocations. Please wait a minute until triggering another test.'
              )
            }
            return sentrySimpleReThrowHandler(error)
          }
        )
      },
      (success: boolean) => setTestWebhookResult(success),
      ErrorGroupConstants.WEBHOOK
    )
  )

export const testExistingWebhookEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [TEST_EXISTING_WEBHOOK],
      PreloaderConstants.TEST_EXISTING_WEBHOOK,
      (action: TypedActionInterface<string>) => {
        ToastHelper.info(
          'Webhook test has started. It might take several seconds to complete.'
        )
        return GrpcWebhookService.testExistingWebhook(action.payload).catch(
          (error: GrpcRequestError) => {
            if (error.code === GrpcCodes.RESOURCE_EXHAUSTED) {
              ToastHelper.error(
                'Too many invocations. Please wait a minute until triggering another test.'
              )
            }

            return sentrySimpleReThrowHandler(error)
          }
        )
      },
      (success: boolean) => {
        ToastHelper[success ? 'success' : 'error'](
          `Webhook test ${success ? 'is successful.' : 'failed.'}`
        )

        return emptyAction()
      },
      ErrorGroupConstants.WEBHOOK
    )
  )

export const getEventTypesEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_EVENT_TYPES],
      PreloaderConstants.REQUEST_EVENT_TYPES,
      () =>
        GrpcWebhookService.getEventTypes().catch(
          sentryReThrowCatchHandler('Error')
        ),
      (data: Array<EventType>) => setEventTypes(data),
      ErrorGroupConstants.WEBHOOK
    )
  )

export const getWebhookInvocationsEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_WEBHOOK_INVOCATIONS],
      PreloaderConstants.REQUEST_WEBHOOK_INVOCATIONS,
      (action: TypedActionInterface<WebhookInvocationRequest>) => {
        return GrpcWebhookService.getWebhookInvocations(
          action.payload.webHooksIds,
          action.payload.periods
        ).catch(sentryReThrowCatchHandler('Error'))
      },
      (data: Array<WebhooksInvocations>) => setWebhooksInvocations(data),
      ErrorGroupConstants.WEBHOOK
    )
  )
