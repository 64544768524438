"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BackupStatus = exports.Provider = exports.RansomwareScanStatus = void 0;
var RansomwareScanStatus;
(function (RansomwareScanStatus) {
    RansomwareScanStatus[RansomwareScanStatus["CLEAN"] = 0] = "CLEAN";
    RansomwareScanStatus[RansomwareScanStatus["INFECTED"] = 1] = "INFECTED";
})(RansomwareScanStatus = exports.RansomwareScanStatus || (exports.RansomwareScanStatus = {}));
var Provider;
(function (Provider) {
    Provider[Provider["ELASTIO"] = 0] = "ELASTIO";
    Provider[Provider["AWS"] = 1] = "AWS";
    Provider[Provider["OVA_VEEAM"] = 2] = "OVA_VEEAM";
    Provider[Provider["OVA_COMMVAULT"] = 3] = "OVA_COMMVAULT";
    Provider[Provider["OVA_RUBRIK"] = 4] = "OVA_RUBRIK";
    Provider[Provider["OVA_COHESITY"] = 5] = "OVA_COHESITY";
    Provider[Provider["OVA_VERITAS_NET_BACKUP"] = 6] = "OVA_VERITAS_NET_BACKUP";
})(Provider = exports.Provider || (exports.Provider = {}));
var BackupStatus;
(function (BackupStatus) {
    BackupStatus[BackupStatus["GOOD"] = 0] = "GOOD";
    BackupStatus[BackupStatus["BAD"] = 1] = "BAD";
})(BackupStatus = exports.BackupStatus || (exports.BackupStatus = {}));
