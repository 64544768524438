"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobCloudConnectorKind = exports.CloudConnectorJobStatus = void 0;
var CloudConnectorJobStatus;
(function (CloudConnectorJobStatus) {
    CloudConnectorJobStatus[CloudConnectorJobStatus["SCHEDULED"] = 0] = "SCHEDULED";
    CloudConnectorJobStatus[CloudConnectorJobStatus["STARTING"] = 1] = "STARTING";
    CloudConnectorJobStatus[CloudConnectorJobStatus["IN_PROGRESS"] = 2] = "IN_PROGRESS";
    CloudConnectorJobStatus[CloudConnectorJobStatus["FAILED"] = 3] = "FAILED";
    CloudConnectorJobStatus[CloudConnectorJobStatus["SUCCEEDED"] = 4] = "SUCCEEDED";
    CloudConnectorJobStatus[CloudConnectorJobStatus["ABORTED"] = 5] = "ABORTED";
})(CloudConnectorJobStatus = exports.CloudConnectorJobStatus || (exports.CloudConnectorJobStatus = {}));
var JobCloudConnectorKind;
(function (JobCloudConnectorKind) {
    JobCloudConnectorKind[JobCloudConnectorKind["UNKNOWN"] = 0] = "UNKNOWN";
    JobCloudConnectorKind[JobCloudConnectorKind["EBS_BACKUP"] = 1] = "EBS_BACKUP";
    JobCloudConnectorKind[JobCloudConnectorKind["EBS_RESTORE"] = 2] = "EBS_RESTORE";
    JobCloudConnectorKind[JobCloudConnectorKind["EC2_BACKUP"] = 3] = "EC2_BACKUP";
    JobCloudConnectorKind[JobCloudConnectorKind["EC2_RESTORE"] = 4] = "EC2_RESTORE";
    JobCloudConnectorKind[JobCloudConnectorKind["EFS_BACKUP"] = 5] = "EFS_BACKUP";
    JobCloudConnectorKind[JobCloudConnectorKind["ROLLUP"] = 6] = "ROLLUP";
    JobCloudConnectorKind[JobCloudConnectorKind["BLOCK_BACKUP"] = 7] = "BLOCK_BACKUP";
    JobCloudConnectorKind[JobCloudConnectorKind["BLOCK_RESTORE"] = 8] = "BLOCK_RESTORE";
    JobCloudConnectorKind[JobCloudConnectorKind["FILE_BACKUP"] = 9] = "FILE_BACKUP";
    JobCloudConnectorKind[JobCloudConnectorKind["FILE_RESTORE"] = 10] = "FILE_RESTORE";
    JobCloudConnectorKind[JobCloudConnectorKind["STREAM_BACKUP"] = 11] = "STREAM_BACKUP";
    JobCloudConnectorKind[JobCloudConnectorKind["STREAM_RESTORE"] = 12] = "STREAM_RESTORE";
    JobCloudConnectorKind[JobCloudConnectorKind["ISCAN"] = 13] = "ISCAN";
    JobCloudConnectorKind[JobCloudConnectorKind["IMPORT_SNAPSHOTS"] = 14] = "IMPORT_SNAPSHOTS";
    JobCloudConnectorKind[JobCloudConnectorKind["BACKUP_INTEGRITY_CHECK"] = 15] = "BACKUP_INTEGRITY_CHECK";
    JobCloudConnectorKind[JobCloudConnectorKind["APPLY_RETENTION_POLICY"] = 16] = "APPLY_RETENTION_POLICY";
    JobCloudConnectorKind[JobCloudConnectorKind["FS_CHECK"] = 17] = "FS_CHECK";
    JobCloudConnectorKind[JobCloudConnectorKind["POSTGRES_BACKUP"] = 18] = "POSTGRES_BACKUP";
    JobCloudConnectorKind[JobCloudConnectorKind["POSTGRES_RESTORE"] = 19] = "POSTGRES_RESTORE";
    JobCloudConnectorKind[JobCloudConnectorKind["AWS_BACKUP_IMPORT"] = 20] = "AWS_BACKUP_IMPORT";
    JobCloudConnectorKind[JobCloudConnectorKind["VAULT_CATALOG_RESTORE"] = 21] = "VAULT_CATALOG_RESTORE";
    JobCloudConnectorKind[JobCloudConnectorKind["DATABASE_MAINTENANCE"] = 22] = "DATABASE_MAINTENANCE";
    JobCloudConnectorKind[JobCloudConnectorKind["POSTGRES_RECOVERY_TEST"] = 23] = "POSTGRES_RECOVERY_TEST";
    JobCloudConnectorKind[JobCloudConnectorKind["S3_BACKUP"] = 24] = "S3_BACKUP";
    JobCloudConnectorKind[JobCloudConnectorKind["S3_RESTORE"] = 25] = "S3_RESTORE";
    JobCloudConnectorKind[JobCloudConnectorKind["AWS_BACKUP_RP_ISCAN"] = 26] = "AWS_BACKUP_RP_ISCAN";
    JobCloudConnectorKind[JobCloudConnectorKind["AWS_BACKUP_RP_EC2_SCAN"] = 27] = "AWS_BACKUP_RP_EC2_SCAN";
    JobCloudConnectorKind[JobCloudConnectorKind["AWS_BACKUP_RP_EBS_SCAN"] = 28] = "AWS_BACKUP_RP_EBS_SCAN";
    JobCloudConnectorKind[JobCloudConnectorKind["AWS_BACKUP_RP_EFS_SCAN"] = 29] = "AWS_BACKUP_RP_EFS_SCAN";
    JobCloudConnectorKind[JobCloudConnectorKind["AWS_EFS_SCAN"] = 30] = "AWS_EFS_SCAN";
    JobCloudConnectorKind[JobCloudConnectorKind["AWS_BACKUP_RP_EC2_IMPORT"] = 31] = "AWS_BACKUP_RP_EC2_IMPORT";
    JobCloudConnectorKind[JobCloudConnectorKind["AWS_BACKUP_RP_EBS_IMPORT"] = 32] = "AWS_BACKUP_RP_EBS_IMPORT";
    JobCloudConnectorKind[JobCloudConnectorKind["VAULT_REPLICATION"] = 33] = "VAULT_REPLICATION";
    JobCloudConnectorKind[JobCloudConnectorKind["AWS_EC2_AMI_SCAN"] = 34] = "AWS_EC2_AMI_SCAN";
    JobCloudConnectorKind[JobCloudConnectorKind["AWS_FSX_ONTAP_SCAN"] = 35] = "AWS_FSX_ONTAP_SCAN";
    JobCloudConnectorKind[JobCloudConnectorKind["AWS_EBS_SCAN"] = 36] = "AWS_EBS_SCAN";
    JobCloudConnectorKind[JobCloudConnectorKind["AWS_EC2_SCAN"] = 37] = "AWS_EC2_SCAN";
    JobCloudConnectorKind[JobCloudConnectorKind["AWS_BACKUP_RP_FSX_ONTAP_SCAN"] = 38] = "AWS_BACKUP_RP_FSX_ONTAP_SCAN";
    JobCloudConnectorKind[JobCloudConnectorKind["AWS_EC2_AMI_IMPORT"] = 39] = "AWS_EC2_AMI_IMPORT";
    JobCloudConnectorKind[JobCloudConnectorKind["AWS_EBS_SNAPSHOT_SCAN"] = 40] = "AWS_EBS_SNAPSHOT_SCAN";
    JobCloudConnectorKind[JobCloudConnectorKind["AWS_S3_SCAN"] = 41] = "AWS_S3_SCAN";
    JobCloudConnectorKind[JobCloudConnectorKind["KIND_AWS_EBS_SNAPSHOTS_SCAN"] = 42] = "KIND_AWS_EBS_SNAPSHOTS_SCAN";
    JobCloudConnectorKind[JobCloudConnectorKind["KIND_AWS_BACKUP_RP_S3_SCAN"] = 43] = "KIND_AWS_BACKUP_RP_S3_SCAN";
})(JobCloudConnectorKind = exports.JobCloudConnectorKind || (exports.JobCloudConnectorKind = {}));
