"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TenantStatisticsModel = void 0;
__exportStar(require("./types"), exports);
class TenantStatisticsModel {
    #ransomwareStats;
    #malwareStats;
    #fsCheckStats;
    #vaultStatistics;
    constructor(attributes) {
        this.#ransomwareStats = attributes.ransomwareStats;
        this.#malwareStats = attributes.malwareStats;
        this.#fsCheckStats = attributes.fsCheckStats;
        this.#vaultStatistics = attributes.vaultStatistics;
    }
    getTotalFileScansNumber() {
        return [...this.#malwareStats, ...this.#ransomwareStats].reduce((acc, { scannedFiles }) => acc + scannedFiles, 0);
    }
    getTotalFileSystemsChecks() {
        return this.#fsCheckStats.reduce((acc, { totalChecks }) => acc + totalChecks, 0);
    }
    getTotalBytesUploaded() {
        return this.#vaultStatistics.reduce((acc, { bytesUploaded }) => acc + bytesUploaded, 0);
    }
    getBytesIncluded() {
        return this.#vaultStatistics.reduce((acc, { bytesIncluded }) => acc + bytesIncluded, 0);
    }
    slice(criteria) {
        return new TenantStatisticsModel({
            ransomwareStats: slice(this.#ransomwareStats, criteria),
            malwareStats: slice(this.#malwareStats, criteria),
            fsCheckStats: slice(this.#fsCheckStats, criteria),
            vaultStatistics: slice(this.#vaultStatistics, criteria),
        });
    }
}
exports.TenantStatisticsModel = TenantStatisticsModel;
function slice(array, { accountIds, regions, containTags }) {
    if ((!accountIds && !regions) || containTags) {
        return array;
    }
    return array.filter(({ accountId, region }) => {
        if (accountIds && !accountIds.includes(accountId)) {
            return false;
        }
        if (regions && !regions.includes(region)) {
            return false;
        }
        return true;
    });
}
