/* eslint-disable import/no-extraneous-dependencies */
import {
  baseRequestScenario,
  sentryReThrowCatchHandler,
  sentrySimpleReThrowHandler,
  toastRequestScenario,
} from '@store/epics/epic-func'
import {
  ACTIVATE_SOURCES,
  DEACTIVATE_SOURCES,
  DELETE_SOURCES,
  INIT_SOURCE_ID,
  REQUEST_COST_FOR_ACCOUNT,
  resetSourceIdSelection,
  setAccountAliasName,
  setAccountDescription,
  setAccountRedStackId,
  setCostForAccount,
  setSourceIdTableData,
  SUBMIT_SOURCE_ID,
} from '@store/actions/source-id.action'
import PreloaderConstants from '@lib/constants/preloader.constant'
import ActionInterface from '@lib/interfaces/action.interface'
import ToastHelper from '@lib/helpers/toast.helper'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import { getAllRedStacks } from '@store/actions/rex.action'
import { StateObservable } from 'redux-observable'
import {
  getAccountAliasName,
  getAccountDescription,
  getAccountId,
  getDataForInstallation,
  getRsIdForRemoved,
  sourceIdFormTouched,
} from '@store/selectors/source-id.selector'
import RedStackModel from '@lib/models/red-stack.model'
import StoreService from '@lib/services/store.service'
import TableFactory from '@lib/factories/table.factory'
import { emptyAction } from '@store/actions/default/empty.action'
import RedStackStatusConstant from '@lib/constants/red-stack-status.constant'
import VpcModel from '@lib/models/vpc.model'
import GeneralService from '@lib/services/general.service'
import ValueInterface from '@lib/interfaces/value.interface'
import PagePathConstant from '@lib/constants/page-path.constant'
import GrpcRexService from '@lib/services/grpc/grpc-rex.service'
import GrpcKrabsService from '@lib/services/grpc/grpc-krabs.service'
import { ScheduleTenantJobUseCase } from '@features/scheduled-jobs-monitoring/use-cases'
import { ScheduledTenantJobsRepository } from '@features/scheduled-jobs-monitoring/repositories'
import { createLogger } from '@features/scheduled-jobs-monitoring/infrastructure/logging'
import { ScheduledTenantJobsClient } from '@features/scheduled-jobs-monitoring/clients'

const scheduleTenantJobUseCase = new ScheduleTenantJobUseCase(
  new ScheduledTenantJobsRepository(createLogger()),
  new ScheduledTenantJobsClient(),
  ToastHelper
)

export const deactivateSourcesEpic = (action$: any) =>
  action$.pipe(
    toastRequestScenario(
      [DEACTIVATE_SOURCES],
      PreloaderConstants.DEACTIVATE_SOURCES,
      // request
      (action: ActionInterface) =>
        GrpcRexService.deactivate(action.payload.redStackId).catch(
          sentrySimpleReThrowHandler
        ),
      // response
      () => getAllRedStacks(),
      // success message
      (
        action: ActionInterface
      ) => `The sources in ${action.payload.regionName} region in
      ${action.payload.accountId} account has been disconnected`,
      // error message
      (
        action: ActionInterface
      ) => `This sources in ${action.payload.regionName} region in
      ${action.payload.accountId} account is not disconnected`
    )
  )

export const activateSourcesEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [ACTIVATE_SOURCES],
      PreloaderConstants.ACTIVATE_SOURCES,
      (action: ActionInterface) =>
        GrpcRexService.activate(action.payload.redStackId)
          .then((jobId) => ({
            jobId,
            action,
          }))
          .catch((error: Error) => {
            return sentryReThrowCatchHandler(
              `Cannot activate sources - ${error}`
            )
          }),
      async ({ jobId }: { jobId: string; action: ActionInterface }) => {
        await scheduleTenantJobUseCase.execute(jobId)
        return emptyAction()
      },
      ErrorGroupConstants.SOURCE_ID
    )
  )

export const deleteSourcesEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [DELETE_SOURCES],
      PreloaderConstants.DELETE_SOURCES,
      (action: ActionInterface) => {
        const redStacks: Array<RedStackModel> = action.payload
        const requests: Array<Promise<any>> = []

        redStacks.forEach((model) => {
          const { redStackId } = model
          let baseRequest = Promise.resolve(true)

          if (
            RedStackStatusConstant.ACTIVE === model.status ||
            RedStackStatusConstant.UPGRADE_REQUIRED === model.status
          ) {
            baseRequest = GrpcRexService.deactivate(redStackId)
          }
          requests.push(
            baseRequest.then(() => GrpcRexService.delete(redStackId))
          )
        })

        return Promise.all(requests).catch(
          sentryReThrowCatchHandler('Cannot perform the sources deletion')
        )
      },
      () => {
        ToastHelper.success('The sources has been deleted')
        return getAllRedStacks()
      },
      ErrorGroupConstants.SOURCE_ID
    )
  )
export const initSourceIdEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [INIT_SOURCE_ID],
      PreloaderConstants.INIT_SOURCE_ID,
      (action: ActionInterface) =>
        Promise.all([
          GrpcRexService.getAllRedStacksByAccountId(action.payload),
          GrpcRexService.listAwsVpcArr(action.payload),
          GrpcRexService.listSupportedAwsRegions(),
        ]).catch(
          sentryReThrowCatchHandler(
            'Cannot get necessary information for the source page'
          )
        ),
      ([redStacks, vpcList, regions]: [
        Array<RedStackModel>,
        Array<VpcModel>,
        Array<ValueInterface>
      ]) => {
        const redStackElem = redStacks[0]
        if (redStackElem) {
          StoreService.dispatchAction(
            setAccountRedStackId(redStackElem.redStackId)
          )
          StoreService.dispatchAction(
            setAccountAliasName(redStackElem.accountAlias)
          )
          StoreService.dispatchAction(
            setAccountDescription(redStackElem.accountDescription)
          )
        }
        StoreService.dispatchAction(resetSourceIdSelection())
        const tableData = TableFactory.sourceIdTable(
          redStacks,
          vpcList,
          regions
        )
        return setSourceIdTableData(tableData)
      },
      ErrorGroupConstants.SOURCE_ID
    )
  )

export const submitSourceIdEpic = (
  action$: any,
  state$: StateObservable<any>
) =>
  action$.pipe(
    baseRequestScenario(
      [SUBMIT_SOURCE_ID],
      PreloaderConstants.SUBMIT_SOURCE_ID,
      () => {
        const requests: Array<Promise<any>> = []

        // "installation - deletion" regions
        const accountId = getAccountId(state$.value)
        const arrForInstallation = getDataForInstallation(state$.value)
        const arrForRemoved = getRsIdForRemoved(state$.value)

        arrForInstallation.forEach((obj: any) => {
          const subnetsList: Array<string> = []
          obj.options.forEach((el: ValueInterface) => {
            if (el) {
              subnetsList.push(el.name)
            }
          })

          requests.push(
            GrpcRexService.installRedstack(
              obj.name,
              obj.selectedOption?.name ?? '',
              obj.extraValue,
              obj.value,
              subnetsList
            ).then(async (jobId: string) => {
              await scheduleTenantJobUseCase.execute(jobId)
            })
          )
        })
        arrForRemoved.forEach((redStackId: string) => {
          const baseRequest = GrpcRexService.deactivate(redStackId)
          requests.push(
            baseRequest.then(() =>
              GrpcRexService.delete(redStackId).then(() =>
                ToastHelper.success('The sources has been deleted')
              )
            )
          )
        })

        // "alias - description" form
        const isFormChanged = sourceIdFormTouched(state$.value)
        if (isFormChanged) {
          const accountAliasName = getAccountAliasName(state$.value)
          const accountDescription = getAccountDescription(state$.value)
          requests.push(
            GrpcRexService.updateAwsAccount(
              accountId,
              accountAliasName,
              accountDescription
            )
          )
        }

        return Promise.all(requests).catch(
          sentryReThrowCatchHandler('Cannot perform the submit source action')
        )
      },
      () => {
        GeneralService.navigate(PagePathConstant.SOURCES)
        return emptyAction()
      },
      ErrorGroupConstants.SOURCE_ID
    )
  )

export const requestVaultsCostEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_COST_FOR_ACCOUNT],
      PreloaderConstants.REQUEST_COST_FOR_ACCOUNT,
      // request
      (action: ActionInterface) => {
        const { accountId, regions, dateRange } = action.payload
        return GrpcKrabsService.getVaultsCost(accountId, regions, dateRange)
          .catch((error: Error) => {
            ToastHelper.error(error.message)
            return sentryReThrowCatchHandler(
              `Cannot get vaults Cost - ${error.message}`
            )
          })
          .then((data: any) => data?.[0]?.costsForRegionsList)
      },
      (result: Array<{ vaultName: string; costUsd: number }>) =>
        setCostForAccount(result),
      // response
      ErrorGroupConstants.SOURCE_ID
    )
  )
