/* eslint-disable import/no-extraneous-dependencies */
import TimeHelper from '@lib/helpers/time.helper'
import { Server, OvaBackupProviderProto } from '@lib/clients/grpc-imports'
import { OvaServer } from 'blues-corejs/dist/models'
import { OvaBackupProvider } from 'blues-corejs/dist/models/ova-backup-provider'

export class OvaServerTransformer {
  #server: Server.AsObject

  constructor(server: Server.AsObject) {
    this.#server = server
  }

  transform(): OvaServer {
    return this.#transformServer(this.#server)
  }

  #transformServer(server: Server.AsObject): OvaServer {
    return new OvaServer({
      backupProvider: this.#convertBackupProvider(server.backupProvider),
      backupProviderAssetId: server.backupProviderAssetId,
      ccAssetId: server.ccAssetId,
      createdAt: TimeHelper.secondsToDate(server.createdAt?.seconds),
      id: server.id,
      name: server.name,
      osName: server.osName,
      ovaAccountId: server.ovaAccountId,
      updatedAt: TimeHelper.secondsToDate(server.updatedAt?.seconds),
    })
  }

  #convertBackupProvider(provider: OvaBackupProviderProto): OvaBackupProvider {
    const mapping: Record<OvaBackupProviderProto, OvaBackupProvider> = {
      [OvaBackupProvider.VERITAS_NET_BACKUP]:
        OvaBackupProvider.VERITAS_NET_BACKUP,
      [OvaBackupProvider.VEEAM]: OvaBackupProvider.VEEAM,
      [OvaBackupProvider.COHESITY]: OvaBackupProvider.COHESITY,
      [OvaBackupProvider.RUBRIK]: OvaBackupProvider.RUBRIK,
      [OvaBackupProvider.COMMVAULT]: OvaBackupProvider.COMMVAULT,
    }

    return mapping[provider]
  }
}
