import { useEffect, useState } from 'react'
import { PageMeta } from '@lib/engine-types'
import LayoutConstant from '@lib/constants/layout.constant'
import GeneralService from '@lib/services/general.service'
import RequestService from '@lib/services/request.service'
import StoreService from '@lib/services/store.service'
import AuthProviderConstant, {
  AuthProviderData,
} from '@lib/constants/data/auth/auth-provider.constant'
import { decode } from 'js-base64'
import SystemHelper from '@lib/helpers/system.helper'
import { Dispatch } from 'redux'
import { NextRouter } from 'next/dist/shared/lib/router/router'
import ActionInterface from '@lib/interfaces/action.interface'

import LocalStorageHelper from '@lib/helpers/local-storage.helper'
import { setEnginePageMeta } from '@store/actions/engine.action'
import ServerErrorConstant from '@lib/constants/data/error/server-error.constant'
import { grpcServices } from './grpc-services'
import { GrpcClient } from '@lib/clients/grpc-client'
import {
  EngineInitResponse,
  EngineInitSuccessResponse,
} from '@pages/api/engine-init'
import { handleError } from './handle-error'
import { getEngineInitData } from './getEngineInitData'
import { UsersClient } from '@lib/clients'
import { StrHelper, TimeHelper } from '@lib/helpers'

const usersClient = new UsersClient()

function parseTimeZone(timeZone?: string): undefined | string {
  if (!timeZone) {
    return
  }

  return StrHelper.base64DecodeObj(timeZone)?.value as string
}

function getAuthProviderByToken(authToken: string): AuthProviderData {
  try {
    const { sub: authProviderId } = JSON.parse(
      decode(authToken.split('.')[1] ?? '')
    )
    return AuthProviderConstant.getByAuth0Id(String(authProviderId))
  } catch (e: any) {
    SystemHelper.throwErrorInLocalEnv(
      `Cannot parse the auth token for detecting a provider: ${e.message}`
    )
    return AuthProviderConstant.PROVIDER_UNDEFINED
  }
}

function isSuccessResponse(
  response: EngineInitResponse
): response is EngineInitSuccessResponse {
  return (response as EngineInitSuccessResponse).accessToken !== undefined
}

function useEngineInit(
  pageMeta: PageMeta,
  dispatch: Dispatch<ActionInterface>,
  router: NextRouter
): { hasToken: boolean; isReady: boolean } {
  // we need it in order to trigger re-rendering
  const [hasToken, setHasToken] = useState<boolean>(false)
  const [isReady, setIsReady] = useState<boolean>(false)

  async function engineInit() {
    dispatch(setEnginePageMeta(pageMeta))

    if (GeneralService.initFinished) {
      return
    }

    switch (pageMeta.layout) {
      case LayoutConstant.Main:
      case LayoutConstant.MainTopBar:
      case LayoutConstant.Deployment:
        // we have a single endpoint for getting all necessary
        // data, it can not be done by SSR because in this case
        // NextJS will do this request on each navigation under hood
        // by "fetch", on the backend side much easier to do the auth
        // because on the frontend we would have to add a provider with
        // all configs and it would increase the logic how we initiate
        // the engine
        let engineInitData = await getEngineInitData()
        // this case may handle the socket hanging
        if (
          engineInitData.code &&
          ServerErrorConstant.isUnexpectedError(engineInitData.code)
        ) {
          engineInitData = await getEngineInitData()
        }

        const { code } = engineInitData

        if (code) {
          handleError(code)
          return
        }

        if (!isSuccessResponse(engineInitData)) {
          return
        }

        const { accessToken, expiredAt } = engineInitData

        //------------------- setup services
        GeneralService.init(router, getAuthProviderByToken(accessToken))
        RequestService.init(accessToken)
        GrpcClient.setToken(accessToken)

        grpcServices.map((v) => v.init(accessToken))
        const userConfig = await usersClient.getUserConfig()
        const parsedTimeZoneFromConfig = parseTimeZone(
          userConfig.TimeZone?.value
        )
        TimeHelper.initTimezone(parsedTimeZoneFromConfig)
        setIsReady(true)

        if (SystemHelper.shouldAddDebugCode()) {
          // eslint-disable-next-line no-console
          console.log('[Client GRPC] Dev mode enabled')
          // @ts-ignore
          const enableDevTools = globalThis.__GRPCWEB_DEVTOOLS__ || (() => {})
          // @ts-ignore
          enableDevTools(grpcServices.map((v) => v.client))
        }

        StoreService.init(dispatch)

        setHasToken(true)

        //------------------- set up global cell data
        LocalStorageHelper.saveGlobalCellData({
          expiredAt,
        })

        //------------------- trigger re-rendering
        break

      case LayoutConstant.Empty:
      case LayoutConstant.Login:
      case LayoutConstant.Image:
      case LayoutConstant.Error:
        setIsReady(true)
        GeneralService.init(router, AuthProviderConstant.PROVIDER_UNDEFINED)
        break
    }
  }

  useEffect(() => {
    engineInit()
  }, [pageMeta.path])

  return {
    hasToken,
    isReady,
  }
}

export default useEngineInit
