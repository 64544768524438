/* eslint-disable import/no-extraneous-dependencies */
import { Nullable } from '@lib/engine-types'
import {
  EBS,
  EBSSnapshot,
  EC2,
  EFS,
  GenericHost,
  S3Bucket,
  TagsArray,
  TenantStatisticsModel,
} from 'blues-corejs/dist'

export interface SliceCriteria {
  accountIds?: Array<string>
  regions?: Array<string>
  include_tags?: SliceCriteriaTags
  exclude_tags?: SliceCriteriaTags
}

export interface SliceableItem {
  awsAccountId: string

  awsRegion: string

  tags: TagsArray
}

export interface SliceCriteriaTags {
  tags: Array<SliceCriteriaTag>
  operator: SliceCriteriaTagsCombinationOperator
}

export interface SliceCriteriaTag {
  key: string
  value: Nullable<string>
}

export enum SliceCriteriaTagsCombinationOperator {
  AND = 0,
  OR = 1,
}

export interface SliceOutput {
  ec2Instances: Array<EC2>
  ebsVolumes: Array<EBS>
  ebsSnapshots: Array<EBSSnapshot>
  s3Buckets: Array<S3Bucket>
  filesystems: Array<EFS>
  genericHosts: Array<GenericHost>
  tenantStatistics: TenantStatisticsModel
  allEc2Instances: Array<EC2>
  allEbsVolumes: Array<EBS>
  allEbsSnapshots: Array<EBSSnapshot>
  allS3Buckets: Array<S3Bucket>
  allFilesystems: Array<EFS>
  allGenericHosts: Array<GenericHost>
}
