"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListIscanReports = void 0;
const models_1 = require("../../models");
const aws_asset_1 = require("../../models/assets/aws/aws-asset");
const malware_1 = require("../../models/scans/malware");
const types_1 = require("../../models/scans/malware/types");
const ransomware_1 = require("../../models/scans/ransomware");
const types_2 = require("../../models/scans/ransomware/types");
const types_3 = require("./types");
class ListIscanReports {
    #assets;
    #assetItems;
    #backups;
    #scans;
    #directScanReport;
    #backupScanReport;
    constructor({ assets, assetItems, backups, scans }) {
        this.#assets = assets;
        this.#assetItems = assetItems;
        this.#backups = backups;
        this.#scans = scans;
        this.#directScanReport = {
            isAssetItemDirectScan: false,
            directScans: [],
        };
        this.#backupScanReport = {
            backupId: '',
            backup: undefined,
            backupReports: [],
        };
    }
    execute() {
        return this.#prepareOutput();
    }
    #prepareOutput() {
        if (!this.#scans.length) {
            return {
                reportsList: [],
            };
        }
        const scansOutput = this.#scans.map((scanRun) => {
            const scansMalware = scanRun.malwareScansList;
            const scansRansomware = scanRun.ransomwareScansList;
            const allScans = [
                ...scanRun.malwareScansList,
                ...scanRun.ransomwareScansList,
            ];
            const scanTargetKind = this.#getScanTargetKind(allScans);
            this.#directScanReport = {
                isAssetItemDirectScan: false,
                directScans: [],
            };
            this.#backupScanReport = {
                backupId: '',
                backup: undefined,
                backupReports: [],
            };
            const firstScanAccountAndRegion = this.#getAssetAccountAndRegion(allScans[0]);
            return {
                accountId: firstScanAccountAndRegion.accountId,
                regionName: firstScanAccountAndRegion.region,
                createdAt: this.#getCreatedAtTime(allScans),
                status: this.#getIscanStatus(scansMalware, scansRansomware),
                kind: scanTargetKind,
                directScanReport: scanTargetKind === types_3.ScanTargetKind.DIRECT_SCAN
                    ? this.#getDirectScanReport(scansMalware, scansRansomware)
                    : null,
                backupScanReport: scanTargetKind === types_3.ScanTargetKind.BACKUP
                    ? this.#getBackupScanReport(scansMalware, scansRansomware)
                    : null,
                scanRun: scanRun,
            };
        });
        return {
            reportsList: scansOutput,
        };
    }
    #getCreatedAtTime(scans) {
        return scans.reduce((completeTime, scan) => {
            if (scan && scan.createdAt > completeTime) {
                return scan.createdAt;
            }
            return completeTime;
        }, new Date(0));
    }
    #getAssetAccountAndRegion(scan) {
        const emptyAccountAndRegion = {
            accountId: '',
            region: '',
        };
        const scanAssetId = this.#getScanAssetId(scan);
        if (!scanAssetId) {
            return emptyAccountAndRegion;
        }
        const scanAsset = this.#getScanAsset(scanAssetId);
        if (!scanAsset) {
            return emptyAccountAndRegion;
        }
        if (scanAsset instanceof aws_asset_1.AWSAsset) {
            return {
                accountId: scanAsset.awsAccountId,
                region: scanAsset.awsRegion,
            };
        }
        return emptyAccountAndRegion;
    }
    #getIscanStatus(scansMalware, scansRansomware) {
        const hasMalwareThreats = scansMalware.some((malwareScan) => malwareScan.status === types_1.MalwareScanStatus.INFECTED);
        const hasRansomwareThreats = scansRansomware.some((ransomwareScan) => ransomwareScan.status === types_2.RansomwareScanStatus.INFECTED);
        if (hasMalwareThreats && hasRansomwareThreats) {
            return types_3.IscanStatus.MALWARE_AND_RANSOMWARE;
        }
        if (hasMalwareThreats) {
            return types_3.IscanStatus.MALWARE;
        }
        if (hasRansomwareThreats) {
            return types_3.IscanStatus.RANSOMWARE;
        }
        return types_3.IscanStatus.OK;
    }
    #getScanTargetKind(scans) {
        // all scans in run has the same scanTarget.target, so get the first one
        const firstScanInRun = scans[0];
        if (firstScanInRun?.scanTarget.target instanceof malware_1.MalwareScanBackup ||
            firstScanInRun?.scanTarget.target instanceof ransomware_1.RansomwareScanBackup) {
            return types_3.ScanTargetKind.BACKUP;
        }
        return types_3.ScanTargetKind.DIRECT_SCAN;
    }
    #getScanAssetId(scan) {
        if (!scan) {
            return '';
        }
        const { target } = scan.scanTarget;
        if (target instanceof malware_1.MalwareScanBackup ||
            target instanceof ransomware_1.RansomwareScanBackup) {
            if (target.source.asset) {
                return target.source.asset.backupAssetId;
            }
            if (target.source.assetItem) {
                return target.source.assetItem.backupAssetId;
            }
        }
        if ('assetId' in target) {
            return target.assetId;
        }
        return '';
    }
    #getDirectScanTargetIds(scan) {
        const scanTargetIds = {
            assetId: null,
            assetItemId: null,
        };
        if (!scan) {
            return scanTargetIds;
        }
        const { target } = scan.scanTarget;
        if ('assetItemId' in target) {
            scanTargetIds.assetItemId = target.assetItemId;
            scanTargetIds.assetId = target.assetId;
        }
        if ('assetId' in target) {
            scanTargetIds.assetId = target.assetId;
        }
        return scanTargetIds;
    }
    #getScanAsset(assetId) {
        if (!assetId) {
            return undefined;
        }
        const scanAsset = this.#assets.find((asset) => asset.id === assetId);
        return scanAsset;
    }
    #getScanAssetItem(assetItemId) {
        if (!assetItemId) {
            return null;
        }
        let assetItemKind = null;
        const diskAssetItem = this.#assetItems.disksList.find((disk) => disk.id === assetItemId);
        const streamAssetItem = this.#assetItems.streamsList.find((stream) => stream.id === assetItemId);
        const fileAssetItem = this.#assetItems.filesList.find((file) => file.id === assetItemId);
        const localVolumeAssetItem = this.#assetItems.volumesList.find((volume) => volume.id === assetItemId);
        if (diskAssetItem) {
            assetItemKind = types_3.AssetItemKind.DISK;
        }
        if (streamAssetItem) {
            assetItemKind = types_3.AssetItemKind.STREAM;
        }
        if (fileAssetItem) {
            assetItemKind = types_3.AssetItemKind.FILE;
        }
        if (localVolumeAssetItem) {
            assetItemKind = types_3.AssetItemKind.LOCAL_VOLUME;
        }
        return {
            scanAssetItem: diskAssetItem ||
                streamAssetItem ||
                fileAssetItem ||
                localVolumeAssetItem,
            assetItemKind: assetItemKind,
        };
    }
    #getAssetHumanReadableIdAndKind(asset) {
        if (!asset) {
            return null;
        }
        if (asset instanceof models_1.EC2) {
            return {
                assetHumanReadableId: asset.awsId,
                assetKind: types_3.AssetKind.AWS_EC2,
            };
        }
        if (asset instanceof models_1.EBS) {
            return {
                assetHumanReadableId: asset.awsId,
                assetKind: types_3.AssetKind.AWS_EBS,
            };
        }
        if (asset instanceof models_1.EFS) {
            return {
                assetHumanReadableId: asset.awsId,
                assetKind: types_3.AssetKind.AWS_EFS,
            };
        }
        if (asset instanceof models_1.S3Bucket) {
            return {
                assetHumanReadableId: asset.awsId,
                assetKind: types_3.AssetKind.AWS_S3,
            };
        }
        if (asset instanceof models_1.GenericHost) {
            return {
                assetHumanReadableId: asset.hostname,
                assetKind: types_3.AssetKind.GENERIC_HOST,
            };
        }
        return null;
    }
    #getAssetItemHumanReadableId(assetItem) {
        if (!assetItem) {
            return '';
        }
        switch (assetItem.assetItemKind) {
            case types_3.AssetItemKind.DISK:
                return assetItem.scanAssetItem.deviceName;
            case types_3.AssetItemKind.STREAM:
                return assetItem.scanAssetItem.name;
            case types_3.AssetItemKind.FILE:
                return assetItem.scanAssetItem.path;
            case types_3.AssetItemKind.LOCAL_VOLUME:
                return assetItem.scanAssetItem.deviceName;
            default:
                return '';
        }
    }
    #getBackupScanTargetIds(scan) {
        const scanTargetIds = {
            assetId: null,
            assetItemId: null,
            backupId: '',
        };
        const { target } = scan.scanTarget;
        if (target instanceof malware_1.MalwareScanBackup ||
            target instanceof ransomware_1.RansomwareScanBackup) {
            scanTargetIds.backupId = target.backupId;
            if (target.source.asset) {
                scanTargetIds.assetId = target.source.asset.backupAssetId;
            }
            if (target.source.assetItem) {
                scanTargetIds.assetId = target.source.assetItem.backupAssetId;
                scanTargetIds.assetItemId = target.source.assetItem.backupAssetItemId;
            }
        }
        return scanTargetIds;
    }
    #getScanBackup(backupId) {
        if (!backupId) {
            return undefined;
        }
        const scanBackup = this.#backups.find((backup) => backup.awsbRp?.id === backupId ||
            backup.ebsSnapshot?.id === backupId ||
            backup.elastioRp?.id === backupId ||
            backup.ovaBackup?.id === backupId);
        return scanBackup;
    }
    #handleMalwareScanForDirectReport(malwareScan) {
        const scanTargetIds = this.#getDirectScanTargetIds(malwareScan);
        const scanAsset = this.#getScanAsset(scanTargetIds.assetId);
        const scanAssetItem = this.#getScanAssetItem(scanTargetIds.assetItemId);
        const assetItemHumanReadableId = this.#getAssetItemHumanReadableId(scanAssetItem);
        const assetHumanReadableIdAndKind = this.#getAssetHumanReadableIdAndKind(scanAsset);
        this.#directScanReport.isAssetItemDirectScan =
            scanTargetIds.assetItemId !== null;
        this.#directScanReport.directScans.push({
            malwareScanId: malwareScan.id,
            ransomwareScanId: null,
            jobId: malwareScan.jobId,
            assetId: scanTargetIds.assetId,
            assetItemId: scanTargetIds.assetItemId,
            assetKind: assetHumanReadableIdAndKind?.assetKind ?? types_3.AssetKind.UNDEFINED,
            status: malwareScan.status,
            humanReadableId: assetItemHumanReadableId ||
                assetHumanReadableIdAndKind?.assetHumanReadableId ||
                '',
        });
    }
    #handleRansomwareScanForDirectReport(ransomwareScan) {
        const scanTargetIds = this.#getDirectScanTargetIds(ransomwareScan);
        const scanAsset = this.#getScanAsset(scanTargetIds.assetId);
        const scanAssetItem = this.#getScanAssetItem(scanTargetIds.assetItemId);
        const assetItemHumanReadableId = this.#getAssetItemHumanReadableId(scanAssetItem);
        const assetHumanReadableIdAndKind = this.#getAssetHumanReadableIdAndKind(scanAsset);
        this.#directScanReport.isAssetItemDirectScan =
            scanTargetIds.assetItemId !== null;
        this.#directScanReport.directScans.push({
            malwareScanId: null,
            ransomwareScanId: ransomwareScan.id,
            jobId: ransomwareScan.jobId,
            assetId: scanTargetIds.assetId,
            assetItemId: scanTargetIds.assetItemId,
            assetKind: assetHumanReadableIdAndKind?.assetKind ?? types_3.AssetKind.UNDEFINED,
            status: ransomwareScan.status,
            humanReadableId: assetItemHumanReadableId ||
                assetHumanReadableIdAndKind?.assetHumanReadableId ||
                '',
        });
    }
    #handleMalwareScanForBackupReport(malwareScan) {
        const scanTargetIds = this.#getBackupScanTargetIds(malwareScan);
        const scanAsset = this.#getScanAsset(scanTargetIds.assetId);
        const scanAssetItem = this.#getScanAssetItem(scanTargetIds.assetId);
        const assetItemHumanReadableId = this.#getAssetItemHumanReadableId(scanAssetItem);
        const assetHumanReadableIdAndKind = this.#getAssetHumanReadableIdAndKind(scanAsset);
        const scanBackup = this.#getScanBackup(scanTargetIds.backupId);
        this.#backupScanReport.backupId = scanTargetIds.backupId;
        this.#backupScanReport.backup = scanBackup;
        this.#backupScanReport.backupReports.push({
            malwareScanId: malwareScan.id,
            ransomwareScanId: null,
            jobId: malwareScan.jobId,
            assetId: scanTargetIds.assetId,
            assetItemId: scanTargetIds.assetItemId,
            assetKind: assetHumanReadableIdAndKind?.assetKind ?? types_3.AssetKind.UNDEFINED,
            status: malwareScan.status,
            humanReadableId: assetHumanReadableIdAndKind?.assetHumanReadableId ||
                assetItemHumanReadableId,
        });
    }
    #handleRansomwareScanForBackupReport(ransomwareScan) {
        const scanTargetIds = this.#getBackupScanTargetIds(ransomwareScan);
        const scanAsset = this.#getScanAsset(scanTargetIds.assetId);
        const scanAssetItem = this.#getScanAssetItem(scanTargetIds.assetId);
        const assetItemHumanReadableId = this.#getAssetItemHumanReadableId(scanAssetItem);
        const assetHumanReadableIdAndKind = this.#getAssetHumanReadableIdAndKind(scanAsset);
        const scanBackup = this.#getScanBackup(scanTargetIds.backupId);
        this.#backupScanReport.backupId = scanTargetIds.backupId;
        this.#backupScanReport.backup = scanBackup;
        this.#backupScanReport.backupReports.push({
            malwareScanId: null,
            ransomwareScanId: ransomwareScan.id,
            jobId: ransomwareScan.jobId,
            assetId: scanTargetIds.assetId,
            assetItemId: scanTargetIds.assetItemId,
            assetKind: assetHumanReadableIdAndKind?.assetKind ?? types_3.AssetKind.UNDEFINED,
            status: ransomwareScan.status,
            humanReadableId: assetHumanReadableIdAndKind?.assetHumanReadableId ||
                assetItemHumanReadableId,
        });
    }
    #findMalwareReportIndex(ransomwareScan, isDirectScanReport) {
        const scanTargetIds = isDirectScanReport
            ? this.#getDirectScanTargetIds(ransomwareScan)
            : this.#getBackupScanTargetIds(ransomwareScan);
        const malwareReports = isDirectScanReport
            ? this.#directScanReport.directScans
            : this.#backupScanReport.backupReports;
        let malwareReportIndex = -1;
        if (scanTargetIds.assetItemId) {
            malwareReportIndex = malwareReports.findIndex((malwareReport) => malwareReport.jobId === ransomwareScan.jobId &&
                scanTargetIds.assetItemId === malwareReport.assetItemId);
        }
        if (!scanTargetIds.assetItemId && scanTargetIds.assetId) {
            malwareReportIndex = malwareReports.findIndex((malwareReport) => malwareReport.jobId === ransomwareScan.jobId &&
                scanTargetIds.assetId === malwareReport.assetId);
        }
        return malwareReportIndex;
    }
    #getDirectScanReport(scansMalware, scansRansomware) {
        scansMalware.forEach((malwareScan) => {
            this.#handleMalwareScanForDirectReport(malwareScan);
        });
        // no malware scans processed
        if (!this.#directScanReport.directScans.length) {
            scansRansomware.forEach((ransomwareScan) => {
                this.#handleRansomwareScanForDirectReport(ransomwareScan);
            });
        }
        // TODO: need to make it more reusable
        if (this.#directScanReport.directScans.length) {
            scansRansomware.forEach((ransomwareScan) => {
                const directMalwareIndex = this.#findMalwareReportIndex(ransomwareScan, true);
                if (directMalwareIndex !== -1) {
                    this.#directScanReport.directScans[directMalwareIndex].ransomwareScanId = ransomwareScan.id;
                    if (ransomwareScan.status === types_2.RansomwareScanStatus.INFECTED) {
                        this.#directScanReport.directScans[directMalwareIndex].status =
                            ransomwareScan.status;
                    }
                }
                else {
                    this.#handleRansomwareScanForDirectReport(ransomwareScan);
                }
            });
        }
        return this.#directScanReport;
    }
    #getBackupScanReport(scansMalware, scansRansomware) {
        scansMalware.forEach((malwareScan) => {
            this.#handleMalwareScanForBackupReport(malwareScan);
        });
        // no malware scans processed
        if (!this.#backupScanReport.backupReports.length) {
            scansRansomware.forEach((ransomwareScan) => {
                this.#handleRansomwareScanForBackupReport(ransomwareScan);
            });
        }
        // TODO: need to make it more reusable
        if (this.#backupScanReport.backupReports.length) {
            scansRansomware.forEach((ransomwareScan) => {
                // find malware scan with the same jobId and AssetId or AssetItem
                const backupMalwareIndex = this.#findMalwareReportIndex(ransomwareScan, false);
                if (backupMalwareIndex !== -1) {
                    this.#backupScanReport.backupReports[backupMalwareIndex].ransomwareScanId = ransomwareScan.id;
                    if (ransomwareScan.status === types_2.RansomwareScanStatus.INFECTED) {
                        this.#backupScanReport.backupReports[backupMalwareIndex].status =
                            ransomwareScan.status;
                    }
                }
                else {
                    this.#handleRansomwareScanForBackupReport(ransomwareScan);
                }
            });
        }
        return this.#backupScanReport;
    }
}
exports.ListIscanReports = ListIscanReports;
