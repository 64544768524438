import { useRouter } from 'next/router'
import type { ReactNode } from 'react'
import React, { createContext, useContext, useEffect, useState } from 'react'

interface HValidation {
  history: Array<string>
  setHistory: (value: React.SetStateAction<Array<string>>) => void

  back(fallbackHref: string): void
}

const HistoryContext = createContext<HValidation>({} as HValidation)
export const HistoryProvider = ({ children }: { children: ReactNode }) => {
  const { asPath, push, pathname } = useRouter()

  const initialHistoryArray = localStorage.getItem('routeHistory')
    ? JSON.parse(localStorage.getItem('routeHistory')!)
    : []

  const [history, setHistory] = useState<Array<string>>(initialHistoryArray)

  function back(fallbackHref: string) {
    for (let i = history.length - 2; i >= 0; i--) {
      const route = history[i]

      if (route && route !== pathname) {
        push(route)
        const newHistory = history.slice(0, i)
        localStorage.setItem('routeHistory', JSON.stringify(newHistory))
        setHistory(newHistory)
        return
      }
    }
    if (fallbackHref) {
      push(fallbackHref)
    }
  }

  useEffect(() => {
    setHistory((previous): Array<string> => {
      for (let i = previous.length; i >= 0; i--) {
        const previousNew =
          previous[i - 1] === asPath
            ? previous.slice(0, i - 1)
            : previous.slice(0, i)

        const newHistory = [...previousNew, asPath]
        localStorage.setItem('routeHistory', JSON.stringify(newHistory))
        return newHistory
      }
      return []
    })
  }, [asPath])

  return (
    <HistoryContext.Provider
      value={{
        back,
        history,
        setHistory,
      }}
    >
      {children}
    </HistoryContext.Provider>
  )
}

export function useHistory(): HValidation {
  const context = useContext(HistoryContext)
  return context
}
