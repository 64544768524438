import { Nullable } from '@lib/engine-types'
import { UrlObject } from 'url'
import { NextRouter } from 'next/dist/shared/lib/router/router'
import SystemHelper from '@lib/helpers/system.helper'
import AuthProviderConstant, {
  AuthProviderData,
} from '@lib/constants/data/auth/auth-provider.constant'

interface AppRouter {
  push(url: UrlObject | string, as?: any, options?: any): Promise<boolean>
  replace(url: UrlObject | string, as?: any, options?: any): Promise<boolean>
  back(): void
}

class GeneralServiceInner {
  private currentRouter: Nullable<AppRouter>

  private authProvider = AuthProviderConstant.PROVIDER_UNDEFINED

  public get initFinished(): boolean {
    return !!this.currentRouter
  }

  public init(router: Nullable<NextRouter>, authProvider: AuthProviderData) {
    this.currentRouter = router
    this.authProvider = authProvider
  }

  public getAuthProvider(): AuthProviderData {
    return this.authProvider
  }

  public getRouter() {
    return this.currentRouter
  }

  public navigate(route: any): Promise<boolean> {
    if (route === null || route === undefined) {
      return Promise.resolve(false)
    }
    if (!this.currentRouter) {
      SystemHelper.throwErrorInLocalEnv(
        'General Service - router was not initialized'
      )
      return Promise.resolve(false)
    }
    try {
      return this.currentRouter.push(String(route))
    } catch (e) {
      throw new Error('Router is not set')
    }
  }

  public navigateBack(): void | Promise<boolean> {
    if (!this.currentRouter) {
      SystemHelper.throwErrorInLocalEnv(
        'General Service - router was not initialized'
      )
      return Promise.resolve(false)
    }
    try {
      return this.currentRouter.back()
    } catch (e) {
      throw new Error('Router is not set')
    }
  }
}

const GeneralService = new GeneralServiceInner()

export default GeneralService
