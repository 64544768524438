import PreloaderConstants from '@lib/constants/preloader.constant'
import {
  disablePreloaderAction,
  enablePreloaderAction,
} from '@store/actions/preloader.action'
import ActionInterface from '@lib/interfaces/action.interface'
import { EngineCallback, Nullable } from '@lib/engine-types'
import Modal from '@lib/constants/modal.constant'
import { hideModal } from '@store/actions/modal.action'
import PagePathConstant from '@lib/constants/page-path.constant'
import SystemHelper from '@lib/helpers/system.helper'

class StoreServiceInner {
  // @ts-ignore
  private dispatch: Nullable<EngineCallback<ActionInterface>> = null

  public init(dispatch: EngineCallback<ActionInterface>) {
    this.dispatch = dispatch
  }

  public dispatchAction(action: ActionInterface) {
    this.dispatch?.(action)
  }

  public enablePreloader(key: PreloaderConstants) {
    const action = enablePreloaderAction(key)
    this.dispatchAction(action)
  }

  public disablePreloader(key: PreloaderConstants) {
    const action = disablePreloaderAction(key)
    this.dispatchAction(action)
  }

  public logout() {
    SystemHelper.pureNavigate(PagePathConstant.AUTH0_LOGOUT)
  }

  public hideModal(modal: Modal) {
    const action = hideModal(modal)
    this.dispatchAction(action)
  }
}

const StoreService = new StoreServiceInner()

export default StoreService
