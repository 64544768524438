import GrpcBaseService from '@lib/services/grpc/base/grpc-base.service'
import { KrabsPromiseClient } from 'blue-stack-libs/krabs-grpc-libs/js/krabs/krabs_grpc_web_pb'
import VaultModel from '@lib/models/vault.model'
import {
  GetAssetsTotalNumberRequest,
  GetElastioCostAdvantagesInfoRequest,
  GetPausedAssetsNumberRequest,
  GetPercentageOfCoveredByBackupPoliciesAssetsRequest,
  GetPercentOfProtectedAssetsRequest,
  GetProtectedAssetsNumberRequest,
  GetRecoverabilityIndexRequest,
  GetRecoverabilityStatsRequest,
  GetUnhealthyRecoveryPointsNumberRequest,
  GetUnprotectedAssetsNumberRequest,
  GetVaultCostStatsRequest,
  GetVaultsCostRequest,
  StatisticsRequest,
  GetStorageReductionMetricsRequest,
  GetStorageReductionMetricsResponse,
} from 'blue-stack-libs/krabs-grpc-libs/js/krabs/krabs_pb'
import VaultCostInterface from '@lib/interfaces/vault-cost.interface'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import {
  AccountStatisticsList,
  AggregatedStatisticsList,
  RegionStatisticsList,
  VaultStatisticsList,
} from '@lib/interfaces/statistics.interface'
import { DashboardStatisticRequestParams } from '@lib/interfaces/dashboard.interface'
import { DefinedDateRangeTuple, Nullable } from '@lib/engine-types'
import { StatisticsFactory } from '@lib/factories/statistics.factory'
import CostAdvantagesModel from '@lib/models/cost-advantages.model'
import RecoverabilityStatsInterface from '@lib/interfaces/recoverability-stats.interface'
import { DEFAULT_DATE_RANGE } from '@lib/constants/time.constant'
import CoveredByBackupPoliciesAssets from '@lib/interfaces/covered-by-backup-policies-assets.interface'

class GrpcKrabsService extends GrpcBaseService {
  protected static client = new KrabsPromiseClient('', null, null)
  protected static clientName = 'Krabs'

  public static async getVaultCostStats(
    vault: VaultModel
  ): Promise<VaultCostInterface> {
    const [startOfMonth, currentDay] = DEFAULT_DATE_RANGE()
    const request = new GetVaultCostStatsRequest()
    request.setRegion(vault.region)
    request.setVaultName(vault.name)
    request.setAccountId(vault.accountId)
    request.setStartAt(Timestamp.fromDate(new Date(startOfMonth)))
    request.setEndAt(Timestamp.fromDate(new Date(currentDay)))
    const result = await this.handleQuery(
      this.client.getVaultCostStats,
      request
    )
    return result.toObject()
  }

  public static async getVaultsCost(
    accountId: string,
    regions: Array<string>,
    dateRange: DefinedDateRangeTuple
  ): Promise<
    Array<{
      accountId: string
      costsForRegionsList: Array<{
        region: string
        vaultsAndCostsList: Array<{
          vaultName: string
          costUsd: number
        }>
      }>
    }>
  > {
    const request = new GetVaultsCostRequest()
    const [startDate, endDate] = dateRange
    request.setRegionsList(regions)
    request.setAccountIdsList([accountId])

    request.setStartAt(Timestamp.fromDate(new Date(startDate)))
    request.setEndAt(Timestamp.fromDate(new Date(endDate)))
    const result = await this.handleQuery(this.client.getVaultsCost, request)
    return result.toObject().costsForAccountsList
  }

  public static async getAssetsTotalNumber(): Promise<number> {
    const request = new GetAssetsTotalNumberRequest()
    const result = await this.handleQueryLong(
      this.client.getAssetsTotalNumber,
      request
    )
    return result.toObject().number
  }

  public static async getUnprotectedAssetsNumber(): Promise<number> {
    const request = new GetUnprotectedAssetsNumberRequest()
    const result = await this.handleQueryLong(
      this.client.getUnprotectedAssetsNumber,
      request
    )
    return result.toObject().number
  }

  public static async getProtectedAssetsNumber(): Promise<number> {
    const request = new GetProtectedAssetsNumberRequest()
    const result = await this.handleQueryLong(
      this.client.getProtectedAssetsNumber,
      request
    )
    return result.toObject().number
  }

  public static async getPercentOfProtectedAssets(): Promise<number> {
    const request = new GetPercentOfProtectedAssetsRequest()
    const result = await this.handleQueryLong(
      this.client.getPercentOfProtectedAssets,
      request
    )
    return result.toObject().percent
  }

  public static async getRecoverabilityIndex(): Promise<number> {
    const request = new GetRecoverabilityIndexRequest()
    const result = await this.handleQueryLong(
      this.client.getRecoverabilityIndex,
      request
    )
    return result.toObject().index
  }

  public static async getUnhealthyRecoveryPointsNumber(): Promise<number> {
    const request = new GetUnhealthyRecoveryPointsNumberRequest()
    const result = await this.handleQueryLong(
      this.client.getUnhealthyRecoveryPointsNumber,
      request
    )
    return result.toObject().number
  }

  public static async getPausedAssetsNumber(): Promise<number> {
    const request = new GetPausedAssetsNumberRequest()
    const result = await this.handleQueryLong(
      this.client.getPausedAssetsNumber,
      request
    )
    return result.toObject().number
  }

  public static async getVaultsStatistics(
    params: DashboardStatisticRequestParams
  ): Promise<Nullable<VaultStatisticsList>> {
    const { dateRange } = params
    const [startAt, endAt] = dateRange
    const request = new StatisticsRequest()
    request.setStartAt(Timestamp.fromDate(new Date(startAt)))
    request.setEndAt(Timestamp.fromDate(new Date(endAt)))
    const result = await this.handleQueryLong(
      this.client.getVaultsStatistics,
      request
    )
    return result.toObject().vaultsList
  }

  public static async getRegionsStatistics(
    params: DashboardStatisticRequestParams
  ): Promise<Nullable<RegionStatisticsList>> {
    const { dateRange } = params
    const [startAt, endAt] = dateRange
    const request = new StatisticsRequest()
    request.setStartAt(Timestamp.fromDate(new Date(startAt)))
    request.setEndAt(Timestamp.fromDate(new Date(endAt)))
    const result = await this.handleQueryLong(
      this.client.getRegionsStatistics,
      request
    )
    return result.toObject().regionsList
  }

  public static async getAccountsStatistics(
    params: DashboardStatisticRequestParams
  ): Promise<Nullable<AccountStatisticsList>> {
    const { dateRange } = params
    const [startAt, endAt] = dateRange
    const request = new StatisticsRequest()
    request.setStartAt(Timestamp.fromDate(new Date(startAt)))
    request.setEndAt(Timestamp.fromDate(new Date(endAt)))
    const result = await this.handleQueryLong(
      this.client.getAccountsStatistics,
      request
    )
    return result.toObject().accountsList
  }

  public static async getAggregatedStatistics(
    params: DashboardStatisticRequestParams
  ): Promise<Nullable<AggregatedStatisticsList>> {
    const { dateRange } = params
    const [startAt, endAt] = dateRange
    const request = new StatisticsRequest()
    request.setStartAt(Timestamp.fromDate(new Date(startAt)))
    request.setEndAt(Timestamp.fromDate(new Date(endAt)))
    const result = await this.handleQueryLong(
      this.client.getAggregatedStatistics,
      request
    )
    return result.toObject().statisticsList
  }

  public static async getRecoverabilityStats(): Promise<RecoverabilityStatsInterface> {
    const request = new GetRecoverabilityStatsRequest()
    const result = await this.handleQueryLong(
      this.client.getRecoverabilityStats,
      request
    )
    return result.toObject()
  }

  public static async getElastioCostAdvantagesInfo(): Promise<CostAdvantagesModel> {
    const request = new GetElastioCostAdvantagesInfoRequest()
    const result = await this.handleQueryRetryLong(
      this.client.getElastioCostAdvantagesInfo,
      request
    )
    return StatisticsFactory.buildCostAdvantages(result.toObject())
  }

  public static async getPercentageOfCoveredByBackupPoliciesAssets(): Promise<
    Nullable<CoveredByBackupPoliciesAssets>
  > {
    const request = new GetPercentageOfCoveredByBackupPoliciesAssetsRequest()
    const result = await this.handleQueryLong(
      this.client.getPercentageOfCoveredByBackupPoliciesAssets,
      request
    )
    return (
      result.toObject().percentage ??
      StatisticsFactory.buildCoveredByBackupPoliciesAssetsEmpty()
    )
  }

  public static async getStorageReductionMetrics(): Promise<GetStorageReductionMetricsResponse> {
    const request = new GetStorageReductionMetricsRequest()

    return await this.handleQueryLong(
      this.client.getStorageReductionMetrics,
      request
    )
  }
}

export default GrpcKrabsService
