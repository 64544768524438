import LangAwsRegionConstant from '@lib/constants/lang/lang-aws-region.constant'
import LangConstant from '@lib/constants/lang/lang.constant'
import LangAssetKindConstant from '@lib/constants/lang/lang-asset-kind.constant'
import { AssetKind } from '@lib/constants/grpc/asset-kind.constant'
import { VIRow } from '@lib/engine-types'

abstract class LangHelper {
  private static getSingleTranslation<T>(
    collection: Map<T, LangConstant>,
    id: T,
    lang = 'en'
  ): string {
    const defaultResult = String(id)

    // if we do not have translation
    if (!collection.has(id)) {
      return defaultResult
    }

    const value = collection.get(id)
    if (!value) {
      return defaultResult
    }

    const elem = value[lang]
    if (!elem) {
      return defaultResult
    }
    return elem.single
  }

  public static getAwsRegionSingleTranslation(id: string, lang = 'en'): string {
    return this.getSingleTranslation(LangAwsRegionConstant, id, lang)
  }

  public static getAllRegions(lang = 'en'): VIRow {
    const result: VIRow = []
    LangAwsRegionConstant.forEach((value, key) => {
      result.push({
        name: key,
        label: value[lang]?.single ?? '',
      })
    })
    return result
  }

  public static getAssetKindSingleTranslation(id: AssetKind, lang = 'en') {
    return this.getSingleTranslation(LangAssetKindConstant, id, lang)
  }

  public static pluralizeEn(word: string, amount?: number) {
    if (amount !== undefined && amount === 1) {
      return word
    }
    const plural: { [key: string]: string } = {
      '(quiz)$': '$1zes',
      '^(ox)$': '$1en',
      '([m|l])ouse$': '$1ice',
      '(matr|vert|ind)ix|ex$': '$1ices',
      '(x|ch|ss|sh)$': '$1es',
      '([^aeiouy]|qu)y$': '$1ies',
      '(hive)$': '$1s',
      '(?:([^f])fe|([lr])f)$': '$1$2ves',
      '(shea|lea|loa|thie)f$': '$1ves',
      sis$: 'ses',
      '([ti])um$': '$1a',
      '(tomat|potat|ech|her|vet)o$': '$1oes',
      '(bu)s$': '$1ses',
      '(alias)$': '$1es',
      '(octop)us$': '$1i',
      '(ax|test)is$': '$1es',
      '(us)$': '$1es',
      '([^s]+)$': '$1s',
    }
    const irregular: { [key: string]: string } = {
      move: 'moves',
      foot: 'feet',
      goose: 'geese',
      sex: 'sexes',
      child: 'children',
      man: 'men',
      tooth: 'teeth',
      person: 'people',
    }
    const uncountable: Array<string> = [
      'sheep',
      'fish',
      'deer',
      'moose',
      'series',
      'species',
      'money',
      'rice',
      'information',
      'equipment',
      'bison',
      'cod',
      'offspring',
      'pike',
      'salmon',
      'shrimp',
      'swine',
      'trout',
      'aircraft',
      'hovercraft',
      'spacecraft',
      'sugar',
      'tuna',
      'you',
      'wood',
    ]
    if (uncountable.indexOf(word.toLowerCase()) >= 0) {
      return word
    }
    for (const w in irregular) {
      const pattern = new RegExp(`${w}$`, 'i')
      const replace = irregular[w] ?? ''
      if (pattern.test(word)) {
        return word.replace(pattern, replace)
      }
    }
    for (const reg in plural) {
      const pattern = new RegExp(reg, 'i')
      if (pattern.test(word)) {
        return word.replace(pattern, plural[reg] ?? '')
      }
    }
    return word
  }
}

export default LangHelper
