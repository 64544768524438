/* eslint-disable import/no-extraneous-dependencies */
import {
  AWSBRecoveryPoint,
  EBS,
  EBSSnapshot,
  EC2,
  ElastioRecoveryPoint,
  Policy,
} from 'blues-corejs/dist'

import {
  ListPlansRequest,
  ListPlansResponse,
  ScheduleScansRequest,
} from '@lib/clients/grpc-imports'

import { PechkinPromiseClient } from 'blue-stack-libs/pechkin-grpc-libs/js/pechkin/pechkin_grpc_web_pb'
import { GrpcClient } from '@lib/clients/grpc-client'
import { PechkinTransformer } from '@lib/clients/pechkin/transformer'
import ToastHelper from '@lib/helpers/toast.helper'
import {
  sentryReThrowCatchHandler,
  sentrySimpleReThrowHandler,
} from '@store/epics/epic-func'
import { VIRow } from '@lib/engine-types'

import { RestoreRequest } from './restore-request'
import { RecoveryPointKind } from 'blues-corejs/dist/models/backups/aws/awsb/types'

export interface ListPlans {
  plansList: Array<Policy>
}

type Asset = EC2 | EBS

export class PechkinClient extends GrpcClient<PechkinPromiseClient> {
  #pechkinPromiseClient: PechkinPromiseClient

  #transformer: PechkinTransformer

  constructor(hostName = '', transformer = new PechkinTransformer()) {
    super()
    this.#pechkinPromiseClient = this.getClient(hostName)
    this.#transformer = transformer
  }

  protected initClient(hostName: string): PechkinPromiseClient {
    return new PechkinPromiseClient(hostName, null, null)
  }

  protected innerClientTypeId(): string {
    return 'PechkinClient'
  }

  async listPolicies(): Promise<ListPlans> {
    const request = new ListPlansRequest()

    const response = (
      await this.retryGrpcCall(
        () => this.#pechkinPromiseClient.listPlans(request, this.metadata()),
        {
          requestName: 'PechkinPromiseClient/listPlans',
        }
      )
    ).toObject()

    return this.#transformPoliciesListResponse(response)
  }

  async scheduleAssetScans(assetList: Array<Asset>): Promise<Array<string>> {
    const isEveryAssetCanBeDirectlyScanned = assetList.every(
      (asset) => asset.canBeDirectlyScanned
    )

    if (!isEveryAssetCanBeDirectlyScanned) {
      ToastHelper.error('Failed to schedule scans')
      sentryReThrowCatchHandler(
        '[scheduleEc2Scans] assetList is not an instance of EC2 or EBS'
      )
      return []
    }

    const request = new ScheduleScansRequest()

    const scansList: Array<ScheduleScansRequest.Scan> = []

    for (const asset of assetList) {
      const scan = new ScheduleScansRequest.Scan()
      const scanType = new ScheduleScansRequest.DirectScanOptions()
      scanType.setMalware(true)
      scanType.setRansomware(true)

      if (asset instanceof EC2) {
        const ec2Scan = new ScheduleScansRequest.AwsEc2Scan()
          .setAwsAccountId(asset.awsAccountId)
          .setAwsRegion(asset.awsRegion)
          .setAwsInstanceId(asset.awsId)
          .setScanType(scanType)
        scan.setEc2(ec2Scan)
        scansList.push(scan)
      } else {
        const ebsScan = new ScheduleScansRequest.AwsEbsScan()
          .setAwsAccountId(asset.awsAccountId)
          .setAwsRegion(asset.awsRegion)
          .setAwsVolumeId(asset.awsId)
          .setScanType(scanType)
        scan.setAwsEbs(ebsScan)
        scansList.push(scan)
      }
    }

    request.setScansList(scansList)
    try {
      const result = await this.retryGrpcCall(
        () =>
          this.#pechkinPromiseClient.scheduleScans(request, this.metadata()),
        {
          requestName: 'PechkinPromiseClient/scheduleScans',
        }
      )
      return result.getResultsList().map((s) => s.getJobId())
    } catch (error) {
      if (error instanceof Error) {
        sentrySimpleReThrowHandler(error)
      }
      if (typeof error === 'string') {
        sentryReThrowCatchHandler(error)
      }
      return []
    }
  }

  async awsBackupRpScan(backup: AWSBRecoveryPoint): Promise<Array<string>> {
    try {
      const scan = new ScheduleScansRequest.Scan()
      let awsBackupRpScan:
        | ScheduleScansRequest.AwsBackupEc2RpScan
        | ScheduleScansRequest.AwsBackupEbsRpScan

      switch (backup.kind) {
        case RecoveryPointKind.EC2:
          awsBackupRpScan = new ScheduleScansRequest.AwsBackupEc2RpScan()
          scan.setAwsBackupEc2Rp(awsBackupRpScan)
          break
        case RecoveryPointKind.EBS:
          awsBackupRpScan = new ScheduleScansRequest.AwsBackupEbsRpScan()
          scan.setAwsBackupEbsRp(awsBackupRpScan)
          break

        default:
          throw new Error('Failed to schedule scans')
      }

      const directScanOptions = new ScheduleScansRequest.DirectScanOptions()
        .setMalware(true)
        .setRansomware(true)

      awsBackupRpScan
        .setArn(backup.arn)
        .setAwsVaultName(backup.vault.name)
        .setAwsRegion(backup.region)
        .setAwsAccountId(backup.accountId)
        .setScanType(directScanOptions)

      const requestWrapper = new ScheduleScansRequest().setScansList([scan])
      const resultsList = await this.retryGrpcCall(
        () =>
          this.#pechkinPromiseClient.scheduleScans(
            requestWrapper,
            this.metadata()
          ),
        {
          requestName: 'PechkinPromiseClient/scheduleScans',
        }
      )
      return resultsList.getResultsList().map((s) => s.getJobId())
    } catch (error) {
      this.#handleErrors(error)
      return []
    }
  }

  async awsEbsSnapshotScan(backup: EBSSnapshot): Promise<Array<string>> {
    try {
      const directScanOptions = new ScheduleScansRequest.DirectScanOptions()
        .setRansomware(true)
        .setMalware(true)
      const awsBackupEbsRpScanRequest =
        new ScheduleScansRequest.Scan().setAwsEbsSnapshot(
          new ScheduleScansRequest.AwsEbsSnapshotScan()
            .setAwsRegion(backup.awsRegion)
            .setSnapshotId(backup.awsId)
            .setAwsAccountId(backup.awsAccountId)
            .setScanType(directScanOptions)
        )

      const requestWrapper = new ScheduleScansRequest().setScansList([
        awsBackupEbsRpScanRequest,
      ])
      const resultsList = await this.retryGrpcCall(
        () =>
          this.#pechkinPromiseClient.scheduleScans(
            requestWrapper,
            this.metadata()
          ),
        {
          requestName: 'PechkinPromiseClient/scheduleScans',
        }
      )
      return resultsList.getResultsList().map((s) => s.getJobId())
    } catch (error) {
      this.#handleErrors(error)

      return []
    }
  }

  async scheduleRecoveryPointScan(
    backup: ElastioRecoveryPoint
  ): Promise<Array<string>> {
    try {
      const erpScanOptions = new ScheduleScansRequest.ErpScan.ErpScanOptions()
      const scheduleScansRequest = new ScheduleScansRequest()

      const scan = new ScheduleScansRequest.Scan()

      const erpScan = new ScheduleScansRequest.ErpScan()
      erpScan.setScanType(erpScanOptions.setMalware(true).setRansomware(true))

      scan.setErpScan(erpScan)

      const elastioRecoveryPoint =
        new ScheduleScansRequest.ErpScan.RecoveryPoint()
          .setRecoveryPointId(backup.ccRpId)
          .setCcId(backup.ccId)
          .setCcVaultId(backup.vaultName)
          .setShard(backup.vault.shard)

      erpScan.setRecoveryPointsList([elastioRecoveryPoint])

      scheduleScansRequest.setScansList([scan])

      const resultsList = await this.retryGrpcCall(
        () =>
          this.#pechkinPromiseClient.scheduleScans(
            scheduleScansRequest,
            this.metadata()
          ),
        {
          requestName: 'PechkinPromiseClient/scheduleScans',
        }
      )

      return resultsList.getResultsList().map((s) => s.getJobId())
    } catch (error) {
      this.#handleErrors(error)
      return []
    }
  }

  #handleErrors(error: unknown): void {
    if (error instanceof Error) {
      sentrySimpleReThrowHandler(error)
      return
    }

    if (typeof error === 'string') {
      sentryReThrowCatchHandler(error)
      return
    }

    console.error('Unhandled error type', error)
  }

  async scheduleRestoreEBS({
    ccId,
    ccRpId,
    ccVaultId,
    ccAssetId,
    availabilityZone,
    tags,
  }: {
    ccId: string
    ccRpId: string
    ccAssetId: string
    ccVaultId: string
    availabilityZone: string
    tags: VIRow
  }): Promise<void> {
    const request = new RestoreRequest({
      ccId,
      ccRpId,
      ccVaultId,
      availabilityZone,
      tags,
    }).createRequestEBS(ccAssetId)

    try {
      await this.retryGrpcCall(
        () =>
          this.#pechkinPromiseClient.scheduleRestore(request, this.metadata()),
        {
          requestName: 'PechkinPromiseClient/scheduleRestore',
        }
      )
      ToastHelper.success('Schedule Restore successfully')
    } catch (error) {
      ToastHelper.error('Failed to Schedule Restore')
      this.#handleErrors(error)
    }
  }

  async scheduleRestoreEC2({
    ccId,
    ccRpId,
    ccVaultId,
    subnetId,
    securityGroupIdsList,
    tags,
  }: {
    ccId: string
    ccRpId: string
    ccVaultId: string
    subnetId: string
    securityGroupIdsList: Array<string>
    tags: VIRow
  }): Promise<void> {
    const request = new RestoreRequest({
      ccId,
      ccRpId,
      ccVaultId,
      subnetId,
      securityGroupIdsList,
      tags,
    }).createRequestEC2()

    try {
      await this.retryGrpcCall(
        () =>
          this.#pechkinPromiseClient.scheduleRestore(request, this.metadata()),
        {
          requestName: 'PechkinPromiseClient/scheduleRestore',
        }
      )
      ToastHelper.success('Schedule Restore successfully')
    } catch (error) {
      ToastHelper.error('Failed to Schedule Restore')

      if (error instanceof Error) {
        sentrySimpleReThrowHandler(error)
      }

      if (typeof error === 'string') {
        sentryReThrowCatchHandler(error)
      }
    }
  }

  #transformPoliciesListResponse(
    response: ListPlansResponse.AsObject
  ): ListPlans {
    return {
      plansList: response.plansList.map(({ plan }) =>
        // @ts-ignore TODO: how come plan could be undefined?
        this.#transformer.transformPolicy(plan)
      ),
    }
  }
}

const pechkinClient = new PechkinClient()

export { pechkinClient }
