/* eslint-disable import/no-extraneous-dependencies */
import ActionInterface from '@lib/interfaces/action.interface'
import { DashboardState } from '@store/states/dashboard.state'
import {
  SET_ASSETS_EBS_VULNERABILITIES_SELECTED_FILTERS,
  SET_BACKUP_COMPLIANCE_SCORE,
  SET_COST_ADVANTAGES,
  SET_COVERED_BY_BACKUP_POLICIES_ASSETS,
  SET_COVERED_BY_BACKUP_POLICIES_ASSETS_ERROR_MESSAGE,
  SET_DASHBOARD_ACCOUNT_STATISTICS,
  SET_DASHBOARD_ASSETS_UNDER_PROTECTION_PERCENT,
  SET_DASHBOARD_ASSETS_UNDER_PROTECTION_PERCENT_ERROR_MESSAGE,
  SET_DASHBOARD_BACKUP_JOBS_LIST,
  SET_DASHBOARD_DATA,
  SET_DASHBOARD_PROTECTED_ASSETS,
  SET_DASHBOARD_PROTECTED_ASSETS_ERROR_MESSAGE,
  SET_DASHBOARD_RECOVERABILITY_INDEX,
  SET_DASHBOARD_RECOVERABILITY_INDEX_ERROR_MESSAGE,
  SET_DASHBOARD_TOTAL_ASSETS,
  SET_DASHBOARD_TOTAL_ASSETS_ERROR_MESSAGE,
  SET_DASHBOARD_UNHEALTHY_RP,
  SET_DASHBOARD_UNHEALTHY_RP_ERROR_MESSAGE,
  SET_DASHBOARD_UNPROTECTED_ASSETS,
  SET_DASHBOARD_UNPROTECTED_ASSETS_ERROR_MESSAGE,
  SET_EBS_VULNERABILITIES_SELECT_SEARCH,
  SET_EBS_VULNERABILITIES_SELECTED_FILTERS,
  SET_EC2_INVENTORY_SELECT_SEARCH,
  SET_EC2_INVENTORY_SELECT_SEARCH_V3,
  SET_EC2_INVENTORY_SELECTED_FILTERS,
  SET_EC2_INVENTORY_SELECTED_FILTERS_V3,
  SET_INSTANCES_THREATS_FILTERS,
  SET_QUARANTINED_VOLUMES_SELECT_SEARCH,
  SET_QUARANTINED_VOLUMES_SELECTED_FILTERS,
  SET_RECOVERABILITY_STATS,
  SET_RECOVERABILITY_STATS_ERROR_MESSAGE,
  SET_RECOVERY_EXPOSURE_FILTERS,
  SET_SNAPSHOT_VULNERABILITY_SELECT_SEARCH,
  SET_SNAPSHOT_VULNERABILITY_SELECTED_FILTERS,
  SET_SNAPSHOTS_VULNERABILITIES_SELECTED_FILTERS,
  SET_VOLUME_INVENTORY_SELECT_SEARCH,
  SET_VOLUME_INVENTORY_SELECT_SEARCH_V3,
  SET_VOLUME_INVENTORY_SELECTED_FILTERS,
  SET_VOLUME_INVENTORY_SELECTED_FILTERS_V3,
  SET_VOLUME_WITH_SNAPSHOTS_SELECT_SEARCH,
  SET_VOLUME_WITH_SNAPSHOTS_SELECTED_FILTERS,
  SET_VOLUMES_THREADS_FILTERS,
  SET_S3_BUCKET_INVENTORY_SELECTED_FILTERS,
} from '@store/actions/dashboard.action'
import PaginationFactory from '@lib/factories/pagination.factory'
import { EMPTY_DATE_RANGE } from '@lib/constants/time.constant'
import { StatisticsFactory } from '@lib/factories/statistics.factory'
import FilterFactory from '@lib/factories/filter.factory'
import { FilterIdDashboard } from '@lib/constants/grpc/filter.constant'
import DataHelper from '@lib/helpers/data.helper'
// eslint-disable-next-line import/no-extraneous-dependencies
import { SnapshotsVulnerabitiesFilters } from 'blues-corejs/dist/models/inventory.model'
import { VulnerabilitiesDetectionStrategy } from 'blues-corejs/dist/use_cases'

const initialState: DashboardState = {
  accounts: null,
  vaults: null,
  regions: null,
  totalAssetsNumber: null,
  pausedAssetsNumber: null,
  unprotectedAssetsNumber: null,
  protectedAssetsNumber: null,
  recoverabilityIndex: null,
  unhealthyRecoveryPoints: null,
  assetsUnderProtectionPercent: null,
  totalAssetsErrorMessage: null,
  pausedAssetsErrorMessage: null,
  protectedAssetsErrorMessage: null,
  unprotectedAssetsErrorMessage: null,
  recoverabilityIndexErrorMessage: null,
  assetsUnderProtectionPercentErrorMessage: null,
  unhealthyRecoveryPointsErrorMessage: null,
  recoverabilityStatsErrorMessage: null,
  backupJobList: [],
  backupJobRequestParameters: {
    pagination: PaginationFactory.buildEmpty(),
    filters: [
      {
        name: '',
      },
    ],
    dateRange: EMPTY_DATE_RANGE(),
  },
  latestJob: null,
  costAdvantages: StatisticsFactory.buildCostAdvantagesEmpty(),
  recoverabilityStats: null,
  coveredByBackupPoliciesAssets: null,
  coveredByBackupPoliciesAssetsErrorMessage: null,
  backupComplianceScore: {
    score: 0,
    none: undefined,
  },
  dashboardData: null,
  search: '',

  tableVolumeWithSnapshotsData: [],
  possibleVolumeWithSnapshotsFilters: [],
  selectedVolumeWithSnapshotsFilters: [],

  tableSnapshotVulnerabilitiesData: [],
  possibleSnapshotVulnerabilitiesFilters: [],
  selectedSnapshotVulnerabilitiesFilters: [],

  possibleEbsVulnerabilitiesFilters: [],
  selectedEbsVulnerabilitiesFilters: [],
  tableEbsVulnerabilitiesData: [],

  possibleQuarantinedVolumesFilters: [],
  selectedQuarantinedVolumesFilters: [],
  tableQuarantinedVolumesData: [],

  tableVolumeInventoryData: [],
  possibleVolumeInventoryFilters: [],
  selectedVolumeInventoryFilters: [],
  searchVolumeInventoryV3: '',
  possibleVolumeInventoryFiltersV3: [],
  selectedVolumeInventoryFiltersV3: [],

  tableEc2InventoryData: [],
  possibleEc2InventoryFilters: [],
  selectedEc2InventoryFilters: [],
  searchEc2InventoryV3: '',
  possibleEc2InventoryFiltersV3: [],
  selectedEc2InventoryFiltersV3: [],

  tableInstancesThreatsData: [],
  possibleInstancesThreatsFilters: [],
  selectedInstancesThreatsFilters: [],

  tableVolumesThreatsData: [],
  possibleVolumesThreatsFilters: [],
  selectedVolumesThreatsFilters: [],

  possibleThreatsFilters: [],

  tableVolumesVulnerabilitiesData: [],
  possibleVolumesVulnerabilitiesFilters: [],
  selectedVolumesVulnerabilitiesFilters: [],

  tableSnapshotsVulnerabilitiesData: [],
  possibleSnapshotsVulnerabilitiesFilters: [],
  selectedSnapshotsVulnerabilitiesFilters: [],

  tableVolumesExposureData: [],
  possibleVolumesExposureFilters: [],
  selectedVolumesExposureFilters: [],

  tableS3BucketInventoryData: [],
  possibleS3BucketInventoryFiltersV3: [],
  selectedS3BucketInventoryFiltersV3: [],
  searchS3BucketInventoryV3: '',
}

function dashboardReducer(
  prevState = initialState,
  action: ActionInterface
): DashboardState {
  switch (action.type) {
    case SET_DASHBOARD_ACCOUNT_STATISTICS:
      return {
        ...prevState,
        accounts: action.payload,
      }

    case SET_DASHBOARD_TOTAL_ASSETS:
      return {
        ...prevState,
        totalAssetsNumber: action.payload,
      }

    case SET_DASHBOARD_PROTECTED_ASSETS:
      return {
        ...prevState,
        protectedAssetsNumber: action.payload,
      }

    case SET_DASHBOARD_UNPROTECTED_ASSETS:
      return {
        ...prevState,
        unprotectedAssetsNumber: action.payload,
      }

    case SET_DASHBOARD_UNHEALTHY_RP:
      return {
        ...prevState,
        unhealthyRecoveryPoints: action.payload,
      }

    case SET_DASHBOARD_RECOVERABILITY_INDEX:
      return {
        ...prevState,
        recoverabilityIndex: action.payload,
      }

    case SET_DASHBOARD_ASSETS_UNDER_PROTECTION_PERCENT:
      return {
        ...prevState,
        assetsUnderProtectionPercent: action.payload,
        assetsUnderProtectionPercentErrorMessage: null,
      }

    case SET_DASHBOARD_TOTAL_ASSETS_ERROR_MESSAGE:
      return {
        ...prevState,
        totalAssetsNumber: null,
        totalAssetsErrorMessage: action.payload,
      }

    case SET_DASHBOARD_PROTECTED_ASSETS_ERROR_MESSAGE:
      return {
        ...prevState,
        protectedAssetsNumber: null,
        protectedAssetsErrorMessage: action.payload,
      }

    case SET_DASHBOARD_UNPROTECTED_ASSETS_ERROR_MESSAGE:
      return {
        ...prevState,
        unprotectedAssetsNumber: null,
        unprotectedAssetsErrorMessage: action.payload,
      }

    case SET_DASHBOARD_UNHEALTHY_RP_ERROR_MESSAGE:
      return {
        ...prevState,
        unhealthyRecoveryPoints: null,
        unhealthyRecoveryPointsErrorMessage: action.payload,
      }

    case SET_DASHBOARD_RECOVERABILITY_INDEX_ERROR_MESSAGE:
      return {
        ...prevState,
        recoverabilityIndex: null,
        recoverabilityIndexErrorMessage: action.payload,
      }

    case SET_DASHBOARD_ASSETS_UNDER_PROTECTION_PERCENT_ERROR_MESSAGE:
      return {
        ...prevState,
        assetsUnderProtectionPercent: null,
        assetsUnderProtectionPercentErrorMessage: action.payload,
      }

    case SET_RECOVERABILITY_STATS_ERROR_MESSAGE:
      return {
        ...prevState,
        recoverabilityStats: null,
        recoverabilityStatsErrorMessage: action.payload,
      }

    case SET_DASHBOARD_BACKUP_JOBS_LIST:
      return {
        ...prevState,
        backupJobList: action.payload,
      }

    case SET_COST_ADVANTAGES:
      return {
        ...prevState,
        costAdvantages: action.payload,
      }

    case SET_RECOVERABILITY_STATS:
      return {
        ...prevState,
        recoverabilityStats: action.payload,
      }

    case SET_COVERED_BY_BACKUP_POLICIES_ASSETS:
      return {
        ...prevState,
        coveredByBackupPoliciesAssets: action.payload,
        coveredByBackupPoliciesAssetsErrorMessage: null,
      }

    case SET_COVERED_BY_BACKUP_POLICIES_ASSETS_ERROR_MESSAGE:
      return {
        ...prevState,
        coveredByBackupPoliciesAssets: null,
        coveredByBackupPoliciesAssetsErrorMessage: action.payload,
      }

    case SET_BACKUP_COMPLIANCE_SCORE:
      return {
        ...prevState,
        backupComplianceScore: action.payload,
      }

    case SET_DASHBOARD_DATA:
      const quarantinedFilters =
        action.payload?.getQuarantinedVolumesPossibleFilters()
      const filtersVolumeWithSnapshots =
        action.payload?.getVolumeSnapshotPossibleFilters()
      const filtersVolumeInventory =
        action.payload?.getVolumeInventoryPossibleFilters()
      const filtersSnapshotVulnerabilities =
        action.payload?.getSnapshotVulnerabilitiesPossibleFilters()
      const filtersInstancesActiveThreats =
        action.payload?.getInstancesWithActiveThreatsPossibleFilters()
      const filtersVolumesActiveThreats =
        action.payload?.getVolumesWithActiveThreatsPossibleFilters()
      const filtersActiveThreats =
        action.payload?.getActiveThreatsPossibleFilters()
      const filtersVolumesVulnerabilities =
        action.payload?.getVolumesWithVulnerabilitiesPossibleFilters(
          new VulnerabilitiesDetectionStrategy()
        )
      const filtersSnapshotsVulnerabilities =
        action.payload?.getSnapshotsWithVulnerabilitiesPossibleFilters(
          new VulnerabilitiesDetectionStrategy()
        )
      const filtersRecoveryExposure =
        action.payload?.getAssetsWithRecoveryExposurePossibleFilters()

      const dataForPossibleVolumeWithSnapshotsFilters = [
        {
          id: FilterIdDashboard.ACCOUNT_IDS,
          accountIds: filtersVolumeWithSnapshots?.accountIds,
        },
        {
          id: FilterIdDashboard.REGIONS,
          regions: filtersVolumeWithSnapshots?.regions,
        },
        {
          id: FilterIdDashboard.COVERED,
          covered: filtersVolumeWithSnapshots?.covered,
        },
      ]

      const dataForPossibleVolumeInventoryFilters = [
        {
          id: FilterIdDashboard.ACCOUNT_IDS,
          accountIds: filtersVolumeInventory?.accountIds,
        },
        {
          id: FilterIdDashboard.REGIONS,
          regions: filtersVolumeInventory?.regions,
        },
        {
          id: FilterIdDashboard.PROTECTED,
          protected: filtersVolumeInventory?.protected,
        },
      ]

      const dataForPossibleVolumeInventoryFiltersV3 = [
        {
          id: FilterIdDashboard.ACCOUNT_IDS,
          accountIds: filtersVolumeInventory?.accountIds,
        },
        {
          id: FilterIdDashboard.REGIONS,
          regions: filtersVolumeInventory?.regions,
        },
        {
          id: FilterIdDashboard.COVERED,
          covered: filtersVolumeInventory?.covered,
        },
        {
          id: FilterIdDashboard.BACKUPS_ON_SCHEDULE,
          backupsOnSchedule: filtersVolumeInventory?.backupsOnSchedule,
        },
      ]

      const dataForPossibleSnapshotVulnerabilities = [
        {
          id: FilterIdDashboard.ACCOUNT_IDS,
          accountIds: filtersSnapshotVulnerabilities?.accountIds,
        },
        {
          id: FilterIdDashboard.REGIONS,
          regions: filtersSnapshotVulnerabilities?.regions,
        },
        {
          id: FilterIdDashboard.SNAPSHOT_VULNERABILITY_TYPES,
          snapshotVulnerabilityTypes:
            filtersSnapshotVulnerabilities?.snapshotVulnerabilityTypes,
        },
      ]

      const quarantinedDataForPossibleFilters = [
        {
          id: FilterIdDashboard.MALWARES,
          malwares: quarantinedFilters?.malwaresList,
        },
        {
          id: FilterIdDashboard.RANSOMWARES,
          ransomwares: quarantinedFilters?.ransomwaresList,
        },
      ]

      const filtersEbsVulnerabilities =
        action.payload?.getEBSVulnerabilitiesPossibleFilters()

      const dataForEbsVulnerabilitiesPossibleFilters = [
        {
          id: FilterIdDashboard.ACCOUNT_IDS,
          accountIds: filtersEbsVulnerabilities?.accountIds,
        },
        {
          id: FilterIdDashboard.REGIONS,
          regions: filtersEbsVulnerabilities?.regions,
        },
        {
          id: FilterIdDashboard.EBS_VULNERABILITIES,
          ebsVulnerabilities: filtersEbsVulnerabilities?.ebsVulnerabilities,
        },
      ]

      const filtersEc2Inventory =
        action.payload?.getEc2InventoryPossibleFilters()

      const dataForPossibleEc2InventoryFilters = [
        {
          id: FilterIdDashboard.ACCOUNT_IDS,
          accountIds: filtersEc2Inventory?.accountIds,
        },
        {
          id: FilterIdDashboard.REGIONS,
          regions: filtersEc2Inventory?.regions,
        },
        {
          id: FilterIdDashboard.OS_KINDS,
          osKinds: filtersEc2Inventory?.osKinds,
        },
        {
          id: FilterIdDashboard.PROTECTED,
          protected: filtersEc2Inventory?.protected,
        },
      ]

      const dataForPossibleEc2InventoryFiltersV3 = [
        {
          id: FilterIdDashboard.ACCOUNT_IDS,
          accountIds: filtersEc2Inventory?.accountIds,
        },
        {
          id: FilterIdDashboard.REGIONS,
          regions: filtersEc2Inventory?.regions,
        },
        {
          id: FilterIdDashboard.COVERED,
          covered: filtersEc2Inventory?.covered,
        },
        {
          id: FilterIdDashboard.BACKUPS_ON_SCHEDULE,
          backupsOnSchedule: filtersEc2Inventory?.backupsOnSchedule,
        },
      ]

      const filterS3BucketInventory =
        action.payload?.getS3BucketInventoryPossibleFilters()
      const dataForPossibleS3BucketInventoryFilters = [
        {
          id: FilterIdDashboard.ACCOUNT_IDS,
          accountIds: filterS3BucketInventory?.accountIds,
        },
        {
          id: FilterIdDashboard.REGIONS,
          regions: filterS3BucketInventory?.regions,
        },
        {
          id: FilterIdDashboard.COVERED,
          covered: filterS3BucketInventory?.covered,
        },
        {
          id: FilterIdDashboard.STATE,
          state: filterS3BucketInventory?.state,
        },
      ]

      const dataForPossibleInstancesActiveThreatsFilters = [
        {
          id: FilterIdDashboard.ACCOUNT_IDS,
          accountIds: filtersInstancesActiveThreats?.accountIds,
        },
        {
          id: FilterIdDashboard.REGIONS,
          regions: filtersInstancesActiveThreats?.regions,
        },
        {
          id: FilterIdDashboard.RANSOMWARES,
          ransomwares: filtersInstancesActiveThreats?.ransomwaresList,
        },
        {
          id: FilterIdDashboard.MALWARES,
          malwares: filtersInstancesActiveThreats?.malwaresList,
        },
        {
          id: FilterIdDashboard.FS_CHECK,
          fsCheck: filtersInstancesActiveThreats?.fsCheck,
        },
      ]

      const dataForPossibleVolumesThreatsFilters = [
        {
          id: FilterIdDashboard.ACCOUNT_IDS,
          accountIds: filtersVolumesActiveThreats?.accountIds,
        },
        {
          id: FilterIdDashboard.REGIONS,
          regions: filtersVolumesActiveThreats?.regions,
        },
        {
          id: FilterIdDashboard.RANSOMWARES,
          ransomwares: filtersVolumesActiveThreats?.ransomwaresList,
        },
        {
          id: FilterIdDashboard.MALWARES,
          malwares: filtersVolumesActiveThreats?.malwaresList,
        },
        {
          id: FilterIdDashboard.FS_CHECK,
          fsCheck: filtersVolumesActiveThreats?.fsCheck,
        },
      ]

      const dataForPossibleThreatsFilters = [
        {
          id: FilterIdDashboard.ACCOUNT_IDS,
          accountIds: filtersActiveThreats?.accountIds,
        },
        {
          id: FilterIdDashboard.REGIONS,
          regions: filtersActiveThreats?.regions,
        },
        {
          id: FilterIdDashboard.RANSOMWARES,
          ransomwares: filtersActiveThreats?.ransomwaresList,
        },
        {
          id: FilterIdDashboard.MALWARES,
          malwares: filtersActiveThreats?.malwaresList,
        },
        {
          id: FilterIdDashboard.FS_CHECK,
          fsCheck: filtersActiveThreats?.fsCheck,
        },
      ]

      const dataForPossibleAssetsVulnerabilityFilters = [
        {
          id: FilterIdDashboard.ACCOUNT_IDS,
          accountIds: filtersVolumesVulnerabilities?.accountIds,
        },
        {
          id: FilterIdDashboard.REGIONS,
          regions: filtersVolumesVulnerabilities?.regions,
        },
        {
          id: FilterIdDashboard.EBS_VULNERABILITIES,
          ebsVulnerabilities: filtersVolumesVulnerabilities?.ebsVulnerabilities,
        },
        {
          id: FilterIdDashboard.RISK_TYPE,
          riskType: filtersVolumesVulnerabilities?.riskType,
        },
      ]

      const dataForPossibleSnapshotsVulnerabilityFilters = [
        {
          id: FilterIdDashboard.ACCOUNT_IDS,
          accountIds: filtersSnapshotsVulnerabilities?.accountIds,
        },
        {
          id: FilterIdDashboard.REGIONS,
          regions: filtersSnapshotsVulnerabilities?.regions,
        },
        {
          id: FilterIdDashboard.SNAPSHOT_VULNERABILITY_TYPES,
          snapshotVulnerabilityTypes:
            filtersSnapshotsVulnerabilities?.snapshotVulnerabilityTypes,
        },
        {
          id: FilterIdDashboard.RISK_TYPE,
          riskType: filtersSnapshotsVulnerabilities?.riskType,
        },
      ]

      const dataForPossibleRecoveryExposureFilters = [
        {
          id: FilterIdDashboard.ACCOUNT_IDS,
          accountIds: filtersRecoveryExposure?.accountIds,
        },

        {
          id: FilterIdDashboard.REGIONS,
          regions: filtersRecoveryExposure?.regions,
        },
        {
          id: FilterIdDashboard.RANSOMWARES,
          ransomwares: filtersRecoveryExposure?.ransomwaresList,
        },
        {
          id: FilterIdDashboard.MALWARES,
          malwares: filtersRecoveryExposure?.malwaresList,
        },
        {
          id: FilterIdDashboard.FS_CHECK,
          fsCheck: filtersRecoveryExposure?.fsCheck,
        },
        {
          id: FilterIdDashboard.EBS_VULNERABILITIES,
          ebsVulnerabilities: filtersRecoveryExposure?.ebsVulnerabilities,
        },
      ]

      return {
        ...prevState,
        dashboardData: action.payload,
        tableVolumeWithSnapshotsData:
          action.payload?.getVolumesSnapshotCoverage({}) ?? [],
        possibleVolumeWithSnapshotsFilters:
          dataForPossibleVolumeWithSnapshotsFilters.map(
            FilterFactory.buildVolumeSnapshotCoverageFilter
          ),
        tableSnapshotVulnerabilitiesData:
          action.payload?.getSnapshotVulnerabilities({}) ?? [],
        possibleSnapshotVulnerabilitiesFilters:
          dataForPossibleSnapshotVulnerabilities.map(
            FilterFactory.buildSnapshotVulnerabilitiesFilter
          ),
        tableEbsVulnerabilitiesData:
          action.payload?.getEBSVulnerabilities({}) ?? [],
        possibleEbsVulnerabilitiesFilters:
          dataForEbsVulnerabilitiesPossibleFilters.map(
            FilterFactory.buildEbsVulnerabilitiesFilter
          ),
        tableQuarantinedVolumesData:
          action.payload?.getQuarantinedVolumes({}) ?? [],
        possibleQuarantinedVolumesFilters:
          quarantinedDataForPossibleFilters.map(
            FilterFactory.buildQuarantinedVolumesFilter
          ),
        tableVolumeInventoryData: action.payload?.getVolumesInventory({}) ?? [],
        possibleVolumeInventoryFilters:
          dataForPossibleVolumeInventoryFilters.map(
            FilterFactory.buildVolumeInventoryFilter
          ),
        possibleVolumeInventoryFiltersV3:
          dataForPossibleVolumeInventoryFiltersV3.map(
            FilterFactory.buildVolumeInventoryFilter
          ),

        possibleEc2InventoryFilters: dataForPossibleEc2InventoryFilters.map(
          FilterFactory.buildEc2InventoryFilter
        ),
        possibleEc2InventoryFiltersV3: dataForPossibleEc2InventoryFiltersV3.map(
          FilterFactory.buildEc2InventoryFilter
        ),

        possibleS3BucketInventoryFiltersV3:
          dataForPossibleS3BucketInventoryFilters.map(
            FilterFactory.buildS3BucketInventoryFilter
          ),

        possibleInstancesThreatsFilters:
          dataForPossibleInstancesActiveThreatsFilters.map(
            FilterFactory.buildActiveInstancesThreatsFilter
          ),

        possibleVolumesThreatsFilters: dataForPossibleVolumesThreatsFilters.map(
          FilterFactory.buildActiveVolumesThreatsFilter
        ),

        possibleThreatsFilters: dataForPossibleThreatsFilters.map(
          FilterFactory.buildActiveThreatsFilter
        ),

        possibleVolumesVulnerabilitiesFilters:
          dataForPossibleAssetsVulnerabilityFilters.map(
            FilterFactory.buildAssetsVulnerabilityFilter
          ),

        possibleSnapshotsVulnerabilitiesFilters:
          dataForPossibleSnapshotsVulnerabilityFilters.map(
            FilterFactory.buildSnapshotsVulnerabilityFilter
          ),

        possibleVolumesExposureFilters:
          dataForPossibleRecoveryExposureFilters.map(
            FilterFactory.buildRecoveryExposureFilter
          ),

        tableInstancesThreatsData:
          action.payload?.getInstancesWithActiveThreatsData({}) ?? [],

        tableVolumesThreatsData:
          action.payload?.getVolumesWithActiveThreatsData({}) ?? [],

        tableVolumesVulnerabilitiesData:
          action.payload?.getVolumesWithVulnerabilitiesData(
            {},
            new VulnerabilitiesDetectionStrategy()
          ) ?? [],

        tableSnapshotsVulnerabilitiesData:
          action.payload?.getSnapshotsWithVulnerabilitiesData(
            {},
            new VulnerabilitiesDetectionStrategy()
          ) ?? [],

        tableVolumesExposureData:
          action.payload?.getVolumesWithRecoveryExposureData({}) ?? [],
      }

    case SET_VOLUME_WITH_SNAPSHOTS_SELECTED_FILTERS:
      const dataForNewTable: Record<string, any> =
        DataHelper.getDataForDashboardFilter(action.payload, prevState.search)

      return {
        ...prevState,
        selectedVolumeWithSnapshotsFilters: action.payload,
        tableVolumeWithSnapshotsData:
          prevState.dashboardData?.getVolumesSnapshotCoverage(
            dataForNewTable
          ) ?? [],
      }

    case SET_VOLUME_WITH_SNAPSHOTS_SELECT_SEARCH:
      const dataSearchForNewTable: Record<string, any> =
        DataHelper.getDataForDashboardFilter(
          prevState.selectedVolumeWithSnapshotsFilters,
          action.payload
        )

      return {
        ...prevState,
        search: action.payload,
        tableVolumeWithSnapshotsData:
          prevState.dashboardData?.getVolumesSnapshotCoverage(
            dataSearchForNewTable
          ) ?? [],
      }

    case SET_VOLUME_INVENTORY_SELECTED_FILTERS:
      const dataForNewTableVolumeInventory: Record<string, any> =
        DataHelper.getDataForDashboardFilter(action.payload, prevState.search)

      return {
        ...prevState,
        selectedVolumeInventoryFilters: action.payload,
        tableVolumeInventoryData:
          prevState.dashboardData?.getVolumesInventory(
            dataForNewTableVolumeInventory
          ) ?? [],
      }

    case SET_VOLUME_INVENTORY_SELECTED_FILTERS_V3:
      const dataForNewTableVolumeInventoryV3: Record<string, any> =
        DataHelper.getDataForDashboardFilter(
          action.payload,
          prevState.searchVolumeInventoryV3
        )

      return {
        ...prevState,
        selectedVolumeInventoryFiltersV3: action.payload,
        tableVolumeInventoryData:
          prevState.dashboardData?.getVolumesInventory(
            dataForNewTableVolumeInventoryV3
          ) ?? [],
      }

    case SET_VOLUME_INVENTORY_SELECT_SEARCH:
      const dataSearchForNewTableVolumeInventory: Record<string, any> =
        DataHelper.getDataForDashboardFilter(
          prevState.selectedVolumeInventoryFilters,
          action.payload
        )

      return {
        ...prevState,
        search: action.payload,
        tableVolumeInventoryData:
          prevState.dashboardData?.getVolumesInventory(
            dataSearchForNewTableVolumeInventory
          ) ?? [],
      }

    case SET_VOLUME_INVENTORY_SELECT_SEARCH_V3:
      const dataSearchForNewTableVolumeInventoryV3: Record<string, any> =
        DataHelper.getDataForDashboardFilter(
          prevState.selectedVolumeInventoryFiltersV3,
          action.payload
        )

      return {
        ...prevState,
        searchVolumeInventoryV3: action.payload,
        tableVolumeInventoryData:
          prevState.dashboardData?.getVolumesInventory(
            dataSearchForNewTableVolumeInventoryV3
          ) ?? [],
      }

    case SET_S3_BUCKET_INVENTORY_SELECTED_FILTERS:
      const dataForNewTableS3BucketInventoryV3: Record<string, any> =
        DataHelper.getDataForDashboardFilter(
          action.payload,
          prevState.searchS3BucketInventoryV3
        )

      return {
        ...prevState,
        selectedS3BucketInventoryFiltersV3: action.payload,
        tableS3BucketInventoryData:
          prevState.dashboardData?.getS3BucketInventory(
            dataForNewTableS3BucketInventoryV3
          ) ?? [],
      }

    case SET_SNAPSHOT_VULNERABILITY_SELECTED_FILTERS:
      const dataForNewTableSnapshotVulnerability: Record<string, any> =
        DataHelper.getDataForDashboardFilter(action.payload, prevState.search)
      return {
        ...prevState,
        selectedSnapshotVulnerabilitiesFilters: action.payload,
        tableSnapshotVulnerabilitiesData:
          prevState.dashboardData?.getSnapshotVulnerabilities(
            dataForNewTableSnapshotVulnerability
          ) ?? [],
      }

    case SET_SNAPSHOT_VULNERABILITY_SELECT_SEARCH:
      const dataSearchForNewTableSnapshotVulnerability: Record<string, any> =
        DataHelper.getDataForDashboardFilter(
          prevState.selectedSnapshotVulnerabilitiesFilters,
          action.payload
        )

      return {
        ...prevState,
        search: action.payload,
        tableSnapshotVulnerabilitiesData:
          prevState.dashboardData?.getSnapshotVulnerabilities(
            dataSearchForNewTableSnapshotVulnerability
          ) ?? [],
      }

    case SET_EBS_VULNERABILITIES_SELECTED_FILTERS:
      const dataForEbsVulnerabilitiesTable: Record<string, any> =
        DataHelper.getDataForDashboardFilter(action.payload, prevState.search)

      return {
        ...prevState,
        selectedEbsVulnerabilitiesFilters: action.payload,
        tableEbsVulnerabilitiesData:
          prevState.dashboardData?.getEBSVulnerabilities(
            dataForEbsVulnerabilitiesTable
          ) ?? [],
      }

    case SET_EBS_VULNERABILITIES_SELECT_SEARCH:
      const dataSearchForEbsVulnerabilitiesTable: Record<string, any> =
        DataHelper.getDataForDashboardFilter(
          prevState.selectedEbsVulnerabilitiesFilters,
          action.payload
        )

      return {
        ...prevState,
        search: action.payload,
        tableEbsVulnerabilitiesData:
          prevState.dashboardData?.getEBSVulnerabilities(
            dataSearchForEbsVulnerabilitiesTable
          ) ?? [],
      }

    case SET_QUARANTINED_VOLUMES_SELECTED_FILTERS:
      const dataForQuarantinedVolumesTable: Record<string, any> =
        DataHelper.getDataForDashboardFilter(action.payload, prevState.search)

      return {
        ...prevState,
        selectedQuarantinedVolumesFilters: action.payload,
        tableQuarantinedVolumesData:
          prevState.dashboardData?.getQuarantinedVolumes(
            dataForQuarantinedVolumesTable
          ) ?? [],
      }

    case SET_QUARANTINED_VOLUMES_SELECT_SEARCH:
      const dataSearchForQuarantinedVolumesTable: Record<string, any> =
        DataHelper.getDataForDashboardFilter(
          prevState.selectedQuarantinedVolumesFilters,
          action.payload
        )

      return {
        ...prevState,
        search: action.payload,
        tableQuarantinedVolumesData:
          prevState.dashboardData?.getQuarantinedVolumes(
            dataSearchForQuarantinedVolumesTable
          ) ?? [],
      }

    case SET_EC2_INVENTORY_SELECT_SEARCH:
      const dataSearchForEc2Inventory: Record<string, any> =
        DataHelper.getDataForDashboardFilter(
          prevState.selectedEc2InventoryFilters,
          action.payload
        )

      return {
        ...prevState,
        search: action.payload,
        tableEc2InventoryData:
          prevState.dashboardData?.getEc2Inventory(dataSearchForEc2Inventory) ??
          [],
      }

    case SET_EC2_INVENTORY_SELECT_SEARCH_V3:
      const dataSearchForEc2InventoryV3: Record<string, any> =
        DataHelper.getDataForDashboardFilter(
          prevState.selectedEc2InventoryFiltersV3,
          action.payload
        )

      return {
        ...prevState,
        searchEc2InventoryV3: action.payload,
        tableEc2InventoryData:
          prevState.dashboardData?.getEc2Inventory(
            dataSearchForEc2InventoryV3
          ) ?? [],
      }

    case SET_EC2_INVENTORY_SELECTED_FILTERS:
      const dataForForEc2InventoryTable: Record<string, any> =
        DataHelper.getDataForDashboardFilter(action.payload, prevState.search)

      return {
        ...prevState,
        selectedEc2InventoryFilters: action.payload,
        tableEc2InventoryData:
          prevState.dashboardData?.getEc2Inventory(
            dataForForEc2InventoryTable
          ) ?? [],
      }

    case SET_EC2_INVENTORY_SELECTED_FILTERS_V3:
      const dataForEc2InventoryTableV3: Record<string, any> =
        DataHelper.getDataForDashboardFilter(
          action.payload,
          prevState.searchEc2InventoryV3
        )

      return {
        ...prevState,
        selectedEc2InventoryFiltersV3: action.payload,
        tableEc2InventoryData:
          prevState.dashboardData?.getEc2Inventory(
            dataForEc2InventoryTableV3
          ) ?? [],
      }

    case SET_INSTANCES_THREATS_FILTERS:
      const dataForNewTableInstancesThreats: Record<string, any> =
        DataHelper.getDataForDashboardFilter(action.payload, prevState.search)

      return {
        ...prevState,
        selectedInstancesThreatsFilters: action.payload,
        tableInstancesThreatsData:
          prevState.dashboardData?.getInstancesWithActiveThreatsData(
            dataForNewTableInstancesThreats
          ) ?? [],
      }

    case SET_VOLUMES_THREADS_FILTERS:
      const dataForNewTableVolumesThreads: Record<string, any> =
        DataHelper.getDataForDashboardFilter(action.payload, prevState.search)
      return {
        ...prevState,
        selectedVolumesThreatsFilters: action.payload,
        tableVolumesThreatsData:
          prevState.dashboardData?.getVolumesWithActiveThreatsData(
            dataForNewTableVolumesThreads
          ) ?? [],
      }

    case SET_ASSETS_EBS_VULNERABILITIES_SELECTED_FILTERS:
      const dataForNewTableAssetsEbsVulnerabilities: Record<string, any> =
        DataHelper.getDataForDashboardFilter(action.payload, prevState.search)
      return {
        ...prevState,
        selectedVolumesVulnerabilitiesFilters: action.payload,
        tableVolumesVulnerabilitiesData:
          prevState.dashboardData?.getVolumesWithVulnerabilitiesData(
            dataForNewTableAssetsEbsVulnerabilities,
            new VulnerabilitiesDetectionStrategy()
          ) ?? [],
      }

    case SET_SNAPSHOTS_VULNERABILITIES_SELECTED_FILTERS:
      const dataForNewTableSnapshotsVulnerabilities: Record<string, any> =
        DataHelper.getDataForDashboardFilter(action.payload, prevState.search)
      return {
        ...prevState,
        selectedSnapshotsVulnerabilitiesFilters: action.payload,
        tableSnapshotsVulnerabilitiesData:
          prevState.dashboardData?.getSnapshotsWithVulnerabilitiesData(
            dataForNewTableSnapshotsVulnerabilities as SnapshotsVulnerabitiesFilters,
            new VulnerabilitiesDetectionStrategy()
          ) ?? [],
      }

    case SET_RECOVERY_EXPOSURE_FILTERS:
      const dataForNewTableRecoveryExposure: Record<string, any> =
        DataHelper.getDataForDashboardFilter(action.payload, prevState.search)
      return {
        ...prevState,
        selectedVolumesExposureFilters: action.payload,
        tableVolumesExposureData:
          prevState.dashboardData?.getVolumesWithRecoveryExposureData(
            dataForNewTableRecoveryExposure
          ) ?? [],
      }

    default:
      return prevState
  }
}

export default dashboardReducer
