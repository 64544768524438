/* eslint-disable import/no-extraneous-dependencies */
import { GrpcClient } from '@lib/clients/grpc-client'
import {
  KrabsGetOvaAccountSummariesRequestPb,
  GetOvaDataScannedStatisticsRequestPb,
  GetOvaFilesScannedStatisticsRequestPb,
  KrabsOvaStatisticsPromiseClient,
} from '@lib/clients/grpc-imports'
import { OvaBackupProvider } from 'blues-corejs/dist/models/ova-backup-provider'
import { OvaBackupProvider as OvaBackupProviderProto } from 'blue-stack-libs/krabs-grpc-libs/js/krabs/krabs_pb'
import {
  AccountSummaryResponse,
  GetDataScannedStatisticsResponse,
} from './types'

interface OvaStatisticsRequestParams {
  ovaAccountIds: Array<string>
  backupProviders: Array<OvaBackupProvider>
}

export class KrabsOvaStatisticsClient extends GrpcClient<KrabsOvaStatisticsPromiseClient> {
  #krabsOvaStatisticsClient: KrabsOvaStatisticsPromiseClient

  constructor(hostName = '') {
    super()
    this.#krabsOvaStatisticsClient = this.getClient(hostName)
  }

  protected innerClientTypeId(): string {
    return 'KrabsOvaStatisticsPromiseClient'
  }

  protected initClient(hostName = ''): KrabsOvaStatisticsPromiseClient {
    return new KrabsOvaStatisticsPromiseClient(hostName, null, null)
  }

  async getFilesScannedStatistics(
    params: OvaStatisticsRequestParams
  ): Promise<number> {
    const request = new GetOvaFilesScannedStatisticsRequestPb()

    request.setOvaAccountIdsList(params.ovaAccountIds)

    request.setBackupProvidersList(
      params.backupProviders.map((provider) =>
        this.#convertBackupProviderEnumToProto(provider)
      )
    )

    const response = (
      await this.retryGrpcCall(
        () =>
          this.#krabsOvaStatisticsClient.getFilesScannedStatistics(
            request,
            this.metadata()
          ),
        {
          requestName:
            'KrabsOvaStatisticsPromiseClient/getFilesScannedStatistics',
        }
      )
    ).toObject()

    return response.statistics?.filesScans || 0
  }

  async getDataScannedStatistics(
    params: OvaStatisticsRequestParams
  ): Promise<GetDataScannedStatisticsResponse> {
    const request = new GetOvaDataScannedStatisticsRequestPb()

    request.setOvaAccountIdsList(params.ovaAccountIds)

    request.setBackupProvidersList(
      params.backupProviders.map((provider) =>
        this.#convertBackupProviderEnumToProto(provider)
      )
    )

    const response = (
      await this.retryGrpcCall(
        () =>
          this.#krabsOvaStatisticsClient.getDataScannedStatistics(
            request,
            this.metadata()
          ),
        {
          requestName:
            'KrabsOvaStatisticsPromiseClient/getDataScannedStatistics',
        }
      )
    ).toObject()

    return {
      dataScanned: response.statistics?.dataScanned || 0,
      frontEndDataProtected: response.statistics?.frontEndDataProtected || 0,
      rpsScanned: response.statistics?.rpsScanned || 0,
    }
  }

  async listAccountSummaries(
    params: OvaStatisticsRequestParams
  ): Promise<Array<AccountSummaryResponse>> {
    const request = new KrabsGetOvaAccountSummariesRequestPb()

    const filter = new KrabsGetOvaAccountSummariesRequestPb.Filter()

    filter.setOvaAccountIdsList(params.ovaAccountIds)

    filter.setBackupProvidersList(
      params.backupProviders.map((provider) =>
        this.#convertBackupProviderEnumToProto(provider)
      )
    )

    request.setFilter(filter)

    const response = (
      await this.retryGrpcCall(
        () =>
          this.#krabsOvaStatisticsClient.listAccountSummaries(
            request,
            this.metadata()
          ),
        {
          requestName: 'KrabsOvaStatisticsPromiseClient/listAccountSummaries',
        }
      )
    ).toObject()

    return response.accountSummariesList.map((accountSummary) => ({
      ovaAccountId: accountSummary.ovaAccountId,
      ovaAccountName: accountSummary.ovaAccountName,
      frontEndDataProtected: accountSummary.frontEndDataProtected,
      dataScanned: accountSummary.dataScanned,
    }))
  }

  #convertBackupProviderEnumToProto(
    backupProvider: OvaBackupProvider
  ): OvaBackupProviderProto {
    const mapping: Record<OvaBackupProvider, OvaBackupProviderProto> = {
      [OvaBackupProvider.VEEAM]: OvaBackupProviderProto.VEEAM,
      [OvaBackupProvider.COMMVAULT]: OvaBackupProviderProto.COMMVAULT,
      [OvaBackupProvider.RUBRIK]: OvaBackupProviderProto.RUBRIK,
      [OvaBackupProvider.COHESITY]: OvaBackupProviderProto.COHESITY,
      [OvaBackupProvider.VERITAS_NET_BACKUP]:
        OvaBackupProviderProto.VERITAS_NET_BACKUP,
    }

    return mapping[backupProvider]
  }
}
