import BaseDataConstant from '@lib/constants/data/base-data.constant'
import SystemHelper from '@lib/helpers/system.helper'

export interface AuthProviderData {
  label: string
  logo: string
  value: number
}

class AuthProviderConstantInner extends BaseDataConstant<AuthProviderData> {
  get PROVIDER_UNDEFINED(): AuthProviderData {
    return {
      label: 'Undefined',
      logo: '/images/auth-providers/empty.svg',
      value: -1,
    }
  }

  get PROVIDER_PASSWORD(): AuthProviderData {
    return {
      label: 'Username-Password',
      logo: '/images/auth-providers/empty.svg',
      value: 1,
    }
  }

  get PROVIDER_GOOGLE(): AuthProviderData {
    return {
      label: 'Google OAuth 2',
      logo: '/images/auth-providers/google.svg',
      value: 2,
    }
  }

  get PROVIDER_MICROSOFT(): AuthProviderData {
    return {
      label: 'Windows Live',
      logo: '/images/auth-providers/microsoft.svg',
      value: 3,
    }
  }

  getByAuth0Id(value: string): AuthProviderData {
    if (/google-oauth2/i.test(value)) {
      return this.PROVIDER_GOOGLE
    }
    if (/windowslive/i.test(value)) {
      return this.PROVIDER_MICROSOFT
    }
    if (/auth0/i.test(value)) {
      return this.PROVIDER_PASSWORD
    }

    SystemHelper.throwErrorInLocalEnv('Cannot parse the auth provider')
    return this.PROVIDER_UNDEFINED
  }
}

const AuthProviderConstant = AuthProviderConstantInner.build(
  AuthProviderConstantInner,
  'PROVIDER_'
)

export default AuthProviderConstant
