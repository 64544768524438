"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.S3Bucket = void 0;
const aws_asset_1 = require("../aws-asset");
class S3Bucket extends aws_asset_1.AWSAsset {
    #objectCount;
    #size;
    #state;
    #retentionPolicy;
    constructor(parameters) {
        const { objectCount, retentionPolicy, size, state, ...awsAssetParameters } = parameters;
        super(awsAssetParameters);
        this.#state = state;
        this.#size = size;
        this.#objectCount = objectCount;
        this.#retentionPolicy = Object.freeze([...retentionPolicy]);
    }
    get objectCount() {
        return this.#objectCount;
    }
    get size() {
        return this.#size;
    }
    get retentionPolicy() {
        return [...this.#retentionPolicy];
    }
    get state() {
        return this.#state;
    }
    get canBeDirectlyScanned() {
        return false;
    }
}
exports.S3Bucket = S3Bucket;
