import { useState, useEffect, useRef } from 'react'
import { ProviderProps, ProviderComponent } from '@lib/engine-types'
import { initializeLDProvider } from './utilities'

export function LaunchDarklyFeatureFlagProvider({ children }: ProviderProps) {
  const isLDInitialized = useRef<boolean>(false)
  const [LDProvider, setLDProvider] = useState<ProviderComponent | null>(null)

  useEffect(() => {
    async function setupLD() {
      if (!isLDInitialized.current) {
        const provider = await initializeLDProvider()
        setLDProvider(() => provider)
        isLDInitialized.current = true
      }
    }
    setupLD()
  }, [])

  if (!LDProvider) {
    return <>{children}</>
  }

  return <LDProvider>{children}</LDProvider>
}
