/* eslint-disable import/no-extraneous-dependencies */
import { LiveAssets } from '@lib/clients/assets/types'
import ActionInterface from '@lib/interfaces/action.interface'
import { ManagementView } from '@lib/models/management-view'
import {
  SET_LIVE_ASSETS_LOADING,
  SET_LIVE_ASSETS_STATE,
  SET_SLICE,
  SET_TENANT_STATISTICS,
  SET_TENANT_STATISTICS_LOADING,
} from '@store/actions/list-live-assets.action'
import { LiveAssetsState } from '@store/states/list-live-assets.state'
import { TenantStatisticsModel } from 'blues-corejs/dist'
import { INITIAL_STATE } from './initial-state'

function liveAssetsReducer(
  prevState: LiveAssetsState = INITIAL_STATE,
  action: ActionInterface
): LiveAssetsState {
  switch (action.type) {
    case SET_LIVE_ASSETS_STATE: {
      const { liveAssetsResponse, tenantStatistics } = action.payload as {
        liveAssetsResponse: LiveAssets
        tenantStatistics: TenantStatisticsModel
      }

      const managementView = new ManagementView({
        ebsSnapshots: liveAssetsResponse.lastEbsSnapshots,
        ebsVolumes: liveAssetsResponse.ebsVolumes,
        ec2Instances: liveAssetsResponse.ec2Instances,
        filesystems: liveAssetsResponse.efs,
        s3Buckets: liveAssetsResponse.s3Buckets,
        genericHosts: liveAssetsResponse.genericHosts,
        tenantStatistics: tenantStatistics,
      })

      const threats = [...liveAssetsResponse.threats]
      const scans = [...liveAssetsResponse.scans]

      return {
        ...prevState,
        threats,
        scans,
        lastBackups: liveAssetsResponse.lastBackups,
        firstBackups: liveAssetsResponse.firstBackups,
        lastEbsSnapshots: liveAssetsResponse.lastEbsSnapshots,
        managementView,
      }
    }
    case SET_SLICE: {
      return {
        ...prevState,
        slice: action.payload,
      }
    }

    case SET_LIVE_ASSETS_LOADING: {
      return {
        ...prevState,
        isLiveAssetsLoading: action.payload,
      }
    }

    case SET_TENANT_STATISTICS: {
      return {
        ...prevState,
        tenantStatistics: action.payload,
      }
    }

    case SET_TENANT_STATISTICS_LOADING: {
      return {
        ...prevState,
        isTenantStatisticsLoading: action.payload,
      }
    }

    default:
      return prevState
  }
}

export default liveAssetsReducer
