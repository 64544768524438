/* eslint-disable import/no-extraneous-dependencies */
import { ListTenantStatisticsResponse } from '@lib/clients/tenant-statistics/types'
import { GetStorageReductionMetricsResponse } from 'blue-stack-libs/krabs-grpc-libs/js/krabs/krabs_pb'
import { TenantStatisticsModel } from 'blues-corejs/dist'

type VaultMetrics =
  GetStorageReductionMetricsResponse.VaultStorageReductionMetrics.AsObject

export class TenantStatisticsFactory {
  build({
    vaultMetricsList,
    tenantStatistics,
  }: {
    tenantStatistics?: ListTenantStatisticsResponse
    vaultMetricsList?: Array<VaultMetrics>
  }) {
    if (!tenantStatistics || !vaultMetricsList) {
      return new TenantStatisticsModel({
        malwareStats: [],
        ransomwareStats: [],
        fsCheckStats: [],
        vaultStatistics: [],
      })
    }

    return new TenantStatisticsModel({
      fsCheckStats: tenantStatistics.fsCheckScansList,
      malwareStats: tenantStatistics.malwareScansList,
      ransomwareStats: tenantStatistics.ransomwareScansList,
      vaultStatistics: this.#processTenantVaultStatistics(vaultMetricsList),
    })
  }

  #processTenantVaultStatistics(vaultMetricsList: Array<VaultMetrics>) {
    if (vaultMetricsList === null) {
      return []
    }

    return vaultMetricsList.map((metric) => {
      return {
        ...metric,
        accountId: metric.awsAccountId,
      }
    })
  }
}
