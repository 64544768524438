/* eslint-disable import/no-extraneous-dependencies */
import {
  sentryReThrowCatchHandler,
  toastRequestScenario,
} from '@store/epics/epic-func'
import PreloaderConstants from '@lib/constants/preloader.constant'
import GeneralService from '@lib/services/general.service'
import { emptyAction } from '@store/actions/default/empty.action'
import { ADD_RETENTION_POLICY } from '@store/actions/add-retention-policy.action'
import {
  getAddRetentionPolicyName,
  getPolicyRetentionAccountIds,
  getPolicyRetentionAssetSources,
  getPolicyRetentionBackupTypes,
  getPolicyRetentionRegions,
  getPolicyRetentionTags,
  getPolicyRetentionVaults,
  getRecoveryPeriod,
} from '@store/selectors/add-retention-policies.selector'
import GrpcRetentionPoliciesService from '@lib/services/grpc/grpc-retention-policies.service'
import { getSelectedAssetsSelect } from '@store/selectors/assets-select.selector'

export const addRetentionPolicyEpic = (action$: any, state$: any) =>
  action$.pipe(
    toastRequestScenario(
      [ADD_RETENTION_POLICY],
      PreloaderConstants.ADD_RETENTION_POLICY,
      () => {
        const retentionPolicyName = getAddRetentionPolicyName(state$.value)
        const recoveryPeriod = getRecoveryPeriod(state$.value)
        const selectedAssets = getSelectedAssetsSelect(state$.value)
        const policyRetentionAccountIds = getPolicyRetentionAccountIds(
          state$.value
        )
        const policyRetentionRegions = getPolicyRetentionRegions(state$.value)
        const policyRetentionVaults = getPolicyRetentionVaults(state$.value)
        const policyRetentionBackupTypes = getPolicyRetentionBackupTypes(
          state$.value
        )
        const policyRetentionAssetSources = getPolicyRetentionAssetSources(
          state$.value
        )
        const policyRetentionTags = getPolicyRetentionTags(state$.value)

        return GrpcRetentionPoliciesService.createRetentionPolicy(
          retentionPolicyName,
          recoveryPeriod,
          selectedAssets,
          policyRetentionAccountIds,
          policyRetentionRegions,
          policyRetentionVaults,
          policyRetentionBackupTypes,
          policyRetentionAssetSources,
          policyRetentionTags
        ).catch(sentryReThrowCatchHandler('Cannot create a retention policy'))
      },
      () => {
        // possible return to the OnBoarding page
        // or to the Policies list page
        GeneralService.navigateBack()
        return emptyAction()
      },
      () => 'The Retention Policy has been created',
      () => 'Error during the Retention Policy creation'
    )
  )
