import SourceIdState from '@store/states/source-id.state'
import ActionInterface from '@lib/interfaces/action.interface'
import {
  CLEAN_SOURCE_ID_FORM,
  INIT_SOURCE_ID,
  MARK_REGIONS_FOR_INSTALLATION,
  MARK_REGIONS_FOR_REMOVED,
  RESET_SOURCE_ID_SELECTION,
  SET_ACCOUNT_ALIAS_NAME,
  SET_ACCOUNT_DESCRIPTION,
  SET_ACCOUNT_RED_STACK_ID,
  SET_SOURCE_ID_TABLE_DATA,
  SET_COST_FOR_ACCOUNT,
} from '@store/actions/source-id.action'
import ObjHelper from '@lib/helpers/obj.helper'
import { VIMatrix, VIRow } from '@lib/engine-types'
import ValueInterface from '@lib/interfaces/value.interface'
import RedStackStatusConstant from '@lib/constants/red-stack-status.constant'

const initialState: SourceIdState = {
  accountAliasName: '',
  accountDescription: '',
  dataForInstallation: [],
  rsIdForRemoved: [],
  isFormTouched: false,
  sourceIdTableData: [],
  accountRedStackId: '',
  accountId: '',
  statusLabel: '',
  costForAccount: [],
}

function sourceIdReducer(
  prevState: SourceIdState = initialState,
  action: ActionInterface
): SourceIdState {
  let newDataForInstallation: Array<ValueInterface>
  let newRsIdForRemoved: Array<string>
  let redStackId: string
  let index: number
  let newSourceIdTableData: VIMatrix
  let selectedOption: ValueInterface
  let statusLabel: string
  let accountId: string
  let region: string
  let valueCheckbox: boolean
  let defaultValueCheckbox: boolean
  let originalStatus: RedStackStatusConstant
  let selectedSubOptions: VIRow

  switch (action.type) {
    case SET_ACCOUNT_ALIAS_NAME:
      return {
        ...prevState,
        accountAliasName: action.payload,
        isFormTouched: true,
      }

    case SET_ACCOUNT_DESCRIPTION:
      return {
        ...prevState,
        accountDescription: action.payload,
        isFormTouched: true,
      }

    case MARK_REGIONS_FOR_INSTALLATION:
      redStackId = action.payload.redStackId
      accountId = action.payload.accountId
      region = action.payload.region
      statusLabel = action.payload.label
      valueCheckbox = action.payload.checkboxValue
      defaultValueCheckbox = action.payload.defaultValueCheckbox
      selectedOption = action.payload.selectedOption
      newRsIdForRemoved = prevState.rsIdForRemoved
      originalStatus = action.payload.originalStatus
      selectedSubOptions = action.payload.selectedSubOptions

      newDataForInstallation = prevState.dataForInstallation.filter(
        (obj) => obj.extraValue !== region
      )

      if (valueCheckbox === defaultValueCheckbox) {
        newRsIdForRemoved = prevState.rsIdForRemoved.filter(
          (v: string) => v !== redStackId
        )
        statusLabel =
          originalStatus === RedStackStatusConstant.UPGRADE_REQUIRED
            ? 'Connected (Upgrade Required)'
            : 'Connected'
      } else {
        newDataForInstallation.push({
          name: accountId,
          extraValue: region,
          selectedOption,
          options: selectedSubOptions,
        })
        statusLabel = 'Going to be installed'
      }

      // update the visual table representation
      newSourceIdTableData = prevState.sourceIdTableData
      index = newSourceIdTableData.findIndex((v) => v[0]?.name === region)
      if (index !== -1) {
        const subnetsValues = newSourceIdTableData[index]?.[1]?.matrix
        if (subnetsValues) {
          const svForOtherRegionVpc = subnetsValues[1]?.filter(
            (elem) =>
              elem.defaultValue !== selectedOption.name &&
              elem.placeholder === region
          )
          subnetsValues[1] = [
            ...(svForOtherRegionVpc ?? []),
            ...selectedSubOptions,
          ]
        }
        newSourceIdTableData = ObjHelper.cloneDeep(prevState.sourceIdTableData)
        const elem = newSourceIdTableData[index]?.[1]
        if (elem) {
          elem.value = valueCheckbox
          elem.label = statusLabel
          elem.selectedOption = selectedOption
          elem.matrix = subnetsValues
        }
      }

      return {
        ...prevState,
        sourceIdTableData: newSourceIdTableData,
        dataForInstallation: newDataForInstallation,
        rsIdForRemoved: newRsIdForRemoved,
      }

    case MARK_REGIONS_FOR_REMOVED:
      redStackId = action.payload.redStackId
      region = action.payload.region
      valueCheckbox = action.payload.checkboxValue
      defaultValueCheckbox = action.payload.defaultValueCheckbox
      statusLabel = action.payload.label
      newDataForInstallation = prevState.dataForInstallation
      originalStatus = action.payload.originalStatus

      newRsIdForRemoved = prevState.rsIdForRemoved.filter(
        (v: string) => v !== redStackId
      )
      if (valueCheckbox === defaultValueCheckbox) {
        newDataForInstallation = prevState.dataForInstallation.filter(
          (obj) => obj.extraValue !== region
        )

        statusLabel =
          originalStatus === RedStackStatusConstant.UPGRADE_REQUIRED
            ? 'Connected (Upgrade Required)'
            : 'Disconnected'
      } else {
        newRsIdForRemoved.push(redStackId)
        statusLabel = 'Going to be removed'
      }

      // update the visual table representation
      newSourceIdTableData = prevState.sourceIdTableData
      index = newSourceIdTableData.findIndex((v) => v[0]?.name === region)
      if (index !== -1) {
        newSourceIdTableData = ObjHelper.cloneDeep(prevState.sourceIdTableData)
        const newSourceIdTableDataElem = newSourceIdTableData[index]?.[1]
        if (newSourceIdTableDataElem) {
          newSourceIdTableDataElem.value = valueCheckbox
          newSourceIdTableDataElem.label = statusLabel
        }
      }

      return {
        ...prevState,
        sourceIdTableData: newSourceIdTableData,
        rsIdForRemoved: newRsIdForRemoved,
        dataForInstallation: newDataForInstallation,
      }

    case SET_SOURCE_ID_TABLE_DATA:
      return {
        ...prevState,
        sourceIdTableData: action.payload,
      }

    case RESET_SOURCE_ID_SELECTION:
      return {
        ...prevState,
        dataForInstallation: [],
        rsIdForRemoved: [],
        isFormTouched: false,
      }

    case CLEAN_SOURCE_ID_FORM:
      return {
        ...prevState,
        accountAliasName: '',
        accountDescription: '',
      }

    case SET_ACCOUNT_RED_STACK_ID:
      return {
        ...prevState,
        accountRedStackId: action.payload,
      }

    case INIT_SOURCE_ID:
      return {
        ...prevState,
        accountId: action.payload,
      }

    case SET_COST_FOR_ACCOUNT:
      return {
        ...prevState,
        costForAccount: action.payload,
      }

    default:
      return prevState
  }
}

export default sourceIdReducer
