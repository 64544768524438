import { TenantStatisticsPromiseClient } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui_api_grpc_web_pb'
import { KrabsPromiseClient } from 'blue-stack-libs/krabs-grpc-libs/js/krabs/krabs_grpc_web_pb'
import {
  GetStorageReductionMetricsRequest,
  GetStorageReductionMetricsResponse,
} from 'blue-stack-libs/krabs-grpc-libs/js/krabs/krabs_pb'
import { GrpcClient } from '../grpc-client/grpc-client'
import { ListTenantStatisticsResponse } from './types'
import { Request as TenantStatisticsRequest } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/tenant_statistics/get_tenant_statistics_pb'

export class TenantStatisticsClient extends GrpcClient<TenantStatisticsPromiseClient> {
  #tenantStatisticsClient: TenantStatisticsPromiseClient

  #krabsClient: KrabsPromiseClient

  constructor(hostName = '') {
    super()
    this.#tenantStatisticsClient = this.getClient(hostName)
    this.#krabsClient = new KrabsPromiseClient(hostName, null, null)
  }

  protected innerClientTypeId(): string {
    return 'TenantStatisticsClient'
  }

  protected initClient(hostName = ''): TenantStatisticsPromiseClient {
    return new TenantStatisticsPromiseClient(hostName, null, null)
  }

  async getTenantStatistics(): Promise<ListTenantStatisticsResponse> {
    const response = (
      await this.retryGrpcCall(
        () =>
          this.#tenantStatisticsClient.listTenantStatistics(
            new TenantStatisticsRequest(),
            this.metadata()
          ),
        {
          requestName: 'TenantStatisticsPromiseClient/listTenantStatistics',
        }
      )
    ).toObject()

    return {
      fsCheckScansList: response.tenantStatistics?.fsCheckScansList || [],
      malwareScansList: response.tenantStatistics?.malwareScansList || [],
      ransomwareScansList: response.tenantStatistics?.ransomwareScansList || [],
    }
  }

  async getStorageReductionMetrics(): Promise<GetStorageReductionMetricsResponse.AsObject> {
    const request = await this.retryGrpcCall(
      () =>
        this.#krabsClient.getStorageReductionMetrics(
          new GetStorageReductionMetricsRequest(),
          this.metadata()
        ),
      {
        requestName: 'KrabsPromiseClient/getStorageReductionMetrics',
      }
    )

    return request.toObject()
  }
}
