import React, { useState } from 'react'
import styles from './topNavigationBar.module.scss'
import ElastioLogoWhiteIcon from '@inline-img/general/elastio-logo-white-icon'
import UserIcon from '@inline-img/general/nav-bar/user-icon'
import MenuIcon from '@inline-img/general/nav-bar/menu-icon'
import SettingIcon from '@inline-img/general/nav-bar/setting-icon'
import QuestionMarkWhiteIcon from '@inline-img/general/nav-bar/question-mark-white-icon'
import BellWithGreenDotIcon from '@inline-img/general/nav-bar/bell-with-green-dot-icon'
import PagePathInterface from '@lib/interfaces/page-path.interface'
import {
  listClasses,
  Menu,
  menuClasses,
  MenuItem,
  menuItemClasses,
  Slide,
  Tooltip,
} from '@mui/material'
import Divider from '@mui/material/Divider'
import Link from 'next/link'
import { useSelector } from 'react-redux'
import { getUser } from '@store/selectors/user.selector'
import useDemo from '@lib/hooks/useDemo'
import StoreService from '@lib/services/store.service'
import Image from 'next/image'
import ExitIcon from '@inline-img/general/nav-bar/exit-icon'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'
import {
  SETTING_MENU,
  USER_DEMO_MENU,
  USER_MENU,
} from '@lib/constants/menu.constant'
import clsx from 'clsx'
import { getEnginePageMeta } from '@store/selectors/engine.selector'
import { useLayoutContext } from '@features/contexts'
import { PagePathConstant } from '@lib/constants'
import { MainMenu } from '@features/MainMenu'

const dividerStyle = {
  borderColor: '#999caa',
  opacity: 0.2,
  marginBottom: '10px',
}

const menuStyled = {
  [`& .${menuClasses.paper}`]: {
    backgroundColor: '#1a1f28',
    borderRadius: 0,
    padding: '8px 4px 0 4px!important',
    width: '200px',
    top: '68px !important',
    right: '0 !important',
    left: 'initial !important',
  },
  [`& .${menuClasses.list}`]: {
    backgroundColor: '#1a1f28',
    color: '#f2f2f2',
  },
  [`& .${listClasses.root}.${menuClasses.list} .${menuItemClasses.root}`]: {
    backgroundColor: '#1a1f28',
    fontSize: 14,
    fontWeight: 600,
    padding: '10px 16px 10px 8px !important',
    marginBottom: '8px',
  },
  [`& .${listClasses.root}.${menuClasses.list} .${menuItemClasses.root}:hover`]:
    {
      color: '#32b6f3',
      backgroundColor: '#1a1f28',
    },
}

const menuMainStyled = {
  [`& .${menuClasses.paper}`]: {
    backgroundColor: '#1a1f28',
    borderRadius: 0,
    padding: '8px 4px 0 4px!important',
    width: '230px',
    top: '68px !important',
    right: '0 !important',
    left: 'initial !important',
  },
  [`& .${menuClasses.list}`]: {
    backgroundColor: '#1a1f28',
    color: '#f2f2f2',
  },
  [`& .${listClasses.root}.${menuClasses.list} .${menuItemClasses.root}`]: {
    backgroundColor: '#1a1f28',
    fontSize: 14,
    fontWeight: 600,
    padding: '10px 16px 10px 8px !important',
    marginBottom: '8px',
  },
  [`& .${listClasses.root}.${menuClasses.list} .${menuItemClasses.root}:hover`]:
    {
      color: '#32b6f3',
      backgroundColor: '#1a1f28',
    },
}

function isMenuSelected(subPath: string, fullPath: string) {
  const normalize = (v: string): string => v.replace(/\/$/, '')
  // slash normalizing
  const finalSubPath = normalize(subPath)
  const finalFullPath = normalize(fullPath)
  // check the beginning of the path
  const re = new RegExp(`^${finalSubPath}`)

  return re.test(finalFullPath)
}

interface Props {
  title: string
  showOnlyOnboarding?: boolean
}

function TopNavigationBar({ title = '', showOnlyOnboarding = false }: Props) {
  const { headerTitle } = useLayoutContext()
  const pageMeta = useSelector(getEnginePageMeta)
  const user = useSelector(getUser)
  const [anchorSettingEl, setAnchorSettingEl] = useState<null | HTMLElement>(
    null
  )
  const [anchorUserEl, setAnchorUserEl] = useState<null | HTMLElement>(null)
  const [anchorMainEl, setAnchorMainEl] = useState<null | HTMLElement>(null)

  const settingMenu = SETTING_MENU()
  const [userMenu, setUserMenu] = useState<Array<PagePathInterface>>(
    USER_MENU()
  )

  const { modeActive } = useDemo({
    runIfModeActive: () => {
      setUserMenu(USER_DEMO_MENU())
    },
  })

  const handleMenuSettingClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorSettingEl(event.currentTarget)
  }

  const handleMenuSettingClose = () => {
    setAnchorSettingEl(null)
  }

  const handleMenuUserClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorUserEl(event.currentTarget)
  }

  const handleMenuUserClose = () => {
    setAnchorUserEl(null)
  }

  const handleMenuMainClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorMainEl(event.currentTarget)
  }

  const handleMenuMainClose = () => {
    setAnchorMainEl(null)
  }

  const onSignOutClick = () => {
    StoreService.logout()
  }

  return (
    <div
      className={styles.topNavigationBar}
      data-testid="top-navigation-bar-testid"
    >
      <div className={styles.iconTitleWrap}>
        <div
          className={styles.elastioIconWrap}
          data-testid="elastio-logo-nav-bar-testid"
        >
          <Link href={PagePathConstant.DASHBOARD}>
            <a>
              <ElastioLogoWhiteIcon />
            </a>
          </Link>
        </div>
        <div className={styles.title} data-testid="title-nav-bar-testid">
          {headerTitle ?? title}
        </div>
      </div>
      <div className={styles.groupIconWrap}>
        {modeActive ? <BellWithGreenDotIcon /> : <div />}
        <Link href="#">
          <a
            id="intercom_custom_link"
            className={styles.iconNavBarWrap}
            data-testid="intercom-icon-testid"
          >
            <Tooltip placement="top" title="Get support">
              <div>
                <QuestionMarkWhiteIcon />
              </div>
            </Tooltip>
          </a>
        </Link>
        <div onClick={handleMenuSettingClick} className={styles.iconNavBarWrap}>
          <SettingIcon />
        </div>

        <div onClick={handleMenuMainClick} className={styles.iconNavBarWrap}>
          <MenuIcon />
        </div>

        <span className={styles.userIconDivider}></span>
        <div
          onClick={handleMenuUserClick}
          className={`${styles.iconNavBarWrap} jsTopMenuUser`}
        >
          <UserIcon />
        </div>
      </div>

      {/*SETTING MENU*/}
      <Menu
        id="setting-menu"
        data-testid="setting-menu-testid"
        anchorEl={anchorSettingEl}
        keepMounted
        open={Boolean(anchorSettingEl)}
        onClick={handleMenuSettingClose}
        onClose={handleMenuSettingClose}
        TransitionComponent={Slide}
        TransitionProps={{
          // @ts-ignore
          direction: 'left',
        }}
        transformOrigin={{
          vertical: -15,
          horizontal: 0,
        }}
        sx={menuStyled}
        disableScrollLock={true}
      >
        {settingMenu.map((page: PagePathInterface, index) => (
          <Link href={page.path} key={index}>
            <a
              className={clsx(`${styles.link}`, {
                [String(styles.selectedMenu)]: isMenuSelected(
                  page.path,
                  pageMeta.path
                ),
              })}
            >
              <MenuItem key={page.path} className={styles.menuItem}>
                {page.icon}
                {page.name}
              </MenuItem>
            </a>
          </Link>
        ))}
      </Menu>

      {/*USER MENU*/}
      <Menu
        id="user-menu"
        data-testid="user-menu-testid"
        anchorEl={anchorUserEl}
        keepMounted
        open={Boolean(anchorUserEl)}
        onClick={handleMenuUserClose}
        onClose={handleMenuUserClose}
        TransitionComponent={Slide}
        TransitionProps={{
          // @ts-ignore
          direction: 'left',
        }}
        transformOrigin={{
          vertical: -15,
          horizontal: 0,
        }}
        sx={menuStyled}
        disableScrollLock={true}
      >
        <div className={styles.userImgNameWrap}>
          {user.isNotEmpty && (
            <>
              <div className={styles.userImg}>
                <Image width={40} height={40} src={user.picture} />
              </div>
              <LongTextTooltip text={`Hi ${user.name}`} maxLength={15} />
            </>
          )}
        </div>
        <Divider sx={dividerStyle} />
        {userMenu.map((page: PagePathInterface, index) => (
          <Link href={page.path} key={index}>
            <a
              className={clsx(`${styles.link} ${page.class}`, {
                [String(styles.selectedMenu)]: isMenuSelected(
                  page.path,
                  pageMeta.path
                ),
              })}
            >
              <MenuItem key={page.path} className={styles.menuItem}>
                {page.icon}
                {page.name}
              </MenuItem>
            </a>
          </Link>
        ))}
        <Divider sx={dividerStyle} />
        <MenuItem onClick={onSignOutClick} className={`${styles.menuItem}`}>
          <ExitIcon />
          <div data-testid="exit-testid">Exit</div>
        </MenuItem>
      </Menu>

      {/* MAIN MENU */}
      <Menu
        id="main-menu"
        data-testid="main-menu-testid"
        anchorEl={anchorMainEl}
        keepMounted
        open={Boolean(anchorMainEl)}
        onClose={handleMenuMainClose}
        TransitionComponent={Slide}
        TransitionProps={{
          // @ts-ignore
          direction: 'left',
        }}
        transformOrigin={{
          vertical: -15,
          horizontal: 0,
        }}
        sx={menuMainStyled}
        disableScrollLock={true}
      >
        <MainMenu
          showOnlyOnboarding={showOnlyOnboarding}
          handleMenuMainClose={handleMenuMainClose}
        />
      </Menu>
    </div>
  )
}

export default TopNavigationBar
